import React, { useEffect } from 'react'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';
import { toast } from 'react-toastify';


export default function Conditionreport13() {
    const urlParams = new URLSearchParams(window.location.search);
    const [driversidecornerimgupload, setdriversidecorner] = useState("");
    const [passangersidecornerimgupload, setpassangersidecorner] = useState("");
    const [passangerrearsidecornerimgupload, setpassangerrearsidecorner] = useState("");
    const [setdriverrearsidecornerimgupload, setdriverrearsidecorner] = useState("");
    const [Interiorimgupload, setInterior] = useState("");
    
    const [Gaugesimgupload, setGauges] = useState("");

    const [Damage1image, setDamage1image] = useState("");
    const [Damage2image, setDamage2image] = useState("");
    const [Damage3image, setDamage3image] = useState("");
    const [loader,setLoader]=useState(1)

    //image file 


    const [driversidecornerimguploadfile, setdriversidecornerfile] = useState("");
    const [passangersidecornerimguploadfile, setpassangersidecornerfile] = useState("");
    const [passangerrearsidecornerimguploadfile, setpassangerrearsidecornerfile] = useState("");
    const [driverrearsidecornerimguploadfile, setdriverrearsidecornerfile] = useState("");
    const [Interiorimguploadfile, setInteriorfile] = useState("");
    
    const [Gaugesimguploadfile, setGaugesfile] = useState("");

    const [Damage1imagefile, setDamage1imagefile] = useState("");
    const [Damage2imagefile, setDamage2imagefile] = useState("");
    const [Damage3imagefile, setDamage3imagefile] = useState("");

    //end image file 

    var Image=[];
    //driver 
    const driversidecorner = (e) => {
      console.log(e)
      const [file] = e.target.files;
      setdriversidecornerfile(e.target.files[0])
    setdriversidecorner(URL.createObjectURL(file));
 
    };

    const driverrearsidecorner = (e) => {
        const [file] = e.target.files;
        setdriverrearsidecornerfile(e.target.files[0])
      setdriverrearsidecorner(URL.createObjectURL(file));
   
      };


    //end driver side

    //passanger side
    const passangerrearsidecorner = (e) => {
        const [file] = e.target.files;
        setpassangerrearsidecornerfile(e.target.files[0])
    
      //   setImgupload(e.target.files[0]);
    
      setpassangerrearsidecorner(URL.createObjectURL(file));
    
      };





      const passangersidecorner = (e) => {
        const [file] = e.target.files;
        setpassangersidecornerfile(e.target.files[0])
    
      //   setImgupload(e.target.files[0]);
    
      setpassangersidecorner(URL.createObjectURL(file));
    
      };


     

      //end passanger side



      //Interior
      const  interior_function = (e) => {
        const [file] = e.target.files;
        setInteriorfile(e.target.files[0])
      setInterior(URL.createObjectURL(file));
     
      };


      //Gauges


      const  Gauges = (e) => {
       

        const [file] = e.target.files;
        setGaugesfile(e.target.files[0])
      //   setImgupload(e.target.files[0]);
      console.log(file)
    
      setGauges(URL.createObjectURL(file));
    
      };


      const  Damage1 = (e) => {
       

        const [file] = e.target.files;
        setDamage1imagefile(e.target.files[0])    
      //   setImgupload(e.target.files[0]);
      console.log(file)
    
      setDamage1image(URL.createObjectURL(file));
    
      };



      const  Damage2 = (e) => {
       

        const [file] = e.target.files;
        console.log(e.target.files[0])
        setDamage2imagefile(e.target.files[0])
      //   setImgupload(e.target.files[0]);
      
    
      setDamage2image(URL.createObjectURL(file));
    
      };
      const  Damage3 = (e) => {
       
        setDamage3imagefile(e.target.files[0])
        const [file] = e.target.files;

    
      setDamage3image(URL.createObjectURL(file));
    
      };
      const [condition_report_value,setcondition_report_value]=useState()
      const dispatch=useDispatch();
      const navigate=useNavigate()
      useEffect(()=>{
               dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
                
                   setcondition_report_value(data);
               

                 

                    setdriversidecorner(data?.add_condition_report_candition?.driversidecornerimguploadfile ==undefined ?(data?.add_condition_report_candition?.vehicle_id?.image[0]==false||data?.add_condition_report_candition?.vehicle_id?.image[0]==undefined?"":data?.add_condition_report_candition?.vehicle_id?.image[0]):process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.driversidecornerimguploadfile);
                    setdriverrearsidecorner( data?.add_condition_report_candition?.driverrearsidecornerimguploadfile ==undefined
                       ?(data?.add_condition_report_candition?.vehicle_id?.image[0]==false  ||data?.add_condition_report_candition?.vehicle_id?.image[1]==undefined
                         ?"":data?.add_condition_report_candition?.vehicle_id?.image[1]):process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.driverrearsidecornerimguploadfile);
                   setpassangerrearsidecorner( data?.add_condition_report_candition?.passangerrearsidecornerimguploadfile ==undefined ?(data?.add_condition_report_candition?.vehicle_id?.image[0]==false  ||data?.add_condition_report_candition?.vehicle_id?.image[2]==undefined?"":data?.add_condition_report_candition?.vehicle_id?.image[2]):process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.passangerrearsidecornerimguploadfile);
                 setpassangersidecorner( data?.add_condition_report_candition?.passangersidecornerimguploadfile ==undefined ?(data?.add_condition_report_candition?.vehicle_id?.image[0]==false  ||data?.add_condition_report_candition?.vehicle_id?.image[3]==undefined?"":data?.add_condition_report_candition?.vehicle_id?.image[3]):process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.passangersidecornerimguploadfile);

                    setInterior( data?.add_condition_report_candition?.Interiorimguploadfile ==undefined ?(data?.add_condition_report_candition?.vehicle_id?.image[0]==false  ||data?.add_condition_report_candition?.vehicle_id?.image[4]==undefined?"":data?.add_condition_report_candition?.vehicle_id?.image[4]):process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.Interiorimguploadfile);

                    setGauges( data?.add_condition_report_candition?.passangerrearsidecornerimguploadfile ==undefined ?(data?.add_condition_report_candition?.vehicle_id?.image[0]==false  ||data?.add_condition_report_candition?.vehicle_id?.image[5]==undefined?"":data?.add_condition_report_candition?.vehicle_id?.image[5]):process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.passangerrearsidecornerimguploadfile);

                    setDamage1image(data?.add_condition_report_candition?.imgupload1 !=undefined? process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.imgupload1:"");
                    setDamage2image(data?.add_condition_report_candition?.imgupload2 !=undefined?process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.imgupload2:"");
                    setDamage3image(data?.add_condition_report_candition?.imgupload3 !=undefined?process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data?.add_condition_report_candition?.imgupload3:"");
             
           
                  
               })
      },[])


    
      const [imagecheck,setImagecheck]=useState([]);

      const imageadd = (value) => {
        const index = imagecheck.indexOf(value);
    
        if (index !== -1) {
          // Value exists, remove it from the array
          const updatedValues = [...imagecheck];
          updatedValues.splice(index, 1);
          setImagecheck(updatedValues);
        } else {
          // Value doesn't exist, push it to the array
          setImagecheck([...imagecheck, value]);
        }
      };


      const save_data=async()=>{
     

        

         if( (driversidecornerimgupload !="" && passangerrearsidecornerimgupload !=""&& passangersidecornerimgupload !=""&& Interiorimgupload !=""&&Gaugesimgupload !=""  && setdriverrearsidecornerimgupload !="")){

          if(driversidecornerimgupload !="" && passangerrearsidecornerimgupload !=""&& passangersidecornerimgupload !=""&& Interiorimgupload !=""&&Gaugesimgupload !=""  && setdriverrearsidecornerimgupload !=""&& condition_report_value.add_condition_report_candition?.type ==1 ){
           
            var formdata=new FormData()
            setLoader(0)

            formdata.append('vehicle_id',(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
       
            formdata.append('driversidecornerimguploadfile', driversidecornerimguploadfile)
            
            formdata.append('driverrearsidecornerimguploadfile', driverrearsidecornerimguploadfile)
            
            formdata.append('passangerrearsidecornerimguploadfile', passangerrearsidecornerimguploadfile)
   
            
            formdata.append('passangersidecornerimguploadfile', passangersidecornerimguploadfile)
            
            formdata.append('Interiorimguploadfile', Interiorimguploadfile)
            
            formdata.append('Gaugesimguploadfile', Gaugesimguploadfile)
   
            formdata.append('imgupload1', Damage1imagefile)
         
            formdata.append('imgupload2', Damage2imagefile)
            
            formdata.append('imgupload3', Damage3imagefile)
            formdata.append('imagecheck', imagecheck)
            
            
       
   
         
           dispatch(MyvehiclesConditionreport(formdata)).then(function(data){
                  navigate("/conditionreport14?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
             })

          }else{
           
            var formdata=new FormData()
    
            setLoader(0)
  
         formdata.append('vehicle_id',(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
       
         formdata.append('driversidecornerimguploadfile', imagecheck[0])
         
         formdata.append('driverrearsidecornerimguploadfile', imagecheck[1])
         
         formdata.append('passangerrearsidecornerimguploadfile', imagecheck[2])

         
         formdata.append('passangersidecornerimguploadfile', imagecheck[3])
         
         formdata.append('Interiorimguploadfile', imagecheck[4])
         
         formdata.append('Gaugesimguploadfile', imagecheck[5])





         
         formdata.append('imgupload1', Damage1imagefile)
         
         formdata.append('imgupload2', Damage2imagefile)
         
         formdata.append('imgupload3', Damage3imagefile)
         
         formdata.append('imagecheck', imagecheck)

      
        dispatch(MyvehiclesConditionreport(formdata)).then(function(data){
               navigate("/conditionreport14?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
          })
        }
        }else if(condition_report_value.add_condition_report_candition?.type ==1){
            toast.warning("A minimum of 6 images are required to proceed")
        }else{
         
          setLoader(0)
          var formdata=new FormData()
  
          formdata.append('vehicle_id',(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
        
          formdata.append('driversidecornerimguploadfile', imagecheck[0])
          
          formdata.append('driverrearsidecornerimguploadfile', imagecheck[1])
          
          formdata.append('passangerrearsidecornerimguploadfile', imagecheck[2])
          formdata.append('passangersidecornerimguploadfile', imagecheck[3])
          
          formdata.append('Interiorimguploadfile', imagecheck[4])
          
          formdata.append('Gaugesimguploadfile', imagecheck[5])
          formdata.append('imgupload1', Damage1imagefile)
          
          formdata.append('imgupload2', Damage2imagefile)
          formdata.append('imagecheck', imagecheck)
          formdata.append('imgupload3', Damage3imagefile)
          
     
 
       
         dispatch(MyvehiclesConditionreport(formdata)).then(function(data){
                navigate("/conditionreport14?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
           })
        }
      
      }




  
      

  




console.log(imagecheck)















     
  return (
    // <UserMaster>
    <div className='white-bg-cover'>
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>  
                    <div className='full-height-view'>
                       {/* Back step*/}               
                       <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel Auction Setup 
                            </NavLink>                       
                        </div>

                         <div className='progress-part-view'>
                         <div className='sm-blue-text-progress'>Vehicle Images</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'78%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'>                     
                        <div className='over'>
                        {
                            condition_report_value?.add_condition_report_candition?.type==0 &&(<>
                                <div className='step-progress-heading'>Select Images</div>
                                <p className='medium-pragraph-text'>Please select the images that you'd like to show in the auction. </p>
                                
                                <section className='image-Selection-c'>
                                <div className='row'>
                                {
                                    condition_report_value?.add_condition_report_candition?.vehicle_id?.image.map(function(image_object,i){
                                return(   <div className='col-md-4 col-6'>
                                    <div className="tile-c">
                                    <input className="checkbox" type="checkbox" id={"checkbox-"+i}  
                                        
                                        checked={imagecheck.includes(image_object)}
                                  onChange={() => imageadd(image_object)}
                                         name="checkbox"/>
                                    <label for={"checkbox-"+i}><img src={image_object} alt="" /></label>
                                    </div>
                                    </div>)
                                    })
                                }
                                

                                </div>
                                </section>
                            </>)
                        }
                       

                        
                       


                       {
                        condition_report_value?.add_condition_report_candition?.type==1 &&(
                            <>
                            <div className='row d-flex align-items-center mb-2'>
                            <div className='col-md-12'>
                            <div className='sub-heading-step'>Exterior</div>                          
                            </div>                          
                        </div>   


                        <div className='row  '>
                            <div className='col-md-8 col-sm-6 col-12 mb-5-mob'>
                               <div className='img-gallery-view-steps'>
                                <div className='img-view-part-dot-large'>
                                <input type="file" onChange={driversidecorner}  />

                                <img src={ driversidecornerimgupload !=""?driversidecornerimgupload:require('../asset_user/images/driver-side-corner.png')}
                                onError={(e) => {
                                              e.currentTarget.src = require('../asset_user/images/driver-side-corner.png');
                                            }}
                                 />
                                <div className='img-text-steps'> {driversidecornerimgupload ==""&&"Driver Side Corner"}</div>
                                </div>
                                <div className='add_input-gallery'>
                                    <input type="file" onChange={driversidecorner}  />
                                </div>
                                <div className='plus-img-icon-step'>
                                <img src={   require('../asset_user/images/add_plus.svg').default} />
                                </div>
                               </div> 
                            </div>
                            <div className='col-md-4 col-sm-6 col-12'>
                               <div className='row'>
                                <div className='col-md-12 col-sm-6 col-12'>
                                <div className='img-gallery-view-steps'>
                                <div className='img-view-part-dot-sm'>
                                <input type="file"  onChange={passangersidecorner} />
                                <img 
                            src={ passangersidecornerimgupload !=""?passangersidecornerimgupload:require('../asset_user/images/passenger-side-corner.png')}
                                        onError={(e) => {
                                              e.currentTarget.src = require('../asset_user/images/passenger-side-corner.png');
                                            }}






                                />
                                <div className='img-text-steps'>  {passangersidecornerimgupload ==""&&"Passenger Side Corner"}</div>
                                </div>
                                <div className='add_input-gallery'>
                                    <input type="file"  onChange={passangersidecorner} />
                                </div>
                                <div className='plus-img-icon-step'>
                                <img src={require('../asset_user/images/add_plus.svg').default} />
                                </div>
                               </div> 
                                </div>
                                <div className='col-md-12 col-sm-6 col-12'>
                                <div className='img-gallery-view-steps'>
                                <div className='img-view-part-dot-sm'>
                                <input type="file"  onChange={passangerrearsidecorner} />
                                <img 
                                
                                
                                src={ passangerrearsidecornerimgupload !=""?passangerrearsidecornerimgupload:require('../asset_user/images/passenger-rear-side-corner.png')}
                                        onError={(e) => {
                                              e.currentTarget.src = require('../asset_user/images/passenger-rear-side-corner.png');
                                            }}
                                
                                
                                
                                
                                
                                 />
                                <div className='img-text-steps'>  {passangerrearsidecornerimgupload ==""&&"Passenger Rear Side Corner"} </div>
                                </div>
                                <div className='add_input-gallery'>
                                    <input type="file" onChange={passangerrearsidecorner} />
                                </div>
                                <div className='plus-img-icon-step'>
                                <img src={require('../asset_user/images/add_plus.svg').default} />
                                </div>
                               </div> 
                                </div>
                               </div>
                            </div>                           
                        </div>
                        <div className='row mb-4 '>
                        <div className='col-md-4 col-sm-4 col-12'>
                        <div className='img-gallery-view-steps'>
                        <div className='img-view-part-dot-sm'>
                        <input type="file" onChange={driverrearsidecorner} />
                        <img 
                        
                        
                        src={ setdriverrearsidecornerimgupload !=""?setdriverrearsidecornerimgupload:require('../asset_user/images/driver-rear-side-corner.png')}
                                        onError={(e) => {
                                              e.currentTarget.src = require('../asset_user/images/driver-rear-side-corner.png');
                                            }}
                        
                        
                         />
                        <div className='img-text-steps'>   {setdriverrearsidecornerimgupload ==""&&"Driver Rear Side Corner"}</div>
                        </div>
                        <div className='add_input-gallery'>
                            <input type="file" onChange={driverrearsidecorner}></input>
                        </div>
                        <div className='plus-img-icon-step'>
                        <img src={require('../asset_user/images/add_plus.svg').default} />
                        </div>
                        </div> 
                        </div>
                        <div className='col-md-4 col-sm-4 col-12'>
                        <div className='img-gallery-view-steps'>
                        <div className='img-view-part-dot-sm'>
                        <input type="file"  onChange={interior_function} />
                        <img 
                        
                        src={ Interiorimgupload !=""?Interiorimgupload:require('../asset_user/images/interior.png')}
                                        onError={(e) => {
                                              e.currentTarget.src = require('../asset_user/images/interior.png');
                                            }}
                        
                        
                       />
                        <div className='img-text-steps' > {Interiorimgupload ==""&&"Interior"}</div>
                        </div>
                        <div className='add_input-gallery'>
                            <input type="file" onChange={interior_function}></input>
                        </div>
                        <div className='plus-img-icon-step'>
                        <img src={require('../asset_user/images/add_plus.svg').default} />
                        </div>
                        </div> 
                        </div>
                        <div className='col-md-4 col-sm-4 col-12'>
                        <div className='img-gallery-view-steps'>
                        <div className='img-view-part-dot-sm'>
                        <input type="file"   onChange={Gauges} />
                        <img src={Gaugesimgupload !=""?Gaugesimgupload:require('../asset_user/images/gauges.png')} />
                        <div className='img-text-steps'> {Gaugesimgupload ==""&&"Gauges"}</div>
                        </div>
                        <div className='add_input-gallery'>
                            <input type="file" onChange={Gauges}></input>
                        </div>
                        <div className='plus-img-icon-step'>
                        <img src={
                            
                            
                            
                            require('../asset_user/images/add_plus.svg').default
                            
                            } 

                        />
                        </div>
                        </div> 
                        </div>
                                                    
                        </div>
                            </>
                        )
                       }
                      




                        <div className='row d-flex align-items-center mb-4 mt-4 mobile-mt-20'>
                            <div className='col-md-12'>
                            <div className='sub-heading-step'>Any Damage (Optional)</div>    
                            <p className='medium-pragraph-text'>Please add photos of any relevant damage or preparation required. </p>                      
                            </div>                          
                        </div>
                        <div className='row mb-4 '>
                        <div className='col-md-4 col-sm-4 col-4'>
                        <div className='img-gallery-view-steps bottom-img-layout'>
                        <div className='img-view-part-dot-sm'>
                        <input type="file"  onChange={Damage1} />
                        {
                            Damage1image ==""?    <div className='img-text-steps'>Click to Add</div>: <img src={Damage1image !=""?Damage1image:require('../asset_user/images/gauges.png')}
                            
                            
                            onError={(e) => {
                                              e.currentTarget.src = require('../asset_user/images/gauges.png')
                                            }}
                            
                            
                             />
                        }
                       

                    
                        </div>
                        <div className='add_input-gallery'>
                            <input type="file" onChange={Damage1}></input>
                        </div>
                        <div className='plus-img-icon-step'>
                        <img src={require('../asset_user/images/add_plus.svg').default} />
                        </div>
                        </div> 
                        </div>
                        <div className='col-md-4 col-sm-4 col-4'>
                        <div className='img-gallery-view-steps bottom-img-layout'>
                        <div className='img-view-part-dot-sm'>
                        <input type="file" onChange={Damage2} />

                        {
                            Damage2image ==""?    <div className='img-text-steps'>Click to Add</div>: <img src={Damage2image !=""?Damage2image:require('../asset_user/images/gauges.png')} />
                        }
                        </div>
                        <div className='add_input-gallery'>
                            <input type="file" onChange={Damage2}></input>
                        </div>
                        <div className='plus-img-icon-step'>
                        <img src={require('../asset_user/images/add_plus.svg').default} />
                        </div>
                        </div> 
                        </div>
                        <div className='col-md-4 col-sm-4 col-4'>
                        <div className='img-gallery-view-steps bottom-img-layout'>
                        <div className='img-view-part-dot-sm'>
                        <input type="file"   onChange={Damage3}  />
                        {
                            Damage3image ==""?    <div className='img-text-steps'>Click to Add</div>: <img src={Damage3image !=""?Damage3image:require('../asset_user/images/gauges.png')} />
                        }
                        </div>
                        <div className='add_input-gallery'>
                            <input type="file" onChange={Damage3}></input>
                        </div>
                        <div className='plus-img-icon-step'>
                        <img src={require('../asset_user/images/add_plus.svg').default} />
                        </div>
                        </div> 
                        </div>
                                                    
                        </div>
                        {
  (condition_report_value?.add_condition_report_candition?.wheel_efurbishment !="0"||condition_report_value?.add_condition_report_candition?.interior_condition !="No Damage"||condition_report_value?.add_condition_report_candition?.bodywork !="0")

&&( <div className='bottom-blue-text-gallery'>
                        In your appraisal you listed: {condition_report_value?.add_condition_report_candition?.bodywork !="0"&&<span>Bodywork Damage,</span>}   
                          {condition_report_value?.add_condition_report_candition?.interior_condition !="No Damage"&&<span> Interior Stains </span>}
                           {condition_report_value?.add_condition_report_candition?.wheel_efurbishment !="0"&& (
                            <>{(condition_report_value?.add_condition_report_candition?.interior_condition !="No Damage"||condition_report_value?.add_condition_report_candition?.bodywork !="0")&&"and"}<span>  Wheel Refurbishments.</span></> )}
                        <br />
                         Please attach images, if appropriate.  
                        </div>)


}
                       </div>

                        
                       
                        
                           
                        </div>

                 

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step"  to={"/conditionreport12?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                      


                        {
                            loader==1?( <button className="nav-link blue-btn-step "  onClick={save_data}  >   
                            
                           
                            
                            Next</button>):( <button className="nav-link blue-btn-step "   >   
                            
                            <i className='fa fa-spinner fa-spin '></i>
                            
                            Next</button>)
                        }
                        </ul>

                        </div> 
                    </div>                     
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>
                        </div>                        
                    </div>
                </div>

            </div>
        <div className="blue-bg-right"></div>
    </div>
//    </UserMaster>
  )
}
