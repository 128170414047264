import React, { useEffect, useState } from 'react'


import FooterHome from '../IncludeHome/FooterHome'
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { BidsBybuyer, ByerheaderAction, GetmakemodelsAction, MybidsAction } from '../../Redux/Action/BuyerVehilesAution';

import ReactPaginate from 'react-paginate';

import BuyerHeader from '../IncludeDashboard/BuyerHeader';
import { Offcanvas } from 'react-bootstrap';
import CalculateCountdown from '../CalculateCountdown/CalculateCountdown';
import Calculatemiles from '../CalculateMiles';
import Countdown from '../CalculateCountdown/Countdown';
const haversine = require("haversine")

export default function Mybids() {


  const [modalShowPlaceoffer, setModalShowPlaceoffer] = useState(false);
  const [setvrmvalue, setsetvrmvalue] = useState("");
  const [file_update, setFile_update] = useState("");
  const [buyerbidvalue, setbuyerbidvalue] = useState("");
  const [showfilter, setShowfilter] = useState(false);
  const [model_list, setModel_list] = useState([]);

  const [filtervalue, setFiltervalue] = useState("");
  const [vat_qualifying, setVat_qualifying] = useState(0);
  const [plus_vat, setPlus_vat] = useState(0);
  const [write_off_cat, setWrite_off_cat] = useState(0);
  const [Without_BIDS, setWithout_BIDS] = useState(0);
  const [With_BIDS, setWith_BIDS] = useState(1);
  const [bidset, setbidset] = useState("");
  const handleClose = () => setModalShowPlaceoffer(false);
  const [page_no,setpage_no]=useState(1)
  const [isOpen, setIsOpen] = useState(false);
  const [imgIndex, setImgIndex] = useState(0);
  const [imageset,setSetimageset]=useState([])
  const [vrmcondtionreport,Setvrmconditionreport]=useState("")
  const [searchInput, setSearchInput] = useState('');
      var  images_1 =[];
      const dispatch=useDispatch()
      const navigate=useNavigate()

useEffect(()=>{
 
  dispatch(MybidsAction({page_no:page_no}))
   dispatch(GetmakemodelsAction())
   dispatch(ByerheaderAction());

},[])

const my_bid_list=useSelector((state)=>state.Buyer.my_bid_list);


const make_model_list=useSelector((state)=>state.Buyer.make_model_list);



//end Add All Optional Extras Below


const condition_report=(vrm)=>{
Setvrmconditionreport(vrm)
}

const handlePageClick =  (page_no) => {
  console.log(page_no)

   setpage_no(page_no?.selected + 1)
 
  dispatch(
      MybidsAction({page_no:page_no?.selected + 1})
  );
};






const submitform=()=>{
  if(bidset !=""){
      dispatch(BidsBybuyer({vrm:setvrmvalue.vrm,valuation:bidset})).then(function(){

      
      setModalShowPlaceoffer(false)
      setbidset("")
      dispatch(MybidsAction({page_no:page_no}))
  })
  }else{
      setModalShowPlaceoffer(true)
  }
}









const start = {
  latitude: my_bid_list?.start?.coordinates[0],
  longitude: my_bid_list?.start?.coordinates[1]
}


const socket =  io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: true,
});

useEffect(() => {

  socket.on("buyerportal", (value) => {
    if(value !=null){
      dispatch(MybidsAction({page_no:page_no}))  
    }
  
   
  });
  // return () => {
  //     socket.disconnect();
  //   };
 },[dispatch])



const handleCloseaccount=()=>{
  setShowfilter(false)
}


const handleChangefilter=(type,value)=>{



          switch(type){
              case 'sort':
      setFiltervalue((filtervalue) => {
          return({
            ...filtervalue,
            
            'sort':value
          });
        });
      break;
      case 'make':
          setFiltervalue((filtervalue) => {
              return({
              ...filtervalue,
              
              'make':value
              });
          });


       var model_list=   make_model_list?.model_list.filter(function(model_obj){
          return model_obj.make == value;
       })
       setModel_list(model_list)


    break;
    case 'model':
        setFiltervalue((filtervalue) => {
            return({
            ...filtervalue,
            
            'model':value
            });
        });
  break;
  case 'price':
      setFiltervalue((filtervalue) => {
          return({
          ...filtervalue,
          
          'price':value
          });
      });
break;
case 'age_min':
  setFiltervalue((filtervalue) => {
      return({
      ...filtervalue,
      
      'age_min':value
      });
  });
  break;
  case 'age_max':
      setFiltervalue((filtervalue) => {
          return({
          ...filtervalue,
          
          'age_max':value
          });
      });
  break;

case 'text':
  setFiltervalue((filtervalue) => {
      return({
      ...filtervalue,
      
      'text':value
      });
  });
  break;
  
  case 'vat_qualifying':
      setFiltervalue((filtervalue) => {
          return({
          ...filtervalue,
          
          'vat_qualifying':value
          });
      });
      break;

  case 'plus_vat':
      setFiltervalue((filtervalue) => {
          return({
          ...filtervalue,
          
          'plus_vat':value
          });
      });
      break;

      case 'write_off_cat':
          setFiltervalue((filtervalue) => {
              return({
              ...filtervalue,
              
              'write_off_cat':value
              });
          });
      break;
      case 'With_BIDS':
          setFiltervalue((filtervalue) => {
              return({
              ...filtervalue,
              
              'With_BIDS':value
              });
          });
          break;
          case 'Without_BIDS':
              setFiltervalue((filtervalue) => {
                  return({
                  ...filtervalue,
                  
                  'Without_BIDS':value
                  });
              });
              break;
          case 'mileage':
              setFiltervalue((filtervalue) => {
                  return({
                  ...filtervalue,
                  
                  'mileage':value
                  });
              });
              break;
              case 'distance_cordingate':
                  setFiltervalue((filtervalue) => {
                      return({
                      ...filtervalue,
                      
                      'distance_cordingate':value
                      });
                  });
                  break;

                          

                      
          

              

  default:
      
}

}


const search_vehicles=()=>{
 
  for(var i = 0; i < filtervalue.length; i++) {
      filtervalue[filtervalue[i]] = filtervalue[i];
   }
 

  dispatch(MybidsAction({page_no:page_no,filtervalue}))
}







const header_count = useSelector(
    (state) => state.Buyer?.buyer_header_count
  );


  return (
  <>
  <BuyerHeader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 col-12'>




       <ul className='sub-menu-dashboard'>
        <li>
          <NavLink to="/liveauction" >Live Auctions <span>({header_count?.live_autction??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/mybids" className='active-border'>My Bids  <span>({header_count?.Mybidstotal??0})</span></NavLink>
        </li>
        
        <li>
          <NavLink to="/watching" >Watching <span>({header_count?.getwatchlist_total??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/won/past30days" >Won <span>({header_count?.total_won??0})</span></NavLink>
        </li> 
        <li>
          <NavLink to="/lost" >Lost <span>({header_count?.totallostlist??0})</span></NavLink>
        </li>   
       </ul> 
     
      </div>
      <div className='col-sm-6 col-md-5 col-lg-2 col-xl-2 col-xxl-2 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'> 
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>My Stock</NavLink>
       </div>
      </div> 
      <div className='col-sm-6 col-md-12 col-lg-3 col-xl-3 col-xxl-3 col-12 '>
      {/* <div className='app-search d-lg-block '>
        <div className='d-flex justify-content-between align-items-center search_box'>
          <div className='position-relative w-100'>
            <input
            className='form-control'
            placeholder='VRM, Make, Model'
            type='search'
            />
            <span className='fa fa-search'></span>
          </div>
        </div>
      </div> */}
      <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
  </div>  
       
    </div>
    </div> 
      </div>
    </section>
    {/* mobile filter show */}
    <section className='filter-home-v2'>
    <div className='mobile-filter-show'>
      <ul>
        <li className='bor-r-c' onClick={()=>{
          setShowfilter(true)
        }}><NavLink to="#" className='sort-home'>Filter</NavLink> </li>
        <li >
        <div className={"dropdown show "}>
        <div className="dropdown-toggle sort-home" type="button" id="dropdownMenuButton" data-toggle="dropdown" onClick={()=>{
          // setFiltersorthome(Filtersorthome==""?"show":"")
        }} aria-haspopup="true" aria-expanded="false">
        Sort By
        </div>
        <div className={"dropdown-menu  "} aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="#">Price (Lowest)</a>
          <a className="dropdown-item" href="#">Price (Highest)</a>
          <a className="dropdown-item" href="#">Latest Car</a>
          <a className="dropdown-item" href="#">Oldest Car</a>
          <a className="dropdown-item" href="#">Make</a>
          <a className="dropdown-item" href="#">Model</a>
          <a className="dropdown-item" href="#">Age (Newest First)</a>
          <a className="dropdown-item" href="#">Distance</a>
          <a className="dropdown-item" href="#">Mileage (Lowest First)</a>
        </div>
      </div>
        </li>
      </ul>
    </div>
    </section>
    {/* main grid data show v2 */}
    <section className='list-data-show mt30'>
    <div className='container container1224'>
    <div className='row'> 
    <div className='col-sm-12 col-md-12 col-lg-3 col-xl-3 col-xxl-3 col-12 desktop-filter-view'>
                   
      <div className='filter-card'>
        <div className='row'>
            <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Sort By</div>
                <div className='form-input-style'>                                   
                    <select onChange={(e)=>{
                        handleChangefilter("sort",e.target.value)
                    }}>
                        <option value="">Show All</option>
                        <option value="-1">Newly Listed (Newest First)</option>
                        <option value="0">Ending Soonest</option>
                        <option value="1">Price Lowest (Lowest Price first)</option>
                    </select>
                </div>
            </div>
            <div className='border-b-filter'></div>
            <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Make</div>
                <div className='form-input-style'>                                   
                    <select  onChange={(e)=>{
                        handleChangefilter("make",e.target.value)
                    }}>
                        <option value="">Show All</option>
                        {
                            ( make_model_list?.make_model_list !=undefined) &&
                            make_model_list?.make_model_list[0]?.make?.map(function(object){
                                return(<option value={object}>{object}</option>)
                            })
                        }
                    </select>
                </div>
            </div>
            <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Model</div>
                <div className='form-input-style'>                                   
                    <select  onChange={(e)=>{
                        handleChangefilter("model",e.target.value)
                    }}>
                        <option value="">Show All</option>
                        {
                                (
                                    model_list !=undefined) &&
                                    model_list?.map(function(object){
                                return(<option value={object.model}>{object.model}</option>)
                            })


                            

                            



                        }
                    </select>
                </div>
            </div>
            <div className='border-b-filter'></div>
            <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Mileage</div>
                <div className='form-input-style'>                                   
                    <select  onChange={(e)=>{
                        handleChangefilter("mileage",e.target.value)
                    }}>                                      
                    <option value="">Show All</option>
                        <option  value="0-100"> Up to 100 miles</option>
                        <option  value="0-1000"> Up to 1,000 miles</option>
                        <option value="0-5000"> Up to 5,000 miles</option>
                        <option value="0-10000"> Up to 10,000 miles</option>
                        <option value="0-20000"> Up to 20,000 miles</option>
                        <option value="0-40000"> Up to 40,000 miles</option>
                        <option value="0-60000"> Up to 60,000 miles</option>
                        <option value="0-80000"> Up to 80,000 miles</option>
                        <option value="0-8000000">Over 80,000 miles</option>
                    </select>
                </div>
            </div>
            

            <div className='col-md-6 mb-3'>
            <div className='form-style-label'>Age</div>
                <div className='form-input-style'>                                   
                    <select  onChange={(e)=>{
                        handleChangefilter("age_min",e.target.value)
                    }}
                    
                    >
                        <option value="">Min</option>
                        <option value="0">Any</option>
                        <option value="2023">2023</option>
                        <option  value="2022">2022</option>
                        <option  value="2021" >2021</option>
                        <option  value="2020">2020</option>
                        <option  value="2019">2019</option>
                        <option  value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009 and Older</option>
                    </select>
                </div>
            </div>
            <div className='col-md-6 mb-3'>
            <div className='form-style-label invisible'>Age</div>
                <div className='form-input-style'>                                   
                <select onChange={(e)=>{
                        handleChangefilter("age_max",e.target.value)
                    }}>
                        <option>Max</option>     
                        <option value="0">Any</option>
                        <option value="2023">2023</option>
                        <option  value="2022">2022</option>
                        <option  value="2021" >2021</option>
                        <option  value="2020">2020</option>
                        <option  value="2019">2019</option>
                        <option  value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009 and Older</option>
                    </select>
                </div>
            </div>
            <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Price Range</div>
                <div className='form-input-style'>                                   
                    <select  onChange={(e)=>{
                        handleChangefilter("price",e.target.value)
                    }}>
                        
                        <option>View All</option>
                        <option value="1000-5000">£1,000 - £5,000</option>
                        <option value="5000-10000">£5,000 - £10,000</option>
                        <option value="10000-15000">£10,000 - £15,000</option>
                        <option value="15000-20000">£15,000 - £20,000</option>
                        <option value="20000-30000">£20,000 - £30,000</option>
                        <option value="30000-50000">£30,000 - £50,000</option>
                        <option value="50000-80000">£50,000 - £80,000</option>
                        <option value="80000-800000000">£80,000 +</option>
                    </select>
                </div>
            </div>
            <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Distance Away</div>
                <div className='form-input-style'>                                   
                    <select      onChange={(e)=>{
                        handleChangefilter("distance_cordingate",e.target.value)
                    }}>
                        <option>View All</option>
                        <option value="0-10 ">Upto 10 miles</option>
                        <option value="0-20">Upto 20 miles</option>
                        <option value="0-30"> Upto 30 miles</option>
                        <option value="0-50">Upto 50 miles</option>
                        <option value="0-100"> Upto 100 miles</option>
                        <option value="100-1000000000">100 miles +</option>
                    </select>
                </div>
            </div>
        </div>

        <div className='filter-toggle-leftside'>
        <div className='form-style-label'>Show</div>

        <div className='row mb-2'>
            <div className='col-md-6'>
            <span className="medium-blue-text-list">Vehicles With Bids</span>
            </div>
            <div className='col-md-6'>
            <div className='toogle-switch-filter-buyer'   >
            <div className="check-box">
                <input type="checkbox"    value={With_BIDS==1?0:1} checked={With_BIDS==1?false:true}  onChange={(e)=>{
                                
                                setWith_BIDS(e.target.value)
                            handleChangefilter("With_BIDS",e.target.value)
                        }} />
            </div>
            </div>
            </div>
        </div>
        <div className='row mb-2'>
            <div className='col-md-6'>
            <span className="medium-blue-text-list">Vehicles Without Bids</span>
            </div>
            <div className='col-md-6'>
            <div className='toogle-switch-filter-buyer'>
            <div className="check-box">
                <input type="checkbox"    value={Without_BIDS==0?1:0}  checked={Without_BIDS==0?false:true}  onChange={(e)=>{
                                
                                setWithout_BIDS(e.target.value)
                            
                            handleChangefilter("Without_BIDS",e.target.value)
                        }}  />
            </div>
            </div>
            </div>
        </div>
        <div className='row mb-2'>
            <div className='col-md-6'>
            <span className="medium-blue-text-list">Write Offs</span>
            </div>
            <div className='col-md-6'>
            <div className='toogle-switch-filter-buyer'>
            <div className="check-box">
                <input type="checkbox"      value={write_off_cat==0?1:0}


                checked={write_off_cat==0?false:true}
                            
                            onChange={(e)=>{
                                
                                setWrite_off_cat(e.target.value)
                            handleChangefilter("write_off_cat",e.target.value)
                        }}/>
            </div>
            </div>                              
            </div>
        </div>
        </div>
        <div className="border-b-filter"></div>
        <div className='filter-toggle-leftside'>
        <div className='form-style-label'>Show Only</div>

        <div className='row mb-2'>
            <div className='col-md-6'>
            <span className="medium-blue-text-list">VAT Qualifying</span>
            </div>
            <div className='col-md-6'>
                <div className='filter-toggle'>
                <div className='toogle-switch-filter-buyer'>
                    <div className="check-box">
                        <input type="checkbox"
                            value={vat_qualifying==0?1:0}
                            checked={vat_qualifying==0?false:true}
                            onChange={(e)=>{
                            
                            setVat_qualifying(e.target.value)
                            handleChangefilter("vat_qualifying",e.target.value)
                    }}
                        />
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div className='row mb-2'>
            <div className='col-md-6'>
            <span className="medium-blue-text-list">Plus VAT</span>
            </div>
            <div className='col-md-6'>
                <div className='filter-toggle'>
                <div className='toogle-switch-filter-buyer'>
                    <div className="check-box">
                        <input type="checkbox"   
                        
                        checked={plus_vat==0?false:true}

                        value={plus_vat==0?1:0}
                            
                            onChange={(e)=>{
                                
                                setPlus_vat(e.target.value)
                            handleChangefilter("plus_vat",e.target.value)
                        }}



                    />
                    </div>
                    </div>
                </div>
            </div>
        </div> 
        <div className='border-b-filter'></div> 
        <div className='row mb-2'>                            
        <div className='col-md-12 mb-3'>
            <div className='form-style-label'>Keyword Search</div>
                <input className='form-input-style-filter' onChange={(e)=>{
                        handleChangefilter("text",e.target.value)
                    }}  placeholder='Type Here'/>  
            </div>
        </div>                      
        </div>
        <div className='button-cta-filter mt-5'>
<button className='small-filter-blue-outline side-small-btn' onClick={()=>{
                setFiltervalue("")
                dispatch(MybidsAction({page_no:page_no}))
            }}>Reset</button>
<button className='small-filter-blue-fill side-small-btn' onClick={search_vehicles}>Update</button>            
</div> 
          
      </div>   
    </div>
    <div className='col-sm-12 col-md-12 col-lg-9 col-xl-9 col-xxl-9 col-12'>
    <div className='product-grid-v2'>
    <div className='row'>     
      
    {
      my_bid_list?.Mybids?.map(function(vehicle_detail ,i){


        
    return  <div className='col-sm-6 col-md-4 col-lg-4 col-xl-4 col-xl-4 col-12 mb-4' key={i}>
        <div className='mobile-card-layout1 white-card-v2'>
        <div className='card-body-v2'>
                                    
            <div className='bids_inner_part'>
            <div className='img_car_bg '>
                <div className='img_relative position-relative' >
        

            <img  src={ vehicle_detail?.newvaluationconditionreports?.images?.length>0 ?vehicle_detail?.newvaluationconditionreports?.images[1]: require('../asset_user/images/placholder-img.png')  } />
                </div>                                 
            </div>

            <div className='padding-equal'>
            <div className='grid-title-colum-v2'>  { vehicle_detail?.newvaluationconditionreports?.year !=undefined?
                vehicle_detail?.newvaluationconditionreports?.year+" "+ (vehicle_detail?.newvaluationconditionreports?.make !=undefined ?vehicle_detail?.newvaluationconditionreports?.make:"") +" "+ (vehicle_detail?.newvaluationconditionreports?.model !=undefined ?vehicle_detail?.newvaluationconditionreports?.model:""):(vehicle_detail?.newvaluationconditionreports?.make !=undefined ?vehicle_detail?.newvaluationconditionreports?.make:"")+" "+ (vehicle_detail?.newvaluationconditionreports?.model !=undefined ?vehicle_detail?.newvaluationconditionreports?.model:"")  }</div>  
            <div className='car-quick-details-v2'>
                    <ul>
                    
                    <li>   {  vehicle_detail?.newvaluationconditionreports.year ??"N/A"} </li>
                                <li>{new Intl.NumberFormat('en-GB')
                                .format(vehicle_detail?.newvaluationconditionreports.mileage)
                                .replace(/\.00$/, '')} miles</li>
                                <li>  {  vehicle_detail?.newvaluationconditionreports.fuel ??"N/A"}</li>
                                <li> {  vehicle_detail?.newvaluationconditionreports.auto_manual ??"N/A"}</li>
                    </ul>
            </div>   
            <div className='section-divide mb-0'>
                <div className='d-flex justify-content-between'> 
                <div className='address-part'>
                <img src={require('../asset_user/images/red_map.svg').default}  className='red-map'/>
                <div className='blact-text-sm'>Southend on sea {  Math.ceil(haversine(start, {latitude:vehicle_detail?.newvaluationconditionreports?.location.coordinates[0],longitude:vehicle_detail?.newvaluationconditionreports?.location.coordinates[1]}, {unit: 'mile'}))            } miles away</div>
                </div>
                </div>
            </div>
            </div>

            <div className='retailprice-part-v2'>
                <div className='d-flex justify-content-between'>
                <div className='left-side-price pl-15'>
                    <span>Current Bid:</span>
                    <div className='blue-text-lg-v2'>
                    {vehicle_detail?.yotraderbids.length ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((vehicle_detail?.yotraderbids.length==0)?0: Math.max(...vehicle_detail?.yotraderbids.map(o => o?.yotrader_value))).replace(/\.00$/, '')}
                            </div>
                            {(((vehicle_detail?.yotraderbids.length ==0)?0 : 
                                                Math.max(...vehicle_detail.yotraderbids.map(o => o.yotrader_value)))>=vehicle_detail?.price_reserve)?<div className='biding-end-text-green'>Reserve met </div>:(vehicle_detail?.price_reserve !=undefined && <div className='red-text-label'>Reserve not met  </div>)}
                    
                </div>

                <div className='retail-price-v2 dark-gry'>
                    <span>Buy Now</span>
                    <div className='blue-text-lg-v2'>
                    {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        })
                                        .format(vehicle_detail?.newvaluationconditionreports?.price_now)
                                        .replace(/\.00$/, '')}
                    </div>
                </div>              
                </div>
            </div>

            <div className='get-bid-btn-part-v2 dark-gry'>
            <div className='totl_price'>                               
                <div className='end-auction-part'>
                <div className='blue-small-text'>Auction Ends: </div>
               
                <Countdown endDate={vehicle_detail?.newvaluationconditionreports?.offer_expire} navigate={navigate} />
                </div>                                          
            <NavLink to={`${Math.ceil(( new Date(vehicle_detail?.offer_expire)-new Date() ) / 36e5)>0?'/buyervehiclesdetails?id':"/BuyerCongratesVehilesdetails?id"}                         =${vehicle_detail?.newvaluationconditionreports?.vrm}`}>  <button className='home-square-white-btn-v2'>   {`${Math.ceil(( new Date(vehicle_detail?.offer_expire)-new Date() ) / 36e5)>0?'BID NOW':"View Vehicle Details"}`}</button>  </NavLink>



         
            </div>
            </div>                                    
            </div>
        </div>
        </div>                            
    </div> 
    })
    } 
    

    <div className='pagination-wrapper'>
                <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={my_bid_list?.total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                />
                </div>
    </div>
</div>
    </div>
    </div>  
    <Offcanvas show={showfilter} onHide={handleCloseaccount} className="offcanvas-sidebar"  placement="start">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Filter</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>
    <div className='filter-card'>
    <div className='row'>
        <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Sort By</div>
            <div className='form-input-style'>                                   
                <select onChange={(e)=>{
                    handleChangefilter("sort",e.target.value)
                }}>
                    <option value="">Show All</option>
                    <option value="-1">Newly Listed (Newest First)</option>
                    <option value="0">Ending Soonest</option>
                    <option value="1">Price Lowest (Lowest Price first)</option>
                </select>
            </div>
        </div>
        <div className='border-b-filter'></div>
        <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Make</div>
            <div className='form-input-style'>                                   
                <select  onChange={(e)=>{
                    handleChangefilter("make",e.target.value)
                }}>
                    <option value="">Show All</option>
                    {
                        ( make_model_list?.make_model_list !=undefined) &&
                        make_model_list?.make_model_list[0]?.make?.map(function(object){
                            return(<option value={object}>{object}</option>)
                        })



                    



                    }
                </select>
            </div>
        </div>
        <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Model</div>
            <div className='form-input-style'>                                   
                <select  onChange={(e)=>{
                    handleChangefilter("model",e.target.value)
                }}>
                    <option value="">Show All</option>
                    {
                            (
                                model_list !=undefined) &&
                                model_list?.map(function(object){
                            return(<option value={object.model}>{object.model}</option>)
                        })


                        

                        



                    }
                </select>
            </div>
        </div>
        <div className='border-b-filter'></div>
        <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Mileage</div>
            <div className='form-input-style'>                                   
                <select  onChange={(e)=>{
                    handleChangefilter("mileage",e.target.value)
                }}>                                      
                <option value="">Show All</option>
                    <option  value="0-100"> Up to 100 miles</option>
                    <option  value="0-1000"> Up to 1,000 miles</option>
                    <option value="0-5000"> Up to 5,000 miles</option>
                    <option value="0-10000"> Up to 10,000 miles</option>
                    <option value="0-20000"> Up to 20,000 miles</option>
                    <option value="0-40000"> Up to 40,000 miles</option>
                    <option value="0-60000"> Up to 60,000 miles</option>
                    <option value="0-80000"> Up to 80,000 miles</option>
                    <option value="0-8000000">Over 80,000 miles</option>
                </select>
            </div>
        </div>
        

        <div className='col-md-6 mb-3'>
        <div className='form-style-label'>Age</div>
            <div className='form-input-style'>                                   
                <select  onChange={(e)=>{
                    handleChangefilter("age_min",e.target.value)
                }}
                
                >
                    <option value="">Min</option>
                    <option value="0">Any</option>
                    <option value="2023">2023</option>
                    <option  value="2022">2022</option>
                    <option  value="2021" >2021</option>
                    <option  value="2020">2020</option>
                    <option  value="2019">2019</option>
                    <option  value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009 and Older</option>
                </select>
            </div>
        </div>
        <div className='col-md-6 mb-3'>
        <div className='form-style-label invisible'>Age</div>
            <div className='form-input-style'>                                   
            <select onChange={(e)=>{
                    handleChangefilter("age_max",e.target.value)
                }}>
                    <option>Max</option>     
                    <option value="0">Any</option>
                    <option value="2023">2023</option>
                    <option  value="2022">2022</option>
                    <option  value="2021" >2021</option>
                    <option  value="2020">2020</option>
                    <option  value="2019">2019</option>
                    <option  value="2018">2018</option>
                    <option value="2017">2017</option>
                    <option value="2016">2016</option>
                    <option value="2015">2015</option>
                    <option value="2014">2014</option>
                    <option value="2013">2013</option>
                    <option value="2012">2012</option>
                    <option value="2011">2011</option>
                    <option value="2010">2010</option>
                    <option value="2009">2009 and Older</option>
                </select>
            </div>
        </div>
        <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Price Range</div>
            <div className='form-input-style'>                                   
                <select  onChange={(e)=>{
                    handleChangefilter("price",e.target.value)
                }}>
                    
                    <option>View All</option>
                    <option value="1000-5000">£1,000 - £5,000</option>
                    <option value="5000-10000">£5,000 - £10,000</option>
                    <option value="10000-15000">£10,000 - £15,000</option>
                    <option value="15000-20000">£15,000 - £20,000</option>
                    <option value="20000-30000">£20,000 - £30,000</option>
                    <option value="30000-50000">£30,000 - £50,000</option>
                    <option value="50000-80000">£50,000 - £80,000</option>
                    <option value="80000-800000000">£80,000 +</option>
                </select>
            </div>
        </div>
        <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Distance Away</div>
            <div className='form-input-style'>                                   
                <select      onChange={(e)=>{
                    handleChangefilter("distance_cordingate",e.target.value)
                }}>
                    <option>View All</option>
                    <option value="0-10 ">Upto 10 miles</option>
                    <option value="0-20">Upto 20 miles</option>
                    <option value="0-30"> Upto 30 miles</option>
                    <option value="0-50">Upto 50 miles</option>
                    <option value="0-100"> Upto 100 miles</option>
                    <option value="100-1000000000">100 miles +</option>
                </select>
            </div>
        </div>
    </div>

    <div className='filter-toggle-leftside'>
    <div className='form-style-label'>Show</div>

    <div className='row mb-2'>
        <div className='col-md-6'>
        <span className="medium-blue-text-list">Vehicles With Bids</span>
        </div>
        <div className='col-md-6'>
        <div className='toogle-switch-filter-buyer'   >
        <div className="check-box">
            <input type="checkbox"    value={With_BIDS==1?0:1} checked={With_BIDS==1?false:true}  onChange={(e)=>{
                            
                            setWith_BIDS(e.target.value)
                        handleChangefilter("With_BIDS",e.target.value)
                    }} />
        </div>
        </div>
        </div>
    </div>
    <div className='row mb-2'>
        <div className='col-md-6'>
        <span className="medium-blue-text-list">Vehicles Without Bids</span>
        </div>
        <div className='col-md-6'>
        <div className='toogle-switch-filter-buyer'>
        <div className="check-box">
            <input type="checkbox"    value={Without_BIDS==0?1:0}  checked={Without_BIDS==0?false:true}  onChange={(e)=>{
                            
                            setWithout_BIDS(e.target.value)
                        
                        handleChangefilter("Without_BIDS",e.target.value)
                    }}  />
        </div>
        </div>
        </div>
    </div>
    <div className='row mb-2'>
        <div className='col-md-6'>
        <span className="medium-blue-text-list">Write Offs</span>
        </div>
        <div className='col-md-6'>
        <div className='toogle-switch-filter-buyer'>
        <div className="check-box">
            <input type="checkbox"      value={write_off_cat==0?1:0}


            checked={write_off_cat==0?false:true}
                        
                        onChange={(e)=>{
                            
                            setWrite_off_cat(e.target.value)
                        handleChangefilter("write_off_cat",e.target.value)
                    }}/>
        </div>
        </div>                              
        </div>
    </div>
    </div>
    <div className="border-b-filter"></div>
    <div className='filter-toggle-leftside'>
    <div className='form-style-label'>Show Only</div>

    <div className='row mb-2'>
        <div className='col-md-6'>
        <span className="medium-blue-text-list">VAT Qualifying</span>
        </div>
        <div className='col-md-6'>
            <div className='filter-toggle'>
            <div className='toogle-switch-filter-buyer'>
                <div className="check-box">
                    <input type="checkbox"
                        value={vat_qualifying==0?1:0}
                        checked={vat_qualifying==0?false:true}
                        onChange={(e)=>{
                        
                        setVat_qualifying(e.target.value)
                        handleChangefilter("vat_qualifying",e.target.value)
                }}
                    />
                </div>
                </div>
            </div>
        </div>
    </div>
    <div className='row mb-2'>
        <div className='col-md-6'>
        <span className="medium-blue-text-list">Plus VAT</span>
        </div>
        <div className='col-md-6'>
            <div className='filter-toggle'>
            <div className='toogle-switch-filter-buyer'>
                <div className="check-box">
                    <input type="checkbox"   
                    
                    checked={plus_vat==0?false:true}

                    value={plus_vat==0?1:0}
                        
                        onChange={(e)=>{
                            
                            setPlus_vat(e.target.value)
                        handleChangefilter("plus_vat",e.target.value)
                    }}



                />
                </div>
                </div>
            </div>
        </div>
    </div> 
    <div className='border-b-filter'></div> 
    <div className='row mb-2'>                            
    <div className='col-md-12 mb-3'>
        <div className='form-style-label'>Keyword Search</div>
            <input className='form-input-style-filter' onChange={(e)=>{
                    handleChangefilter("text",e.target.value)
                }}  placeholder='Type Here'/>  
        </div>
    </div>                      
    </div>
<div className='button-cta-filter mt-5'>
<button className='small-filter-blue-outline side-small-btn' onClick={()=>{
                setFiltervalue("")
                dispatch(MybidsAction({page_no:page_no}))
            }}>Reset</button>
<button className='small-filter-blue-fill side-small-btn' onClick={search_vehicles}>Update</button>            
</div>     
    </div>   

    </Offcanvas.Body>
  </Offcanvas>

{/* conditions modal */}
  </div>
    </section>
<FooterHome />
   </>
    
  )
}
