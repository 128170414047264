import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { GetMyvehiclesConditionreportmyvehicle } from '../../Redux/Action/SellerVehiclesActions';
import Gallery from '../Gallery/Gallery';
import Productheaderdetails from '../Previewdetails/Productheaderdetails';
import FooterHome from '../IncludeHome/FooterHome';

export default function SoldVehicledetails() {

  const [previewlistingobj,setPreviewlistingobj]=useState()
  
  const urlParams = new URLSearchParams(window.location.search);
  const [thankspop, setThankspop]=useState(false)
 


  const dispatch=useDispatch();
  const navigate=useNavigate()

      useEffect(()=>{
      dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
       
          
         
          setPreviewlistingobj(data);

   

      })
      },[])
    



const closeButton=()=>{
  setThankspop(false)
  navigate('/myvehiclesdetail?id='+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
}

  return (
    <>
 <HeaderDashboard />
 <div className='bg-headerwhite-v2 detail-page-subheader'>
  <div className='container container1200'>
        <div className='row d-flex align-items-center'>
          <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 col-8'>
          <div className="page-heading-v2-details">Seller Congrats Vehicle Details</div>
          </div>         
        </div>
  </div>
</div>
<div className='detail-page-back'>
  <div className='container container1200'>
        <div className='detail-back-part'>
          <div className='row d-flex align-items-center'>
            <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
              {/* Back */}               
              <div className='white-bg-step '>  
                  <NavLink to={"/past30days"} className='back-step-arrow'>
                  <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to All Sold 
                  </NavLink>                       
              </div>
            </div>
            <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>                            
              <div className='end-bid'>  
                  <NavLink to="" className='biding-end'>Bidding Ended</NavLink>                       
              </div>
            </div>
          </div>
        </div>
  </div>
</div>
<div className='slider-img-details-Section'>
                        <div className='container container1200'>
                        <div className='bor-b-v2'>
                            <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-4 col-xxl-4 col-12 mobile-padding-0'>
                                <div className='slider-img-left-part'>
                                <Gallery  images_data={previewlistingobj?.add_condition_report_candition} />
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-5 col-xxl-5 col-12'>
                                <div className='slider-img-right-content'>
                            <Productheaderdetails details={previewlistingobj?.add_condition_report_candition} />
                                <div className='bor-b-v2'></div>            
                                
                                <div className='listing-rowSection'>
                                <div className='listing-detail-row bor-b-v2'>
                                <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>WINNING BID:</div>

                                <div className='listing-colum35'>
                                    <div className='blue-text-price-lg'>{(new Intl.NumberFormat('en-GB',{   style: 'currency',  currency: 'GBP',})
                                        .format(parseInt(previewlistingobj?.add_condition_report_candition?.soldvehiclesdetail?.saleprice))
                                        .replace(/\.00$/, ''))}</div>
                                </div>      
                                </div>  

                            
                                </div>
                                <div className='listing-rowSection'>
                                <div className='listing-detail-row'>
                                <div className='listing-tooltip-part'>
                                <div className='left-corner-logo'>
                                
                                    <div className='sm-logo-tooltip-left'>
                                    <img src={require('../asset_user/images/logo_symbol.png')} />   
                                    </div>
                                    </div>
                                    <div className='white-box-bg-box-shadow'>  
                                    <div className='corner-cut-left'>
                                    <img src={require('../asset_user/images/corner-shadow.svg').default}/> 
                                    </div>                                  
                                        <div className='text-part-listing'>
                                        <span>Congratulations!</span>
                                        </div>
                                        <div className='sm-text-blue-listing-tooltip'>You can now contact the buyer to arrange payment and collection
</div>                    
                                </div>                                      
                                </div>       
                                </div>           
                                </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-6 col-xl-3 col-xxl-3 col-12 right-mobile-part-white'>
                            <div className="white-box-outline-right">
                            <div className="heading-underline-text">Buyer Details</div>
                            <div className="blue-bg-color">
                            <img src={require('../asset_user/images/logo1.svg').default}/>
                            </div>
                            <div className="heading-right-lg-blue">{previewlistingobj?.add_condition_report_candition?.user_id?.company_name}</div>
                            <div className="row">
                            <div className="col-md-8">
                            <div className='sm-text-light'>{previewlistingobj?.add_condition_report_candition?.user_id?.town_city}</div>
                            <div className='sm-text-light'>{previewlistingobj?.add_condition_report_candition?.user_id?.post_code}</div>
                            <div className='sm-text-light'>{previewlistingobj?.add_condition_report_candition?.user_id?.county}</div>
                            </div><div className="col-md-4 p-0">
                            <div className="xsm-text-light">
                            <a href={`https://www.google.com/maps/search/?api=1&query=${previewlistingobj?.add_condition_report_candition?.user_id?.location?.coordinates[0]},${previewlistingobj?.add_condition_report_candition?.user_id?.location?.coordinates[1]}`} target="_blank">View on Map</a>
                            </div>
                            </div>
                            </div>
                            <div className="row mt-4">
                            <div className="col-md-12">
                            <div className="contact-part-icon">
                            {/* <img src={require('../asset_user/images/mail.svg').default}/>*/}
                            <div className="sm-text-light">{previewlistingobj?.add_condition_report_candition?.user_id?.phone_number}</div>
                            </div>
                            <div className="contact-part-icon">
                            <div className="sm-text-light font11">{previewlistingobj?.add_condition_report_candition?.user_id?.email}</div>
                            </div>
                            </div>
                            </div>
                            <div className="row mt-5">
                            <div className="col-md-12">
                            <div className="full-width-btn-outline">
                            <a href={previewlistingobj?.add_condition_report_candition?.user_id?.website_url} target="_blank">View Website</a>
                            </div>
                            </div>
                            </div>
                            </div>
                            </div>          
                            </div>
                        </div>        
                        </div>
</div>  
<div className='bottom-content-details'>
<div className='container container1200'>  
<div className='row'>
<div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
<div className="listing-sec-heading-underline">Winning Bid</div>  
</div>
</div>
<div className='green-outline-bg-box mb-3'>
  <div className='row height90 d-flex align-items-center'>
  <div className='col-sm-2 col-lg-2 col-md-2 col-xl-2 col-3 mobile-colum-show'>
    <div className='logo-symbol-step large-text-hash'>#1</div>                        
    </div>
    <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-5'>
    <div className='checkw-text-step'>
    <img src={require('../../Component/asset_user/images/check_green.svg').default}/> 
    <div className='check-text-steps blue-text-color'>YoTrader Dealer Bid</div> 
    </div>                  
    </div>
    <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-4'>
    <div className='white-price-label-steps green-text-color'>{(new Intl.NumberFormat('en-GB',{   style: 'currency',  currency: 'GBP',})
                                        .format(parseInt(previewlistingobj?.add_condition_report_candition?.soldvehiclesdetail?.saleprice))
                                        .replace(/\.00$/, ''))}</div>                           
    </div>
  </div>
  </div>      
<div className='row'>
<div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
<div className="listing-sec-heading-underline">Vehicle Details</div>  
</div>
</div>
<div className='row'>
<div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
<div className='listing-sec-subheading'>Condition Report</div>
<div className='row'>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
<div className='blue-sm-text-p-bold'>Mechanical Issues :</div>
<div className='blue-sm-text-p-bold'>Bodywork Prep :</div>
<div className='blue-sm-text-p-bold'>Interior Prep :</div>
<div className='blue-sm-text-p-bold'>Service History :</div>
<div className='blue-sm-text-p-bold'>V5 Present :</div>
<div className='blue-sm-text-p-bold'>No. Keys :</div>
</div>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.mechanical}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.bodywork==undefined?"":"Up to £"}    {previewlistingobj?.add_condition_report_candition?.bodywork}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.interior_condition}</div>
<div className='blue-sm-text-p-light'> {previewlistingobj?.add_condition_report_candition?.service_history}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.v5_present}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.keys}</div>
</div>
</div>
</div>
<div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
<div className='listing-sec-subheading'>Optional Extras</div>
<div className='row'>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
<div className='blue-sm-text-p-bold'>Sunroof</div>
<div className='blue-sm-text-p-bold'>Panoramic Sunroof</div>
<div className='blue-sm-text-p-bold'>Wheel Upgrade</div>
<div className='blue-sm-text-p-bold'>Half - Leather Seats</div>
<div className='blue-sm-text-p-bold'>Full Leather Seats</div>
<div className='blue-sm-text-p-bold'>Heated Seats</div>
<div className='blue-sm-text-p-bold'>SatNav</div>
<div className='blue-sm-text-p-bold'>Bluetooth</div>
<div className='blue-sm-text-p-bold'>Other</div>
</div> 

<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-light'>{ (previewlistingobj?.add_condition_report_candition?.roof=="Sunroof"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.roof=="Panoramic Roof"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.wheel_upgrade=="Wheel Upgrade"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'>  {(previewlistingobj?.add_condition_report_candition?.leather=="Half Leather"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.leather=="Full Leather"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.heated_seats=="Heated Seats"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.satnav=="Satnav"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.bluetooth=="Bluetooth"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.other??"")}</div>

</div>                       
</div>
</div>
<div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
<div className='listing-sec-subheading'>Trade Information</div>
<div className='row'>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
<div className='blue-sm-text-p-bold'>VAT Qualifying:</div>
<div className='blue-sm-text-p-bold'>Plus VAT</div>
<div className='blue-sm-text-p-bold'>Write Off:</div>
</div> 
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.vat_qualifying=="1"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.plubs_vat=="1"?"Yes":"No")}</div>
<div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.write_off_cat=="1"?"Yes":"No")}</div>
</div>                              
</div>
</div>
</div>
<div className='row d-flex align-items-center mt-3'>
<div className='col-md-12'>
<div className='listing-sec-heading-underline'>Seller Description</div>
</div>
</div>
<div className='row d-flex align-items-center '>
<div className='col-md-12'>
<div className='blue-sm-text-p-light'>            
<div className='description-wrap'>{previewlistingobj?.add_condition_report_candition?.description ??""}</div>
</div>
</div>
</div>{previewlistingobj?.add_condition_report_candition?.mechanicaldescription  !=undefined &&

(   <div className='row d-flex align-items-center mt-3'>
<div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col col-12'>
<div className='blue-sm-text-p-bold'>Mechanical Issues Recorded :</div>
</div>
<div className='col-md-9'>
<div className='blue-sm-text-p-light-i'>  {previewlistingobj?.add_condition_report_candition?.mechanicaldescription}</div>
</div>
</div>)
}
<div className='row d-flex align-items-center mt-5'>
<div className='col-md-12'>
<div className='blue-light-bg-listing'>
<div className='listing-sec-heading-underline'>Full Specification </div>
<div className='row'>
<div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
<div className='row'>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
<div className='blue-sm-text-p-bold'>Make:</div>
<div className='blue-sm-text-p-bold'>Model:</div>
{/* <div className='blue-sm-text-p-bold'>Type:</div> */}
<div className='blue-sm-text-p-bold'>Body Colour:</div>

<div className='blue-sm-text-p-bold'>Mileage:</div>
<div className='blue-sm-text-p-bold'>Trim:</div>
</div> 
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.make !=undefined?previewlistingobj?.add_condition_report_candition?.make:previewlistingobj?.add_condition_report_candition?.vehicle_id.make}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.model !=undefined?previewlistingobj?.add_condition_report_candition?.model:previewlistingobj?.add_condition_report_candition?.vehicle_id.model}</div>
{/* <div className='blue-sm-text-p-light'>AMG Line</div> */}
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.colour !=undefined?previewlistingobj?.add_condition_report_candition?.colour:previewlistingobj?.add_condition_report_candition?.vehicle_id.colour}</div>

<div className='blue-sm-text-p-light'>{new Intl.NumberFormat('en-GB')
                .format(parseInt(previewlistingobj?.add_condition_report_candition?.vehicle_id?.mileage))
                .replace(/\.00$/, '')}</div>
<div className='blue-sm-text-p-light'>
{/* Half Leather */}


</div>
</div>                              
</div>
</div>
<div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
<div className='row'>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
<div className='blue-sm-text-p-bold'>Fuel:</div>
<div className='blue-sm-text-p-bold'>Transmission:</div>
<div className='blue-sm-text-p-bold'>Engine Size:</div>
<div className='blue-sm-text-p-bold'>Number of Doors:</div>
<div className='blue-sm-text-p-bold'>Reg Number:</div>
<div className='blue-sm-text-p-bold'>Chassis Number:</div>
</div> 
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.fuel !=undefined&&previewlistingobj?.add_condition_report_candition?.fuel}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.transmission !=undefined&&previewlistingobj?.add_condition_report_candition?.transmission}</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.engine_size !=undefined &&previewlistingobj?.add_condition_report_candition?.engine_size}</div>
<div className='blue-sm-text-p-light'>

{/* 5 */}


</div>
<div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}</div>
<div className='blue-sm-text-p-light'>
{/* WBAAX52050JX45750 */}


</div>
</div>                              
</div>
</div>
<div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
<div className='row'>
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-bold'>First Registered:</div>
<div className='blue-sm-text-p-bold'>Total Owners:</div>
<div className='blue-sm-text-p-bold'>V5 Present:</div>
<div className='blue-sm-text-p-bold'>MOT Expiration:</div>
</div> 
<div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
<div className='blue-sm-text-p-light'>
{/* 01/04/2019 */}
</div>
<div className='blue-sm-text-p-light'>
{/* 3 */}


</div>
<div className='blue-sm-text-p-light'>
{/* Yes */}
</div>
<div className='blue-sm-text-p-light'>
{/* 01/04/2024 */}
</div>
</div>                              
</div>
</div>
</div>
</div>

</div>
</div>
<div className='row mt-3'>
<div className='col-sm-10 col-lg-10 col-md-10 col-xl-11 col mx-auto'>
<div className='note-sm-text-listing'>
* Although every effort has been made to give a full an accurate description of the vehicle, please allow up to £200 for items that may have been missed .
Yo trader do not sell vehicles and do not take any responsibility if a vehicle has been misdescribed . 
</div>
</div>
</div>

</div>
</div>


    <FooterHome />
    </>
  )
}
