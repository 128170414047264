import React from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import { NavLink } from 'react-router-dom'
import FooterHome from '../IncludeHome/FooterHome'
import BuyerHeader from '../IncludeDashboard/BuyerHeader'
import { useEffect } from 'react'
import { ByerheaderAction, LostAction } from '../../Redux/Action/BuyerVehilesAution'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

export default function Lost() {
  const dispatch=useDispatch()
  useEffect(()=>{
    dispatch(ByerheaderAction());
    dispatch(LostAction({page_no:1}))
  },[])
  const header_count = useSelector(
    (state) => state.Buyer?.buyer_header_count
  );
  const lost_listlist = useSelector(
    (state) => state.Buyer?.lost_list
  );
  console.log(lost_listlist,"lost_listlistlost_listlist")
  return (
    <>
  <BuyerHeader />
  <BuyerHeader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 col-12'>
 

       <ul className='sub-menu-dashboard'>
        <li>
          <NavLink to="/liveauction" >Live Auctions <span>({header_count?.live_autction??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/mybids" >My Bids  <span>({header_count?.Mybidstotal??0})</span></NavLink>
        </li>
        
        <li>
          <NavLink to="/watching">Watching <span>({header_count?.getwatchlist_total??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/won/past30days">Won <span>({header_count?.total_won??0})</span></NavLink>
        </li> 
        <li>
          <NavLink to="/lost"  className='active-border' >Lost <span>({header_count?.totallostlist??0})</span></NavLink>
        </li>   
       </ul> 
     
      </div>
      <div className='col-sm-6 col-md-5 col-lg-2 col-xl-2 col-xxl-2 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'> 
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>My Stock</NavLink>
       </div>
      </div> 
      <div className='col-sm-6 col-md-12 col-lg-3 col-xl-3 col-xxl-3 col-12 '>
      {/* <div className='app-search d-lg-block '>
        <div className='d-flex justify-content-between align-items-center search_box'>
          <div className='position-relative w-100'>
            <input
            className='form-control'
            placeholder='VRM, Make, Model'
            type='search'
            />
            <span className='fa fa-search'></span>
          </div>
        </div>
      </div> */}
      <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
  </div>  
       
    </div>
    </div> 
      </div>
    </section>
      {/* Header subheader white background */}
   
    {/* mobile filter show */}
    <section className='filter-home-v2'>
        <div className='mobile-filter-show'>
        <ul>
            <li className='bor-r-c' onClick={()=>{
            
            }}><NavLink to="#" className='sort-home'>Filter</NavLink> </li>
            <li >
            <div className={"dropdown show "}>
            <div className="dropdown-toggle sort-home" type="button" id="dropdownMenuButton" data-toggle="dropdown" onClick={()=>{
            
            }} aria-haspopup="true" aria-expanded="false">
            Sort By
            </div>
            <div className={"dropdown-menu  "} aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#">Price (Lowest)</a>
            <a className="dropdown-item" href="#">Price (Highest)</a>
            <a className="dropdown-item" href="#">Latest Car</a>
            <a className="dropdown-item" href="#">Oldest Car</a>
            <a className="dropdown-item" href="#">Make</a>
            <a className="dropdown-item" href="#">Model</a>
            <a className="dropdown-item" href="#">Age (Newest First)</a>
            <a className="dropdown-item" href="#">Distance</a>
            <a className="dropdown-item" href="#">Mileage (Lowest First)</a>
            </div>
        </div>
            </li>
        </ul>
        </div>
   

    {/* conditions modal */}
    </section>
    {/* list-data-show */}
    <section className='list-data-show mt30'>
   
      {
        lost_listlist?.Lostlist?.map(function(data_obj,i){
          return(
            <div className='container container1224' key={i}>
    <div className='product-list-v2 mb-3'>
 
    <div className='row'>
   
          <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-12'>
            <div className='img_car_bg '>
            <img className='footer-logo-yo'  src={  data_obj?.newvaluation?.driverrearsidecornerimguploadfile==undefined?
                                                                
                                                                (  data_obj?.newvaluation?.vehicle_id?.length==0?require('../asset_user/images/placholder-img.png'):
                                                                  
                                                                  data_obj?.vehicle_id[0]?.image[0]==false?require('../asset_user/images/placholder-img.png'): data_obj?.vehicle_id[0]?.image[0]):
                                                                  (data_obj?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("http://") == 0 ||data_obj?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("https://") == 0) ?data_obj?.newvaluation?.driverrearsidecornerimguploadfile:
                                                              process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data_obj?.newvaluation?.driverrearsidecornerimguploadfile
  
                          }       onError={(e) => {  e.currentTarget.src = require('../asset_user/images/placholder-img.png'); }}
  />                           
            </div>
          </div>
          <div className='col-sm-8 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-12'>
            <div className='list-car-view-details'>
              <div className="grid-title-colum-v2">  { data_obj?.newvaluation?.year !=undefined?
    data_obj?.newvaluation?.year+" "+ (data_obj?.newvaluation?.make !=undefined ?data_obj?.newvaluation?.make:"") +" "+ (data_obj?.newvaluation?.model !=undefined ?data_obj?.newvaluation?.model:""):(data_obj?.newvaluation?.make !=undefined ?data_obj?.newvaluation?.make:"")+" "+ (data_obj?.newvaluation?.model !=undefined ?data_obj?.newvaluation?.model:"")  }</div> 
              <div className="home-viewcar-no-v2">{data_obj?.newvaluation?.vrm}</div>   
              <div className='car-extra-info'>
              <ul className='ul-fixed-width'>
                <li>Selling Dealer:</li>
                <li>Contact:</li>
                <li>Email: </li>
                <li>Phone</li>
              </ul>
              <ul className='car-extra-details-list'>
                <li>{data_obj?.user_detail[0]?.company_name}</li>
                <li>{data_obj?.user_detail[0]?.name}</li>
              
                <li>{data_obj?.user_detail[0]?.email}</li>
                <li>{data_obj?.user_detail[0]?.phone_number} </li>
              </ul>
              </div>   
            </div>
          </div>
          <div className='col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
            <div className='right-side-part'>
              <div className='bid-price'>
                <div className='bid-group-mobile'>
                <div className='sm-black-text'>Lost Bid</div>
                <div className='lg-green-text'>{new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',
                                                                            })
                                                                            .format(data_obj.saleprice==undefined?( Math.max.apply( null, Object.values( data_obj?.yotraderbids ).map( c => c.yotrader_value ) )):data_obj.saleprice)
                                                                            .replace(/\.00$/, '')}</div>
                </div>
                <div className='md-black-text'>Auction Ended: {moment(data_obj?.createdAt).format('DD MMMM  YYYY, h:mm a')}</div>
              </div>
              <div className='button-part-bottom'>
              <div className='flex-part'>
              <NavLink to={"/BuyerDontwindbid?id="+data_obj?.newvaluation?.vrm} className="home-square-white-btn-v2 width100-btn text-center">View Vehicle Details</NavLink>              
              </div>
                
              </div>
            </div>
          </div>
          </div>
      </div> 
      </div>
          )
        })
      }
     
 
    </section> 
</>
  )
}
