import React, { useState } from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import { NavLink, useNavigate } from 'react-router-dom';
import Accountheader from '../../IncludeDashboard/Accountheader';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AccountheaderAction, PendingcollectionAction, VehicleDeliveredStatusupdateAction } from '../../../Redux/Action/BuyerVehilesAution';
import Pagination from '../../Pagination/Pagination';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../DealerShipinfo/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51NSN9FK8IqEbWoY8cY6HCCFZ28bQECPn2v3iIuKQeBnzc4X7XqowWsgrDl7oy49J2UnAODFhm8f0w61rA6GLp1Ix00AjdWT8tF');

export default function Review() {
const [show,setShow]=useState(false)
const [stripeshow,setStripe]=useState(false)
const [buyvehilcesid,setBuyvehilcesid]=useState("")

const [delivered_status,setDelivered_status]=useState("")
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(PendingcollectionAction({
      page_no:1
    }))
  },[])
  const Pendingcollection=useSelector((state)=>state.Buyer.pending_collection)
const handleClose=()=>{
  setShow(false)
}

useEffect(()=>{
  dispatch(AccountheaderAction());
},[])
const account_header_count = useSelector(
  (state) => state.Buyer?.account_header_count
);

const closestripe=()=>{
  setStripe(false)
}
  return (
    <>
   <Accountheader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" >Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" >Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson">Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity">Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews" className='active-border'>Reviews <span>(1)</span></NavLink>
        </li>    */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>
      {/* Header subheader white background */}
      <section className='bg-subheaderwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/reviews" className='active-border' >Pending Collection <span>({account_header_count?.pendingcollectiontotalcount??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/pendingreviews" >Pending Review <span>({account_header_count?.pendingreviewtotal??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/complete" >Complete <span>({account_header_count?.completereviewstotal??0})</span></NavLink>
        </li>   
       </ul>      
      </div>       
    </div>
    </div> 
      </div>
    </section>
    {/* list-data-show */}
    <section className='list-data-show mt30 dealership-form'>
    <div className='container container1224'>
   

{
  Pendingcollection?.pendingcollectionlist?.map(function(object_pending_collection,i){
    return(   <div className='product-list-v2 mb-3'><div className='row' key={i}>
          <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-12'>
            <div className='img_car_bg '>
     
                        <img className='footer-logo-yo'  src={  object_pending_collection?.newvaluation?.driverrearsidecornerimguploadfile==undefined?
                                                            
                                                          (  object_pending_collection?.newvaluation?.vehicle_id?.length==0?require('../../asset_user/images/placholder-img.png'):
                                                            
                                                            object_pending_collection?.vehicle_id[0]?.image[0]==false?require('../../asset_user/images/placholder-img.png'): object_pending_collection?.vehicle_id[0]?.image[0]):
                                                            (object_pending_collection?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("http://") == 0 ||object_pending_collection?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("https://") == 0) ?object_pending_collection?.newvaluation?.driverrearsidecornerimguploadfile:
                                                        process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+object_pending_collection?.newvaluation?.driverrearsidecornerimguploadfile

                    }       onError={(e) => {  e.currentTarget.src = require('../../asset_user/images/placholder-img.png'); }}
/>                                 
            </div>
          </div>
          <div className='col-sm-8 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-12'>
            <div className='list-car-view-details'>
              <div className="grid-title-colum-v2"> { object_pending_collection?.newvaluation?.year !=undefined?
object_pending_collection?.newvaluation?.year+" "+ (object_pending_collection?.newvaluation?.make !=undefined ?object_pending_collection?.newvaluation?.make:"") +" "+ (object_pending_collection?.newvaluation?.model !=undefined ?object_pending_collection?.newvaluation?.model:""):(object_pending_collection?.newvaluation?.make !=undefined ?object_pending_collection?.newvaluation?.make:"")+" "+ (object_pending_collection?.newvaluation?.model !=undefined ?object_pending_collection?.newvaluation?.model:"")  }</div> 
              <div className="home-viewcar-no-v2">{object_pending_collection?.newvaluation?.vrm}</div>   
              <div className='car-extra-info'>
              {
               ( (object_pending_collection?. seler_id)?.toString()==localStorage.getItem('dealer_id'))?(
                <>
                <ul className='ul-fixed-width'>
                <li>Winning Buyer:</li>
                <li>Contact:</li>
                <li>Email: </li>
                <li>Phone</li>
              </ul>
         
              <ul className='car-extra-details-list'>
            <li>{object_pending_collection?.buyer_detail?.company_name}</li>
            <li>{object_pending_collection?.buyer_detail?.name ==""?"N/A":object_pending_collection?.buyer_detail?.name}</li>
            <li>{object_pending_collection?.buyer_detail?.email} </li>
            <li>{object_pending_collection?.buyer_detail?.phone_number}</li>
          </ul>
                </>
            
          ):(
            <>

            
            <ul className='ul-fixed-width'>
                <li>Winning Seller:</li>
                <li>Contact:</li>
                <li>Email: </li>
                <li>Phone</li>
              </ul>
         
              <ul className='car-extra-details-list'>
            <li>{object_pending_collection?.seller_detail?.company_name}</li>
            <li>{object_pending_collection?.buyer_detail?.name ==""?"N/A":object_pending_collection?.buyer_detail?.name}</li>
            <li>{object_pending_collection?.seller_detail?.email} </li>
            <li>{object_pending_collection?.seller_detail?.phone_number}</li>
          </ul></>)
              }
              </div>   
            </div>
          </div>
          <div className='col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
            <div className='right-side-part'>
              <div className='bid-price'>
                <div className='bid-group-mobile'>
                <div className='sm-black-text'>Winning Bid</div>
                <div className='lg-green-text'>{new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',
                                                                        })
                                                                        .format(object_pending_collection.saleprice)
                                                                        .replace(/\.00$/, '')}</div>
                </div>
                <div className='md-black-text'>Auction Ended: {moment(object_pending_collection?.createdAt).format('DD/MM/YYYY, h:mm a')}</div>
              </div>
              <div className='button-part-bottom'>
              <div className='black-text-color'>Was the vehicle collected?</div>
              <div className='flex-part'>




              <button className="home-square-white-btn-v2 home-square-green-btn-v2 review-blue-btn-v2 width100-btn mr-10" onClick={()=>{
                setShow(true)
                var status_data=((object_pending_collection?. seler_id)?.toString()==localStorage.getItem('dealer_id')?
                ({delivered_saler:1}):({delivered_buyer:1}))

          //  (     (object_pending_collection?. seler_id)?.toString()==localStorage.getItem('dealer_id')?(object_pending_collection?.seller_detail?.payment_link==undefined ? setStripe(true):"" ):(object_pending_collection?.buyer_detail?.payment_link==undefined?setStripe(true):"" ))
                setBuyvehilcesid(object_pending_collection?._id)
                setDelivered_status(status_data)
              }}>Yes</button>
              <button className="home-square-white-btn-v2 width100-btn"  onClick={()=>{
                setShow(true)
                var status_data=((object_pending_collection?. seler_id)?.toString()==localStorage.getItem('dealer_id')?
                ({delivered_saler:1}):({delivered_buyer:1}))

                  // ((object_pending_collection?. seler_id)?.toString()==localStorage.getItem('dealer_id')?(object_pending_collection?.seller_detail?.payment_link==undefined ?setStripe(true):"" ):(object_pending_collection?.buyer_detail?.payment_link==undefined ?setStripe(true):"" ))
                setBuyvehilcesid(object_pending_collection?._id)
                setDelivered_status(status_data)
              }}>No</button>
              </div>
                
              </div>
            </div>
          </div>
    </div>
    </div> )
  })
}


{/* pagination */}

<div className='pagination-wrapper'>
<Pagination   pageinationD={Pendingcollection?.total_page} dispatch={dispatch} action_name={PendingcollectionAction} />
</div>
{/*end  pagination */}
  
    </div>

    
    <Modal show={show} onHide={handleClose} className='border-radius'>      
        <Modal.Body>
        <div className='popup-content-error'>
        <div className='alert-icon-popup'>
        <img src={require('../../../Component/asset_user/images/alret.svg').default} alt=''/> 
        </div>
          <div className='popup-center-title-v2'>Confirm Collection</div>
          <div className='popup-content-v2'>
          Are you sure you have collected the vehicle? By confirming collection, you fully accept the YoTrader Terms of purchase.
          </div>
        </div>        
        </Modal.Body>
        <Modal.Footer className='footer-custom-popup'>
        <div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-3 col-12'>
            <div className='bid-blue-sm-btn'>
            <button type="button" onClick={()=>{
                setShow(false)
            }}>Cancel</button>
            </div>
        </div>



        <div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-3 col-12'>
            <div className='bid-green-sm-btn'>
            <button type="button" onClick={()=>{




              dispatch(VehicleDeliveredStatusupdateAction({
                buyvehilces_id:buyvehilcesid,

               delivered_status

              })).then(function(){
                dispatch(PendingcollectionAction({
                page_no:1
              }))
              dispatch(AccountheaderAction());
              setShow(false)
              })
            
            }}>Confirm</button>
            </div>
        </div>
        </Modal.Footer>
      </Modal>
      <Modal show={stripeshow} onHide={closestripe} className='mt-top checkout-popup'>
    <form >
    <div className='modal-body white-bg-popup-top'>     
     <Modal.Header closeButton></Modal.Header>
     <Elements stripe={stripePromise}>
      {/* Your component hierarchy */}
      <PaymentForm open={stripeshow} onClose={closestripe} />
    </Elements>

 </div>

</form>


  </Modal>
    </section> 
    </>
  
  )
}
