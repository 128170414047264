import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux';
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';


export default function Conditionreport12() {
    const urlParams = new URLSearchParams(window.location.search);

     //Trade Information
     const [loader,setLoader]=useState(1)
     const [vatqualifying, setvatqualifying] = useState('0');
     const [plubvat, setplubvat] = useState('0');
     const [writeoff, setwriteoff] = useState('0');
     const [condition_report_value,setcondition_report_value]=useState()

     const dispatch=useDispatch();
     const navigate=useNavigate()
 
         useEffect(()=>{
         dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
            setvatqualifying( data?.add_condition_report_candition?.vat_qualifying)
            setplubvat( data?.add_condition_report_candition?.plubs_vat)
            setwriteoff( data?.add_condition_report_candition?.write_off_cat)
      
         setcondition_report_value(data);
 
      
 
         })
         },[])
  
     const save_data=async()=>{
        setLoader(0)
        dispatch(MyvehiclesConditionreport({
              vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
              vat_qualifying:vatqualifying,
              plubs_vat:plubvat,
              write_off_cat:writeoff
          })).then(function(data){
              navigate("/conditionreport13?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
          })
  
  
      
      }



  return (

    // <UserMaster>
    <div className='white-bg-cover'>
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>  
                    <div className='full-height-view'>
                        {/* Back step*/}               
                        <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel Auction Setup 
                            </NavLink>                       
                        </div> 
                        <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Additional Information</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'72%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'> 
                        <div className='step-progress-heading'>Trade Information</div>
                        <div className='row d-flex align-items-center mb-4 border-bottom-steps mt-5'>
                            <div className='col-md-9'>
                            <div className='sub-heading-step'>VAT Qualifying?</div>
                            <div className='step-grey-text'>
                            A VAT Qualifying Car is car that has previously been owned by a business or is a brand-new car from a main franchiser. 
                            </div>
                            </div>
                            <div className='col-md-3'>
                            <div className='toogle-switch-step'>
                            <div className="check-box">
                                <input type="checkbox"   
                                onChange={(e)=>{
                                    setvatqualifying(e.target.value==0?1:0)
                                }}
                                
                                
                                
                                 value={vatqualifying}                     
                              checked={vatqualifying=="1"?true:false} 
                                
                                
                                
                                  />
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className='row d-flex align-items-center mb-4 border-bottom-steps mt-5'>
                            <div className='col-md-9'>
                            <div className='sub-heading-step'>Plus VAT?</div>
                            <div className='step-grey-text'>
                            Plus VAT means that the commercial vehicle has never passed into private ownership or a dealership/business that is not VAT registered.</div>
                            </div>
                            <div className='col-md-3'>
                            <div className='toogle-switch-step'>
                            <div className="check-box">
                                <input type="checkbox"
                                
                                onChange={(e)=>{
                                    setplubvat(e.target.value==0?1:0)
                                }}
                                

                              value={plubvat} 
                              checked={plubvat=="1"?true:false}

                                
                                  />
                            </div>
                            </div>
                            </div>
                        </div>
                        <div className='row d-flex align-items-center mb-4 mt-5'>
                            <div className='col-md-9'>
                            <div className='sub-heading-step'>Write Off</div>
                            <div className='step-grey-text'>
                            Is it a registered write off? Vehicles which have been in an accident which cannot be repaired due to severity or costs more than the value of the vehicle, a write off category is given. This can reduce the value of the car as the car has been written off. You can quickly and easily find out the date, year and what type of damage occurred.  </div>
                            </div>
                            <div className='col-md-3'>
                            <div className='toogle-switch-step'>
                            <div className="check-box">
                                <input type="checkbox"

                                        onChange={(e)=>{
                                            setwriteoff(e.target.value==0?1:0)
                                }}
                                

                              value={writeoff} 
                              checked={writeoff=="1"?true:false}


                                  />
                            </div>
                            </div>
                            </div>
                        </div>
                        
                           
                        </div>

                   
                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step"  to={"/conditionreport11?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                        <li className="nav-item">
                        {
                            loader==1?( 
                    
                             <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>
                            ):( 
                            
                           <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            )
                        }
                           
                        </li>
                        </ul>
                        </div>
                    </div> 
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>
                        </div>                        
                    </div>
                </div>

            </div>  
        <div className="blue-bg-right"></div>
    </div>
    // </UserMaster>
  )
}
