import React from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AccountheaderAction, CompleteReviewAction } from '../../../Redux/Action/BuyerVehilesAution';
import { Rating } from 'react-simple-star-rating';
import moment from 'moment';
import Pagination from '../../Pagination/Pagination';

export default function Complete() {

  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(CompleteReviewAction({
      page_no:1
    }))
  },[])
  const completereviews=useSelector((state)=>state.Buyer.complete_reviews_list)
  useEffect(()=>{
    dispatch(AccountheaderAction());
  },[])
  const account_header_count = useSelector(
    (state) => state.Buyer?.account_header_count
  );
  return (
    <>
    <HeaderDashboard />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" >Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" >Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson">Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity">Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews" className='active-border'>Reviews <span>(1)</span></NavLink>
        </li>    */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>
      {/* Header subheader white background */}
      <section className='bg-subheaderwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>
 
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/reviews"  >Pending Collection <span>({account_header_count?.pendingcollectiontotalcount??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/pendingreviews" >Pending Review <span>({account_header_count?.pendingreviewtotal??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/complete" className='active-border'>Complete <span>({account_header_count?.completereviewstotal??0})</span></NavLink>
        </li>   
       </ul>        
      </div>       
    </div>
    </div> 
      </div>
    </section>
    {/* mobile filter show */}
    <section className='filter-home-v2'>
        <div className='mobile-filter-show'>
        <ul>
            <li className='bor-r-c' ><NavLink to="/all-vehicles" className='sort-home active-color-stock'>Days In Stock</NavLink> </li>
            <li >
            <div className={"dropdown show "}>
            <div className="dropdown-toggle sort-home" type="button" id="dropdownMenuButton" data-toggle="dropdown" onClick={()=>{
            
            }} aria-haspopup="true" aria-expanded="false">
            Sort By
            </div>
            <div className={"dropdown-menu  "} aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#">Price (Lowest)</a>
            <a className="dropdown-item" href="#">Price (Highest)</a>
            <a className="dropdown-item" href="#">Latest Car</a>
            <a className="dropdown-item" href="#">Oldest Car</a>
            <a className="dropdown-item" href="#">Make</a>
            <a className="dropdown-item" href="#">Model</a>
            <a className="dropdown-item" href="#">Age (Newest First)</a>
            <a className="dropdown-item" href="#">Distance</a>
            <a className="dropdown-item" href="#">Mileage (Lowest First)</a>
            </div>
        </div>
            </li>
        </ul>
        </div>
    

    {/* conditions modal */}
    </section>
    {/* list-data-show */}
    <section className='list-data-show mt30'>
    <div className='container container1224'>

    {
      completereviews?.completereviewslist?.map(function(object_pending_reviews,i){

        return(  <div className='product-list-v2 mb-3' key={i}>
    <div className='row'>
          <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-12'>
            <div className='img_car_bg '>
            <img className='footer-logo-yo'  src={  object_pending_reviews?.newvaluation?.driverrearsidecornerimguploadfile==undefined?
                                                            
                                                            (  object_pending_reviews?.newvaluation?.vehicle_id?.length==0?require('../../asset_user/images/placholder-img.png'):
                                                              
                                                              object_pending_reviews?.vehicle_id[0]?.image[0]==false?require('../../asset_user/images/placholder-img.png'): object_pending_reviews?.vehicle_id[0]?.image[0]):
                                                              (object_pending_reviews?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("http://") == 0 ||object_pending_reviews?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("https://") == 0) ?object_pending_reviews?.newvaluation?.driverrearsidecornerimguploadfile:
                                                          process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+object_pending_reviews?.newvaluation?.driverrearsidecornerimguploadfile
  
                      }       onError={(e) => {  e.currentTarget.src = require('../../asset_user/images/placholder-img.png'); }}
  />                                                      
            </div>
          </div>
          <div className='col-sm-8 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-12'>
            <div className='list-car-view-details'>
              <div className="grid-title-colum-v2"> { object_pending_reviews?.newvaluation?.year !=undefined?
object_pending_reviews?.newvaluation?.year+" "+ (object_pending_reviews?.newvaluation?.make !=undefined ?object_pending_reviews?.newvaluation?.make:"") +" "+ (object_pending_reviews?.newvaluation?.model !=undefined ?object_pending_reviews?.newvaluation?.model:""):(object_pending_reviews?.newvaluation?.make !=undefined ?object_pending_reviews?.newvaluation?.make:"")+" "+ (object_pending_reviews?.newvaluation?.model !=undefined ?object_pending_reviews?.newvaluation?.model:"")  }</div> 
              <div className="home-viewcar-no-v2">{object_pending_reviews?.newvaluation?.vrm}</div>   
              <div className='car-extra-info'>
              {
               ( (object_pending_reviews?. seler_id)?.toString()==localStorage.getItem('dealer_id'))?(
                <>
                <ul className='ul-fixed-width'>
                <li>Winning Buyer:</li>
                <li>Contact:</li>
                <li>Email: </li>
                <li>Phone</li>
              </ul>
         
              <ul className='car-extra-details-list'>
            <li>{object_pending_reviews?.buyer_detail?.company_name}</li>

            <li>{object_pending_reviews?.buyer_detail?.name ==""?"N/A":object_pending_reviews?.buyer_detail?.name} </li>
            <li>{object_pending_reviews?.buyer_detail?.email} </li>
            <li>{object_pending_reviews?.buyer_detail?.phone_number}</li>
          </ul>
                </>
            
          ):(
            <>

            
            <ul className='ul-fixed-width'>
                <li>Winning Seller:</li>
                <li>Contact:</li>
                <li>Email: </li>
                <li>Phone</li>
              </ul>
         
              <ul className='car-extra-details-list'>
            <li>{object_pending_reviews?.seller_detail?.company_name}</li>
            <li>{object_pending_reviews?.seller_detail?.name ==""?"N/A":object_pending_reviews?.seller_detail?.name} </li>
            <li>{object_pending_reviews?.seller_detail?.email} </li>
            <li>{object_pending_reviews?.seller_detail?.phone_number}</li>
          </ul></>)
              }
              </div>   
            </div>
          </div>
          <div className='col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
            <div className='right-side-part'>
              <div className='bid-price'>
                <div className='bid-group-mobile'>
                <div className='sm-black-text'>Winning Bid</div>
                <div className='lg-green-text'>{new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',
                                                                        })
                                                                        .format(object_pending_reviews?.saleprice)
                                                                        .replace(/\.00$/, '')}</div>
                </div>
                <div className='md-black-text'>Auction Ended: {moment(object_pending_reviews?.createdAt).format('DD/MM/YYYY, h:mm a')}</div>
              </div>
              <div className='button-part-bottom'>
              <div className='black-text-color'>Star Rating: </div>
              <Rating
             initialValue={     ( (object_pending_reviews?.seler_id)?.toString()==localStorage.getItem('dealer_id'))? object_pending_reviews?.seller_rating:object_pending_reviews?. buyer_rating}
                // onPointerMove={onPointerMove}
                /* Available Props */
                readonly={true}
              />
                
              </div>
            </div>
          </div>
      </div>
      </div> )
      })
    }
  {/* pagination */}

<div className='pagination-wrapper'>
<Pagination   pageinationD={completereviews?.total_page} dispatch={dispatch} action_name={AccountheaderAction} />
</div>
{/*end  pagination */}
    </div>
    </section> 
    </>
  
  )
}
