import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
export default function NotFound() {
  return (
    <>
      <div className='error-page'>
        <div className='container container1200'>
          <div className='error-page-content'>
            <h1>SORRY, THE PAGE NOT FOUND.</h1>
            <p>The page you are looking for is no longer available</p>
            <div className='error-img'>
            <img src={require('../asset_user/images/notfound.svg').default}/>
            </div>
            <NavLink to="#"> Go to Homepage </NavLink>
          </div>
        </div>
      </div>
    </>
  )
}
