import React from 'react'
import { useNavigate } from 'react-router-dom';
export default function FooterHome() {
  const navigate=useNavigate();
  const registration=()=>{
    
  
    navigate("/registration");
    window.scrollTo(0, 0);
  }


  return (
    <footer className='footer-bg-blue'>
        <div className='container container1200'>
        <div className='fotter-logo'>
        <img src={require('../asset_user/images/header-logo/parrot_logo.svg').default} alt='yotrader-logo'  className='header-logo'/>
        </div>
        <div className='row'>
          <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-12 mb-4'>
            <div className='footer-text-heading'>
              Quick Links
            </div>
            <ul className='footer-quicklinks'>
              <li>
                <a  onClick={()=>{
                  navigate("/");
                      window.scrollTo(0, 0);
                }}>Home</a>
              </li>
              <li>
                <a onClick={()=>{
                  navigate("/registration");
                  window.scrollTo(0, 0)
                }} >Join Us</a>
              </li>
              <li>
                <a href="#">Careers <span className='badge-parrot-link'>We're Hiring</span></a>
              </li>
              <li>
                <a href="#" >FAQ</a>
              </li>
            
            </ul>
          </div> 
          <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-12 mb-4'>
            <div className='footer-text-heading'>
            Find Us
            </div>
            <ul className='footer-quicklinks'>
              <li>
                Mansfield Innovation Centre
                <a href="#"></a>
              </li>
              <li>
              Oakham Business Park
              </li>
              <li>
              Mansfield
              </li>
              <li>
              Nottinghamshire
              </li>
              <li>
              NG18 5BR
              </li>
            </ul>
          </div> 
          <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-12 mb-4'>
            <div className='footer-text-heading'>
            Contact Us
            </div>
            <ul className='footer-quicklinks'>
              <li>
                <a href="tel:01156940404" className='underline-none'>0115 6940 404</a>
              </li>
              <li>
                <a href="mailto:sales@yotrader.co.uk" className='underline-none parrot-color-link'>sales@yotrader.co.uk</a>
              </li>              
            </ul>
          </div>        
        </div>
        
      </div>  
    </footer>
  )
}
