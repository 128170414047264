import { useDispatch } from 'react-redux'


import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import CurrencyInput from 'react-currency-input-field';

import { BuyNowAction, CounterofferAction } from './Redux/Action/SellerVehiclesActions';


export default function BidLIstShow(props) {


    const [show, setShow] = useState(false); 
    const [showcounteroffer, setshowcounteroffer] = useState(false); 
    const [vrm, setVrm] = useState(""); 
    const [price,setprice]=useState(0)
    const [user_bid_id,setUser_bid_id]=useState("")
    const [counteroffer,setcounteroffer]=useState(0)
    const [user_id,setUser_id]=useState("")


   const dispatch=useDispatch();
   


   //submit accepet offer 
    const sumitbid=()=>{
    if(( props?.offer_expire !=undefined && Math.ceil(( new Date(props?.offer_expire)-new Date() ) / 36e5)==0)){
        dispatch(BuyNowAction(({vrm:vrm,user_id:user_id,counterofferprice:counteroffer})))
        setShow(false)
        
    }else{
        setShow(false)
        toast.warning("You will accept offer after auction end")
    }
      }

  //end accepet offfer


  //modal close function 

const handleClose = () => {
  setcounteroffer(0)
  setUser_id("")
    setShow(false)
    
};

const handleClosecounteroffer = () => {

    setshowcounteroffer(false)
    
};

const CounterofferActionsave=()=>{


    var heightestbidvalue= props?.Bidlist?.length ==0?"0":(props?.Bidlist?.length==0?0: Math.max(parseInt( props?.Bidlist?.map(o => o.yotrader_value))))
    if(price !=0 && price !=""){
        if(price>heightestbidvalue){
            dispatch(CounterofferAction({
                vrm:vrm,
                user_id:user_id,
                counter_offer:price,
                user_bid_id:user_bid_id
            }))
            props.callaftercounteroffer();
            setshowcounteroffer(false)
        }else{
            toast.warning("The counteroffer value is always greater than the highest bid value.")
        }
 
   
    }else{
    toast.warning("Counter offer value is required")
    }
   
}

  return (
    <>
     




{props?.Bidlist?.map(function(data_obj,i){
    if(  data_obj.type==1 ||  data_obj.type==2){
        return(

  <div className='row ' >
     <div className='col-sm-12 col-lg-12 col-md-12 col-xl-12 col col-12'>
     <div className='blue-bg-box-steps mb-3'>
      <div className='row d-flex align-items-center height90'>     
     <div className='col-sm-2 col-lg-2 col-md-2 col-xl-2 col-3 mobile-colum-sho'>
      <div className='logo-symbol-step img-logo-sm'>
      <img src={require('./Component/asset_user/images/logo_symbol.png')} />   
      </div>                        
      </div>
      <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-5'>
      <div className='checkw-text-step'>
      <img src={require('./Component/asset_user/images/check_green.svg').default}/> 
      <div className='check-text-steps '><div className="mobie-hide">YoTrader</div> Trusted Bidder</div> 
      </div>                  
      </div>
      <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-4'>
      <div className='white-price-label-steps'>£ {new Intl.NumberFormat('en-GB')
                      .format(data_obj?.yotrader_value)
                      .replace(/\.00$/, '')}</div>                           
      </div>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col-12 '>
      {
          props?.status !=0 && <div className='green-lg-btn-steps mobile-t-m'>
          <button type="button"   onClick={()=>{
            setVrm(data_obj?.vrm)
          setShow(true)
          }}>Accept Offer</button>
      </div>       
      }
                          
      </div> 
     </div>
      </div>
     </div>                   
  </div> 
)
}else{


return (  
  <div className='green-outline-bg-box mb-3'>
  <div className='row height90 d-flex align-items-center' key={i}>
  <div className='col-sm-2 col-lg-2 col-md-2 col-xl-2 col-3 mobile-colum-show'>
    <div className='logo-symbol-step large-text-hash'>
    # {i+1}  
    </div>                        
    </div>
    <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-5'>
    <div className='checkw-text-step'>
    {/* <img src={require('./UserComponent/asset_user/images/check_green.svg').default}/>  */}
    <div className='check-text-steps blue-text-color'>YoTrader Dealer Bid</div> 
    </div>                  
    </div>
    <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-4'>
    <div className='white-price-label-steps green-text-color'>£{new Intl.NumberFormat('en-GB')
                    .format(data_obj?.yotrader_value)
                    .replace(/\.00$/, '')}</div>                           
    </div>
    <div className='col-sm-12 col-lg-4 col-md-4 col-xl-4 col-12'>
    <div className='table-list-flex'>
    <div className='green-lg-btn-steps mobile-t-m'>
        <button type="button"   onClick={()=>{
            setVrm(data_obj?.vrm)
            setUser_id(data_obj?.user_id)

            setcounteroffer(data_obj?.yotrader_value)
          setShow(true)
        }}>Accept Offer</button>
    </div>       
   <div className='counter_offer_btn'>
        <button type="button" className=' primary-btn-outline-green'  onClick={()=>{
            setVrm(data_obj?.vrm)
            setUser_id(data_obj?.user_id)
            setUser_bid_id(data_obj?._id)
            setprice(data_obj?.counteroffers[0]?.counter_offer)
            setshowcounteroffer(true)
        }}>Counter Offer</button>
    </div> 
    </div>       

</div>     


  </div>
  </div>

    
    
    
    
    
    
    )
}


})}



<Modal show={show} onHide={handleClose} className='small-modal-popup'>
<Modal.Header closeButton>
<Modal.Title>Accept Offer</Modal.Title>
</Modal.Header>
<Modal.Body>Are you sure you want to accept this offer?
This action can't be undone.</Modal.Body>
<Modal.Footer className='d-flex justify-content-between'>


<div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-4 col-12'>
  <div className='bid-blue-sm-btn'>
  <button type="button" onClick={handleClose}>Cancel</button>
  </div>
</div>



<div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-4 col-12'>
  <div className='bid-green-sm-btn'>
  <button type="button" onClick={sumitbid}>Accept Offer</button>
  </div>
</div>
</Modal.Footer>
</Modal>
<Modal show={showcounteroffer} onHide={handleClosecounteroffer} className='default-popup'>
<Modal.Header closeButton>
{/* <Modal.Title>Accept Offer</Modal.Title> */}
</Modal.Header>
<Modal.Body >
<p className='mb-0'>
You’re about to send a counter offer to the buyer. 
The amount you enter will also adjust your reserve price to the same value as the counter offer.
</p>
<div className='row'>
           <div className="col-md-3">
           <div className="current_bid">
           <p className="">Current Bid:</p>
           </div>
           </div>
           <div className="col-md-3">
            <div className="current_bid_price">
            <p className="">{ props?.Bidlist?.length ==0?"£0":new Intl.NumberFormat('en-GB',
                     {   style: 'currency',  currency: 'GBP',}).format((props?.Bidlist?.length==0)?0: Math.max(parseInt( props?.Bidlist?.map(o => o.yotrader_value)))).replace(/\.00$/, '')}</p>
            </div>
           </div>
    </div>
    <div className='row align_bid'>
      <div className="col-sm-3 col-lg-3 col-md-3 col-xl-3 col-xxl-3 col-3">
      <div className="current_bid enter_amount">
      <p className="">Counter Offer:</p>
      </div>
      </div>
      <div className="col-sm-4 col-lg-4 col-md-4 col-xl-4 col-xxl-4 col-4">
      <div className="current_bid_price bid-input-listing m-0">



      <CurrencyInput
                  
                  className='form-control'
                intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                  onChange={(e)=>{ 
                      setprice(  Number(e.target.value.replace(/[^0-9\.-]+/g,"")))
                      }}


                placeholder={'Enter Amount'}

                value={price}
                                />
      </div>
      </div>
      <div className='col-sm-5 col-lg-5 col-md-5 col-xl-5 col-xxl-5 col-5'>
        <div className='bid-blue-sm-btn modal_blue_btn'>
        <button type="button" onClick={CounterofferActionsave}>Submit Counter Offer</button>
        </div>
      </div>
    </div>
</Modal.Body>


    
<Modal.Footer>



</Modal.Footer>
</Modal>

    </>
  )
}
