import React, { useRef, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import ReactEncrypt from 'react-encrypt'

export default function Conditionreport1() {
    const [loader,setLoader]=useState(1)
    const urlParams = new URLSearchParams(window.location.search);
    var manualstatus=""


if(((urlParams.get('manual')==1&&`&manual=1`)) !=false){

var manualstatus="&manual=1";
}

  return (
    // <UserMaster>    
    <div className='white-bg-cover'>
            <div className='container container1200'>                
                <div className='row'>                
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'> 
                    <div className='full-height-view'>
                       {/* Back step*/}               
                       <div className='white-bg-step '>  
                        <NavLink to={("/all-vehicles")} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/> Cancel Auction Setup
                        </NavLink>                       
                        </div>

                        <div className='progress-part-view'>
                            <div className='sm-blue-text-progress'>Terms & Conditions</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'6%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>

                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'>                        
                        <div className='step-progress-heading'>Auction Terms</div>
                            <div className='sub-heading-step'>Terms & Conditions *</div>
                            <div className='step-grey-text'>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas sed orci lectus. Vivamus bibendum, sapien vitae laoreet pulvinar, augue erat ultricies lectus, vitae sagittis lectus diam vitae purus. Duis dignissim, quam ac sagittis consectetur, sapien nunc varius lectus, nec ultricies urna ipsum a libero. Integer ac nulla tellus. Morbi condimentum elit ac ex feugiat, ac aliquet est maximus. Morbi odio massa, rutrum vel accumsan a, accumsan non nisl. Nulla placerat sollicitudin mauris eu porttitor. Vestibulum id tempus ligula. Donec nec luctus neque, vel euismod purus. Nullam feugiat odio ut augue consectetur malesuada. Morbi volutpat lorem vel eros congue, in pulvinar ex ullamcorper. In in purus tempus, venenatis eros quis, vestibulum velit. Praesent volutpat metus consequat lobortis tempor. Mauris vel pulvinar libero. Pellentesque id mollis nisl, eu feugiat urna.
                            </div>
                            <div className='link-blue-underline'>
                            <NavLink to="/terms" target='blank'> Full Terms & Conditions</NavLink>
                            </div>
                        </div>

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step invisible" to="/conditionreport2">Next</NavLink>
                        </li>
                


                        <li className="nav-item">
                        {
                            loader==1?( 
                                
                                <NavLink className="nav-link blue-btn-step" to={("/conditionreport2?id="+((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))))+manualstatus}> Next</NavLink>

                            ):( 
                            
                           
                            
                           <NavLink className="nav-link blue-btn-step" to={("/conditionreport2?id="+((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))))+manualstatus}><i className='fa fa-spinner fa-spin '></i> Next</NavLink>

                            
                            
                            )
                        }
                           
                        </li>
                        </ul>
                        </div>    
                    </div>                     
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>
                        </div>                        
                    </div>
                </div>
            </div> 
        <div className="blue-bg-right"></div>
    </div>
    
  )
}
