
  import {
    useElements,
    useStripe,
    CardElement,
 
  } from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import { UpdatepaymentmethodAction } from "../../../Redux/Action/AuthenticationAction";

import io from "socket.io-client";
import { Button } from "react-bootstrap";
  const PaymentForm = ({ open, onClose }) => {

 



    const stripe = useStripe();
    const elements = useElements();
    const dispatch=useDispatch();
    const socket =  io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: true,
    });
    const handleSubmit = (stripe, elements) => async () => {
        const cardElement = elements.getElement(CardElement);
      
        const {error, paymentMethod} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        });
      
        if (error) {
          console.log('[error]', error);
        } else {
          console.log('[PaymentMethod]', paymentMethod);
  
   
   
           dispatch(UpdatepaymentmethodAction({
            payment_link:paymentMethod.id
           }))
        onClose()
        
           
        }
      };

    return (
      <>    


    
      <div className="">In order to continue, please enter your payment details below.
        Payments are only processed after the successful completion of a purchase/ sale. 
        You will not be charged to list or bid on an item</div>  
        <br />
        <CardElement   />       
        <div className='green-btn-pop-large' >
     <button type='button'  onClick={handleSubmit(stripe, elements)}>Submit</button>
   </div>




 
      </>
    );
  }

    export default  PaymentForm