import {  BUY_PRODUCT_DETAILS, COMPLETE_REVIEWS, HEADER_ACCOUNT_COUNT, HEADER_COUNT_BUYER, LIVE_BIDS_VEHICLE, LOST_ACTION, MAKE_MODEL_LIST, MODAL_CLOSE, MY_BID_LIST, MY_PASS30DAY_BUYER_LIST, MY_PURCHES_LIST, PENDING_COLLECTION, PENDING_REVIEWS, WATCH_LIST_DATA } from "../Constant/Constant";

const initialization={
    live_auction:[],
    make_model_list:[],
    mypurcheslist:[],
    my_bid_list:[],
    watching_list:[],
    buyer_header_count:[],
    pending_collection:[],
    pending_reviews:[],
    buy_product_details:[],
    complete_reviews_list:[],
    account_header_count:[],
    modalstatus:"",
    lost_list:[],
    buyer_past30days:[]

}



export default function BuyerReducer(state=initialization,action) {
    switch(action.type){

        case LIVE_BIDS_VEHICLE:
            return({
                ...state,
                live_auction:action.payload
            });
            case MAKE_MODEL_LIST:
                return({
                    ...state,
                    make_model_list:action.payload
                });
            case MY_PURCHES_LIST:
            return({
                ...state,
                mypurcheslist:action.payload
            });
            case MY_BID_LIST:
                return({
                    ...state,
                    my_bid_list:action.payload
                });
              
            case WATCH_LIST_DATA:
            return({
                ...state,
                watching_list:action.payload
            });
            
            
            case HEADER_COUNT_BUYER:
                return({
                    ...state,
                    buyer_header_count:action.payload
                });
            case PENDING_COLLECTION:
                return({
                    ...state,
                    pending_collection:action.payload
                });
            case PENDING_REVIEWS:
                return({
                    ...state,
                    pending_reviews:action.payload
                });
                
            case BUY_PRODUCT_DETAILS:
                return({
                    ...state,
                    buy_product_details:action.payload
                });
                    
            case COMPLETE_REVIEWS:
                return({
                    ...state,
                    complete_reviews_list:action.payload
                });
            case HEADER_ACCOUNT_COUNT:
                return({
                    ...state,
                    account_header_count:action.payload
                });

                case MODAL_CLOSE:
                    return({
                        ...state,
                        modalstatus:action.payload
                    });
            case LOST_ACTION:
            return({
                ...state,
                lost_list:action.payload
            });                     
            case MY_PASS30DAY_BUYER_LIST:
                return({
                    ...state,
                    buyer_past30days:action.payload
                });      
                    
            
            default:

            return state

    }
}


