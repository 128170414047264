import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'

import { io } from 'socket.io-client';

import { GetMyvehiclesConditionreportmyvehicle } from '../../Redux/Action/SellerVehiclesActions';
import BidLIstShow from '../../BidLIstShow';

export default function Conditionreport3() {
    const urlParams = new URLSearchParams(window.location.search);
    const [loader,setLoader]=useState(1)
    const dispatch=useDispatch();
    const navigate=useNavigate()
    const [condition_report_value,setcondition_report_value]=useState()
    useEffect(()=>{
             dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
               
                setcondition_report_value(data)
                
             })
    },[])

    const socket=io(process.env.REACT_APP_SOCKET_URL, {
      autoConnect: true,
    })


    socket.on(`bidend${condition_report_value?.add_condition_report_candition?.vrm}`,(values)=>{

      
           
      navigate('/vehiclesolddetail?id='+(condition_report_value?.add_condition_report_candition?.vrm))
      
      })
console.log(condition_report_value)
 
  return (

    <div className='white-bg-cover'>
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>  
                    <div className='full-height-view'>
                        {/* Back step*/}               
                        <div className='white-bg-step '>  
                        <NavLink to={("/all-vehicles")} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to All Stock
                        </NavLink>                       
                        </div>  
                        <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Bids Received</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'18%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'>                       
                     
                        <div className='step-progress-heading'>You’ve got bids!</div>
                        <div className='row mb-4'>
                            <div className='col-md-12 text-center'>
                            <div className='blue-text-steps mb-1'>Congratulations! You’ve already received { condition_report_value==undefined?"0 bid": (condition_report_value?.Bidlist?.length)+   condition_report_value?.Bidlist?.length<=1?"bid":"bids"}  from YoTrader’s Trusted Bidders! </div>
                           
                           
                           {

                            condition_report_value !=undefined &&
                          (  ( condition_report_value?.Bidlist[0]?.yotrader_value>( condition_report_value?.add_condition_report_candition?.price_reserve||condition_report_value?.add_condition_report_candition?.price_now))?  <div className='green-text-steps mb-1'>Your highest bid is already more than you wanted!</div>:  <div className='green-text-steps mb-1'>Your Highest Bid is {


                            
          condition_report_value?.add_condition_report_candition?.price_reserve==undefined? new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                      })
                        .format(parseInt(condition_report_value?.add_condition_report_candition?.price_now -(condition_report_value?.Bidlist.length==0?0: condition_report_value?.Bidlist[0]?.yotrader_value)))
                        .replace(/\.00$/, ''):
          new Intl.NumberFormat('en-GB', {
                        style: 'currency',
                        currency: 'GBP',
                      })
              .format(condition_report_value?.add_condition_report_candition?.price_reserve - (condition_report_value?.Bidlist.length==0?0: condition_report_value?.Bidlist[0]?.yotrader_value))
              .replace(/\.00$/, '')
         } away from your { condition_report_value?.add_condition_report_candition?.price_reserve==undefined?"buy it now price":"reserve price"}!</div>
                      )     }
                           
                           
                           
                           
                           
                          
                            <div className='blue-text-steps mb-1'>You can accept these right away or send your vehicle to a live auction with over 3000 active bidders!</div>
                            </div>                            
                        </div>

                        {
                          condition_report_value !=undefined && <BidLIstShow  Bidlist={condition_report_value?.Bidlist}   offer_expire={ condition_report_value?.add_condition_report_candition?.offer_expire}/>
                        }
                       

                           
                         
                        
                           
                        </div>

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step" to={"/sendtoAuctiondetails?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Save for Later</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link blue-btn-step "   to={"/conditionreport4?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Send to Auction</NavLink>


                             
                        </li>
                        </ul>
                        </div>
                    </div>                        
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>
                        <div className='right-tooltip-part'>
                            <div className='white-box-bg'>


                          {
                            ( condition_report_value?.Bidlist[0]?.yotrader_value>( condition_report_value?.add_condition_report_candition?.price_reserve||condition_report_value?.add_condition_report_candition?.price_now))?  <div className='white-box-text'>Your highest bid is already more than you wanted!</div>:
                       
                            <div className='white-box-text'>
                                     <span className='green-text'>  {
                            
                            condition_report_value?.add_condition_report_candition?.price_reserve==undefined? new Intl.NumberFormat('en-GB', {
                                          style: 'currency',
                                          currency: 'GBP',
                                        })
                                          .format(condition_report_value?.add_condition_report_candition?.price_now - (condition_report_value?.Bidlist.length==0?0: condition_report_value?.Bidlist[0]?.yotrader_value))
                                          .replace(/\.00$/, ''):
                            new Intl.NumberFormat('en-GB', {
                                          style: 'currency',
                                          currency: 'GBP',
                                        })
                                          .format(condition_report_value?.add_condition_report_candition?.price_reserve - (condition_report_value?.Bidlist.length==0?0: condition_report_value?.Bidlist[0]?.yotrader_value))
                                          .replace(/\.00$/, '')
                        
                        }</span> away from your { condition_report_value?.add_condition_report_candition?.price_reserve==undefined?"buy it now price":"reserve price"}!
                                </div>
                          }
                               
                                <div className='corner-cut'>
                                <img src={require('../asset_user/images/corner.svg').default}/> 
                                </div>
                            </div>
                            <div className='sm-logo-tooltip'>
                            <img src={require('../asset_user/images/logo_symbol.png')} />   
                            </div>
                        </div>
                        </div>                        
                    </div>
                </div>

            </div>
        <div className="blue-bg-right"></div>
    </div>

  )
}
