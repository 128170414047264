import React from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NavLink } from 'react-router-dom';
import HeaderHome from '../IncludeHome/HeaderHome';
import FooterHome from '../IncludeHome/FooterHome';

export default function Step1Registration({ formData, setFormData, nextStep }) {
  const formik = useFormik({
    initialValues: {
      name: formData.name || '',
      position: formData.position || '',
      email: formData.email || '',
      confirmemail: formData.confirmemail || '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().required('Email is required'),
      position: Yup.string().required('Position is required'),
    confirmemail:Yup .string().required('Confirm Email is required').oneOf([Yup.ref('email'), null], 'Email must match')  
    }),
    onSubmit: values => {
      // Handle step 1 submission
      setFormData({
        ...formData,
        ...values,
      });
      nextStep();
    },
  });
  return (
    <>
    <HeaderHome />
 <form onSubmit={formik.handleSubmit}>
    <section className='form-layout'>
        <div className='container container1200'>
            <div className='row'>
                <div className='col-sm-10 col-md-8 col-lg-8 col-xl-8 col-12 mx-auto'>
                    <div className='card-white-form mtb-150 signup-card-o'>
                        <div className='form-content'>
                            <div className='black-text-large mb-3'>Start your free membership today!</div>
                            <div className='black-text-paragraph-sm mb-3'>Just three quick and easy steps to get online.</div>
                            <div className='black-text-paragraph-sm mb-3'>To qualify for an account, you’ll need to be a registered motor trader and you’ll need to provide us with your DMS provider information. </div>
                            <div className='step-circle-part'>
                                <ul>
                                    <li>
                                        <NavLink to="" className="blue-circle-active">1</NavLink>
                                        <span>Your Details</span>
                                    </li>
                                    <li>
                                        <NavLink to="">2</NavLink>
                                        <span>Company Details</span>
                                    </li>
                                    <li>
                                        <NavLink to="">3</NavLink>
                                        <span>Confirmation</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='form-part-signup'>
                            <div className='row'>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                    <div className="form-group">
                                        <label for="">Full Name</label>
                                        <input type="text" className="form-control" id="" name='name' placeholder="Type here" 
                                         value={formik.values.name}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          
                                          />
                                            {formik.touched.name && formik.errors.name && (
                                          <div className='error'>{formik.errors.name}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                    <div className="form-group">
                                        <label for="">Position</label>
                                        <input type="text" className="form-control" id="" name='position' placeholder="Select"  
                                         value={formik.values.position}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          
                                          />
                                            {formik.touched.position && formik.errors.position && (
                                          <div className='error'>{formik.errors.position}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                    <div className="form-group">
                                        <label for="">Email</label>
                                        <input type="email" className="form-control" id="" name='email' placeholder="email@email.com" 
                                         value={formik.values.email}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          
                                          />
                                            {formik.touched.email && formik.errors.email && (
                                          <div className='error'>{formik.errors.email}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                    <div className="form-group">
                                        <label for="">Confirm Email</label>
                                        <input type="email" className="form-control" id="" name='confirmemail' placeholder="email@email.com"   value={formik.values.confirmemail}   onChange={formik.handleChange}
                                          onBlur={formik.handleBlur}
                                          
                                          />
                                            {formik.touched.confirmemail && formik.errors.confirmemail && (
                                          <div className='error'>{formik.errors.confirmemail}</div>
                                        )}
                                    </div>
                                </div>
                                <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                <div className='next-btn-part'>
                                        <NavLink to="" className="primary-btn-white invisible">Back</NavLink>
                                        <button  type='submit' className="primary-btn-blue">Next</button>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </form>
    <FooterHome />
    </>
   
  )
}

