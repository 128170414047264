const haversine = require("haversine")

 const  Calculatemiles=(start,live_bids_obj)=>{
  console.log(live_bids_obj)
  
    if(live_bids_obj?.user_detail==undefined){
      if(live_bids_obj !=undefined){

        return Math.ceil(haversine(start, {latitude:live_bids_obj?.user_id?.location.coordinates[0],longitude:live_bids_obj.user_id?.location.coordinates[1]}, {unit: 'mile'}))
      }

        
    }else{
        return Math.ceil(haversine(start, {latitude:live_bids_obj?.user_detail[0]?.location.coordinates[0],longitude:live_bids_obj.user_detail[0]?.location.coordinates[1]}, {unit: 'mile'}))
    }
      
    }

    export default Calculatemiles