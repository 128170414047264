import React, { useEffect, useState } from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Getvaluationvehicleunderwrite_data, Getvaluationvehicleunderwritevrm, MannualValuation, VehicleslistsellerAction, deletenewvaluation } from '../../Redux/Action/SellerVehiclesActions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { io } from 'socket.io-client';
import ReactPaginate from 'react-paginate';
import FooterHome from '../IncludeHome/FooterHome';
import CalculateCountdown from '../CalculateCountdown/CalculateCountdown';
import { SalerheaderAction } from '../../Redux/Action/BuyerVehilesAution';
import Loader from 'react-loader-spinner'
import Countdown from '../CalculateCountdown/Countdown';
export default function MyAuction() {

  const [page_no, setpage_no] = useState(1);
  const dispatch=useDispatch();
  const [loadIndicator, setLoadIndicator] = useState(true);
  useEffect(() => {
      dispatch(MannualValuation({page_no:1})).then(function(){
    setTimeout(()=>{
      setLoadIndicator(false)
    },600)
       
      });
      dispatch(SalerheaderAction());
    },[] );
    const mannual_valuation = useSelector(
      (state) => state.VehicleReducer.mannual_valuation
    );

    const [newvaluationid,Setnewvaluationid]=useState("")

const [vehiclevrm, setvehiclevrm] = useState('');


    const [showaccept, setaccept] = useState(false);


    const vehicle_underwrite=()=>{
      setaccept(true)
    }
    const handleshowaccept=()=>  setaccept(false);
    const ManualUploadClose = () => setmanualupload(false);
    const navigate=useNavigate();
    const [manualupload, setmanualupload] = useState(false);
    const formikunderwrite = useFormik({
      initialValues: {
        vrm: '',
         mileage: '',
      },
      validationSchema: Yup.object({
        vrm: Yup.string().required('TYPE VRM is required'),
        mileage: Yup.string().required('Mileage is required'),
      }),
      onSubmit: (values) => {
        values.type="GETOFFER";
        
        setLoadIndicator(true)
      
        dispatch(Getvaluationvehicleunderwritevrm(values)).then(function(data){
        if(data !=undefined){
          navigate('/Conditionreport1?vrm='+data?.vehicle?.vrm+"&manual=1")
        
          setaccept(false)
         setLoadIndicator(false)
          formikunderwrite.resetForm()
        }else{
          toast.warn('VRM could not be found. Please try a different VRM')
          setaccept(false)
          setLoadIndicator(false)
          formikunderwrite.resetForm()
        }
        })
        
         
         
     
      
      
      },
    });



//end Add All Optional Extras Below
const [vrmcondtionreport,Setvrmconditionreport]=useState("")

const condition_report=(vrm)=>{
Setvrmconditionreport(vrm)
}





//timer 







//end timer

//pagination 


const handlePageClick =  (page_no) => {


// setpage_no(page_no?.selected + 1)

dispatch(
  MannualValuation({page_no:page_no?.selected + 1})
);
};




const socket =  io(process.env.REACT_APP_SOCKET_URL, {
  autoConnect: true,
});
useEffect(()=>{
  

  socket.on("buyerportal", (value) => {

          dispatch(MannualValuation({page_no:1})); 
      
     
     
    });

    


//     return () => {
//     socket.disconnect();
//   };
},[dispatch])


window.totalmyauction=mannual_valuation?.totalnewvalution

const header_count = useSelector(
  (state) => state.VehicleReducer?.header_count
);

  return (
    <>
<HeaderDashboard />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>
       <ul className='sub-menu-dashboard'>
        <li>
          <NavLink to="/all-vehicles" >My Stock <span>({window.allvehicles ?? header_count?.total_vehicles})</span></NavLink>
        </li>
        <li>
          <NavLink to="/myauction" className='active-border'>My Auctions <span>({window.totalmyauction ?? header_count?.totalmyauctionsvehicles})</span></NavLink>
        </li>
        <li>
          <NavLink to="/draft">Drafts <span>({window.totaldraftvehicles ?? header_count?.totaldraftvehicles})</span></NavLink>
        </li>
        <li>
          <NavLink to="/soldvehicles" >Sold Vehicles <span>({header_count?.totalsoldvehicles})</span></NavLink>
        </li>   
       </ul> 
     
      </div>
      <div className='col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn'>
       <NavLink to="" className='blue-btn-square' onClick={()=>{
        setmanualupload(true)
       }} >Manual Upload</NavLink>  
       <NavLink to="/liveauction" className='blue-outline-btn-square'>Live Auctions</NavLink>
       </div>
      </div> 
      <div className='col-sm-6 col-md-12 col-lg-12 col-xl-3 col-xxl-3 col-12 '>
      {/* <div className='app-search d-lg-block '>
        <div className='d-flex justify-content-between align-items-center search_box'>
          <div className='position-relative w-100'>
            <input
            className='form-control'
            placeholder='VRM, Make, Model'
            type='search'
            />
            <span className='fa fa-search'></span>
          </div>
        </div>
      </div> */}
      <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
  </div>  
       
    </div>
    </div> 
      </div>
    </section>
    {/* mobile filter show */}
    <section className='filter-home-v2'>
    <div className='mobile-filter-show'>
      <ul>
        <li className='bor-r-c' onClick={()=>{
          // setShowfilterhome(true)
        }}><NavLink to="#" className='sort-home active-color-stock'>Days In Stock</NavLink> </li>
        <li >
        <div className={"dropdown show "}>
        <div className="dropdown-toggle sort-home" type="button" id="dropdownMenuButton" data-toggle="dropdown" onClick={()=>{
          // setFiltersorthome(Filtersorthome==""?"show":"")
        }} aria-haspopup="true" aria-expanded="false">
        Sort By
        </div>
        <div className={"dropdown-menu  "} aria-labelledby="dropdownMenuButton">
          <a className="dropdown-item" href="#">Price (Lowest)</a>
          <a className="dropdown-item" href="#">Price (Highest)</a>
          <a className="dropdown-item" href="#">Latest Car</a>
          <a className="dropdown-item" href="#">Oldest Car</a>
          <a className="dropdown-item" href="#">Make</a>
          <a className="dropdown-item" href="#">Model</a>
          <a className="dropdown-item" href="#">Age (Newest First)</a>
          <a className="dropdown-item" href="#">Distance</a>
          <a className="dropdown-item" href="#">Mileage (Lowest First)</a>
        </div>
      </div>
        </li>
      </ul>
    </div>
    </section>
    {/* main grid data show v2 */}
    <section className='list-data-show mt30'>
    <div className='container container1224'>
    <div className='loader-full' style={{display:(loadIndicator==false?"none":"")}}>
        {
              loadIndicator && <Loader type="Oval"  width={100} radius={5}  color="#323b65" height={65}  />
          }        
        </div>

    <div className='product-grid-v2'>

    <div className='row'>
                {
                  (  mannual_valuation?.newvaluationlist?.length>0) &&
                  mannual_valuation?.newvaluationlist?.map(function (vehicle_detail,i) {

                          return (
                            <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4' key={i}>
                              <div className='mobile-card-layout1 card-v2'>
                                <div className='card-body-v2'>
                                                          
                                  <div className='bids_inner_part'>
                                    <div className='img_car_bg '>
                                      <div className='img_relative position-relative' >
                                        <img
                                          src={ vehicle_detail?.images?.length>0?((vehicle_detail?.images[1]==false||vehicle_detail?.images[0]=="")?require('../asset_user/images/placholder-img.png'):vehicle_detail?.images[1]): require('../asset_user/images/placholder-img.png')  }
                                          className='img-fluid'
                                          onError={(e) => {
                                            e.currentTarget.src = require('../asset_user/images/placholder-img.png');
                                          }}
                                          alt='car'
                                        />
                                      </div>                                 
                                    </div>
                                    <div className='grid-title-colum-v2'>
                                     { vehicle_detail?.year !=undefined? vehicle_detail?.year+" "+ (vehicle_detail?.make !=undefined ?vehicle_detail?.make:"") +" "+ (vehicle_detail?.model !=undefined ?vehicle_detail?.model:""):(vehicle_detail?.make !=undefined ?vehicle_detail?.make:"")+" "+ (vehicle_detail?.model !=undefined ?vehicle_detail?.model:"")  }
                                    </div>  
                                    <div className='car-quick-details-v2'>
                                    


                                          <ul>
                                            <li>   {  vehicle_detail.year ??"N/A"} </li>
                                            <li>{new Intl.NumberFormat('en-GB')
                                            .format(vehicle_detail.mileage)
                                            .replace(/\.00$/, '')} miles</li>
                                            <li>  {  vehicle_detail.fuel ??"N/A"}</li>
                                            <li> {  vehicle_detail.auto_manual ??"N/A"}</li>
                                          </ul>



                                    </div>   
                                    <div className='section-divide'>
                                      <div className='d-flex justify-content-between'> 
                                        <div className='address-part'>
                                        <img src={require('../asset_user/images/red_map.svg').default}  className='red-map'/>
                                        <div className='blact-text-sm'>Southend on sea</div>
                                        </div>                                 
                                        <div className='home-viewcar-no-v2'>
                                          {vehicle_detail.vrm}
                                        </div>
                                      </div>
                                    </div>
      
                                    <div className='retailprice-part-v2'>
                                      <div className='d-flex justify-content-between'>
                                      <div className='left-side-price'>
                                          <span>Current Bid:</span>
                                          <div className='blue-text-md'>
                                          {vehicle_detail?.yotraderbids?.length ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((vehicle_detail?.yotraderbids?.length==0)?0: Math.max(...vehicle_detail.yotraderbids.map(o => o.yotrader_value))).replace(/\.00$/, '')}
                                          </div>
                                          {(((vehicle_detail?.yotraderbids?.length ==0)?0 : 
                                                            Math.max(...vehicle_detail.yotraderbids.map(o => o.yotrader_value)))>=vehicle_detail?.price_reserve)?<div className='biding-end-text-green'>Reserve met </div>:(vehicle_detail?.price_reserve !=undefined && <div className='red-text-label'>Reserve not met  </div>)}
                                        </div>

                                        <div className='retail-price-v2 dark-gry'>
                                          <span>Buy Now</span>
                                          <div className='blue-text-lg-v2'>
                                          {new Intl.NumberFormat('en-GB', {
                                            style: 'currency',
                                            currency: 'GBP',
                                          })
                                            .format(vehicle_detail?.price_now)
                                            .replace(/\.00$/, '')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className='get-bid-btn-part-v2 dark-gry'>
                                    <div className='totl_price'>                               
                                      <div className='end-auction-part'>
                                        <div className='end-auction-black'>Auction Ends: </div>
 
                                        <Countdown endDate={vehicle_detail?.offer_expire} navigate={navigate} />
                                      </div>         
                                      <button className='home-square-white-btn-v2' onClick={() =>    navigate(`${Math.ceil(( new Date(vehicle_detail?.offer_expire)-new Date() ) / 36e5)>0?'/Vehiclesdetails?id':"/sendtoAuctiondetails?id"}=${vehicle_detail.vrm}`)}
                                                        >{`${Math.ceil(( new Date(vehicle_detail?.offer_expire)-new Date() ) / 36e5)>0?'View All Offers':"View Details"}`}</button>
                          
                                  
                                    </div>

                                    </div>

                                  

                                    
                                  </div>
                                </div>
                              </div>
                            
                            </div>
                            
                          );
                      

                        
                        
                  })      }

         
                  



                  <div className='pagination-wrapper'>
                  <ReactPaginate
                    previousLabel={'previous'}
                    nextLabel={'next'}
                    breakLabel={'...'}
                    pageCount={mannual_valuation?.total_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination justify-content-center'}
                    pageClassName={'page-item'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'page-item'}
                    previousLinkClassName={'page-link'}
                    nextClassName={'page-item'}
                    nextLinkClassName={'page-link'}
                    breakClassName={'page-item'}
                    breakLinkClassName={'page-link'}
                    activeClassName={'active'}
                  />
                </div>
                </div>
    </div>  
{/* <Modal className="filter-part-home left"show={showfilterhome} onHide={handleClosefilterhome}>
<Modal.Header closeButton>
  <Modal.Title>Filter</Modal.Title>
</Modal.Header>
<Modal.Body>
<div className="row">
<div className="col-md-12 mb-3">
<div className="form-style-label">Sort By</div>
<div className="form-input-style">
<select>
<option value="">Show All</option>
<option value="-1">Newly Listed (Newest First)</option>
<option value="0">Ending Soonest</option>
<option value="1">Price Lowest (Lowest Price first)</option>
</select>
</div>
</div>
<div className="border-b-filter"></div>
<div className="col-md-12 mb-3">
<div className="form-style-label">Make</div>
<div className="form-input-style">
<select>
<option value="">Show All</option>
<option value="DODGE (USA)">DODGE (USA)</option>
<option value="RENAULT">RENAULT</option>
<option value="PEUGEOT">PEUGEOT</option>
</select>
</div>
</div>
<div className="col-md-12 mb-3">
<div className="form-style-label">Model</div>
<div className="form-input-style">
<select>
<option value="">Show All</option>
</select>
</div>
</div>
<div className="border-b-filter"></div>
<div className="col-md-12 mb-3">
<div className="form-style-label">Mileage</div>
<div className="form-input-style">
<select>
<option value="">Show All</option>
<option value="0-100">Up to 100 miles</option>
<option value="0-1000">Up to 1,000 miles</option>
<option value="0-5000">Up to 5,000 miles</option>
<option value="0-10000">Up to 10,000 miles</option>
<option value="0-20000">Up to 20,000 miles</option>
<option value="0-40000">Up to 40,000 miles</option>
<option value="0-60000">Up to 60,000 miles</option>
<option value="0-80000">Up to 80,000 miles</option>
<option value="0-8000000">Over 80,000 miles</option>
</select>
</div>
</div>
<div className="col-md-6 mb-3">
<div className="form-style-label">Age</div>
<div className="form-input-style">
<select>
<option value="">Min</option>
<option value="0">Any</option>
<option value="2023">2023</option>
<option value="2022">2022</option>
<option value="2021">2021</option>
<option value="2020">2020</option>
<option value="2019">2019</option>
<option value="2018">2018</option>
<option value="2017">2017</option>
<option value="2016">2016</option>
<option value="2015">2015</option>
<option value="2014">2014</option>
<option value="2013">2013</option>
<option value="2012">2012</option>
<option value="2011">2011</option>
<option value="2010">2010</option>
<option value="2009">2009 and Older</option>
</select>
</div>
</div>
<div className="col-md-6 mb-3">
<div className="form-style-label invisible">Age</div>
<div className="form-input-style">
<select>
<option>Max</option>
<option value="0">Any</option>
<option value="2023">2023</option>
<option value="2022">2022</option>
<option value="2021">2021</option>
<option value="2020">2020</option>
<option value="2019">2019</option>
<option value="2018">2018</option>
<option value="2017">2017</option>
<option value="2016">2016</option>
<option value="2015">2015</option>
<option value="2014">2014</option>
<option value="2013">2013</option>
<option value="2012">2012</option>
<option value="2011">2011</option>
<option value="2010">2010</option>
<option value="2009">2009 and Older</option>
</select>
</div>
</div>
<div className="col-md-12 mb-3">
<div className="form-style-label">Price Range</div>
<div className="form-input-style">
<select>
<option>View All</option>
<option value="1000-5000">£1,000 - £5,000</option>
<option value="5000-10000">£5,000 - £10,000</option>
<option value="10000-15000">£10,000 - £15,000</option>
<option value="15000-20000">£15,000 - £20,000</option>
<option value="20000-30000">£20,000 - £30,000</option>
<option value="30000-50000">£30,000 - £50,000</option>
<option value="50000-80000">£50,000 - £80,000</option>
<option value="80000-800000000">£80,000 +</option>
</select>
</div>
</div>
<div className="col-md-12 mb-3">
<div className="form-style-label">Distance Away</div>
<div className="form-input-style">
<select>
<option>View All</option>
<option value="0-10 ">Upto 10 miles</option>
<option value="0-20">Upto 20 miles</option>
<option value="0-30">Upto 30 miles</option>
<option value="0-50">Upto 50 miles</option>
<option value="0-100">Upto 100 miles</option>
<option value="100-1000000000">100 miles +</option>
</select>
</div>
</div>
</div>
<div className="filter-toggle-leftside">
<div className="form-style-label">Show</div>
<div className="row mb-2">
<div className="col-md-6 col-6">
<span className="medium-blue-text-list">Vehicles With Bids</span>
</div>
<div className="col-md-6 col-6">
<div className="toogle-switch-filter-buyer">
<div className="check-box"><input type="checkbox" value="0" /></div>
</div>
</div>
</div>
<div className="row mb-2">
<div className="col-md-6 col-6">
<span className="medium-blue-text-list">Vehicles Without Bids</span>
</div>
<div className="col-md-6 col-6">
<div className="toogle-switch-filter-buyer">
<div className="check-box"><input type="checkbox" value="1" /></div>
</div>
</div>
</div>
<div className="row mb-2">
<div className="col-md-6 col-6">
<span className="medium-blue-text-list">Write Offs</span>
</div>
<div className="col-md-6 col-6">
<div className="toogle-switch-filter-buyer">
<div className="check-box"><input type="checkbox" value="1" /></div>
</div>
</div>
</div>
</div>
<div className="border-b-filter"></div>
<div className="filter-toggle-leftside">
<div className="form-style-label">Show Only</div>
<div className="row mb-2">
<div className="col-md-6 col-6">
<span className="medium-blue-text-list">VAT Qualifying</span>
</div>
<div className="col-md-6 col-6">
<div className="filter-toggle">
<div className="toogle-switch-filter-buyer">
<div className="check-box"><input type="checkbox" value="1" /></div>
</div>
</div>
</div>
</div>
<div className="row mb-2">
<div className="col-md-6 col-6">
<span className="medium-blue-text-list">Plus VAT</span>
</div>
<div className="col-md-6 col-6">
<div className="filter-toggle">
<div className="toogle-switch-filter-buyer">
<div className="check-box"><input type="checkbox" value="1" /></div>
</div>
</div>
</div>
</div>
<div className="border-b-filter"></div>
<div className="row mb-2">
<div className="col-md-12 mb-3">
<div className="form-style-label">Keyword Search</div>
<input className="form-input-style-filter" placeholder="Type Here" />
</div>
</div>
</div>
<div className="row mt-5 mt-mobile">
<div className="col-sm-10 col-lg-6 col-md-6 col-xl-6 col col-6">
<button className="small-filter-blue-outline side-small-btn">Reset</button>
</div>
<div className="col-sm-2 col-lg-6 col-md-6 col-xl-6 col col-6 side-end">
<button className="small-filter-blue-fill side-small-btn">Update</button>
</div>
</div>

</Modal.Body>
<Modal.Footer>

  





</Modal.Footer>
</Modal> */}

{/* conditions modal */}
  </div>
    </section>

    {/* <!--manualupload  Modal --> */}
      <Modal show={manualupload} onHide={ManualUploadClose} className='mt-top'>
    <form onSubmit={formikunderwrite.handleSubmit}>
    <div className='modal-body blue-bg-popup-top'>     
     <Modal.Header closeButton> </Modal.Header>
   <div className="input-group mb-3">     
     <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm'   onChange={formikunderwrite.handleChange} onBlur={formikunderwrite.handleBlur}/>
   </div>
   {formikunderwrite.errors.vrm ? (
                  <p className='error'>{formikunderwrite.errors.vrm}</p>
                ) : null}
   <div className='form-group'>
     <input type="text"  className='form-control white-input-pop'  name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
    placeholder="Mileage" />      
    {formikunderwrite.errors.mileage ? (
                  <p className='error'>{formikunderwrite.errors.mileage}</p>
                ) : null} 
   </div>
   <div className='green-btn-pop-large'>
     <button type='submit'>Get Trade Bids</button>
   </div>
 </div>

</form>


  </Modal>
<FooterHome />
    </>    
  )
}
