import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from 'react-router-dom'
import { useFormik } from 'formik';
import {Button, Modal, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { LoginAction } from '../../Redux/Action/AuthenticationAction';
import { useEffect } from 'react';
import { AccountheaderAction } from '../../Redux/Action/BuyerVehilesAution';



export default function Accountheader() {
    const [password_text, Setpassword] = useState('password');
    const [show, setShow] = useState(false);
    const [showaccount, setShowaccount] = useState(false);
    
    const password_hide_show = () => {
      Setpassword(password_text == 'input' ? 'password' : 'input');
    };
    const dispatch = useDispatch();
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: Yup.object({
        email: Yup.string().email().required('Email is required'),
        password: Yup.string().required('Password is required'),
      }),
      onSubmit: (values) => {
        console.log(values);
        dispatch(LoginAction(values));
      },
    });
  
  
    const [show1, setShow1] = useState(false);
  
  const handleClose = () => setShow1(false);
  const handleCloseaccount = () => setShowaccount(false);
  
  const handleShow = () => setShow1(true);
  
  
  useEffect(()=>{
    dispatch(AccountheaderAction());
  },[])
  const account_header_count = useSelector(
    (state) => state.Buyer?.account_header_count
  );
  return (
    <>


<Navbar expand="lg" className="header_bg_black">
<Container className='container1224'>
<Navbar.Toggle  onClick={handleShow} />
  <Navbar.Brand href="/" className='logo-header'><img src={require('../asset_user/images/header-logo/parrot_logo.svg').default} alt='yotrader-logo'  className='header-logo'/></Navbar.Brand>


  {
    window.location.pathname=="/"?(   <div className='user-icon-drop' onClick={()=>{setShow(true)}}><img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'    />  </div>):( <div className='user-icon-drop' onClick={()=>{setShowaccount(true)}}><img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'    />  </div>)
  }
   



   

    <Nav className="ms-auto headerinner-menu-link"> 
    <NavLink to="/reviews" className='header-white-outline-btn-square mr-10' >Reviews</NavLink> 
      <NavLink to="/dealershipinfo" className='header-green-btn-square mr-30' >Account</NavLink>  
      <NavLink to="" className=''  onClick={()=>{
        localStorage.clear();
        window.location.href="/"
      }}>Log Out</NavLink>         
    </Nav>
</Container>
</Navbar>
{/*  mobile menu sidebar */}
<Offcanvas show={show1} onHide={handleClose} className="offcanvas-sidebar">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>

    <ul className='offcanvas-ul'>
    <li className='white-subHeader-btn justify-content-around'>

      <NavLink to="/all-vehicles"  className='blue-outline-btn-square' >My Stock</NavLink>   
      </li> 
      <li>
      <NavLink to="/dealershipinfo"  className={`${window.location.pathname=="/dealershipinfo"?"active-border":""}`} >Dealership Info</NavLink>
    </li>
    <li>
      <NavLink to="/dealershiprofile" className={`${window.location.pathname=="/dealershiprofile"?"active-border":""}`}>Dealership Profile</NavLink>
    </li>
    <li>
      <NavLink to="/contactperson" className={`${window.location.pathname=="/contactperson"?"active-border":""}`}>Contact Person</NavLink>
    </li>
    <li>
      <NavLink to="/accountsecurity" className={`${window.location.pathname=="/accountsecurity"?"active-border":""}`}>Account & Security </NavLink>
    </li> 
    <li>
      <NavLink to="/reviews" className={`${window.location.pathname=="/reviews"?"active-border":""}`}>Reviews </NavLink>
    </li> 


    </ul> 

    </Offcanvas.Body>
  </Offcanvas>


  <Offcanvas show={showaccount} onHide={handleCloseaccount} className="offcanvas-sidebar"  placement="end">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>
    <ul className='offcanvas-ul-btn'>
    <li>
        <NavLink to="/reviews" className='header-blue-outline-btn-square mr-10' >Reviews</NavLink>          
        </li> 
        <li>
        <NavLink to="/dealershipinfo" className='header-green-btn-square' >Account</NavLink>          
        </li>       
      </ul>
    <ul className='offcanvas-ul'>
     
   
 


      <li>
      <NavLink to="" className='' onClick={()=>{
        localStorage.clear();
        window.location.href="/"
      }}>Log Out</NavLink>   
      </li> 

    </ul> 

    </Offcanvas.Body>
  </Offcanvas>
  </>
  )
}
