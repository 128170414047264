import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';
import { toast } from 'react-toastify';


export default function Conditionreport15() {
    const urlParams = new URLSearchParams(window.location.search);


    const [loader,setLoader]=useState(1)


    const [condition_report_value,setcondition_report_value]=useState()
    const [auction_length,setAuction_length]=useState("")

    const dispatch=useDispatch();
    const navigate=useNavigate()

        useEffect(()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
         
            
            setAuction_length(data?.add_condition_report_candition?.auction_length);
        setcondition_report_value(data);

     

        })
        },[])
 
    const save_data=async()=>{
        if(auction_length !="" &&auction_length !=undefined){

            setLoader(0)
       dispatch(MyvehiclesConditionreport({
             vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
             auction_length:auction_length
         })).then(function(data){
             navigate("/previewdetails?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
         })
        }else{
            toast.warning("Auction days is required")
        }
 
     
     }
  return (
    // <UserMaster>
    <div className='white-bg-cover'>
            <div className='container-fluid1 container max-width-equal1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>     
                    <div className='full-height-view'>
                         {/* Back step*/}               
                       <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel
                            </NavLink>                       
                        </div>
                        <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Auction Length</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'100%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'> 
                        <div className='step-progress-heading'>Set Your Auction Length</div>
                        <div className='row mb-4'>
                            <div className='col-md-12 text-center'>
                            <div className='blue-text-steps mb-3'>Need to sell quickly? You can now set a 1, 2 or 4 day auction!</div>
                           </div>                            
                        </div>
                            <div className='row d-flex align-items-center mb-3'>
                                <div className='col-md-12'>
                                <div className="custom-radio-steps">		
                                    <ul>
                                    <li>
                                    <input type="radio" id="option3" name="selector"
                                      value="1 Days"  onChange={(e)=>{
                                        setAuction_length(e.target.value)

                                    }}  


                                        checked={
                                            auction_length=="1 Days"?true:false
                                        }
                                    
                                     />
                                    <label for="option3">1 Day</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    </li> 

                                     <li>
                                    <input type="radio" id="option2" name="selector"
                                    
                                    
                                    value="2 Days"  onChange={(e)=>{
                                        setAuction_length(e.target.value)

                                    }}  


                                        checked={
                                            auction_length=="2 Days"?true:false
                                        }

                                     />
                                    <label for="option2">2 Days</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    </li>      
                                    <li>
                                    <input type="radio" id="option1" name="selector" value="4 Days"  onChange={(e)=>{
                                        setAuction_length(e.target.value)

                                    }}  


                                        checked={
                                            auction_length=="4 Days"?true:false
                                        }
                                    />
                                    <label for="option1">4 Days</label>
                                    
                                    <div className="check"></div>
                                    </li>
                                    
                                   
                                                                    
                                </ul>
                                </div>
                                </div>                        
                            </div>                             
                        </div>

                 


                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step"  to={"/conditionreport14?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                  
                        <li className="nav-item">
                        {
                            loader==1?( 
                    
                             <button className="nav-link green-btn-step "  onClick={save_data}  >   Next  </button>
                            ):( 
                            
                           <button className="nav-link green-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            )
                        }
                           
                        </li>
                        </ul>


                        </div>
                    </div>               
                      
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>                      
                        </div>                        
                    </div>
                </div>
            </div> 
        <div className="blue-bg-right"></div>
    </div>
    // </UserMaster>
  )
}
