
import { useFormik } from 'formik';
import React, { useState } from 'react'
import {Button, Modal, Offcanvas } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom'
import * as Yup from 'yup';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { LoginAction } from '../../Redux/Action/AuthenticationAction';

export default function HeaderHome() {
    const [password_text, Setpassword] = useState('password');
    const [show, setShow] = useState(false);
    const password_hide_show = () => {
      Setpassword(password_text == 'input' ? 'password' : 'input');
    };
    const dispatch = useDispatch();
    const formik = useFormik({
      initialValues: {
        email: '',
        password: '',
      },
      validationSchema: Yup.object({
        email: Yup.string().email().required('Email is required'),
        password: Yup.string().required('Password is required'),
      }),
      onSubmit: (values) => {
        console.log(values);
        dispatch(LoginAction(values));
      },
    });


    const [show1, setShow1] = useState(false);

  const handleClose = () => setShow1(false);
  const handleShow = () => setShow1(true);
  return (
<>
{/* // Header Section start */}
<Navbar expand="lg" className="header_bg_black ">
<Container className='container1200'>
<Navbar.Brand href="/" className='logo-header'><img src={require('../asset_user/images/header-logo/parrot_logo.svg').default} alt='yotrader-logo'  className='header-logo'/></Navbar.Brand>
<Navbar.Toggle  onClick={handleShow} /> 
  {/* <div className='user-icon-drop' onClick={()=>{setShow(true)}}>
    <img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'/>
  </div> */}
    <Nav className="ms-auto header-menu-link">
      <NavLink to="/about-us" className='mr-40'>About Us</NavLink>
      <NavLink to="/contact-us" className='mr-40'>Contact Us</NavLink> 
      <NavLink to="#" onClick={()=>{setShow(true)}} className='mr-30'>Sign In</NavLink> 
      <NavLink to="/registration" className='parrot_btn_header' >Sign up now</NavLink>            
    </Nav>
</Container>
</Navbar>
{/*  mobile menu sidebar */}
  <Offcanvas show={show1} onHide={handleClose} className="offcanvas-sidebar">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>
    <ul className='offcanvas-ul-btn'>
        <li>
        <NavLink to="/registration" className='red_btn_header ml-10' >SIGN UP</NavLink>         
        </li>
        <li>
        <Nav.Link href="#" className='green_btn_header' onClick={()=>{setShow(true)}}>SIGN IN</Nav.Link>
        </li>
      </ul>
    <ul className='offcanvas-ul'>
      <li>
      <NavLink to="/about-us" className='mr-40'>About Us</NavLink>
      </li>
      <li>
      <NavLink to="/contact-us" className='mr-40'>Contact Us</NavLink> 
      </li>  
    </ul> 
    </Offcanvas.Body>
  </Offcanvas>


{/* login modal */}
  <Modal show={show} onHide={()=>{  setShow(false)   }}  aria-labelledby='contained-modal-title-vcenter' centered  className='popup-white-bg' >
  <div className='modal-body'>
    <div className='row align-items-center'>           
        <div className='sign_in'>
          <form className='w-100' onSubmit={formik.handleSubmit}>
            <div className='card box-shadow-none'>
              <div className='card-body '>
                <div className='title_signin'>
                  <h1>Log in and start trading</h1>
                  <div className='form-group mb-3 mt-4'>
                    <label for=''>Email</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Email Credentials'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='email'
                      value={formik.values.email}
                    />


                    {formik.errors.email ? (
                      <p className='error'>{formik.errors.email}</p>
                    ) : null}

                    
                  </div>
                  <div className='form-group mb-3'>
                    <label for=''>Password</label>
                    <div className='input-group'>
                      <input
                        type={password_text}
                        className='form-control br-none br-r-none'
                        id='password'
                        placeholder='Login Password'
                        aria-label='Username'
                        aria-describedby='basic-addon1'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name='password'
                        value={formik.values.password}
                      />

                      <div className='input-group-prepend'>
                        <span
                          className='input-group-text bl-none '
                          id='basic-addon1'
                          onClick={() => password_hide_show()}
                        >
                          <i className='fa fa-eye' id='eye'></i>
                        </span>
                      </div>
                    </div>
                    {formik.errors.password ? (
                      <p className='error'>{formik.errors.password}</p>
                    ) : null}
                  </div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <div
                        className='form-check form-switch form-switch-md mb-3'
                        dir='ltr'
                      >
                        <input
                          className='form-check-input'
                          type='checkbox'
                          id='SwitchCheckSizemd'
                        />
                        <label
                          className='form-check-label'
                          for='SwitchCheckSizemd'
                        >
                          Remember me
                        </label>
                      </div>
                    </div>
                    <div className='forgot-link'>
                      <NavLink to='/forgotdealer'>
                        Forgot Password?
                      </NavLink>
                    </div>
                  </div>
                  <div className='btn_submit mt-4'>
                    <button className='black-lg-btn-primary-home'>
                      Sign In
                    </button>
                  </div>
                  <div className='signup-popup-link'>Not a member yet? </div>
                  <div className='black-outline-btn-signin'>
                  <NavLink to="/registration">Sign Up</NavLink>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>    
    </div>
  </div>      
  </Modal>
{/*End login modal */}
</>

  )
}
