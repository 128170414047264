

import React, { useEffect, useState } from 'react';

const Homeanumation = () => {  

var offsetStart = 0;
var offsetEnd = 0;

window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll', ( window.pageYOffset - offsetStart ) / ( document.body.offsetHeight - offsetStart - offsetEnd - window.innerHeight ));
}, false);


  return (
    <div className='mobilehomeSec' id="mobilehomeSec">
    {/* <img src={require('../asset_user/images/home-page/section-mobile.svg').default} alt=''/>  */}
    
    <div className='mobile-animatedSec' >
    <div className='mobile-frame'>
    <img src={require('../asset_user/images/home-page/mobile-frame.png')} alt=''/>
    </div>
    
    <div className='animated-textSec' >
    <div className='top-text-ani'>
    <div className='text-row-animated light-yellow'><img src={require('../asset_user/images/home-page/highest.png')} alt=''/> </div>
    <div className='text-row-animated white-text-color'><img src={require('../asset_user/images/home-page/super.png')} alt=''/></div>
    </div>
    
    <div className='middle-text-ani'>
    <div className='text-row-animated dark-light-yellow'><img src={require('../asset_user/images/home-page/trusted.png')} alt=''/></div>
    <div className='text-row-animated white-text-color'><img src={require('../asset_user/images/home-page/launch.png')} alt=''/> </div>
    </div>
    
    <div className='bottom-text-ani'>
    <div className='text-row-animated white-text-color'><img src={require('../asset_user/images/home-page/auction.png')} alt=''/></div>
    <div className='text-row-animated white-text-color'><img src={require('../asset_user/images/home-page/stress.png')} alt=''/></div>
    </div>
    
    </div>
    
    </div>
    
    </div>
  );
};

export default Homeanumation;



