import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Yup from 'yup'
import HeaderHome from '../IncludeHome/HeaderHome';
import FooterHome from '../IncludeHome/FooterHome';
import { DmsProviderAction } from '../../Redux/Action/SellerVehiclesActions';

export default function Step2Registration({ formData, setFormData, nextStep, prevStep }) {

    const [address, setaddress] = useState([]);
    const [addressdata,setAddressdata]=useState("")
    const [status, setStatus] = useState(null);
    const dispatch=useDispatch()
    useEffect(() => {
        dispatch(DmsProviderAction());
      }, []);
      const dms_provider_data = useSelector(
        (state) => state.VehicleReducer.dms_provider
      );


    const formiksecond = useFormik({
        initialValues: {
          dealership_name: formData.dealership_name || '',
          post_code: formData.post_code || '',
          phone_number: formData.phone_number || '',
          website_url: formData.website_url || '',
          dms_provider: formData.dms_provider || '',
        },
        validationSchema: Yup.object({
          dealership_name: Yup.string().required('Dealership name is required'),
          post_code: Yup.string().required('Post code is required'),
          phone_number: Yup.string().required('Phone number is required'),
          website_url:Yup .string().required('Website url is required'),
          dms_provider:Yup .string().required('DMS provider is required')
    
        }),
        onSubmit: values => {
          // Handle step 1 submission
          setFormData({
            ...formData,
            ...values,
          });
          nextStep();
        },
      });
    
      const fetchData = async () => {
        try {
         
          const url = `https://api.getaddress.io/find/${formiksecond.values.post_code}?api-key=ueCcfQKEGEKvm7B03ls5lA36743&expand=true`;
          const response = await fetch(url);  
          const json = await response.json();
          console.log(json);
          setaddress(json);
        } catch (error) {
          console.log('error', error.message);
        }
      };
      function changeStatus(e) {
        setStatus(e.target.value);
      
        var data_list = address.addresses[e.target.value];
        setAddressdata({
          dealer_house_name_number: data_list.line_1,
          town_city: data_list.town_or_city,
          county:data_list.county
        })
      
       
      }
  return (
    <>
 <HeaderHome />
    <form onSubmit={formiksecond.handleSubmit}>
   <section className='form-layout'>
          <div className='container container1200'>
              <div className='row'>
                  <div className='col-sm-10 col-md-8 col-lg-8 col-xl-8 col-12 mx-auto'>
                      <div className='card-white-form mtb-150 signup-card-o'>
                          <div className='form-content'>
                              <div className='black-text-large mb-3'>Start your free membership today!</div>
                              <div className='black-text-paragraph-sm mb-3'>Just three quick and easy steps to get online.</div>
                              <div className='black-text-paragraph-sm mb-3'>To qualify for an account, you’ll need to be a registered motor trader and you’ll need to provide us with your DMS provider information. </div>
                              <div className='step-circle-part'>
                                  <ul>
                                      <li>
                                          <NavLink to="" className="blue-circle-active">1</NavLink>
                                          <span>Your Details</span>
                                      </li>
                                      <li>
                                          <NavLink to="" className="blue-circle-active">2</NavLink>
                                          <span>Company Details</span>
                                      </li>
                                      <li>
                                          <NavLink to="">3</NavLink>
                                          <span>Confirmation</span>
                                      </li>
                                  </ul>
                              </div>
                              <div className='form-part-signup'>
                              <div className='row'>
                                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                      <div className="form-group">
                                          <label for="">Dealership Name</label>
                                          <input type="text" className="form-control" id="" name='dealership_name' placeholder="Type here"  value={formiksecond.values.dealership_name}   onChange={
                                            
                                      formiksecond.handleChange
                                            
                                            
                                            }
                                          onBlur={formiksecond.handleBlur}
                                          
                                          />
                                            {formiksecond.touched.dealership_name && formiksecond.errors.dealership_name && (
                                          <div className='error'>{formiksecond.errors.dealership_name}</div>
                                        )}
                                      </div>
                                  </div>
                                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-6 mb-3'>
                                      <div className="form-group">
                                          <label for="">Post Code</label>
                                          <input type="text" className="form-control" id="" name='post_code' placeholder="Type here" value={formiksecond.values.post_code} 
                                          
                                          onChange={  formiksecond.handleChange
                                           
                                                  
                                                  
                                                  }
                                          
                                          
                                        
                                          
                                          />
                                            {formiksecond.touched.post_code && formiksecond.errors.post_code && (
                                          <div className='error'>{formiksecond.errors.post_code}</div>
                                        )}
                                      </div>
                                  </div>
                                  <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-4 mb-3'>
                                      <button className="primary-btn-blue search-btn" type="button"  onClick={() => fetchData()}>Search</button>                                   
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                      <div className="form-group">
                                           <select
                                      name='dms_provider'
                                      className="form-control arrow-visible"
                                      id=''
                                      value={status}
                                      onChange={changeStatus}
                                    >
                                      <option value=''>Please select</option>
                                      {address?.addresses?.map(function (
                                        ADDRESS_DATAT,
                                        k
                                      ) {
                                        return (
                                          <option value={k} key={k}>
                                            {ADDRESS_DATAT.line_1 +
                                              ADDRESS_DATAT.county +
                                              ADDRESS_DATAT.town_or_city}
                                          </option>
                                        );
                                      })}
                                    </select>
                                      </div>
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                      <div className="form-group">
                                          <label for="">Phone Number</label>
                                          <input type="text" className="form-control" id="" name='phone_number' placeholder="+44" value={formiksecond.values.phone_number}  
                                          
                                          

                                          onChange={
                                            
                                            formiksecond.handleChange
                                                  
                                                  
                                                  }
                                          onBlur={formiksecond.handleBlur}
                                          
                                          />
                                            {formiksecond.touched.phone_number && formiksecond.errors.phone_number && (
                                          <div className='error'>{formiksecond.errors.phone_number}</div>
                                        )}
                                      </div>
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                      <div className="form-group">
                                          <label for="">Website</label>
                                          <input type="text" className="form-control" id="" name='website_url' placeholder="Type here" value={formiksecond.values.website_url}  
                                          
                                          
                                          
                                         

                                          onChange={
                                            
                                            formiksecond.handleChange
                                                  
                                                  
                                                  }


                                          onBlur={formiksecond.handleBlur}
                                          
                                          />
                                            {formiksecond.touched.website_url && formiksecond.errors.website_url && (
                                          <div className='error'>{formiksecond.errors.website_url}</div>
                                        )}
                                      </div>
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                      <div className="form-group">
                                          <label for="">DMS Provider</label>
                                          <select
                                    name='dms_provider'
                                    className='form-control arrow-visible'
                                    id=''
                                   

                               



                                           
                                    onBlur={formiksecond.handleBlur}
                                    value={formiksecond.values.dms_provider}
                                    onChange={ formiksecond.handleChange }
                                  >
                                    <option value=''>Select DMS Provider</option>
                                    {dms_provider_data.map(function (dms_provider, k) {
                                      return (
                                        <option value={dms_provider._id} key={k}>
                                          {dms_provider.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {formiksecond.touched.dms_provider && formiksecond.errors.dms_provider && (
                                          <div className='error'>{formiksecond.errors.dms_provider}</div>
                                        )}
  
                                      </div>
                                  </div>
                                  <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                      <div className='next-btn-part'>
                                          <button to="" className="primary-btn-white"  onClick={prevStep}>Back</button>
                                          <button to="" className="primary-btn-blue" type="submit">Next</button>
                                      </div>
                                  </div>
                              </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </section>
  
       
  
      </form>

         <FooterHome />
    </>
   
  )
}
