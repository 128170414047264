import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'


import { toast } from 'react-toastify';
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';

export default function Conditionreport4() {
    const urlParams = new URLSearchParams(window.location.search);
    const [detailmachenical,setmachenicaldetail]=useState("none")
    const [loader,setLoader]=useState(1)

    const [mechanical, setmechanical] = useState('');
    const [mechanicaldescription, setmechanicaldescription] = useState('');

   const [condition_report_value,setcondition_report_value]=useState()
   const dispatch=useDispatch();
   const navigate=useNavigate()
   useEffect(()=>{
            dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
              
                setmechanical( data?.add_condition_report_candition?.mechanical==undefined?"":data?.add_condition_report_candition?.mechanical)
                setmechanicaldescription(data?.add_condition_report_candition?.mechanical==undefined?"":data?.add_condition_report_candition?.mechanicaldescription)
                setmachenicaldetail(data?.add_condition_report_candition?.mechanical=="Issues Recorded"?"":"none")
                setcondition_report_value(data);
        
               
            })
   },[])




   const save_data=async()=>{

            if(mechanical !="")
            {
                setLoader(0)
if(mechanical=="Issues Recorded"){
    if(mechanicaldescription !=""){
        dispatch(MyvehiclesConditionreport({
            vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
            mechanical:mechanical,
            mechanicaldescription:mechanicaldescription
    
            // price_reserve:optionalprice
        })).then(function(data){
       
            navigate("/conditionreport5?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
        })
    }else{
        toast.error("More details are required")
    }

}else{
    setLoader(0)
    dispatch(MyvehiclesConditionreport({
        vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
        mechanical:mechanical,
        mechanicaldescription:mechanicaldescription

        // price_reserve:optionalprice
    })).then(function(data){
        navigate("/conditionreport5?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
    })

}

    

    }else{
       toast.error("Please complete the following information to proceed.")
    }
    }
   

 

  return (
    // <UserMaster>
    <div className='white-bg-cover'>
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
                    <div className='full-height-view'>
                        {/* Back step*/}               
                        <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel 
                            </NavLink>                       
                        </div>

                        <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Mechanical Issues</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'24%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'>  
                        <div className='step-progress-heading'>Are there any Mechanical Issues?</div>
                        <div className='row mb-4'>
                            <div className='col-md-12 text-center'>
                            <div className='blue-text-steps mb-3'>Mechanical issues include anything that affects the running or movement of the vehicle, such as: Engine, Gearbox, Braking, Suspension and Electronics.</div>
                           </div>                            
                        </div>
                            <div className='row d-flex align-items-center mb-3'>
                                <div className='col-md-12'>
                                <div className="custom-radio-steps">		
                                    <ul>
                                    <li>
                                    <input type="radio" id="option1" name="selector" value='No issues' onChange={(e)=>{
                                        setmechanical(e.target.value)

                                        setmachenicaldetail("none")
                                    }}
                                    
                                    checked={mechanical=="No issues"?true:false}
                                    
                                     />
                                    <label for="option1">No Mechanical Issues</label>
                                    
                                    <div className="check"></div>
                                    </li>
                                    
                                    <li>
                                    <input type="radio" id="option2"  value='Issues Recorded'  name="selector" onChange={(e)=>{
                                        setmechanical(e.target.value)
                                        setmachenicaldetail("")
                                    }} 

                                    checked={mechanical=="Issues Recorded"?true:false}


                                    />
                                    <label for="option2">Mechanical Issues Present</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    <div className='option-textarea-show'    style={{display:detailmachenical}}>
                                    <div className='blue-text-steps mb-2'>Please provide more details *</div>
                                    <div className='textarea-option-show'>
                                        <textarea placeholder='Type here...' onChange={(e)=>{
                                         
                                            setmechanicaldescription(e.target.value)

                                        }}   value={mechanicaldescription}   ></textarea>
                                    </div>
                                    </div> 
                                    </li> 
                                   
                                </ul>
                                </div>
                                </div>                        
                            </div>   

                        </div>

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step"   to={  condition_report_value?.Bidlist.length==0?"/Vehicles_detail_actution?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')): "/conditionreport3?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                  


                        <li className="nav-item">
                        {
                            loader==1?( 
                    
                             <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>
                            ):( 
                            
                           <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            )
                        }
                           
                        </li>

                        </ul>
                        </div>

                    </div>
                  
                    
                    
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>                      
                        </div>                        
                    </div>
                </div>
            </div>   
        <div className="blue-bg-right"></div>
    </div>
//  </UserMaster>
  )
}
