import { DEALER_STOCKE_AGE, DEALER_VEHICLES, DMS_PROVIDER, DRAFT_DATA, HEADER_COUNT, HOME_PAGE_VEHICLES, MY_AUCTION_DATA, MY_SALE_LIST } from "../Constant/Constant";


const initialization={
    dms_provider:[],
    vehicle_list:[],
    mannual_valuation:[],
    draftvehicles:[],
    soldvehicles:[],
    dealer_stocke_age:[],
    header_count:[],
    vehicles_home:[]
}

export default function VehicleReducer(state=initialization,action) {
    switch(action.type){

        case DMS_PROVIDER:
            return({
                ...state,
                dms_provider:action.payload
            });
        case DEALER_VEHICLES:
            return {
                ...state,
                vehicle_list: action.payload,
            };
        case MY_AUCTION_DATA:
            return {
                ...state,
                mannual_valuation:action.payload
            };

        case DRAFT_DATA:
            return {
                ...state,
                draftvehicles:action.payload
            };
            
        case MY_SALE_LIST:
        return({
            ...state,
            soldvehicles:action.payload
        })

        case HEADER_COUNT:
            return({
                ...state,
                header_count:action.payload
            });

            case DEALER_STOCKE_AGE:
                return({
                    ...state,
                    dealer_stocke_age:action.payload
                });
        case HOME_PAGE_VEHICLES:
            return({
                ...state,
                vehicles_home:action.payload
            });
                
            
            default:

            return state

    }
 
}
