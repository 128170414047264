import React from 'react'
import ReactPaginate from 'react-paginate'

export default function Pagination({pageinationD,dispatch,action_name}) {
    const handlePageClick =  (page_no) => {

 
   
        dispatch(
            action_name(page_no?.selected +1)
        );
      };
    
  return (
    <div className='pagination-wrapper'>
    <ReactPaginate
      previousLabel={'previous'}
      nextLabel={'next'}
      breakLabel={'...'}
      pageCount={pageinationD}
      marginPagesDisplayed={2}
      pageRangeDisplayed={3}
     onPageChange={handlePageClick}
      containerClassName={'pagination justify-content-center'}
      pageClassName={'page-item'}
      pageLinkClassName={'page-link'}
      previousClassName={'page-item'}
      previousLinkClassName={'page-link'}
      nextClassName={'page-item'}
      nextLinkClassName={'page-link'}
      breakClassName={'page-item'}
      breakLinkClassName={'page-link'}
      activeClassName={'active'}
    />
  </div>
  )
}
