module.exports={
    USER_LOGIN_SUCCESSFULLY:"USER_LOGIN_SUCCESSFULLY",
    DEALER_VEHICLES:"DEALER_VEHICLES",
    DMS_PROVIDER:"DMS_PROVIDER",
    MY_AUCTION_DATA:"MY_AUCTION_DATA",
    DRAFT_DATA:"DRAFT_DATA",
    MY_SALE_LIST:"MY_SALE_LIST",
    LIVE_BIDS_VEHICLE:"LIVE_BIDS_VEHICLE",
    HEIGHEST_BID:"HEIGHEST_BID",
    MAKE_MODEL_LIST:"MAKE_MODEL_LIST",
    HEADER_COUNT:"HEADER_COUNT",
    DEALER_STOCKE_AGE:"DEALER_STOCKE_AGE",
    MY_PURCHES_LIST:"MY_PURCHES_LIST",
    MY_BID_LIST:"MY_BID_LIST",
    WATCH_LIST_DATA:"WATCH_LIST_DATA",
    HEADER_COUNT_BUYER:"HEADER_COUNT_BUYER",
    PENDING_COLLECTION:"PENDING_COLLECTION",
    PENDING_REVIEWS:"PENDING_REVIEWS",
    BUY_PRODUCT_DETAILS:"BUY_PRODUCT_DETAILS",
    COMPLETE_REVIEWS:"COMPLETE_REVIEWS",
    HEADER_ACCOUNT_COUNT:"HEADER_ACCOUNT_COUNT",
    MODAL_CLOSE:"MODAL_CLOSE",
    LOST_ACTION:"LOST_ACTION",
    HOME_PAGE_VEHICLES:"HOME_PAGE_VEHICLES",
    MY_PASS30DAY_BUYER_LIST:"MY_PASS30DAY_BUYER_LIST"

}