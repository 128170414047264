import { toast } from "react-toastify";
import API from "../Service/Api";
import { BUY_PRODUCT_DETAILS, COMPLETE_REVIEWS, HEADER_ACCOUNT_COUNT, HEADER_COUNT, HEADER_COUNT_BUYER, HEADER_COUNT_BYER, HEIGHEST_BID, LIVE_BIDS_VEHICLE, LOST_ACTION, MAKE_MODEL_LIST, MY_BID_LIST, MY_PASS30DAY_BUYER_LIST, MY_PURCHES_LIST, PENDING_COLLECTION, PENDING_REVIEWS, WATCH_LIST_DATA } from "../Constant/Constant";

export const LiveBidlist=(obj_data)=>async(dispatch)=>{
    try {
        
     const listliveBids=  await API.post('/LivebidsList',obj_data);
  
     if(listliveBids?.data?.status==200){
            dispatch({
                type:LIVE_BIDS_VEHICLE,
                payload:listliveBids.data.data
            })
     }else{
        toast.error(listliveBids.data.message)
     }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const BidsBybuyer=(obj_data)=>async(dispatch)=>{
    try {
        
     const bid_by_buyer=  await API.post('/BidsBybuyer',obj_data);
  
     if(bid_by_buyer?.data?.status==200){
       
        dispatch({
            type:HEIGHEST_BID,
            payload:bid_by_buyer.data.data
        })
     }else{
        toast.error(bid_by_buyer.data.message)
     }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const GetmakemodelsAction=()=>async(dispatch)=>{
    try {
        const Makemodellist=  await API.get(`/Getmakemodels`);
      
  
        if(Makemodellist?.data?.status==200){
       
            dispatch({
                type:MAKE_MODEL_LIST,
                payload:Makemodellist.data.data
            })
         }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const SalerheaderAction=()=>async(dispatch)=>{
    try {
        const headercount=  await API.get(`/salerheader`);
       

      
  
        if(headercount?.data?.status==200){
       
            dispatch({
                type:HEADER_COUNT,
                payload:headercount.data.data
            })
         }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const MyPurchesAction=(page_no)=>async(dispatch)=>{
    try {
        const mypurcheslist= await API.get(`/MyPurches?page_no=${page_no}`);

        if(mypurcheslist?.data?.status==200){
        dispatch({
            type:MY_PURCHES_LIST,
            payload:mypurcheslist?.data?.data
            });

        }
   
   
    } catch (error) {
        // toast.error(error.message)
    }
}


export const MybidsAction=(page_no)=>async(dispatch)=>{
    try {
        const mybids= await API.post(`/Mybids`,page_no);
  

        if(mybids?.data?.status==200){
            console.log(mybids?.data?.data)
        dispatch({
            type:MY_BID_LIST,
            payload:mybids?.data?.data
            });

        }
   
   
    } catch (error) {
        // toast.error(error.message)
    }
}


export const GetWatchlistAction=(page_no)=>async(dispatch)=>{
    try {
        const GetWatchlist=  await API.post(`/GetWatchlist`,page_no);
        
  
        if(GetWatchlist?.data?.status==200){
       
            dispatch({
                type:WATCH_LIST_DATA,
                payload:GetWatchlist.data.data
            })
         }else{
            toast.error(GetWatchlist.data.message)
         }
    } catch (error) {
        // toast.error(error.message)
    }
}


export const ByerheaderAction=()=>async(dispatch)=>{
    try {
        const headercount=  await API.get(`/byerheader`);
       

      
  
        if(headercount?.data?.status==200){
       
            dispatch({
                type:HEADER_COUNT_BUYER,
                payload:headercount.data.data
            })
         }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const PendingcollectionAction=(data_value)=>async(dispatch)=>{
    try {
        const pendingcollections=  await API.post(`/Pendingcollection`,data_value);
       

      
  
        if(pendingcollections?.data?.status==200){
       
            dispatch({
                type:PENDING_COLLECTION,
                payload:pendingcollections.data.data
            })
         }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const PendingReviewAction=(data_value)=>async(dispatch)=>{
    try {
        const pendingviewlist=  await API.post(`/PendingReview`,data_value);
       

      
  
        if(pendingviewlist?.data?.status==200){
       
            dispatch({
                type:PENDING_REVIEWS,
                payload:pendingviewlist.data.data
            })
         }
    } catch (error) {
        // toast.error(error.message)
    }
}
export const GetBuyproductdetailsAction=(data_value)=>async(dispatch)=>{
    try {
        const getbuyproductdetails=  await API.post(`/GetBuyproductdetails`,data_value);
       

      
  
        if(getbuyproductdetails?.data?.status==200){
       
            dispatch({
                type:BUY_PRODUCT_DETAILS,
                payload:getbuyproductdetails.data.data
            })
         }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const VehicleDeliveredStatusupdateAction=(data_value)=>async(dispatch)=>{
    try {
        const updatestatus=  await API.post(`/VehicleDeliveredStatusupdate`,data_value);
       

      
  
        if(updatestatus?.data?.status==200){
          
           toast.success(updatestatus.data.message)
           return updatestatus.data.message
           
         }
    } catch (error) {
        // toast.error(error.message)
    }
}


export const CompleteReviewAction=(data_value)=>async(dispatch)=>{
    try {
        const completereviews=  await API.post(`/CompleteReview`,data_value);
       

      
  
        if(completereviews?.data?.status==200){
          
            dispatch({
                type:COMPLETE_REVIEWS,
                payload:completereviews.data.data
            })
           
         }
    } catch (error) {
        // toast.error(error.message)
    }
}


export const AccountheaderAction=()=>async(dispatch)=>{
    try {
        const Accountheaders=  await API.get(`/Accountheader`);
       

      
  
        if(Accountheaders?.data?.status==200){
          
            dispatch({
                type:HEADER_ACCOUNT_COUNT,
                payload:Accountheaders.data.data
            })
           
         }
    } catch (error) {
        // toast.error(error.message)
    }
}

export const LostAction=(pagination_object)=>async(dispatch)=>{
    try {
        const Lostresponse=  await API.post(`/Lost`,pagination_object);
       console.log(Lostresponse)

      
  
        if(Lostresponse?.data?.status==200){
          
            dispatch({
                type:LOST_ACTION,
                payload:Lostresponse.data.data
            })
           
         }
    } catch (error) {
        // toast.error(error.message)
    }
}




export const buyerpass30daysAction=(page_no)=>async(dispatch)=>{
    try {
        const mypurcheslist= await API.get(`/buyerpass30days?page_no=${page_no}`);

        if(mypurcheslist?.data?.status==200){
        dispatch({
            type:MY_PASS30DAY_BUYER_LIST,
            payload:mypurcheslist?.data?.data
            });

        }
   
   
    } catch (error) {
        // toast.error(error.message)
    }
}