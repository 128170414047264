import React from 'react'

export default function WinningBidlist(props) {
    return (
     <>
      {props?.Bidlist?.map(function(data_obj,i){
    if(  data_obj.type==1 ||  data_obj.type==2){
        return(

  <div className='row ' >
     <div className='col-sm-12 col-lg-12 col-md-12 col-xl-12 col col-12'>
     <div className='blue-bg-box-steps mb-3'>
      <div className='row d-flex align-items-center height90'>     
     <div className='col-sm-2 col-lg-2 col-md-2 col-xl-2 col-3 mobile-colum-sho'>
      <div className='logo-symbol-step img-logo-sm'>
      <img src={require('../asset_user/images/logo_symbol.png')} />   
      </div>                        
      </div>
      <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-5'>
      <div className='checkw-text-step'>
      <img src={require('../asset_user/images/check_green.svg').default}/> 
      <div className='check-text-steps '><div className="mobie-hide">YoTrader</div> Trusted Bidder</div> 
      </div>                  
      </div>
      <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-4'>
      <div className='white-price-label-steps'>£ {new Intl.NumberFormat('en-GB')
                      .format(data_obj?.yotrader_value)
                      .replace(/\.00$/, '')}</div>                           
      </div>
 
     </div>
      </div>
     </div>                   
  </div> 
)
}else{


return (  
  <div className='green-outline-bg-box mb-3'>
  <div className='row height90 d-flex align-items-center' key={i}>
  <div className='col-sm-2 col-lg-2 col-md-2 col-xl-2 col-3 mobile-colum-show'>
    <div className='logo-symbol-step large-text-hash'>
    # {i+1}  
    </div>                        
    </div>
    <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-5'>
    <div className='checkw-text-step'>
    {/* <img src={require('./UserComponent/asset_user/images/check_green.svg').default}/>  */}
    <div className='check-text-steps blue-text-color'>YoTrader Dealer Bid</div> 
    </div>                  
    </div>
    <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col-4'>
    <div className='white-price-label-steps green-text-color'>£{new Intl.NumberFormat('en-GB')
                    .format(data_obj?.yotrader_value)
                    .replace(/\.00$/, '')}</div>                           
    </div>
    <div className='col-sm-12 col-lg-4 col-md-4 col-xl-4 col-12'>
    <div className='table-list-flex'>
       

    </div>       

</div>     


  </div>
  </div>

    
    
    
    
    
    
    )
}


})}
     </>
      )
}
