import React from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import { NavLink, useNavigate } from 'react-router-dom';
import Accountheader from '../../IncludeDashboard/Accountheader';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Dealer_details_dealer, change_password_dealer, email_update_dealer } from '../../../Redux/Action/AuthenticationAction';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../DealerShipinfo/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51NSN9FK8IqEbWoY8cY6HCCFZ28bQECPn2v3iIuKQeBnzc4X7XqowWsgrDl7oy49J2UnAODFhm8f0w61rA6GLp1Ix00AjdWT8tF');

export default function AccountSecurity() {
  const [stripeshow,setStripe]=useState(false)
  const [dealerinfo,setDealerinfo]=useState("")
  const user_logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };
  //change_password_dealer
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirmpassword: '',
    },
    validationSchema: yup.object({
      old_password: yup.string().required('Old password is required'),
      new_password: yup.string().required('New password is required'),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref('new_password'), null], 'Passwords must match'),
    }),
    onSubmit: (values) => {
      dispatch(change_password_dealer(values));
    },
  });
  //change_password_dealer
  //email_update_dealer
  useEffect(() => {
    dispatch(Dealer_details_dealer(localStorage.getItem('dealer_id'))).then(
      (data) => {
        var fileds = ['email'];

        fileds.forEach((el) => {
          formikemail_update.setFieldValue(el, data[el], false);
        });
        setDealerinfo(data)
      }
    );
  }, [localStorage.getItem('dealer_id')]);

  const formikemail_update = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required('Email is required'),
    }),
    onSubmit: (values) => {
      dispatch(email_update_dealer(values));
    },
  });
  //email_update_dealer
  const closestripe=()=>{
    setStripe(false)
  }
  return (
    <>
   <Accountheader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" >Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" >Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson" >Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity" className='active-border'>Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews">Reviews <span>(1)</span></NavLink>
        </li>     */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>
    {/* mobile filter show */}

    {/* dealership form */}
    <section className='dealership-form mt30'>
    <div className='container container1224'>
    <div className='row row-reverse-mobile'>
    <div className='col-sm-12 col-md-4 col-lg-5 col-xl-5 col-xxl-5 col-12'>
    <div className='dealership-whitecard mb-3'>
    <div className='page-heading-v2'>Dealer Support</div>
    <div className='contact-info-dealer'>
      <div className='blact-text-normal fontweight500'>Mon-Sat 9am-5pm</div>
      <div className='blact-text-normal fontweight500'>sales@yotrader.co.uk</div>
      <div className='blact-text-normal fontweight500'>0115 694 0404</div>

      <div className='blact-text-normal mt-4'>All our dealer reps have direct lines, if you call, we answer. No holding.</div>
    </div>
    </div> 
    <div className='dealership-whitecard mb-3'>
    <div className='bank-card-part'>
    <div className='page-heading-v2'>Update Payment Details</div>
    {
      dealerinfo.payment_link==undefined?( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Add</div>):( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Update</div>)
    }
   
   
    </div>
    </div> 
    <div className='dealership-whitecard mb-3'>
    <NavLink to="#" className="red-color-lg">Logout</NavLink>
    </div>  
    </div>
    <div className='col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 col-12'>
      <div className='dealership-whitecard mb-3'>
      <div className='page-heading-v2'>Update Email</div>
      <div className='form_action '>

      <form onSubmit={formikemail_update.handleSubmit}>

      
        <div className='row bor-b-v2 d-flex align-items-center'>
          <div className='col-sm-12 col-md-7 col-lg-7 col-xl-7 col-xxl-7 col-12'>
            <div className='form-group'>
              <label for=''>Update Email</label>
              <input
                type='text'
                className='form-control'
                placeholder='Email address'
                onChange={formikemail_update.handleChange}
                                    onBlur={formikemail_update.handleBlur}
                                    name='email'
                                    value={formikemail_update.values.email}
              />
               {formikemail_update.errors.email ? (
                                    <strong style={{ color: 'red' }}>
                                      {formikemail_update.errors.email}
                                    </strong>
                                  ) : null}
            </div>                  
          </div>  
      
          <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-12 mt-4'>
          <div className='update-btn-part'>
              <button type='submit' className='dealer_btn_update width100 height40-btn'>Update</button>
            </div>             
          </div>
        </div>

        </form>


        <div className='update-pass'>
        <div className='page-heading-v2'>Update Password</div>
        <div className='row'>
        <form onSubmit={formik.handleSubmit}>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 '>
          <div className='row d-flex align-items-center mt-5'>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Old Password</label>
              <input
                type='password'
                className='form-control'
                placeholder='Old Password'
                name='old_password'
                onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                  value={formik.values.old_password}
              />
               {formik.errors.old_password ? (
                                    <strong style={{ color: 'red' }}>
                                      {formik.errors.old_password}
                                    </strong>
                                  ) : null}
            </div>                  
          </div>  
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>New Password</label>
              <input
                type='password'
                className='form-control'
                placeholder='New Password'
                name='new_password'
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
                value={formik.values.new_password}
              />
                {formik.errors.new_password ? (
                  <strong style={{ color: 'red' }}>
                    {formik.errors.new_password}
                  </strong>
                ) : null}
            </div>                  
          </div> 
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
            <div className='form-group'>
              <label for=''>Re-Enter New Password</label>
              <input
                type='password'
                className='form-control'
                placeholder='Re-Enter New Password'
                name='confirmpassword'
                                    onChange={formik.handleChange}
                                    onBlur={formik.onBlur}
                                    value={formik.values.confirmpassword}
              />
               {formik.errors.confirmpassword ? (
                                    <strong style={{ color: 'red' }}>
                                      {formik.errors.confirmpassword}
                                    </strong>
                                  ) : null}
            </div>                  
          </div> 
      
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mt-4'>
          <div className='update-btn-part'>
              <button   type='submit' className='dealer_btn_update width100'>Update</button>
            </div>             
          </div>
        </div>
          </div>
          </form>
        </div>
        </div>
      </div>
      </div>
    </div>          
      </div>
    </div>
    </section> 
    <Modal show={stripeshow} onHide={closestripe} className='mt-top checkout-popup'>
    <form >
    <div className='modal-body white-bg-popup-top'>     
     <Modal.Header closeButton></Modal.Header>
     <Elements stripe={stripePromise}>
      {/* Your component hierarchy */}
      <PaymentForm open={stripeshow} onClose={closestripe} />
    </Elements>

 </div>

</form>


  </Modal>
    </>
  
  )
}
