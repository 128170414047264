import React, { useEffect, useState } from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import FooterDashboard from '../IncludeDashboard/FooterDashboard'
import { Container, Dropdown, Modal, Nav, Navbar, Offcanvas } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Getvaluationvehicleunderwrite_data, Getvaluationvehicleunderwritevrm, VehicleslistsellerAction, deletenewvaluation } from '../../Redux/Action/SellerVehiclesActions'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { io } from 'socket.io-client';
import ReactPaginate from 'react-paginate';
import FooterHome from '../IncludeHome/FooterHome'
import { SalerheaderAction } from '../../Redux/Action/BuyerVehilesAution'
import Loader from 'react-loader-spinner'
// import Slider from 'react-bootstrap-slider';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-slider/dist/css/bootstrap-slider.min.css';
import {isMobile} from 'react-device-detect';

// import 'rc-tooltip/assets/bootstrap.css';
// import Tooltip from 'rc-tooltip';


import Slider, { SliderTooltip } from "rc-slider";

import "rc-tooltip/assets/bootstrap.css";
import "rc-slider/assets/index.css";
import ManualuploadModal from '../ManualuploadModal/ManualuploadModal'
import CalculateCountdown from '../CalculateCountdown/CalculateCountdown'



const { Range, Handle } = Slider;
const intlNumber = Intl.NumberFormat("en", { notation: "compact" });












const handleStyle = { backgroundColor: "#E6FF00", borderColor: "#E6FF00" };
const trackStyle = { backgroundColor: "#E6FF00" };






export default function Allvehicles() {
  const [show1, setShow1] = useState(false);
  const [showaccount, setShowaccount] = useState(false);
    const [live_expire_vehicles,Setlive_expire_vehicles]=useState(0)
    const [stockslidermobile, setStockslidermobile] = useState('mobile-range-slide-hide');
    const [Bidtrader, setyotradebid] = useState('');
    const [manualupload, setmanualupload] = useState(false);
    const [showfilter, setShowfilter] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [slidervalue, setSlidervalue] = useState(50);
    const [shownoprice, setshownoprice] = useState(false);
    const [loadIndicator, setLoadIndicator] = useState(true);
    const [show, setShow] = useState(false);
    
    const [showfilterhome, setShowfilterhome] = useState(false);
    const [shownnoevaluation, setshownnoevaluation] = useState(false);
  
    const [vehiclevrm, setvehiclevrm] = useState('');
    
    const [showconfirmation, setShowconfirmation] = useState(false);
    
    const [showAcceptingoffer, setshowAcceptingoffer] = useState(false);
    const [showaccept, setaccept] = useState(false);
    const [slider, setsearch_vale] = useState("");
    const [page_no, setpage_no] = useState(1);
    const [filtervalue, setFiltervalue] = useState({price:-1});
    //calculation
    const [width, setWidth] = useState(window.innerWidth);
  
    const [Showvaluation, setShowvaluation] = useState(false);

    const dispatch = useDispatch();

    const handleClose = () => setShowfilter(false);
    const ManualUploadClose = () => setmanualupload(false);
    const handleClosevaluation = () => setShowvaluation(false);
    const handleshowaccept=()=>  setaccept(false);
    const handleCloseconfi_close=()=>{
      setShowconfirmation(false);
    }



    const  handleCloseAcceptingoffer=()=>{
        setshowAcceptingoffer(false)
      } 


    const handleClosefilterhome=()=>setShowfilterhome(false)
    const handleClosenoprice = () => setshownoprice(false);
  
  
    const handleShow_condition = () => {
  
      setShow(true);
  
      setShowconfirmation(false);
      //dispatch(Getevaluation(vehicle_id,"TCBGACCEPT"));
     
    
  
  
    };




    useEffect(() => {
      dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,filtervalue})).then(function(){
        setTimeout(()=>{
          setLoadIndicator(false)
        },200)
      });
      dispatch(SalerheaderAction());
    }, []);
    const vehicle_list_data = useSelector(
      (state) => state.VehicleReducer?.vehicle_list?.dealer_vehicle_list
    );

    
    const pageinationD = useSelector(
      (state) => state.VehicleReducer?.vehicle_list?.pagination
    );

    const header_count = useSelector(
      (state) => state.VehicleReducer?.header_count
    );
    const handlePageClick =  (page_no) => {

        setpage_no(page_no?.selected + 1)
        setLoadIndicator(true)
      
          dispatch(VehicleslistsellerAction({page_no:page_no?.selected + 1 ,searchInput:searchInput,live_expire_vehicles:(live_expire_vehicles),slidervalue:slidervalue,filtervalue} ))
        .then(function(){
          setLoadIndicator(false)
        });
      };
    
    
      const vehiclefilter = (e) => {
    
       
    
        dispatch(VehicleslistsellerAction({page_no:page_no ,searchInput:e,live_expire_vehicles:(live_expire_vehicles),slidervalue:slidervalue,filtervalue} ));
      };
    
    const navigate=useNavigate();

    const formikunderwrite = useFormik({
        initialValues: {
          vrm: '',
           mileage: '',
        },
        validationSchema: Yup.object({
          vrm: Yup.string().required('TYPE VRM is required'),
          mileage: Yup.string().required('Mileage is required'),
        }),
        onSubmit: (values) => {
          values.type="GETOFFER";
          
          setLoadIndicator(true)
          setvehiclevrm(values.vrm)
          dispatch(Getvaluationvehicleunderwritevrm(values)).then(function(data){
          if(data !=undefined){
            navigate('/Conditionreport1?vrm='+data?.vehicle?.vrm+"&manual=1")
          
            setaccept(false)
           setLoadIndicator(false)
            formikunderwrite.resetForm()
          }else{
            toast.warn('VRM could not be found. Please try a different VRM')
            setaccept(false)
            setLoadIndicator(false)
            formikunderwrite.resetForm()
          }
          })
            
        
        },
      });

    const handleShow_condition_no_evaluation=()=>{
        setShow(true);
      }
      const valuation=()=>{
        setshownnoevaluation(false)
      }
       //offer accepted
       const handleCloseconfi = (new_valuation) =>{


        setLoadIndicator(true)
        dispatch(Getvaluationvehicleunderwrite_data({vrm:new_valuation.vrm,mileage:new_valuation.mileage,type:"TCBGACCEPT"}))
         .then(function(){
          setLoadIndicator(false)
        });
    
      }

   
      //end offer accepted
    const keyPress=(e)=>{
        if (e.key === "Enter") {
          vehiclefilter(searchInput);
        }
       }
    



   
       const socket =  io(process.env.REACT_APP_SOCKET_URL, {
        autoConnect: true,
      });

       useEffect(()=>{
      
    
        socket.on("buyerportal", (value) => {
    
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,filtervalue}));
            
          });
    
          
    
    
 
    },[dispatch])
    const handleChangefilter=(value_data)=>{
        setSlidervalue(100-value_data)
         dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,slidervalue:100-value_data,filtervalue}));
      
      
      }

      window.allvehicles=pageinationD?.total_page_data?? header_count?.total_vehicles;
 





    const handleWindowSizeChange = () => {
      setWidth(window.innerWidth);
      }

const handleCloseaccount = () => setShowaccount(false);

useEffect(() => {
  window.addEventListener('resize', handleWindowSizeChange);
  return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
  }
}, []);

    const handle = (props) => {
      const { value, dragging, index, ...restProps } = props;
    
      return (
        
        <SliderTooltip
          prefixCls="rc-slider-tooltip"
          overlayInnerStyle={{
            backgroundColor: "#E6FF00",
            padding: "6px 8px",
            color: "#11190C",
            fontWeight:"600",
            borderRadius:0,
              display:(stockslidermobile=="mobile-range-slide-show"||width>991)?"": stockslidermobile==""?"":"none"
            
          }}

          overlay={100-value}
          visible
          placement="top"
          overlayClassName="custom-overlay-class"
          key={index}
        >
          <Handle value={value} {...restProps}  />
        </SliderTooltip>
      );
    };

    const handleChangefiltersort=(type)=>{
      switch(type){

        case 'price_high':
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,price:-1,slidervalue:slidervalue})).then(function(){
            setTimeout(()=>{
              setLoadIndicator(false)
            },200)
          });
          setFiltervalue({price:-1})
        break;
        case 'price_low':
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,price:1,slidervalue:slidervalue})).then(function(){
            setTimeout(()=>{
              setLoadIndicator(false)
            },200)
          });
          setFiltervalue({price:1})
        break;
        case 'age_new':
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,year:-1,slidervalue:slidervalue})).then(function(){
            setTimeout(()=>{
              setLoadIndicator(false)
            },200)
          });
          setFiltervalue({year:-1})
        break;

        case 'age_old':
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,year:1,slidervalue:slidervalue})).then(function(){
            setTimeout(()=>{
              setLoadIndicator(false)
            },200)
          });
          setFiltervalue({year:1})
        break;

        
        case 'make_z_a':
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,make:-1,slidervalue:slidervalue})).then(function(){
            setTimeout(()=>{
              setLoadIndicator(false)
            },200)
          });
          setFiltervalue({make:-1})
        break;
        case 'make_a_z':
          dispatch(VehicleslistsellerAction({page_no:page_no,searchInput:searchInput,live_expire_vehicles:0,make:1,slidervalue:slidervalue})).then(function(){
            setTimeout(()=>{
              setLoadIndicator(false)
            },200)
          });
          setFiltervalue({make:1})
          break;

        default:
            
        }

        }

  return (
    <>

{/* // Header Section start */}
<Navbar expand="lg" className="header_bg_black">
<Container className='container1224 container'>
<Navbar.Toggle  onClick={()=>{
 setShow1(true);
}} />
  <Navbar.Brand href="/" className='logo-header'><img src={require('../asset_user/images/header-logo/parrot_logo.svg').default} alt='yotrader-logo'  className='header-logo'/></Navbar.Brand>

  {
    window.location.pathname=="/"?(   <div className='user-icon-drop' onClick={()=>{setShow(true)}}><img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'    />  </div>):( <div className='user-icon-drop' onClick={()=>{setShowaccount(true)}}><img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'    />  </div>)
  }
    <Nav className="ms-auto headerinner-menu-link"> 
      <NavLink to="/reviews" className='header-white-outline-btn-square mr-10' >Reviews</NavLink> 
      <NavLink to="/dealershipinfo" className='header-green-btn-square mr-30' >Account</NavLink>  
      <NavLink to="" className=''  onClick={()=>{
        localStorage.clear();
        window.location.href="/"
      }}>Log Out</NavLink>         
    </Nav>
</Container>
</Navbar>
{/*  mobile menu sidebar */}
<Offcanvas show={show1} onHide={()=>{
  setShow1(false)
}} className="offcanvas-sidebar">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'> 
    <ul className='offcanvas-ul-btn'>
        <li>
        <NavLink to="/liveauction" className='blue-outline-btn-square mr-10'>Live Auctions</NavLink>       
        </li>    
        <li>
        <NavLink to="#" className='blue-btn-square' onClick={()=>{
        setmanualupload(true);
        setShow1(false)
      }} >Manual Upload</NavLink>         
        </li>     
      </ul>

    <ul className='offcanvas-ul mb-0'> 
      <li>
      <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
      {/* <div className='app-search d-lg-block p-2'>
        <div className='d-flex justify-content-between align-items-center search_box'>
          <div className='position-relative w-100'>
          <input
              className='form-control'
              placeholder='VRM, Make, Model'
              type='search'
              value={searchInput}
              onChange={e => {
              setSearchInput(e.target.value);}}
              onKeyDown={keyPress}
              />
            <span className='fa fa-search'></span>
          </div>
        </div>
      </div> */}
      </li>
      <li>
      <NavLink to="/all-vehicles" className={`${window.location.pathname=="/all-vehicles"?"active-border":""}`}>My Stock <span>({header_count?.total_vehicles})</span></NavLink>
    </li>
    <li>
      <NavLink to="/myauction" className={`${window.location.pathname=="/myauction"?"active-border":""}`}>My Auctions <span>({header_count?.totalmyauctionsvehicles})</span></NavLink>
    </li>
    <li>
      <NavLink to="/draft"  className={`${window.location.pathname=="/draft"?"active-border":""}`}>Drafts <span>({header_count?.totaldraftvehicles})</span></NavLink>
    </li>
    <li>
      <NavLink to="/soldvehicles" className={`${window.location.pathname=="/soldvehicles"?"active-border":""}`}>Sold Vehicles <span>({header_count?.totalsoldvehicles})</span></NavLink>
    </li> 
    <li>
    <NavLink to="/reviews" className={`${window.location.pathname=="/reviews"?"active-border":""}`}>Reviews </NavLink>
    </li> 

    </ul> 
 
    </Offcanvas.Body>
  </Offcanvas>


  <Offcanvas show={showaccount} onHide={handleCloseaccount} className="offcanvas-sidebar"  placement="end">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>
    <ul className='offcanvas-ul-btn'>
        <li>
        <NavLink to="/reviews" className='header-blue-outline-btn-square mr-10' >Reviews</NavLink>          
        </li>    
        <li>
        <NavLink to="/dealershipinfo" className='header-green-btn-square' >Account</NavLink>          
        </li>     
      </ul>
    <ul className='offcanvas-ul'>
     
   
 


      <li>
      <NavLink to="" className='' onClick={()=>{
        localStorage.clear();
        window.location.href="/"
      }}>Log Out</NavLink>   
      </li> 

    </ul> 

    </Offcanvas.Body>
  </Offcanvas>


  {/* conditions modal */}


    {/* <!--manualupload  Modal --> */}
    <Modal show={manualupload} onHide={ManualUploadClose} className='mt-top'>
    <form onSubmit={formikunderwrite.handleSubmit}>
    <div className='modal-body blue-bg-popup-top'>     
     <Modal.Header closeButton> </Modal.Header>
   <div className="input-group mb-3">     
     <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm'   onChange={formikunderwrite.handleChange}
      onBlur={formikunderwrite.handleBlur}
       value={formikunderwrite.values.vrm} />
   </div>
   {formikunderwrite.errors.vrm ? (
                  <p className='error'>{formikunderwrite.errors.vrm}</p>
                ) : null}
   <div className='form-group'>
     <input type="text"  className='form-control white-input-pop' 
      value={formikunderwrite.values.mileage}
      name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
    placeholder="Mileage" />      
    {formikunderwrite.errors.mileage ? (
                  <p className='error'>{formikunderwrite.errors.mileage}</p>
                ) : null} 
   </div>
   <div className='green-btn-pop-large'>
     <button type='submit'>Get Trade Bids</button>
   </div>
 </div>

</form>


  </Modal>
  {/* Header subheader white background */}
  <section className='bg-headerwhite-v2'>
    <div className='container1224 container'> 
    <div className='page-headerSec-v2'>
  <div className='row d-flex align-items-center'>
    <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>
    <ul className='sub-menu-dashboard'>
      <li>
        <NavLink to="/all-vehicles" className='active-border'>My Stock <span>({window.allvehicles ??header_count?.total_vehicles})</span></NavLink>
      </li>
      <li>
        <NavLink to="/myauction">My Auctions <span>({window.totalmyauction ??header_count?.totalmyauctionsvehicles})</span></NavLink>

      </li>
      <li>
        <NavLink to="/draft">Drafts <span>({window.totaldraftvehicles ?? header_count?.totaldraftvehicles})</span></NavLink>
      </li>
      <li>
        <NavLink to="/soldvehicles">Sold Vehicles <span>({header_count?.totalsoldvehicles})</span></NavLink>
      </li>   
    </ul> 
  
    </div>
    <div className='col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3 col-12 mtb-20'>
    <div className='white-subHeader-btn'>
    <NavLink to="" className='blue-btn-square' onClick={()=>{ setmanualupload(true)}} >Manual Upload</NavLink>  
    <NavLink to="/liveauction" className='blue-outline-btn-square'>Live Auctions</NavLink>
    </div>
    </div> 
    <div className='col-sm-6 col-md-12 col-lg-12 col-xl-3 col-xxl-3 col-12 '>   
     <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
        {/* <div className='app-search d-lg-block '>
          <div className='d-flex justify-content-between align-items-center search_box'>
            <div className='position-relative w-100'>
              <input
              className='form-control'
              placeholder='VRM, Make, Model'
              type='search'
              value={searchInput}
              onChange={e => {
              setSearchInput(e.target.value);}}
              onKeyDown={keyPress}
              />
              <span className='fa fa-search'></span>
            </div>
          </div>
        </div> */}
    </div>      
  </div>
  </div> 
    </div>
  </section>
   {/* mobile filter show */}
   <section className='filter-home-v2'>
  <div className='mobile-filter-show'>
  <ul>
    <li className={`bor-r-c  ${stockslidermobile=="mobile-range-slide-show" &&"active-color-stock"}`} onClick={()=>{
      setStockslidermobile(stockslidermobile=="mobile-range-slide-hide"?"mobile-range-slide-show":"mobile-range-slide-hide")
    }}><NavLink to="#" className='sort-home' >Days In Stock</NavLink> </li>
    <li >



  <Dropdown>
      <Dropdown.Toggle  className="dropdown sort-home" id="dropdown-basic">
      Sort By
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item  onClick={()=>{
         handleChangefiltersort('price_low')
        }}>Price (Lowest)</Dropdown.Item>
        <Dropdown.Item onClick={()=>{
         handleChangefiltersort('price_high')
        }}>Price (Highest)</Dropdown.Item>
        <Dropdown.Item  onClick={()=>{
         handleChangefiltersort('age_new')
        }}>Age Newest First</Dropdown.Item>
        <Dropdown.Item  onClick={()=>{
         handleChangefiltersort('age_old')
        }}>Age Oldest First (Default)</Dropdown.Item>
        <Dropdown.Item  onClick={()=>{
         handleChangefiltersort('make_a_z')
        }}>Make A-Z</Dropdown.Item>
        <Dropdown.Item onClick={()=>{
         handleChangefiltersort('make_z_a')
        }}>Make Z-A</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>

  
    </li>
  </ul>
  </div>

    {/* <!--manualupload  Modal --> */}
    <Modal show={manualupload} onHide={ManualUploadClose} className='mt-top'>
    <form onSubmit={formikunderwrite.handleSubmit}>
    <div className='modal-body blue-bg-popup-top'>     
     <Modal.Header closeButton> </Modal.Header>
   <div className="input-group mb-3">     
     <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm'   onChange={formikunderwrite.handleChange} onBlur={formikunderwrite.handleBlur}/>
   </div>
   {formikunderwrite.errors.vrm ? (
                  <p className='error'>{formikunderwrite.errors.vrm}</p>
                ) : null}
   <div className='form-group'>
     <input type="text"  className='form-control white-input-pop'  name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
    placeholder="Mileage" />      
    {formikunderwrite.errors.mileage ? (
                  <p className='error'>{formikunderwrite.errors.mileage}</p>
                ) : null} 
   </div>
   <div className='green-btn-pop-large'>
     <button type='submit'>Get Trade Bids</button>
   </div>
 </div>

</form>


  </Modal>

  {/* <!--filter  Modal --> */}
  <Modal show={showfilter} onHide={handleClose} >
  <div className='modal-body'>
    <div className='filter_set p-3'>
      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Stock Over 20 Days
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test1'
                name='radio-group'
                checked
              />
              <label for='test1'>Yes</label>
            </p>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test2'
                name='radio-group'
                checked
              />
              <label for='test2'>No</label>
            </p>
          </div>
        </div>
      </div>

      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Stock Over 40 Days
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test3'
                name='radio-group'
                checked
              />
              <label for='test3'>Yes</label>
            </p>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test4'
                name='radio-group'
                checked
              />
              <label for='test4'>No</label>
            </p>
          </div>
        </div>
      </div>

      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Stock Over 60 Days
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test5'
                name='radio-group'
                checked
              />
              <label for='test5'>Yes</label>
            </p>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test6'
                name='radio-group'
                checked
              />
              <label for='test6'>No</label>
            </p>
          </div>
        </div>
      </div>

      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Stock Over 80 Days
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test7'
                name='radio-group'
                checked
              />
              <label for='test7'>Yes</label>
            </p>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test8'
                name='radio-group'
                checked
              />
              <label for='test8'>No</label>
            </p>
          </div>
        </div>
      </div>

      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Mileage
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <select name='' className='form-select' id=''>
                <option value=''>Min</option>
              </select>
            </p>
            <p>
              <select name='' className='form-select' id=''>
                <option value=''>Max</option>
              </select>
            </p>
          </div>
        </div>
      </div>

      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Mileage
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <select name='' className='form-select' id=''>
                <option value=''>Min</option>
              </select>
            </p>
            <p>
              <select name='' className='form-select' id=''>
                <option value=''>Max</option>
              </select>
            </p>
          </div>
        </div>
      </div>

      <div className='flitr_inner_wrap'>
        <div className='form-group'>
          <label className='label_title' for=''>
            Show Only Bids
          </label>
          <div className='flex_ing_wrap'>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test9'
                name='radio-group'
                checked
              />
              <label for='test9'>Yes</label>
            </p>
            <p>
              <input
                type='radio'
                className='radio_btn'
                id='test10'
                name='radio-group'
                checked
              />
              <label for='test10'>No</label>
            </p>
          </div>
        </div>
      </div>

      <div className='btn_set '>
        <button
          className='btn reset_btn mr-3'
          onClick={() => handleClose()}
        >
          Reset{' '}
        </button>
        <button className='btn apply_btn'>Apply</button>
      </div>
    </div>
  </div>

  </Modal>

  {/* <!--confirmation  Modal --> */}
  <Modal  className='bg-transparent'
  show={showconfirmation}
  onHide={handleCloseconfi_close}
  aria-labelledby='contained-modal-title-vcenter'
  centered
  >
  <div className='modal-body blue-bg-popup'>

      <Modal.Header closeButton>
      <img
    src={require('../asset_user/images/logo1.svg').default}
    alt='yotrader-logo'
  />
      </Modal.Header>



    <div className='confir_ion p-3'>
      <div className='confirmation_center text-center'>
        <h2>1st Trade Offer</h2>              
        <h1>
          {new Intl.NumberFormat('en-GB', {
            style: 'currency',
            currency: 'GBP',
          })
            .format(parseInt(Bidtrader))
            .replace(/\.00$/, '')}{' '}
        </h1>
        <p className="mb-0">This offer is guaranteed for 4 days</p>
        <p>For</p>
        <div className='btnside'>
          <button className='btn btn-progress'>{vehiclevrm}</button>
        </div>
      </div>
      <div className='btn_set '>
        <button
          className='btn conf_btn '
        
          onClick={() => handleShow_condition()}
          data-dismiss='modal'
        >
        Get More Bids*{' '}
        </button>
        <button
          className='btn  clear_btn'
          data-toggle='modal'
          data-target='#details_confirm'
          onClick={() => handleCloseconfi()}
        >
          Accept
        </button>
      </div>
      <div className='small-text-popup'>
      * {vehiclevrm} will be sent to a panel of over 75 qualified buyers
      

      </div>
    </div>
  </div>

  {/* <Modal.Footer>
    <Button variant='secondary' onClick={handleClose}>
      Close
    </Button>
    <Button variant='primary' onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer> */}
  </Modal>

  {/* valuetion 0 popup */}
  <Modal  className='bg-transparent'
  show={Showvaluation}
  onHide={handleClosevaluation}
  aria-labelledby='contained-modal-title-vcenter'
  centered
  >
  <div className='modal-body blue-bg-popup'>

      <Modal.Header closeButton>
      <img
    src={require('../asset_user/images/logo1.svg').default}
    alt='yotrader-logo'
  />
      </Modal.Header>



    <div className='confir_ion p-3'>
      <div className='confirmation_center text-center'>
                  

        <p className="mb-0">We need some more details to get you an offer. 
          Please complete the condition report and one of our team will be in touch within 48 hours</p>
      
    
      </div>
      <div className='btn_set '>
        <button
          className='btn conf_btn large_btn'
        
          onClick={() => handleShow_condition_no_evaluation()}
          data-dismiss='modal'
        >
        Complete Condition Report*{' '}
        </button>
      
      </div>
      <div className='small-text-popup'>
      * {vehiclevrm} will be sent to a panel of over 75 qualified buyers
      

      </div>
    </div>
  </div>

  {/* <Modal.Footer>
    <Button variant='secondary' onClick={handleClose}>
      Close
    </Button>
    <Button variant='primary' onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer> */}
  </Modal>

  <Modal  className='bg-transparent mt-44'
  show={showaccept}
  onHide={handleshowaccept}
  aria-labelledby='contained-modal-title-vcenter'
  top
  >
  <form onSubmit={formikunderwrite.handleSubmit}>


  <div className='modal-body blue-bg-popup-top'>

      <Modal.Header closeButton>
      {/* <img
    src={require('../asset_user/images/logo.svg').default}
    alt='yotrader-logo'
  /> */}
      </Modal.Header>



    <div className='confir_ion p-3 update-popup-layout'>

    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text grey-bg-group"></span>
      </div>
      <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm' onChange={formikunderwrite.handleChange} onBlur={formikunderwrite.handleBlur} />
      
    </div>
    {formikunderwrite.errors.vrm ? (
                      <p className='error'>{formikunderwrite.errors.vrm}</p>
                    ) : null}



    <div className='form-group'>
      <input type="text"  className='form-control white-input-pop'   name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
        placeholder="Mileage" />
        
    </div>
    {formikunderwrite.errors.mileage ? (
                      <p className='error'>{formikunderwrite.errors.mileage}</p>
                    ) : null}
    <div className='green-btn-pop-large'>
      <button type='submit'>Get Trade Bids</button>
    </div>

      
    </div>
  </div>
  </form>

  </Modal>

  <Modal
  show={shownoprice}
  onHide={handleClose}
  aria-labelledby='contained-modal-title-vcenter'
  centered
  >
  <div className='modal-body'>
    <div className='confir_ion p-3'>
      <div className='confirmation_center text-center text-black-popup'>
        {/* <h2>Call us</h2> */}
        {/* <p>Please call us to confirm your bid on: 0115 694 0404 </p> */}
        <p>Thank you for submitting your condition report. </p>
        <p>Your vehicle has been submitted to over 75 qualified buyers. Please allow up to 48 hours for our team to get you the best trade offer possible. </p>
      </div>
      <div className='btn_set '>
        <button
          className='btn small_btn-c'
          onClick={() => handleClosenoprice()}
          data-dismiss='modal'
        >
          Ok{' '}
        </button>
      </div>
    </div>
  </div>

  {/* <Modal.Footer>
    <Button variant='secondary' onClick={handleClose}>
      Close
    </Button>
    <Button variant='primary' onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer> */}
  </Modal>

  <Modal
  show={shownnoevaluation}
  onHide={handleClose}
  aria-labelledby='contained-modal-title-vcenter'
  centered
  >
  <div className='modal-body'>
    <div className='confir_ion p-3'>
      <div className='confirmation_center text-center text-black-popup'>
        {/* <h2>Call us</h2> */}
        {/* <p>Please call us to confirm your bid on: 0115 694 0404 </p> */}
        
        <p>We are unable to give a instant valuation for this vehicle. Please call the office to get a valuation 0115 694 0404 </p>
      </div>
      <div className='btn_set '>
        <button
          className='btn small_btn-c'
          onClick={() => valuation()}
          data-dismiss='modal'
        >
          Ok{' '}
        </button>
      </div>
    </div>
  </div>

  {/* <Modal.Footer>
    <Button variant='secondary' onClick={handleClose}>
      Close
    </Button>
    <Button variant='primary' onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer> */}
  </Modal>

  {/* <!--confirmation  Modal --> */}

  {/* conditions modal */}




    {/* <!--accepting offer  Modal --> */}




  <Modal
  show={showAcceptingoffer}
  onHide={handleCloseAcceptingoffer}
  aria-labelledby='contained-modal-title-vcenter'
  centered
  >
  <div className='modal-body'>
    <div className='confir_ion p-3'>
      <div className='confirmation_center text-center text-black-popup'>
        {/* <h2>Call us</h2> */}
        {/* <p>Please call us to confirm your bid on: 0115 694 0404 </p> */}
        <p>Thank you for accepting our instant offer. </p>
        <p> The Car Buying Group will be in contact to arrange collection and payment.</p>
        <p> The Car Buying Group will be in contact within 24 hours.</p>
      </div>
      <div className='btn_set '>
        <button
          className='btn small_btn-c'
          onClick={() => handleCloseAcceptingoffer()}
          data-dismiss='modal'
        >
          Ok{' '}
        </button>
      </div>
    </div>
  </div>

  {/* <Modal.Footer>
    <Button variant='secondary' onClick={handleClose}>
      Close
    </Button>
    <Button variant='primary' onClick={handleClose}>
      Save Changes
    </Button>
  </Modal.Footer> */}
  </Modal>
  {/* <Modal className="filter-part-home left"show={showfilterhome} onHide={handleClosefilterhome}>
  <Modal.Header closeButton>
  <Modal.Title>Filter</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <div className="row">
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Sort By</div>
  <div className="form-input-style">
  <select>
  <option value="">Show All</option>
  <option value="-1">Newly Listed (Newest First)</option>
  <option value="0">Ending Soonest</option>
  <option value="1">Price Lowest (Lowest Price first)</option>
  </select>
  </div>
  </div>
  <div className="border-b-filter"></div>
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Make</div>
  <div className="form-input-style">
  <select>
  <option value="">Show All</option>
  <option value="DODGE (USA)">DODGE (USA)</option>
  <option value="RENAULT">RENAULT</option>
  <option value="PEUGEOT">PEUGEOT</option>
  </select>
  </div>
  </div>
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Model</div>
  <div className="form-input-style">
  <select>
  <option value="">Show All</option>
  </select>
  </div>
  </div>
  <div className="border-b-filter"></div>
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Mileage</div>
  <div className="form-input-style">
  <select>
  <option value="">Show All</option>
  <option value="0-100">Up to 100 miles</option>
  <option value="0-1000">Up to 1,000 miles</option>
  <option value="0-5000">Up to 5,000 miles</option>
  <option value="0-10000">Up to 10,000 miles</option>
  <option value="0-20000">Up to 20,000 miles</option>
  <option value="0-40000">Up to 40,000 miles</option>
  <option value="0-60000">Up to 60,000 miles</option>
  <option value="0-80000">Up to 80,000 miles</option>
  <option value="0-8000000">Over 80,000 miles</option>
  </select>
  </div>
  </div>
  <div className="col-md-6 mb-3">
  <div className="form-style-label">Age</div>
  <div className="form-input-style">
  <select>
  <option value="">Min</option>
  <option value="0">Any</option>
  <option value="2023">2023</option>
  <option value="2022">2022</option>
  <option value="2021">2021</option>
  <option value="2020">2020</option>
  <option value="2019">2019</option>
  <option value="2018">2018</option>
  <option value="2017">2017</option>
  <option value="2016">2016</option>
  <option value="2015">2015</option>
  <option value="2014">2014</option>
  <option value="2013">2013</option>
  <option value="2012">2012</option>
  <option value="2011">2011</option>
  <option value="2010">2010</option>
  <option value="2009">2009 and Older</option>
  </select>
  </div>
  </div>
  <div className="col-md-6 mb-3">
  <div className="form-style-label invisible">Age</div>
  <div className="form-input-style">
  <select>
  <option>Max</option>
  <option value="0">Any</option>
  <option value="2023">2023</option>
  <option value="2022">2022</option>
  <option value="2021">2021</option>
  <option value="2020">2020</option>
  <option value="2019">2019</option>
  <option value="2018">2018</option>
  <option value="2017">2017</option>
  <option value="2016">2016</option>
  <option value="2015">2015</option>
  <option value="2014">2014</option>
  <option value="2013">2013</option>
  <option value="2012">2012</option>
  <option value="2011">2011</option>
  <option value="2010">2010</option>
  <option value="2009">2009 and Older</option>
  </select>
  </div>
  </div>
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Price Range</div>
  <div className="form-input-style">
  <select>
  <option>View All</option>
  <option value="1000-5000">£1,000 - £5,000</option>
  <option value="5000-10000">£5,000 - £10,000</option>
  <option value="10000-15000">£10,000 - £15,000</option>
  <option value="15000-20000">£15,000 - £20,000</option>
  <option value="20000-30000">£20,000 - £30,000</option>
  <option value="30000-50000">£30,000 - £50,000</option>
  <option value="50000-80000">£50,000 - £80,000</option>
  <option value="80000-800000000">£80,000 +</option>
  </select>
  </div>
  </div>
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Distance Away</div>
  <div className="form-input-style">
  <select>
  <option>View All</option>
  <option value="0-10 ">Upto 10 miles</option>
  <option value="0-20">Upto 20 miles</option>
  <option value="0-30">Upto 30 miles</option>
  <option value="0-50">Upto 50 miles</option>
  <option value="0-100">Upto 100 miles</option>
  <option value="100-1000000000">100 miles +</option>
  </select>
  </div>
  </div>
  </div>
  <div className="filter-toggle-leftside">
  <div className="form-style-label">Show</div>
  <div className="row mb-2">
  <div className="col-md-6 col-6">
  <span className="medium-blue-text-list">Vehicles With Bids</span>
  </div>
  <div className="col-md-6 col-6">
  <div className="toogle-switch-filter-buyer">
  <div className="check-box"><input type="checkbox" value="0" /></div>
  </div>
  </div>
  </div>
  <div className="row mb-2">
  <div className="col-md-6 col-6">
  <span className="medium-blue-text-list">Vehicles Without Bids</span>
  </div>
  <div className="col-md-6 col-6">
  <div className="toogle-switch-filter-buyer">
  <div className="check-box"><input type="checkbox" value="1" /></div>
  </div>
  </div>
  </div>
  <div className="row mb-2">
  <div className="col-md-6 col-6">
  <span className="medium-blue-text-list">Write Offs</span>
  </div>
  <div className="col-md-6 col-6">
  <div className="toogle-switch-filter-buyer">
  <div className="check-box"><input type="checkbox" value="1" /></div>
  </div>
  </div>
  </div>
  </div>
  <div className="border-b-filter"></div>
  <div className="filter-toggle-leftside">
  <div className="form-style-label">Show Only</div>
  <div className="row mb-2">
  <div className="col-md-6 col-6">
  <span className="medium-blue-text-list">VAT Qualifying</span>
  </div>
  <div className="col-md-6 col-6">
  <div className="filter-toggle">
  <div className="toogle-switch-filter-buyer">
  <div className="check-box"><input type="checkbox" value="1" /></div>
  </div>
  </div>
  </div>
  </div>
  <div className="row mb-2">
  <div className="col-md-6 col-6">
  <span className="medium-blue-text-list">Plus VAT</span>
  </div>
  <div className="col-md-6 col-6">
  <div className="filter-toggle">
  <div className="toogle-switch-filter-buyer">
  <div className="check-box"><input type="checkbox" value="1" /></div>
  </div>
  </div>
  </div>
  </div>
  <div className="border-b-filter"></div>
  <div className="row mb-2">
  <div className="col-md-12 mb-3">
  <div className="form-style-label">Keyword Search</div>
  <input className="form-input-style-filter" placeholder="Type Here" />
  </div>
  </div>
  </div>
  <div className="row mt-5 mt-mobile">
  <div className="col-sm-10 col-lg-6 col-md-6 col-xl-6 col col-6">
  <button className="small-filter-blue-outline side-small-btn">Reset</button>
  </div>
  <div className="col-sm-2 col-lg-6 col-md-6 col-xl-6 col col-6 side-end">
  <button className="small-filter-blue-fill side-small-btn">Update</button>
  </div>
  </div>

  </Modal.Body>
  <Modal.Footer>







  </Modal.Footer>
  </Modal> */}

  {/* conditions modal */}
  </section>
  {/* price range show in desktop */}
  <section className={`filter-slider-dashboard  ${stockslidermobile}`} >
  <div className='container container1224'>
  <div className='row d-flex align-items-start'>
    <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-3 mobile-colum-hide' >
      <div className='page-heading-v2'>Days in Stock</div>
    </div>   
    <div className='col-sm-11 col-md-110 col-lg-8 col-xl-8 col-xxl-8 col-11 mx-auto' >
    <Slider
        min={0}
        max={100}
       defaultValue="50"
      
        handle={handle}
         reverse
            onAfterChange={handleChangefilter}
        trackStyle={[trackStyle]}
        handleStyle={[handleStyle, handleStyle]}
      />      
    <div className='slider-number-show'>
      <div className='counter-left'>0</div>
      <div className='counter-right'>100+</div>
    </div>
    </div>     
   
   
    
     

  </div>
    </div> 
  </section>
 
<section className='mobile-heading-show-page'>
  <div className='container container1224'>
  <div className='row'>
    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 '>
    <div className='page-heading-v2'>All Vehicles</div>
    <div className="total-show">({window.allvehicles ??header_count?.total_vehicles})</div>
    </div>
  </div>
  </div>
</section>

  {/* main grid data show v2 */}
  <section className='list-data-show mt30'>
      <div className='container container1224'>
  
      <div className='product-grid-v2'>
      <div className='loader-full' style={{display:(loadIndicator==false?"none":"")}}>
        {
              loadIndicator && <Loader type="Oval"  width={100} radius={5}  color="#67B938" height={65}  />
          }        
        </div>
        <div className='row'>
        {
          ( vehicle_list_data?.length>0) &&
          vehicle_list_data?.map(function (vehicle_detail,i) {

          if(vehicle_detail?.stocking_age>0 &&60>vehicle_detail?.stocking_age){
                {/* green counter stock */}
                var htmnl =(<div className='counter-stock sky-blue-circle-counter'>
                <div className='counter-num'>{vehicle_detail?.stocking_age}</div>
                  <div className='counter-label'>Days in Stock</div>
                </div>)
              }else if(vehicle_detail?.stocking_age>61 &&100>vehicle_detail?.stocking_age){
                {/* yellow counter stock */}
                var htmnl = (<div className='counter-stock yellown-circle-counter '>
                <div className='counter-num'>{vehicle_detail?.stocking_age}</div>
                  <div className='counter-label'>Days in Stock</div>
                </div>)
              }else if(vehicle_detail?.stocking_age>100 &&500>vehicle_detail?.stocking_age){
                {/* yellow counter stock */}
                var htmnl = (
                
                <div className='counter-stock purple-circle-counter'>
                <div className='counter-num'>{vehicle_detail?.stocking_age}+</div>
                  <div className='counter-label'>Days in Stock</div>
                </div>
                
                )
              }
                if(vehicle_detail?.stocking_age>0 &&60>vehicle_detail?.stocking_age){
                  var htmnlsmall =(<p>{vehicle_detail?.stocking_age}</p> )
                }else if(vehicle_detail?.stocking_age>61 &&100>vehicle_detail?.stocking_age){
                  var htmnlsmall = ( <p>{vehicle_detail?.stocking_age}</p>  )
                }else if(vehicle_detail?.stocking_age>101 &&500>=vehicle_detail?.stocking_age){
                  var htmnlsmall = ( <p>{vehicle_detail?.stocking_age}+</p>)
                }      
                  return (
                    <div className='col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4' key={i}>
                      <div className='mobile-card-layout2 card-v2'>
                        <div className='card-body-v2'>
                        {
                          htmnl
                        
                        }                                
                          <div className='bids_inner_part'>
                            <div className='img_car_bg '>
                              <div className='img_relative position-relative' >
                                <img
                                  src={ vehicle_detail.image[0] !=false?vehicle_detail.image[0]: require('../asset_user/images/placholder-img.png')  }
                                  className='img-fluid'
                                  onError={(e) => {
                                    e.currentTarget.src = require('../asset_user/images/placholder-img.png');
                                  }}
                                  alt='car'
                                />
                              </div>
                              {/* <div className='pos_right'>
                                <p className='m-0'>
                                  {new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(vehicle_detail?.price)
                                    .replace(/\.00$/, '')}
                                </p>
                              </div> */}
                            </div>
                            <div className='grid-title-colum-v2'>
                 


                            { vehicle_detail?.year !=undefined? vehicle_detail?.year+" "+ (vehicle_detail?.make !=undefined ?vehicle_detail?.make:"") +" "+ (vehicle_detail?.model !=undefined ?vehicle_detail?.model:""):(vehicle_detail?.make !=undefined ?vehicle_detail?.make:"")+" "+ (vehicle_detail?.model !=undefined ?vehicle_detail?.model:"")  }

                            </div>  
                            <div className='car-quick-details-v2'>                                    
                                  <ul>
                                            <li>   {  vehicle_detail.year ??""} </li>
                                            <li>{new Intl.NumberFormat('en-GB')
                                            .format(vehicle_detail.mileage)
                                            .replace(/\.00$/, '')} miles</li>
                                            <li>  {  vehicle_detail.fuel ??"N/A"}</li>
                                            <li className='auto-color'> {  vehicle_detail.auto_manual ??"N/A"}</li>
                                         
                                          </ul>
                            </div> 
                            {/* <div className='loc-car-card'>
                              <div className='address-loc'>
                              <img src={require('../asset_user/images/red-loc.svg').default}  /> 
                              <div className='address-text'>
                              <div className='normal-text-black'>Southend on sea </div>
                              <div className='normal-text-black'>0 miles away</div>
                              </div>
                              </div>
                              <div className='home-viewcar-no-v2 mb-2'>
                                  {vehicle_detail.vrm}
                                </div>

                            </div>     */}
                            
                            <div className='retailprice-part-v2'>
                              <div className='d-flex justify-content-between'>  
                              {/* <div className='current-bid-card'>
                              <div className='current-text-black'>Current Bid:</div>
                              <div className='blue-text-md'>£19,490</div>
                              <div className='reserve-red'>Reserve not met</div>
                              </div> */}
                              <div className='home-viewcar-no-v2 mb-2'>
                                  {vehicle_detail.vrm}
                                </div>
                                <div className='retail-price-v2'>
                                  <span>Retail Price</span>
                                  <div className='blue-text-lg-v2'>
                                  {new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(vehicle_detail?.price)
                                    .replace(/\.00$/, '')}
                                  </div>
                                </div>

                             
                              </div>
                            </div>

                            <div className='get-bid-btn-part-v2'>
                            <div className='totl_price'>
                            {/* <div className="timer-card-ptheme">
                              <div className='end-auction-black'>Auction Ends:</div>
                              <div className='blue-text-md'>23 Hours 30 Mins</div>
                              </div> */}
                        
                              {(()=>{
                                if(    vehicle_detail?.report_condition[0]?.submit_auction_status ==1 &&Math.ceil((new Date(vehicle_detail?.report_condition[0]?.offer_expire)-new Date()  ) / 36e5)>0){
                              return (   <><button 
                              onClick={() =>
                              
                                navigate(`/Vehiclesdetails?id=${vehicle_detail?._id}`)
                              }
                            
                              className='home-square-green-btn-v2 mt-3'>Best offer:   {    vehicle_detail?.yotraderbids.length==0?"--": new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(parseInt(Math.max(...vehicle_detail?.yotraderbids.map(o => o.yotrader_value))))
                                    .replace(/\.00$/, '')}</button>
                                  
                                    <p>Auction ends in {Math.ceil((new Date(vehicle_detail?.report_condition[0]?.offer_expire)-new Date()  ) / 36e5)} hours </p>
                                    </>
                                    )
                                } else if( vehicle_detail?.report_condition[0]?.submit_auction_status ==0 &&   vehicle_detail?.report_condition[0]?.vrm==vehicle_detail?.vrm){
                                  
                                  return (
                                  <>
                                  <button 
                              
                                

                              onClick={() =>
                              
                                navigate(`/sendtoAuctiondetails?id=${vehicle_detail?.vrm}`)
                              }

                              className='home-square-green-btn-v2 mt-3'>Best offer:  {    vehicle_detail?.yotraderbids.length==0?"--": new Intl.NumberFormat('en-GB', {
                                    style: 'currency',
                                    currency: 'GBP',
                                  })
                                    .format(parseInt(Math.max(...vehicle_detail?.yotraderbids.map(o => o.yotrader_value))))
                                    .replace(/\.00$/, '')}</button>
                              
                              <p>Get bids from over 3000 Active Dealers</p>
                              </>
                                  )
                                }
                                
                                else{
                                  return (
                                    <>
                                      <button 
                                      onClick={()=>{
                                    ( vehicle_detail?.report_condition[0]?.submit_auction_status ==1&&   
                                      dispatch(deletenewvaluation( vehicle_detail?.report_condition[0]?._id, vehicle_detail?.report_condition[0]?.vrm)))
                                        navigate("/conditionreport1?id="+vehicle_detail?._id)
                                            }} className='home-square-white-btn-v2 mt-3'>Get Trade Bids </button>
                              
                                        <p>Get bids from over 3000 Active Dealers</p>
                              </>
                              )
                                }
                              })()}
                            </div>
                            </div>

                            {/* <div className='make_dtls'>
                              <div className='lists_data'>
                                <span>Make:</span>
                                <span>{vehicle_detail.make}</span>
                              </div>
                              <div className='lists_data'>
                                <span>Model:</span>
                                <span>{vehicle_detail.model}</span>
                              </div>
                              <div className='lists_data'>
                                <span>Days in Stock:</span>
                                <span>
                                {htmnlsmall}  
                                </span>
                              </div>
                              <div className='p_full'>
                                <h2 className='background double'>
                                </h2>
                              </div>
                            </div> */}

                            
                          </div>
                        </div>
                      </div>
                    
                    </div>
                    
                  );
              

                
                
          })      }

    
          <div className='pagination-wrapper'>
          <ReactPaginate
            previousLabel={'previous'}
            nextLabel={'next'}
            breakLabel={'...'}
            pageCount={pageinationD?.total_page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={'pagination justify-content-center'}
            pageClassName={'page-item'}
            pageLinkClassName={'page-link'}
            previousClassName={'page-item'}
            previousLinkClassName={'page-link'}
            nextClassName={'page-item'}
            nextLinkClassName={'page-link'}
            breakClassName={'page-item'}
            breakLinkClassName={'page-link'}
            activeClassName={'active'}
          />
        </div>
        </div>
      </div> 
      </div>
  </section>

  <Modal  className='bg-transparent mt-44'
  show={showaccept}
  onHide={handleshowaccept}
  aria-labelledby='contained-modal-title-vcenter'
  top
>
<form onSubmit={formikunderwrite.handleSubmit}>


  <div className='modal-body blue-bg-popup-top'>

      <Modal.Header closeButton>
      {/* <img
    src={require('../asset_user/images/logo.svg').default}
    alt='yotrader-logo'
  /> */}
      </Modal.Header>



    <div className='confir_ion p-3 update-popup-layout'>

    <div className="input-group mb-3">
      <div className="input-group-prepend">
        <span className="input-group-text grey-bg-group"></span>
      </div>
      <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm' onChange={formikunderwrite.handleChange} onBlur={formikunderwrite.handleBlur} />
      
    </div>
    {formikunderwrite.errors.vrm ? (
                      <p className='error'>{formikunderwrite.errors.vrm}</p>
                    ) : null}



    <div className='form-group'>
      <input type="text"  className='form-control white-input-pop'   name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
        placeholder="Mileage" />
        
    </div>
    {formikunderwrite.errors.mileage ? (
                      <p className='error'>{formikunderwrite.errors.mileage}</p>
                    ) : null}
    <div className='green-btn-pop-large'>
      <button type='submit'>Get Trade Bids</button>
    </div>
     
      
    </div>
  </div>
  </form>
  
</Modal>
 <FooterHome />
    </>
  )
}
