import React from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import Accountheader from '../../IncludeDashboard/Accountheader';
import { Rating } from 'react-simple-star-rating'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountheaderAction, GetBuyproductdetailsAction, VehicleDeliveredStatusupdateAction } from '../../../Redux/Action/BuyerVehilesAution';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
export default function LeaveReview() {
  const [rating, setRating] = useState(0)
  const secretKey = 'jitendrakumar';
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id_data = queryParams.get('id');






  const dispatch=useDispatch()
useEffect(()=>{
  
  dispatch(GetBuyproductdetailsAction({
    id: id_data
  }))
  dispatch(AccountheaderAction());
},[])



  // Catch Rating value
  const handleRating = (rate) => {
    console.log(rate)
    setRating(rate)

    // other logic
  }
  // Optinal callback functions
  const onPointerEnter = () => console.log('Enter')
  const onPointerLeave = () => console.log('Leave')

  const buyerproductdetails=useSelector((state)=>state.Buyer.buy_product_details)
  const account_header_count = useSelector(
    (state) => state.Buyer?.account_header_count
  );
const navigate=useNavigate();

  // VehicleDeliveredStatusupdateAction

  const formik = useFormik({
    initialValues: {
      rating: '',
      comment: '',
     
    },

    onSubmit: (values) => {
      if(rating>0){

     

    var dataobject=  ( (buyerproductdetails?. seler_id._id)?.toString()==localStorage.getItem('dealer_id'))?
      ({
        review_status_seller:1,
        seller_rating:rating,
        _id:id_data,
        seller_comment:values.comment

      }):({
        review_status_buyer:1,
        buyer_rating:rating,
        _id:id_data,
        buyer_comment:values.comment
      })

      dispatch(VehicleDeliveredStatusupdateAction(dataobject)).then(function(){
        navigate('/pendingreviews')
      })
    }else{
    toast.error('Your feedback is valuable in evaluating the conversation. Kindly choose a message and provide your rating before submitting. Thank you for your cooperation.')
    }
  }
  });
  return (
    <>
  <Accountheader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" >Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" >Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson">Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity">Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews" className='active-border'>Reviews <span>(1)</span></NavLink>
        </li>    */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>
      {/* Header subheader white background */}
      <section className='bg-subheaderwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>

        <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/reviews"  >Pending Collection <span>({account_header_count?.pendingcollectiontotalcount??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/pendingreviews" className='active-border'>Pending Review <span>({account_header_count?.pendingreviewtotal??0})</span></NavLink>
        </li>
        <li>
          <NavLink to="/complete" >Complete <span>({account_header_count?.completereviewstotal??0})</span></NavLink>
        </li>   
       </ul>         
      </div>       
    </div>
    </div> 
      </div>
    </section>
    {/* mobile filter show */}
    {/* <section className='filter-home-v2'>
        <div className='mobile-filter-show'>
        <ul>
            <li className='bor-r-c' onClick={()=>{
            
            }}><NavLink to="#" className='sort-home active-color-stock'>Days In Stock</NavLink> </li>
            <li >
            <div className={"dropdown show "}>
            <div className="dropdown-toggle sort-home" type="button" id="dropdownMenuButton" data-toggle="dropdown" onClick={()=>{
            
            }} aria-haspopup="true" aria-expanded="false">
            Sort By
            </div>
            <div className={"dropdown-menu  "} aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#">Price (Lowest)</a>
            <a className="dropdown-item" href="#">Price (Highest)</a>
            <a className="dropdown-item" href="#">Latest Car</a>
            <a className="dropdown-item" href="#">Oldest Car</a>
            <a className="dropdown-item" href="#">Make</a>
            <a className="dropdown-item" href="#">Model</a>
            <a className="dropdown-item" href="#">Age (Newest First)</a>
            <a className="dropdown-item" href="#">Distance</a>
            <a className="dropdown-item" href="#">Mileage (Lowest First)</a>
            </div>
        </div>
            </li>
        </ul>
        </div>
   
        </section> */}
    {/* conditions modal */}
   
    {/* list-data-show */}
    <section className='list-data-show mt30 moble-mt80'>
    <div className='container container1224'>
    
        <div className='row'>
            <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8 col-xxl-8 col-12 mx-auto'>
         <form  onSubmit={formik.handleSubmit} >
         <div className='review-bg-card'>
                <div className='black-text-lg-bold'>Leave a review</div>
                <div className='row'>
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
                    <div className='img_car_bg '>
    

                         <img
                                          src={ buyerproductdetails?.conditionreport_id?.images.length>0?(buyerproductdetails?.conditionreport_id?.images[1]==false?require('../../asset_user/images/placholder-img.png'):buyerproductdetails?.conditionreport_id?.images[1]): require('../../asset_user/images/placholder-img.png')  }
                                          className='img-fluid'
                                          onError={(e) => {
                                            e.currentTarget.src = require('../../asset_user/images/placholder-img.png');
                                          }}
                                          alt='car'
                                        />                        
                    </div>
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
                    <div className='list-car-view-details flex-none'>
                        <div className="grid-title-colum-v2 height-auto"> { buyerproductdetails?.conditionreport_id?.year !=undefined?
                      buyerproductdetails?.conditionreport_id?.year+" "+ (buyerproductdetails?.conditionreport_id?.make !=undefined ?buyerproductdetails?.conditionreport_id?.make:"") +" "+ (buyerproductdetails?.conditionreport_id?.model !=undefined ?buyerproductdetails?.conditionreport_id?.model:""):(buyerproductdetails?.conditionreport_id?.make !=undefined ?buyerproductdetails?.conditionreport_id?.make:"")+" "+ (buyerproductdetails?.conditionreport_id?.model !=undefined ?buyerproductdetails?.conditionreport_id?.model:"")  }</div> 
                        <div className="home-viewcar-no-v2 mt-4">{buyerproductdetails?.conditionreport_id?.vrm}</div>  
                    </div>
                    </div>
                </div>
                <div className='row mb-4 mt-5'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
                    <div className='reviewlabel'>How was you experience with {( (buyerproductdetails?. seler_id)?.toString()==localStorage.getItem('dealer_id'))?buyerproductdetails?.user_id?.company_name
     :buyerproductdetails?.seler_id?.company_name}</div>
                    <Rating
                onClick={handleRating}
                onPointerEnter={onPointerEnter}
                onPointerLeave={onPointerLeave}
                // onPointerMove={onPointerMove}
                /* Available Props */
              />
                    </div>                  
                </div>
                <div className='row mb-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
                    <div className='reviewlabel'>Leave a comment (Optional)</div>
                    <textarea cols='30' rows='10' className='form-control textarea-review' placeholder='Type here' name='comment'
                    
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                      value={formik.values.comment}></textarea>
                    </div>                  
                </div>             
                <div className='row mb-4'>
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
                    <div className='review-update-btn-part'>
                    <button className="home-square-white-btn-v2 " type='submit'>Leave Review</button>
                    </div>
                    </div>                  
                </div>

                
            </div>  
         </form> 
            
            </div>
        </div>
    </div>
    </section> 
    </>
  
  )
}
