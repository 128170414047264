import React from 'react'
import HeaderHome from '../IncludeHome/HeaderHome'
import FooterHome from '../IncludeHome/FooterHome'

export default function AboutUs() {
  return (
<>
<HeaderHome />
 {/* // <!---------------------- Our Mission -----------------------> */}
 <section className="yo-mission-section yo-section-padding about-us-t-p">
        <div className="container container1200">
          <div className="row">
            <div className="col-12">
              <div className="yo-mission-inner">
                <div className="yo-title-sect">
                  <h2 className="yo-common-head">Buy. Trade. Sell </h2>
                  <p className="yo-common-parah">
                  Our objective is to make trading out of overage, part exchange and unwanted stock quick and easy with no hidden costs.
                  </p>
                </div>
                <div className="yo-card-boxes">
                <div className="yo-cardContent">
                <div className='row'>
                    <div className='col-md-5'>
                        <div className='colum-img-medium-about'>   
                        <img src={require('../asset_user/images/about-1.jpg')} alt='aboutimg'/>         
                        </div>
                    </div>
                    <div className='col-md-7'>
                    <h2 className="yo-common-head ">All your vehicles in one place</h2>
                    <p>
                    With our intelligent technology, you will find all your vehicle In one place. We help you track vehicle stocking age and profile to make it easy to know when it’s time to trade out of over age and wrong profile stock.
                    </p>
                    </div>
                    </div>
                </div>

                  <div className="row mt-3">                   
                    <div className="col-lg-7">
                      <div className="yo-cardContent">
                        <h2 className="yo-common-head">Intelligent Auction</h2>
                        <p>
                        With our intelligent auction, you can choose to list your vehicles in a 1,2 or 4 day auction ensuring you get the best bids possible. 
                        </p>
                        <p>With the ability to bid on multiple vehicles in one go, we have made finding the right profile vehicles for your business quick and simple. </p>
                        <p>Get the best price offered for your part exchanges ensuring you never miss a deal.</p>
                        <p>If you need to sell a vehicle fast, we provide two competitive instant bids on every vehicle.</p>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="yo-cardImg">
                      <img src={require('../asset_user/images/about-3.jpg')} alt='aboutimg'/>   
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // <!---------------------- Our Mission / End ----------------------->

    // <!---------------------- Dealer CTA Section -----------------------> */}
      <section className="yo-dealer-cta-sect yo-section-padding">
        <div className="container container1200">
          <div className="row">
            <div className="col-12">
              <div className="yo-cta-inner">
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="yo-dealer-card">
                      <h2 className="yo-common-head ">
                      Want to trade more cars?{" "}
                      </h2>
                      <p>
                      For dealers that are looking to trade more cars and be part of the yotrader journey,{" "}
                      </p>
                      <h3>
                      Click here to register for a 100% free account.{" "}
                      </h3>
                      <div className="mt-destop-50">
                        <button className="btn yo_red_btn">Join Us</button>
                      </div>
                      <div className="grey_parah">
                        <p>
                        We are a dealer led platform, committed to supporting British dealers fairly and honestly. Our team is dedicated to presenting your vehicles to qualified dealers to ensure you get the best trade bids possible.{" "}
                        </p>
                        <p className="mt-3 mb-0">Antony Johnson CEO </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // <!---------------------- Dealer CTA Section / End ----------------------->


    // <!---------------------- Team Section -----------------------> */}
      <section className="yo-team-section yo-section-padding">
        <div className="container container1200">
          <div className="row">
            <div className="col-12">
              <div className="yo-mission-inner">
                <div className="yo-title-sect">
                  <h2 className="yo-common-head">Meet the team </h2>
                  <p className="yo-common-parah">
                    {" "}
                    With over 30 years of vehicle trading experience, our carefully selected team are here to ensure your trading experience is positive and delivers the best return in your vehicles.{" "}
                  </p>
                </div>
                <div className="yo-team-images">
                  <div className="row">
                    <div className="col-lg-3 col-md-6">
                      <div className="yo-img-inner">
                        <img src={require('../asset_user/images/user_img_place.jpg')}/>
                        <h2 className="yo-team-member-name">Antony</h2>
                        <span className="yo-team-member-position">
                          CEO, Founder
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="yo-img-inner">
                        <img src={require('../asset_user/images/user_img_place.jpg')}/>
                        <h2 className="yo-team-member-name">Antony</h2>
                        <span className="yo-team-member-position">
                          CEO, Founder
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="yo-img-inner">
                        <img src={require('../asset_user/images/user_img_place.jpg')}/>
                        <h2 className="yo-team-member-name">Antony</h2>
                        <span className="yo-team-member-position">
                          CEO, Founder
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                      <div className="yo-img-inner">
                        <img src={require('../asset_user/images/user_img_place.jpg')}/>
                        <h2 className="yo-team-member-name">Antony</h2>
                        <span className="yo-team-member-position">
                          CEO, Founder
                        </span>
                      </div>
                    </div>                  
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // <!---------------------- Team Section / End ----------------------->


    //   <!---------------------- Team Section -----------------------> */}
      <section className="yo-feature-section yo-section-padding">
        <div className="container container1200">
          <div className="row">
            <div className="col-12">
              <div className="yo-mission-inner">
                <div className="yo-title-sect mb-5">
                  <h2 className="yo-common-head ">
                  Helping you Buy Sell Trade
                  </h2>
                </div>
                <div className="yo-features-sect">
                  <div className="row">
                    <div className="col-lg-4 mb-3">                    
                      <div className="yo-feature-inner yo-section-light-bg">
                        <h3>Buy</h3>
                        <p>You can now buy new and used vehicles from verified trusted dealers faster. Listings starting from just 1 day.</p>
                        <p>Our innovative auction provides a seamless experience with detailed appraisals and vehicle images.</p>
                        <p>Buying with confidence. All vehicles offered are from verified dealers.</p>
                        <p>Don't miss the chance to buy with daily emails detailing new stock.</p>
                      </div>
                      
                    </div>
                    <div className="col-lg-4 mb-3">                    
                      <div className="yo-feature-inner yo-section-light-bg">
                        <h3>Sell</h3>
                        <p>Sell your overage, part exchanges and wrong profile vehicles direct to trusted and professional motor dealers.</p>
                        <p>It's simple and faster using our innovative dealer stock management tool to launch vehicles to auction in less than a minute. </p>
                        <p>Why wait longer to sell when you can sell faster. Achieve the highest bids for your vehicles with auctions running from as little as 1 day.</p>
                        <p>No buying fees.</p>
                      </div>
                      
                    </div>
                    <div className="col-lg-4 mb-3">                    
                      <div className="yo-feature-inner yo-section-light-bg">
                        <h3>Trade</h3>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* // <!---------------------- Team Section / End -----------------------> */}
      <FooterHome />
</>
  )
}
