import React from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import Accountheader from '../../IncludeDashboard/Accountheader'

export default function PendingCollection() {
  return (
    <>
  <Accountheader />
         <div>PendingCollection</div>
    </>
    
  )
}
