import React, { useState } from 'react'
import HeaderHome from '../IncludeHome/HeaderHome'
import FooterHome from '../IncludeHome/FooterHome'
import { NavLink, useNavigate } from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { HomepageVehiclesAction, LoginAction } from '../../Redux/Action/AuthenticationAction';
import Accordion from 'react-bootstrap/Accordion';
import { motion } from "framer-motion"
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useEffect } from 'react';
import Pagination from '../Pagination/Pagination';
import { CounterofferAction } from '../../Redux/Action/SellerVehiclesActions';
import Countdown from '../CalculateCountdown/Countdown';
import CalculateCountdown from '../CalculateCountdown/CalculateCountdown';
import Homeanumation from './Homeanumation';
import Brandimagedisplay from './Brandimagedisplay';

import "../asset_user/css/animation.css"

export default function Home1() {
  const [show, setShow] = useState(false);
  const [index, setIndex] = useState(0);
  const [password_text, Setpassword] = useState('password');
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const password_hide_show = () => {
    Setpassword(password_text == 'input' ? 'password' : 'input');
  };
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(HomepageVehiclesAction({
      page_no:1
    }))
  },[])

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(LoginAction(values));
    },
  });


  const testiMonials = [
    {
        name: 'Rekob Ramya',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        address: 'USA',
        img: 'https://i.ibb.co/hgGJc8d/Gareth-Bale.jpg'
    },
    {
        name: 'Brandon Savage',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        address: 'USA',
        img: 'https://i.ibb.co/z7Kp6yr/np-file-33188.jpg'
    },
    {
        name: 'Steve Burns',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        address: 'USA',
        img: 'https://i.ibb.co/CP5sj7g/2856040-58866808-2560-1440.jpg'
    },
    {
        name: 'Kevin Canlas',
        description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s.',
        address: 'USA',
        img: 'https://i.ibb.co/10SYccm/1552313010-354215-noticia-normal.jpg'
    },
]
//Owl Carousel Settings
const options = {
    loop: true,
    center: true,
    items: 1,
    margin: 0,
    autoplay: false,
    dots: false,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    responsive: {
        0: {
            items: 1
        },
        600: {
            items: 3
        },
        1000: {
            items: 3
        }
    }
};


const vehicles_home=useSelector((state)=>state.VehicleReducer.vehicles_home);


const [brandcss, setBrandcss] = useState("none");



const handelshow=()=>{
  setBrandcss("")
}

const handelhide=()=>{
  setBrandcss("none")
}

  return (
<>
 <HeaderHome />

{/* Banner Section Start */}
<section className='form-bannerSec-bg'>
<div className='container container1200'>
    <div className='row d-flex align-items-center vh-port'>
      <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
        <div className='left-banner-content'>
          <div className='banner-heading-form-ptheme'>Launch your car</div> 
          <div className='banner-heading-form-ptheme'>into auction</div> 
          <div className='banner-heading-form-ptheme'>in <span className='light-gry-ptheme'>60 seconds</span>
          </div> 
          <div className='banner-text-pragrph-ptheme mb-2 mt-3'>Join for free. Sign up today have access to daily auctions. 
          With hundreds of cars listed every week from rated Dealers.</div>

          <div className='banner-text-pragrph-ptheme'>Buyer fee starting from £25</div>
          <div className='btn-banner-part'>
            <ul className='banner-btn-group-ptheme'>
              <li >
              <NavLink to="/registration" className='black-btn-primary-home'>Join Yotrader today</NavLink>            
              </li>
              <li className='snapshot-img'><img src={require('../asset_user/images/home-page/snapshot-ex-cam.svg').default} alt=''/> </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
       <div className='hero-banner-img-ptheme'>
       <img src={require('../asset_user/images/home-page/hero_banner.svg').default} alt=''/> 
       </div>
      </div>
    </div>
</div>  
</section>
{/* Banner Section end */}
<div className='gradient-color-dark'>
{/* buy sell trade - start */}
<section className='buy-sellSec Section-divide-home'>
<div className='container container1200'>
<div className='section-heading-ptheme'>Buy. <span className='gry-text-ptheme'>Sell.</span> <span className='parrot-text-ptheme'>Trade</span></div>
<div className='section-pragraph-ptheme width40-fix'>

<div className='pargrph-length-w desktop-pra-home'>With over 3000 approved registered dealers</div>
<div className='pargrph-length-w desktop-pra-home'>we have a huge choice of retail ready and part exchange</div>
<div className='pargrph-length-w desktop-pra-home'>vehicles added to the live auchtion daily.</div>

<div className='mobile-pargph-view'>
  With over 3000 approved registered dealers,we have a huge choice of retail ready and part exchange vehicles added to the live auchtion daily.
</div>




</div>
 <div className='row-custom'>
  <div className='col-xxl-custom col-md-custom col-lg-custom gridm-b'>
   <div className='colum-part-ptheme '>
    <div className='img-grid-ptheme position-relative'>
    <img src={require('../asset_user/images/home-page/grid-f.png')} alt=''/> 
    <div className='purrple-star'>
    <img className="desktop-view" src={require('../asset_user/images/home-page/star-purrple.svg').default} alt=''/> 
    <img className="mobile-view" src={require('../asset_user/images/home-page/star-purrple-short.svg').default} alt=''/> 
    </div>
    <div className='view-auction'>
    <img className="desktop-view" src={require('../asset_user/images/home-page/view-auction.svg').default} alt=''/> 
    <img className="mobile-view" src={require('../asset_user/images/home-page/view-auction-short.svg').default} alt=''/> 
    </div>
    </div>

    <div className='grid-content-ptheme pl-35'>
      <h4>Join Yotrader today for free</h4>
      <p>Sign up today! It's 100% FREE</p>
      <p>No subscription. Low cost fees.</p>
      <p>The Yotrader team is here to help you</p>
    </div>
   </div>
  </div>
  <div className='col-xxl-custom col-md-custom col-lg-custom gridm-b'>
   <div className='colum-part-ptheme '>
    <div className='img-grid-ptheme position-relative'>
    <img className="img-shadow-grid desktop-grid-s" src={require('../asset_user/images/home-page/grid-s.png')} alt=''/> 
    <img className="img-shadow-grid mobile-grid-s" src={require('../asset_user/images/home-page/mobile-grid-s.png')} alt=''/> 
    <div className='magniffy'>
      <img className="desktop-view" src={require('../asset_user/images/home-page/magniffy.png')} alt=''/> 
      <img className="mobile-view" src={require('../asset_user/images/home-page/magniffy-short.png')} alt=''/> 
    </div>
    </div>
    <div className='grid-content-ptheme'>
      <h4>Browse correct profile stock</h4>
      <p>Browsing, buying, selling, bidding on stock..</p>
      <p>Buy with confidence from rated dealers!</p>
     
    </div>
   </div>
  </div>
  <div className='col-xxl-custom col-md-custom col-lg-custom gridm-b'>
   <div className='colum-part-ptheme'>
    <div className='img-grid-ptheme position-relative'>
    <img src={require('../asset_user/images/home-page/grid-third.png')} alt=''/> 
    <div className='hand-pointer'>
      <img className="desktop-view" src={require('../asset_user/images/home-page/pointer.svg').default} alt=''/> 
      <img className="mobile-view" src={require('../asset_user/images/home-page/pointer.svg').default} alt=''/> 
    </div>
    <div className='happy-lemon'>
      <img className="desktop-view tab-view " src={require('../asset_user/images/home-page/lemon-fun.png')} alt=''/> 
      <img className="mobile-view mobile-view-tab"  src={require('../asset_user/images/home-page/lemon-fun-short.png')} alt=''/> 
    </div>
    </div>
    <div className='grid-content-ptheme'>
      <h4>Get Instant Trade Offers!</h4>
      <p className='desktop-pra-home-offers'>Place bids instantly online.</p>
      <p className='desktop-pra-home-offers'>Get Instant Trade Offers!</p>
      <p className='desktop-pra-home-offers'>Launch car to auction in 60 seconds</p>
      <p className='desktop-pra-home-offers'>then just sit back and wait for Bids.</p>
      <div className='mobile-pargph-view-offers'>
      Place bids instantly online.
    </div>
    <div className='mobile-pargph-view-offers'>
    Get Instant Trade Offers!
    </div>
    <div className='mobile-pargph-view-offers'>
    Launch car to auction in 60 seconds then just sit back and wait for Bids.
    </div>
   
    </div>
   </div>
  </div>
  <div className='col-xxl-custom col-md-custom col-lg-custom gridm-b'>
   <div className='colum-part-ptheme'>
    <div className='img-grid-ptheme position-relative'>
    <img src={require('../asset_user/images/home-page/grid-fourth.png')} alt=''/> 
    <div className='winning-bid'>
      <img className="desktop-view" src={require('../asset_user/images/home-page/winning-bid-mob.png')} alt=''/> 
      <img className="mobile-view" src={require('../asset_user/images/home-page/winning-bid-mob-short.png')} alt=''/> 
    </div>
    </div>
    <div className='grid-content-ptheme'>
      <h4>YO! You won the Winning Bid!</h4>
      <p className='desktop-pra-home-wining'>Yotrader is faster smarter and designed to be </p>
      <p className='desktop-pra-home-wining'>quick, easy and as stress free for everyone.</p>
      <p className='desktop-pra-home-wining'>Buy with confidence from Trusted dealers!</p>   
      
      <div className='mobile-pargph-view-wining'>
      Yotrader is faster smarter and designed to be quick, easy and as stress free for everyone. Buy with confidence from Trusted dealers!
    </div>
    </div>
   </div>
  </div>
</div>
</div>
</section>
{/* buy sell trade - end */}

{/* buy sell trade - end */}
<section className='sectonGradientSec '>
<div className='peasy-lemonSec'>
<div className=' section-heading-ptheme'>It's easy, <br />peasy <span className='parrot-text-ptheme'>lemon </span>squeezy!</div>
<div className='calltoaction-btn'>
<NavLink to="/registration" className='parrot-btn-primary-home'>Join Yotrader</NavLink>    
</div>
</div>

<Homeanumation />

<div className=' superchargeSec Section-divide-home pt-super'>
<div className='white-heading-home-ptheme'>Supercharge your Trading Experience.</div>
<div className='black-pragraph-home desktop-pra-home-super'>
Put your focus where it needs to be by letting YoTrader manage your remarketing journey. </div>
<div className='black-pragraph-home desktop-pra-home-super'>We have taken the hassle out of trading your overage or unwanted stock.</div>
<div className='black-pragraph-home desktop-pra-home-super'>Simply select the vehicle to trade get instant trader offers</div>
<div className='black-pragraph-home desktop-pra-home-super'>from Trusted Dealers and send to auction within 60 seconds. </div>
<div className='mobile-pargph-view-super'>
Put your focus where it needs to be by letting YoTrader manage your remarketing journey. We have taken the hassle out of trading your overage or unwanted stock. Simply select the vehicle to trade get instant trader offers from Trusted Dealers and send to auction within 60 seconds. 
</div>
<div className='calltoaction-anchor'>
<NavLink to="/registration" className='parrot-text-color-link'>Learn more <img className="next-arrow-parrot" src={require('../asset_user/images/home-page/arrow.svg').default} alt=''/> </NavLink>    
</div>
</div>
</section>
{/* buy sell trade - end */}
</div>
<section className='logoClientSec white-light-bg '>
<div className='brand-headingSec'>Browse by Brand</div>

<div className='marquee'>

<div  className="marquee--inner">
<ul className='brand-img-ul'>
  <li>
  <img src={require('../asset_user/Browsebybrand/all-logo.png')} />
  </li>
  {/* <li>
  <img src={require('../asset_user/Browsebybrand/bmw.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/ford.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/merc.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/por.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/re.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/rover.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/toyota.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/vw.jpg')} />
  </li> */}


</ul>
<ul className='brand-img-ul mar-ml-2'>
  <li>
  <img src={require('../asset_user/Browsebybrand/all-logo.png')} />
  </li>
  {/* <li>
  <img src={require('../asset_user/Browsebybrand/bmw.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/ford.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/merc.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/por.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/re.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/rover.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/toyota.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/vw.jpg')} />
  </li> */}


</ul>
{/* <ul className='brand-img-ul'>
  <li>
  <img src={require('../asset_user/Browsebybrand/audi.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/bmw.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/ford.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/merc.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/por.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/re.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/rover.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/toyota.jpg')} />
  </li>
  <li>
  <img src={require('../asset_user/Browsebybrand/vw.jpg')} />
  </li>


</ul> */}
</div>


</div>

   {/* <Brandimagedisplay /> */}
   <div className='brand-headingSec cursor-pointer'  onClick={handelshow}  style={{ display:brandcss=="none"?"":"none" }}>+ Show all Brands</div>
   <div className='brand-headingSec cursor-pointer' onClick={handelhide}  style={{ display:brandcss=="none"?"none":"" }}><span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" id="close"><path d="M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12,13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z"></path></svg></span> Hide all brands</div>

<div className='extra-brand-logo' style={{ display:brandcss }}>
<div className='container container1200'>
  <div className='row'>
    <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
      <ul>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Abarth.png')}/>
          </div>
          <div className='title-sm-car'>Abarth</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Bentley.png')}/>
          </div>
          <div className='title-sm-car'>Bentley</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Citroen.png')}/>
          </div>
          <div className='title-sm-car'>Citroen</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/ds.png')}/>
          </div>
          <div className='title-sm-car'>DS Automobiles</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Honda.png')}/>
          </div>
          <div className='title-sm-car'>Honda</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Isuzu.png')}/>
          </div>
          <div className='title-sm-car'>Isuzu</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Lamborghini.png')}/>
          </div>
          <div className='title-sm-car'>Lamborghini</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Lexus.png')}/>
          </div>
          <div className='title-sm-car'>Lexus</div>
          <div className='number-sm-car'>168</div>  
        </li>  
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Renault.png')}/>
          </div>
          <div className='title-sm-car'>Renault</div>
          <div className='number-sm-car'>168</div>  
        </li>                         
      </ul>
    </div>
    <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
      <ul>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Alfa-Romeo.png')}/>
          </div>
          <div className='title-sm-car'>Alfa Romeo</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Chevrolet.png')}/>
          </div>
          <div className='title-sm-car'>Chevrolet</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/cupra.png')}/>
          </div>
          <div className='title-sm-car'>Cupra</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Ferrari.png')}/>
          </div>
          <div className='title-sm-car'>Ferrari</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/hyundai.png')}/>
          </div>
          <div className='title-sm-car'>Hyundai</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Jaguar.png')}/>
          </div>
          <div className='title-sm-car'>Jaguar</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Kia.png')}/>
          </div>
          <div className='title-sm-car'>Kia</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Maserati.png')}/>
          </div>
          <div className='title-sm-car'>Maserati</div>
          <div className='number-sm-car'>168</div>  
        </li>
                
      </ul>
    </div>
    <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
      <ul>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Aston-Martin.png')}/>
          </div>
          <div className='title-sm-car'>Aston Martin</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Chrysler.png')}/>
          </div>
          <div className='title-sm-car'>Chrysler</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Dacia.png')}/>
          </div>
          <div className='title-sm-car'>Dacia</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Fiat.png')}/>
          </div>
          <div className='title-sm-car'>Flat</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Infiniti.png')}/>
          </div>
          <div className='title-sm-car'>Infiniti</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Jeep.png')}/>
          </div>
          <div className='title-sm-car'>Jeep</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Land-Rover.png')}/>
          </div>
          <div className='title-sm-car'>Land Rover</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/maxus.png')}/>
          </div>
          <div className='title-sm-car'>Maxus</div>
          <div className='number-sm-car'>168</div>  
        </li>
                
      </ul>
    </div>
    <div className='col-sm-12 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
      <ul>
      <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Mazda.png')}/>
          </div>
          <div className='title-sm-car'>Mazda</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Mini.png')}/>
          </div>
          <div className='title-sm-car'>Mini</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/other.png')}/>
          </div>
          <div className='title-sm-car'>Other UK</div>
          <div className='number-sm-car'>168</div>  
        </li> 
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/McLaren.png')}/>
          </div>
          <div className='title-sm-car'>Mclaren</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Mitsubishi.png')}/>
          </div>
          <div className='title-sm-car'>Mitsubishi</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Peugeot.png')}/>
          </div>
          <div className='title-sm-car'>Peugeot</div>
          <div className='number-sm-car'>168</div>  
        </li> 


        
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/MG.png')}/>
          </div>
          <div className='title-sm-car'>MG</div>
          <div className='number-sm-car'>168</div>  
        </li>
        <li className='list-flex'>
          <div className='logo-sm-car'>
          <img src={require('../asset_user/images/brand-logo/Nissan.png')}/>
          </div>
          <div className='title-sm-car'>nissan</div>
          <div className='number-sm-car'>168</div>  
        </li>            
                  
      </ul>
    </div>
  </div>
  </div>
</div>   
</section>
{/* slider header Start */}
  <section className='slider-sec'>
    <div className='container container1200'>
    <div className=' superchargeSec'>
      <div className='black-pragraph-home brand-bottom-desktop'>
      With thousands of approved registered dealers, we have a huge choice of</div>
      <div className='black-pragraph-home brand-bottom-desktop'>
      retail ready and part exchange vehicles added to the live auction daily.

      </div>
      <div className='black-pragraph-home brand-bottom-mobile'>
      With thousands of approved registered dealers, we have a huge choice of retail ready and part exchange vehicles added to the live auction daily. </div>
     
      <div className='calltoaction-anchor'>
      <NavLink to="/registration" className='parrot-text-color-link'>Learn more <img className="next-arrow-parrot" src={require('../asset_user/images/home-page/arrow.svg').default} alt=''/> </NavLink>    
      </div>
      </div>
      <div className='row row-reverse-slider slider-part-ptheme' >
        <div className='col-md-4 prr-0'>
        <div className='left-content-slider'>
          <div className='black-header-part'>
            <div className='slider-large-parrot'>Featured this week</div>
            <img className="sm-symbol-logo-slider" src={require('../asset_user/images/home-page/light-logo-icon.svg').default} alt=''/> 
          </div>
          <div className='white-bg-slider'>
            <div className='blue-color-large pb-3'>2019 Mercedes-Benz A200 Hatchback</div>
            <div className='blue-color-medium'>32,546 Miles</div>

            <div className='green-text-group'>
              <div className='price-blue-slider'>£8,550</div>
              <div className='pipline'>|</div>
              <div className='price-blue-slider'>10 bids</div>
            </div>
            <div className='blue-color-medium'>1 Day 5 Hours Remaining </div>
            <div className='blue-light-color-medium'>01/01/2023   <span className='time-slider'>16:30:05</span></div>

            <div className='blue-text-group'>
              <div className='price-black-slider'>£8,550</div>
              <div className='price-black-slider'>Buy it now</div>
            </div>

            <div className='check-slider'>
            <img src={require('../asset_user/images/home-page/tick.svg').default} alt=''/> 
            <div className='skyblue-color-medium'>YoTrader Trusted Seller</div>
            </div>

            <div className='findmore'>
            <NavLink to="" className='gry-dark-color-large underline-none' onClick={()=>{
              setShow(true)
            }}>Find Out More</NavLink>
            <NavLink to=""><img src={require('../asset_user/images/home-page/purpple-arrow.svg').default} alt=''/> </NavLink>
            </div>
          </div>
          <div className='start-curve-vector'>
        <img src={require('../asset_user/images/home-page/star-vector.svg').default} alt=''/>
        </div>
        </div>
        
        </div>
        <div className='col-md-8 pll-0'>
        <div className='slider-part-layout'>
        <Carousel activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
        <img src={require('../asset_user/images/product_listing.png')} alt=''/>     
        </Carousel.Item>

        <Carousel.Item>
        <img src={require('../asset_user/images/product_listing.png')} alt=''/>
        </Carousel.Item>

        <Carousel.Item>
          <img src={require('../asset_user/images/product_listing.png')} alt=''/>     
        </Carousel.Item>
        </Carousel>
         <div className='bid-end-slider-img'>
         <div className='green-box-square orange-box-square blue-box-square parrot-box-square'>
         <ul className='time-part-ul'>
          <li>
            <p>01</p>
            <span>Days</span>
          </li>
          <li>:</li>
          <li>
          <p>24</p>
            <span>Hours</span>
          </li>
          <li>:</li>
          <li>
          <p>30</p>
            <span>Mins</span>
          </li>
          <li>:</li>
          <li>
          <p>60</p>
            <span>Secs</span>
          </li>
        </ul>
         </div>          
        </div>
        </div>       
        </div>
      </div>
    </div>
  </section>
{/* slider header end */}

    {/* colum section Start */}
    <section className='colum-sec grid-bg-img yellow-light-bg Section-divide-home'>
      <div className='container container1200'>
        <div className='section-heading-divide'>
        Live Auctions: <span className='parrot-text-color'>{vehicles_home?.total_vehicles}</span> Live Auctions
        </div>
        <div className='row'> 
        {
          vehicles_home?.dealer_vehicle_list?.map(function(object,i){
            console.log(object?.images)
return( <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3 col-12 mb-3'  onClick={()=>{
                setShow(true)
              }} key={i}>
            <div className='colum-box-home'>
              <div className='img-part-colum'>
              <img  src={ (object?.images?.length>0 && object?.images?.length  !=undefined)?((object?.images[1]=='false'||object?.images[0]=='false')?require('../asset_user/images/placholder-img.png'):object?.images[1]): require('../asset_user/images/placholder-img.png')  }
            
            
            
            />
              </div>
              <div className='colum-box-content'>               
              <div className="colum-title fixheight-home">  { object?.year !=undefined? object?.year+" "+ (object?.make !=undefined ?object?.make:"") +" "+ (object?.model !=undefined ?object?.model:""):(object?.make !=undefined ?object?.make:"")+" "+ (object?.model !=undefined ?object?.model:"")  }</div>
              <div className="badge-gry-ptheme">{new Intl.NumberFormat('en-GB')
                                            .format(object.mileage)
                                            .replace(/\.00$/, '')} miles</div>
              <div className="colum-price">
              
              {object.yotraderbids.length ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((object.yotraderbids.length==0)?0: Math.max(...object.yotraderbids.map(o => o.yotrader_value))).replace(/\.00$/, '')}
              
              
              </div>
              <div className='bid-time-counter-home'>
              <div className="colum-gry-text-color mr-2">    {(object.yotraderbids?.length<=1)?" "+object.yotraderbids?.length+" Bid ":" "+object.yotraderbids?.length+" Bids "}
             
             {/* <Countdown  endDate={object?.expires} /> */}
                   </div>
                   <div className="colum-gry-text-color"> 17h 47m</div>
              </div>
              <div className='group-part-c'>
              <div className="colum-price-black">  {new Intl.NumberFormat('en-GB', {
                                        style: 'currency',
                                        currency: 'GBP',
                                        })
                                        .format(object?.price_now)
                                        .replace(/\.00$/, '')}</div>
              <div className="black-md-text-color">Buy it Now</div>
              </div>
              </div> 

              <div className='mt-loc-home'>
              <div className='loc-card-part'>
              <div className="colum-loc">
              <img src={require('../asset_user/images/loc.svg').default}  />
              </div>
              <div className="colum-gry-text-color">Edinburgh, E23</div>
              </div>
              <div className='learn-more-arror-card'>
                <p>Learn More</p>
                <img src={require('../asset_user/images/home-page/black-arrow.svg').default}  />
              </div>
              </div>           
              
            </div>
          </div>)
          })
        }
         
          
        </div>
      </div>
    </section>   

    {/* pagination */}

{/* <div className='pagination-wrapper yellow-light-bg'>
<Pagination   pageinationD={vehicles_home?.total_page} dispatch={dispatch} action_name={HomepageVehiclesAction} />
</div> */}
{/*end  pagination */} 
    {/* colum section End */}

{/* colum number section Start */}
<section className='yellow-light-bg Section-divide-ptheme Section-divide-home'>
  <div className='container container1200'>
  <div className='section-heading-ptheme'>Buy. <span className='gry-text-ptheme'>Sell.</span> <span className='parrot-text-ptheme'>Trade</span></div>
  <div className='calltoaction-btn'>
  <NavLink to="/registration" className='parrot-btn-primary-lg-home'>Join Yotrader today</NavLink>    
  </div>
  </div>
</section>
{/* colum number section End */}

{/* gallery-fotter Start */}
<section className='gallery-parrot-bg Section-divide-home'>
  <div className='container container1200'>
   <div className='kin-emoji'>
   <img src={require('../asset_user/images/home-page/fotter-graphic/Keith.png')} alt=''/>
   </div> 
   <div className='gallery-text-home'>
    <ul>
      <li><span></span> <p>Bish</p></li>
      <li><span></span> <p>Bash</p></li>
      <li><span></span><p>Yotrader!</p></li>
    </ul>
   </div>         
  </div>
</section>
{/* gallery-fotter Start */}







 

     
    
    <Modal
         show={show}
         onHide={()=>{
          setShow(false)
         }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <div className='modal-body'>
        <div className='row align-items-center'>
           
              <div className='sign_in'>
                <form className='w-100' onSubmit={formik.handleSubmit}>
                  <div className='card box-shadow-none'>
                    <div className='card-body '>
                      <div className='title_signin'>
                        <h1>Log in and start trading</h1>
                        <div className='form-group mb-3 mt-4'>
                          <label for=''>Email</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Email Credentials'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            name='email'
                            value={formik.values.email}
                          />


                          {formik.errors.email ? (
                            <p className='error'>{formik.errors.email}</p>
                          ) : null}

                          
                        </div>
                        <div className='form-group mb-3'>
                          <label for=''>Password</label>
                          <div className='input-group'>
                            <input
                              type={password_text}
                              className='form-control br-none'
                              id='password'
                              placeholder='Login Password'
                              aria-label='Username'
                              aria-describedby='basic-addon1'
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              name='password'
                              value={formik.values.password}
                            />

                            <div className='input-group-prepend'>
                              <span
                                className='input-group-text bl-none '
                                id='basic-addon1'
                                onClick={() => password_hide_show()}
                              >
                                <i className='fa fa-eye' id='eye'></i>
                              </span>
                            </div>
                          </div>
                          {formik.errors.password ? (
                            <p className='error'>{formik.errors.password}</p>
                          ) : null}
                        </div>
                        <div className='d-flex justify-content-between'>
                          <div>
                            <div
                              className='form-check form-switch form-switch-md mb-3'
                              dir='ltr'
                            >
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='SwitchCheckSizemd'
                              />
                              <label
                                className='form-check-label'
                                for='SwitchCheckSizemd'
                              >
                                Remember me
                              </label>
                            </div>
                          </div>
                          <div className='forgot-link'>
                            <NavLink to='/forgotpassword'>
                              Forgot Password?
                            </NavLink>
                          </div>
                        </div>
                        <div className='btn_submit mt-4'>
                          <button className='black-lg-btn-primary-home'>
                            Sign In
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
           
           
  
          </div>
        </div>

      
      </Modal>
    <section className='coming-soon'>
    <div className='container container1200'>
     <h1>Our app is</h1> 
     <h1 className='parrot-text-ptheme'>coming soon!</h1> 
     {/* <p>Stay tuned & Follow for more!</p>       */}
     <img src={require('../asset_user/images/home-page/coming-soon.png')} alt='yotrader-logo'  className='coming-soon-img'/>               
    </div>
    </section>
    <FooterHome />
</>
   
  )
}
