import React, { useEffect } from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import { NavLink, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { AddDealer_stock_age, Dealer_details_dealer, Dealer_profile_update } from '../../../Redux/Action/AuthenticationAction';
import { DmsProviderAction } from '../../../Redux/Action/SellerVehiclesActions';
import Accountheader from '../../IncludeDashboard/Accountheader';

import { useState } from 'react';
import PaymentForm from './PaymentForm';
import { ElementsConsumer } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Modal } from 'react-bootstrap';
import io from "socket.io-client";
const stripePromise = loadStripe('pk_test_51NSN9FK8IqEbWoY8cY6HCCFZ28bQECPn2v3iIuKQeBnzc4X7XqowWsgrDl7oy49J2UnAODFhm8f0w61rA6GLp1Ix00AjdWT8tF');
export default function DealerShipinfo() {
  const user_logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };
  const dispatch = useDispatch();
const [stripeshow,setStripe]=useState(false)
const [dealerinfo,setDealerinfo]=useState("")        
  useEffect(() => {
    dispatch(Dealer_details_dealer(localStorage.getItem('dealer_id'))).then(
      (data) => {
        var fileds = [
          'company_name',
          'phone_number',
          'main_phone_number',
          'post_code',
          'stock_age',
          'dealer_house_name_number',
          'dealer_street',
          'town_city',
          'county',
        ];

        fileds.forEach((el) => {
          formik.setFieldValue(el, data[el], false);
        });


        setDealerinfo(data)
      }
    );
  }, [localStorage.getItem('dealer_id')]);


  const formik = useFormik({
    initialValues: {
      company_name: '',
      phone_number: '',
      main_phone_number: '',
      post_code: '',
      stock_age: '',
      dealer_house_name_number: '',
      dealer_street: '',
      town_city: '',
      county: '',
    },
    validationSchema: yup.object({
      company_name: yup.string().required('Dealer ship name is required'),
      phone_number: yup.string().required('Main Phone Number is required'),
      main_phone_number: yup
        .string()
        .required('Second Phone Number is required'),
      post_code: yup.string().required('Post code is required'),
      stock_age: yup.string().required('Stock age is required'),
      dealer_house_name_number: yup
        .string()
        .required('House detail is required'),
      dealer_street: yup.string().required('Street is required'),
      town_city: yup.string().required('City is required'),
      county: yup.string().required('County is required'),
    }),
    onSubmit: (values) => {
      var save_dealer_profile_detail = new FormData();
      // save_dealer_profile_detail.append('image', imgupload);
      save_dealer_profile_detail.append('company_name', values?.company_name);
      save_dealer_profile_detail.append('phone_number', values.phone_number);
      save_dealer_profile_detail.append(
        'main_phone_number',
        values.main_phone_number
      );
      save_dealer_profile_detail.append('post_code', values.post_code);
      save_dealer_profile_detail.append('stock_age', values.stock_age);
      save_dealer_profile_detail.append(
        'dealer_house_name_number',
        values.dealer_house_name_number
      );
      save_dealer_profile_detail.append('dealer_street', values.dealer_street);
      save_dealer_profile_detail.append('town_city', values.town_city);
      save_dealer_profile_detail.append('county', values.county);
      dispatch(Dealer_profile_update(save_dealer_profile_detail));
    },
  });

  useEffect(() => {
    dispatch(AddDealer_stock_age());
  }, []);
  const age_stock = useSelector(
    (state) => state.VehicleReducer.dealer_stocke_age
  );

//stripe 


const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const closestripe=()=>{
    setStripe(false)
  }

 

  // useEffect(()=>{
  //   console.log("ddddddddddddddd")
  //   setStripe(window?.stripe_status)
  // }, window?.stripe_status)
 

  const modalstatus = useSelector(
    (state) => state.Buyer?.modalstatus
  );

  const socket =  io(process.env.REACT_APP_SOCKET_URL, {
    autoConnect: true,
  });

  socket.on('connect', () => {
    console.log('Socket connected');

 
  });

  socket.on('modalclose', (data) => {
    console.log('Received data from server:', data);
  });





  
  return (
    <>
    <Accountheader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" className='active-border'>Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" >Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson">Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity">Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews">Reviews <span>(1)</span></NavLink>
        </li>     */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>  
    <section className='dealership-form mt30'>
    <div className='container container1224'>
    <div className='row row-reverse-mobile'>
    <div className='col-sm-12 col-md-4 col-lg-5 col-xl-5 col-xxl-5 col-12'>
    <div className='dealership-whitecard mb-3'>
    <div className='page-heading-v2'>Dealer Support</div>
    <div className='contact-info-dealer'>
      <div className='blact-text-normal fontweight500'>Mon-Sat 9am-5pm</div>
      <div className='blact-text-normal fontweight500'>sales@yotrader.co.uk</div>
      <div className='blact-text-normal fontweight500'>0115 694 0404</div>
     

    
      <div className='blact-text-normal mt-4'>All our dealer reps have direct lines, if you call, we answer. No holding.</div>
    </div>
    </div> 


    
    <div className='dealership-whitecard mb-3'>
    <div className='bank-card-part'>
    <div className='page-heading-v2'>Update Payment Details</div>
    {
      dealerinfo.payment_link==undefined?( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Add</div>):( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Update</div>)
    }
   
   
    </div>
    </div> 

    <div className='dealership-whitecard mb-3'>
    <NavLink to="#" className="red-color-lg" onClick={() => user_logout()}>Logout</NavLink>
    </div>  
    </div>
   
    <div className='col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 col-12'>
    <form onSubmit={formik.handleSubmit}>
      <div className='dealership-whitecard mb-3'>
      <div className='page-heading-v2'>Dealership information</div>
      <div className='form_action'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Dealership Name</label>
              <input
                type='text'
                className='form-control'
                placeholder='JCT 600'
                name='company_name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.company_name}
                />


                          {formik.errors.company_name ? (
                                    <strong style={{ color: 'red' }}>
                                      {formik.errors.company_name}
                                    </strong>
                                  ) : null}
            </div>                  
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>House Name or Number</label>
              <input
                type='text'
                className='form-control'
                placeholder='71 - 75'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={
                  formik.values.dealer_house_name_number
                                    }
              />
               {formik.errors.dealer_house_name_number ? (
                <strong style={{ color: 'red' }}>
                  {formik.errors.dealer_house_name_number}
                </strong>
              ) : null}
            </div>                  
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Main Phone Number</label>
              <input
                type='text'
                className='form-control'
                placeholder='020 3880 6050'
                name='phone_number'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone_number}
              />

              {formik.errors.phone_number ? (
                      <strong style={{ color: 'red' }}>
                        {formik.errors.phone_number}
                      </strong>
                    ) : null}
            </div>                 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Street</label>
              <input
                type='text'
                className='form-control'
                name='dealer_street'
                placeholder='Shelton Street'
                onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.dealer_street}
              />
                {formik.errors.dealer_street ? (
                                    <strong style={{ color: 'red' }}>
                                      {formik.errors.dealer_street}
                                    </strong>
                                  ) : null}
            </div>                 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Secondary Phone Number</label>
              <input
                type='text'
                className='form-control'
                placeholder='07477 202 151'
                name='main_phone_number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.main_phone_number}
              />
               {formik.errors.main_phone_number ? (
                  <strong style={{ color: 'red' }}>
                    {formik.errors.main_phone_number}
                  </strong>
                ) : null}
            </div>                
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Town / City</label>
              <input
                type='text'
                className='form-control'
                placeholder='London'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.town_city}
              />
                {formik.errors.town_city ? (
                                    <strong style={{ color: 'red' }}>
                                      {formik.errors.town_city}
                                    </strong>
                                  ) : null}
            </div>               
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Post Code</label>
              <input
                type='text'
                className='form-control'
                placeholder='WC2H 9JQ'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.post_code}
              />
               {formik.errors.post_code ? (
                <strong style={{ color: 'red' }}>
                  {formik.errors.post_code}
                </strong>
              ) : null}
            </div>              
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>County</label>
              <input
                type='text'
                className='form-control'
                placeholder='London'
                name='county'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.county}
              />
               {formik.errors.county ? (
                <strong style={{ color: 'red' }}>
                  {formik.errors.county}
                </strong>
              ) : null}
            </div>              
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Dealer Stock Age</label>
              <select
                name='stock_age'
                className='form-control'
                id=''
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.stock_age}
              >
                <option value=''>Select stock age</option>
                {age_stock.map(function (age_stock, j) {
                  return (
                    <option value={age_stock._id} key={j}>
                      {age_stock.name}
                    </option>
                  );
                })}
              </select>

                {formik.errors.stock_age ? (
                  <p className='error'>
                    {formik.errors.stock_age}
                  </p>
                ) : null}
            </div>              
          </div>
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mt-4'>
          <div className='update-btn-part'>
              <button className='dealer_btn_update'>Update</button>
            </div>             
          </div>
        </div>
      </div>
      </div>
      </form>     
    </div>   
      
      </div>
    </div>
    </section> 

    <Modal show={stripeshow} onHide={closestripe} className='mt-top checkout-popup'>
    <form >
    <div className='modal-body white-bg-popup-top'>     
     <Modal.Header closeButton></Modal.Header>
     <Elements stripe={stripePromise}>
      {/* Your component hierarchy */}
      <PaymentForm open={stripeshow} onClose={closestripe} />
    </Elements>

 </div>

</form>


  </Modal>
    </>
  
  )
}
