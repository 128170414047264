import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import ImageGallery from 'react-image-gallery';
export default function Gallery({images_data}) {


    const [images,setImages]=useState([])
    const playRef = useRef(null);

    useEffect(() => {
        if (images_data?.images.length>0  && images_data?.images[0] !='false') {
          

          var filtered = images_data?.images?.filter(function (el) {
            return el != "";
          });
   


          const updatedImages = filtered?.map(function (image_obj) {
            return {
              original: image_obj,
              thumbnail: image_obj
            };
          });
      
          setImages(updatedImages);
        }
        else if(images_data?.images.length==0 ||images_data?.images[0] =='false'){
            setImages([{
            
              original: 'https://portal-new.foxbegin.com/static/media/placholder-img.f1e066d35ee47c4003dc.png',
              thumbnail: 'https://portal-new.foxbegin.com/static/media/placholder-img.f1e066d35ee47c4003dc.png'
            }]); 
        } else if(images_data ==undefined){
          setImages([{
          
            original: 'https://portal-new.foxbegin.com/static/media/placholder-img.f1e066d35ee47c4003dc.png',
            thumbnail: 'https://portal-new.foxbegin.com/static/media/placholder-img.f1e066d35ee47c4003dc.png'
          }]); 
      }
        else{
            const updatedImages = images_data?.vehicle_id?.image.map(function (image_obj) {
                return {
                  original: image_obj,
                  thumbnail: image_obj
                };
              });
          
              setImages(updatedImages); 
        }
      }, [images_data]);
      
 
    
 

     
      const [isPlaying, setIsPlaying] = useState(false);
    
      const playOrPause = () => {
        setIsPlaying((prev) => {
          if (playRef) {
            playRef.current[prev ? "pause" : "play"]();
          }
          return !prev;
        });
      };
      
  return (
    <ImageGallery ref={playRef} items={images} />
  )
}
