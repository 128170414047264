import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { DealerRegistrationAciton } from '../../Redux/Action/AuthenticationAction';
import { Modal } from 'react-bootstrap';
import Step1Registration from './Step1Registration';
import Step2Registration from './Step2Registration';
import FooterHome from '../IncludeHome/FooterHome';
import HeaderHome from '../IncludeHome/HeaderHome';
export default function Registrationindex() {

    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
   
    const [showsucess, setShowsucess] = useState(false);
  
  
    const nextStep = () => {
      setStep(step + 1);
    };
    const dispatch = useDispatch();
    const prevStep = () => {
      setStep(step - 1);
    };
  
  
    const FinalStep = ({ formData, prevStep }) => {
   
      const formik_data = useFormik({
        initialValues: {
          checkbox_one: formData.checkbox_one || '',
          checkbox_two: formData.checkbox_two || '',
        
        },
        validationSchema: Yup.object({
          checkbox_one: Yup.string().required('This is required'),
          checkbox_two: Yup.string().required('This is required'),
    
    
    
      
        }),
        onSubmit: (values) => {
        
          setFormData({
            ...formData,
            ...values,
          });
  
          dispatch(DealerRegistrationAciton(formData)).then(function(data){
            if(data==200){
              setShowsucess(true)
            }
           
          })
        
        },
      });
  
  
  
    
      return (
        <>    
     <form onSubmit={formik_data.handleSubmit}>
     <section className='form-layout'>
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-10 col-md-8 col-lg-8 col-xl-8 col-12 mx-auto'>
                        <div className='card-white-form mtb-150'>
                            <div className='form-content'>
                                <div className='black-text-large mb-3'>Start your free membership today!</div>
                                <div className='black-text-paragraph-sm mb-3'>Just three quick and easy steps to get online.</div>
                                <div className='black-text-paragraph-sm mb-3'>To qualify for an account, you’ll need to be a registered motor trader and you’ll need to provide us with your DMS provider information. </div>
                                <div className='step-circle-part'>
                                    <ul>
                                        <li>
                                            <NavLink to="" className="blue-circle-active">1</NavLink>
                                            <span>Your Details</span>
                                        </li>
                                        <li>
                                            <NavLink to="" className="blue-circle-active">2</NavLink>
                                            <span>Company Details</span>
                                        </li>
                                        <li>
                                            <NavLink to="" className="blue-circle-active">3</NavLink>
                                            <span>Confirmation</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className='form-part-signup'>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3 mb-4'>
                                        <div className="form-group">
                                            <label for="">Terms & Conditions</label>
                                        </div>
                                        <div className='black-text-normal'>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus auctor, neque sit amet sagittis posuere, nisl nulla rhoncus enim, at mattis quam risus in orci. In ut tellus consequat, blandit nunc id, mattis magna. Donec sagittis sapien quis leo varius posuere. Nulla pulvinar ultricies condimentum. Maecenas vestibulum sed leo nec pellentesque. Aliquam laoreet diam aliquam nisl blandit laoreet. Suspendisse potenti. 
                                        </div>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-2'>
                                        <div className="form-check signup-check">
                                            <input type="checkbox" className="form-check-input" id="check1" name='checkbox_one'   value="1"   onChange={(e)=>{
                                           formik_data.setFieldValue('checkbox_one',e.target.value)
                                            }}
                                              onBlur={formik_data.handleBlur}
                                              
                                              />
                                               
                                            <label className="form-check-label" for="check1">Please tick this box to confirm that you work in the Motor Trade</label>
                                        </div>
                                        {formik_data.touched.checkbox_one && formik_data.errors.checkbox_one && (
                                              <div className='error'>{formik_data.errors.checkbox_one}</div>
                                            )}
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-2'>
                                        <div className="form-check signup-check">
                                            <input type="checkbox" className="form-check-input" id="check2" name='checkbox_two' value="1"    onChange={(e)=>{
                                           formik_data.setFieldValue('checkbox_two',e.target.value)
                                            }}
                                              onBlur={formik_data.handleBlur}
                                              
                                              />
                                               
                                            <label className="form-check-label" for="check2">Please tick this box to confirm that you agree to our <span className='green-text-color'>user agreement</span></label>
                                        </div>
                                        {formik_data.touched.checkbox_two && formik_data.errors.checkbox_two && (
                                              <div className='error'>{formik_data.errors.checkbox_two}</div>
                                            )}
                                    </div>
                                    
                                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12 mb-3'>
                                        <div className='next-btn-part'>
                                            <button  className="primary-btn-white" onClick={prevStep}>Back</button>
                                            <button  className="primary-btn-green" type="submit">Next</button>
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>  
        </form>
        </>
      
      );
    };
    switch (step) {
        case 1:
          return (
            <>

    <Step1Registration formData={formData} setFormData={setFormData} nextStep={nextStep} />
               
             
          <Modal
             show={showsucess}
             onHide={()=>{
              setShowsucess(false)
             }}
            aria-labelledby='contained-modal-title-vcenter'
            centered className='popup-white-bg'
          >
            <div className='modal-body'>
            <form className='w-100' action=''>
              <div className='card box-shadow-none mb-0'>
                <div className='card-body '>
                  <div className='title_signin'>
                    <h1>Thank you for submitting your application!</h1>
                    <p className='mt-3'>
                    We've sent an email to your supplied address containing the next steps.
                    </p>
                    <p className='mt-3'>
            If you haven't received an email within the next 30 minutes, please contact our support team on 0115 694 0404</p>
    
                  </div>
                </div>
              </div>
            </form>
            </div>
    
          
          </Modal>
            </>
            
          );
        case 2:
          return (
          
                <>
       
      <Step2Registration formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />
  
            
          <Modal
             show={showsucess}
             onHide={()=>{
              setShowsucess(false)
             }}
            aria-labelledby='contained-modal-title-vcenter'
            centered className='popup-white-bg'
          >
            <div className='modal-body'>
            <form className='w-100' action=''>
              <div className='card box-shadow-none mb-0'>
                <div className='card-body'>
                  <div className='title_signin'>
                    <h1>Thank you for submitting your application!</h1>
                    <p className='mt-3'>
                    We've sent an email to your supplied address containing the next steps.
                    </p>
                    <p className='mt-3'>
            If you haven't received an email within the next 30 minutes, please contact our support team on 0115 694 0404</p>
    
                  
                  </div>
                </div>
              </div>
            </form>
            </div>
    
          
          </Modal>
            </>
          );
        case 3:
          return (
            <>
<HeaderHome />
    
             <FinalStep formData={formData} prevStep={prevStep} />    
          <Modal
             show={showsucess}
             onHide={()=>{
              setShowsucess(false)
             }}
            aria-labelledby='contained-modal-title-vcenter'
            centered className='popup-white-bg'
          >
            <div className='modal-body'>
            <form className='w-100' action=''>
              <div className='card box-shadow-none mb-0'>
                <div className='card-body'>
                  <div className='title_signin'>
                    <h1>Thank you for submitting your application!</h1>
                    <p className='mt-3'>
                    We've sent an email to your supplied address containing the next steps.
                    </p>
                    <p className='mt-3'>
            If you haven't received an email within the next 30 minutes, please contact our support team on 0115 694 0404</p>   
                  
                  </div>
                </div>
              </div>
            </form>
            </div>
    
          
          </Modal>
          <FooterHome />
                  </>
          
          );
        default:
          return null;
      }
}
