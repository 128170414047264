import React from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import FooterHome from '../IncludeHome/FooterHome'
import { NavLink, useNavigate } from 'react-router-dom'
import Gallery from '../Gallery/Gallery'
import "react-image-gallery/styles/css/image-gallery.css";
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { GetMyvehiclesConditionreportmyvehicle, UpdateAuctionstatus } from '../../Redux/Action/SellerVehiclesActions'
import moment from 'moment/moment'
import Productheaderdetails from './Productheaderdetails'
import { Modal } from 'react-bootstrap'
export default function Previewdetails() {
    const [previewlistingobj,setPreviewlistingobj]=useState()
    const [index, setIndex] = useState(0);
    const urlParams = new URLSearchParams(window.location.search);
    const [thankspop, setThankspop]=useState(false)



    const dispatch=useDispatch();
    const navigate=useNavigate()

        useEffect(()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
         
            
           
            setPreviewlistingobj(data);

     

        })
        },[])
      

        const handleSelect = (selectedIndex, e) => {
          setIndex(selectedIndex);
        };
    


        
          const open_modal_submit_auction=()=>{
            setThankspop(true)
          }


const closeButton=()=>{
 

    dispatch(UpdateAuctionstatus({
        id:previewlistingobj?.add_condition_report_candition?._id
    }))
    navigate('/Vehiclesdetails?id='+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
    setThankspop(false)
}
console.log(previewlistingobj)

  return (
    <>
      <HeaderDashboard />
      <div className='bg-headerwhite-v2 detail-page-subheader'>
        <div className='container container1200'>
              <div className='row'>
                <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-6'>
                <div className="page-heading-v2-details">Auction Preview</div>
                </div>
              </div>
        </div>
      </div>
      <div className='detail-page-back'>
        <div className='container container1200'>
              <div className='detail-back-part'>
                <div className='row'>
                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-6'>
                    {/* Back */}               
                    <div className='white-bg-step '>  
                        <NavLink to={"/conditionreport15?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to Edit
                        </NavLink>                       
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>

      <div className='slider-img-details-Section'>
      <div className='container container1200'>
      <div className='bor-b-v2'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12 mobile-padding-0'>
            <div className='slider-img-left-part'>
              <Gallery  images_data={previewlistingobj?.add_condition_report_candition} />
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12'>
            <div className='slider-img-right-content'>
         <Productheaderdetails details={previewlistingobj?.add_condition_report_candition} />
            <div className='bor-b-v2'></div>            
              
            <div className='listing-rowSection'>
            <div className='listing-detail-row bor-b-v2'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Current Bid:</div>

              <div className='listing-colum35'>
                <div className='blue-text-price-lg'>{previewlistingobj?.add_condition_report_candition?.current_bid ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((previewlistingobj?.Bidlist.length==0)?0:parseInt((Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value)))))).replace(/\.00$/, '')}</div>
              </div>

              <div className='listing-colum40'>
                <div className='group-element'>
                <div className='listing-red-sm-text'>  {((Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))) ==0)?0 : 
                                                Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))))>=previewlistingobj?.add_condition_report_candition?.price_reserve?<span className='biding-end-text-green'>Reserve met <br />£  {(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined) &&  (new Intl.NumberFormat('en-GB')
                                              .format(parseInt(previewlistingobj?.add_condition_report_candition?.price_reserve))
                                              .replace(/\.00$/, ''))}</span>:(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined && <span className='listing-red-sm-text'>Reserve not met <br />£ {(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined) &&  (new Intl.NumberFormat('en-GB')
                                              .format(parseInt(previewlistingobj?.add_condition_report_candition?.price_reserve))
                                              .replace(/\.00$/, ''))}</span>)}</div>
                <div className='listing-sm-text-blue'>( {(previewlistingobj?.Bidlist?.length<=1)?" "+previewlistingobj?.Bidlist.length+" Bid ":" "+previewlistingobj?.Bidlist.length+" Bids "})</div>
                </div>               
              </div>  
                          
            </div>  

            {/* <div className='listing-detail-row bor-b-v2 mt-3'>
              <div className='listing-colum25 listing-sm-text-blue pl-0'></div>

              <div className='listing-colum35'>
                <div className='bid-amt-input'>
                  <input  type='text' placeholder='Bid amount'/>
                </div>
                <div className='blue-text-price-xs'>Enter £100 or more</div>
              </div>

              <div className='listing-colum40'>               
                <div className='mt-m-25 button-group-part'>
                  <button type='submit' className='fill-blue-btn-bid'>Submit Bid</button>
                </div>
              </div>              
            </div> */}
            </div>
            <div className='listing-rowSection bor-b-v2'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Buy it Now:</div>

              <div className='listing-colum35'>
                <div className='green-text-price-lg'>{new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(previewlistingobj?.add_condition_report_candition?.price_now)
                                              .replace(/\.00$/, '')}</div>
              </div>

              {/* <div className='listing-colum40'>    
              <div className='button-group-part'>
                  <button type='submit' className='fill-green-btn-bid'>Buy Now</button>
                </div>                        
              </div>  */}
                          
            </div>           
            </div>
            <div className='listing-rowSection'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Bidding Ends:</div>

              <div className='listing-colum35'>
              <div className='listing-sm-text-blue'>{moment(previewlistingobj?.add_condition_report_candition?.offer_expire).format('DD MMMM  YYYY, h:mm a')}</div>
              </div>
              <div className='listing-colum40'>
                <div className='group-element'>
                <div className='listing-sm-text-blue'>(-----)</div>
                </div>               
              </div>                           
            </div>           
            </div>
            <div className='listing-rowSection'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Location:</div>

              <div className='listing-colum35'>
              <div className='listing-sm-text-blue'>Southend On Sea</div>
              </div>                                      
            </div>           
            </div>

            </div>
          </div>
          
        </div>
      </div>        
      </div>
      </div>

      <div className='bottom-content-details'>
        <div className='container container1200'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
            <div className="listing-sec-heading-underline">Vehicle Details</div>  
            </div>
          </div>
          <div className='row'>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
          <div className='listing-sec-subheading'>Condition Report</div>
          <div className='row'>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                  <div className='blue-sm-text-p-bold'>Mechanical Issues :</div>
                  <div className='blue-sm-text-p-bold'>Bodywork Prep :</div>
                  <div className='blue-sm-text-p-bold'>Interior Prep :</div>
                  <div className='blue-sm-text-p-bold'>Service History :</div>
                  <div className='blue-sm-text-p-bold'>V5 Present :</div>
                  <div className='blue-sm-text-p-bold'>No. Keys :</div>
              </div>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.mechanical}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.bodywork==undefined?"":"Up to £"}    {previewlistingobj?.add_condition_report_candition?.bodywork}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.interior_condition}</div>
                  <div className='blue-sm-text-p-light'> {previewlistingobj?.add_condition_report_candition?.service_history}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.v5_present}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.keys}</div>
              </div>
          </div>
      </div>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
          <div className='listing-sec-subheading'>Optional Extras</div>
          <div className='row'>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
              <div className='blue-sm-text-p-bold'>Sunroof</div>
                  <div className='blue-sm-text-p-bold'>Panoramic Sunroof</div>
                  <div className='blue-sm-text-p-bold'>Wheel Upgrade</div>
                  <div className='blue-sm-text-p-bold'>Half - Leather Seats</div>
                  <div className='blue-sm-text-p-bold'>Full Leather Seats</div>
                  <div className='blue-sm-text-p-bold'>Heated Seats</div>
                  <div className='blue-sm-text-p-bold'>SatNav</div>
                  <div className='blue-sm-text-p-bold'>Bluetooth</div>
                  <div className='blue-sm-text-p-bold'>Other</div>
              </div> 

              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
              <div className='blue-sm-text-p-light'>{ (previewlistingobj?.add_condition_report_candition?.roof=="Sunroof"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.roof=="Panoramic Roof"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.wheel_upgrade=="Wheel Upgrade"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'>  {(previewlistingobj?.add_condition_report_candition?.leather=="Half Leather"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.leather=="Full Leather"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.heated_seats=="Heated Seats"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.satnav=="Satnav"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.bluetooth=="Bluetooth"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.other??"")}</div>
              </div>                       
          </div>
      </div>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
          <div className='listing-sec-subheading'>Trade Information</div>
          <div className='row'>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                  <div className='blue-sm-text-p-bold'>VAT Qualifying:</div>
                  <div className='blue-sm-text-p-bold'>Plus VAT</div>
                  <div className='blue-sm-text-p-bold'>Write Off:</div>
              </div> 
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
              <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.vat_qualifying=="1"?"Yes":"No")}</div>
            <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.plubs_vat=="1"?"Yes":"No")}</div>
            <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.write_off_cat=="1"?"Yes":"No")}</div>
              </div>                              
          </div>
      </div>
        </div>
        <div className='row d-flex align-items-center mt-3'>
            <div className='col-md-12'>
                <div className='listing-sec-heading-underline'>Seller Description</div>
            </div>
        </div>
        <div className='row d-flex align-items-center '>
            <div className='col-md-12'>
            <div className='blue-sm-text-p-light'>            
            <div className='description-wrap'>{previewlistingobj?.add_condition_report_candition?.description ??""}</div>
            </div>
            </div>
        </div>
         {previewlistingobj?.add_condition_report_candition?.mechanicaldescription  !=undefined &&

                     (   <div className='row d-flex align-items-center mt-3'>
                            <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col col-12'>
                            <div className='blue-sm-text-p-bold'>Mechanical Issues Recorded :</div>
                            </div>
                            <div className='col-md-9'>
                            <div className='blue-sm-text-p-light-i'>  {previewlistingobj?.add_condition_report_candition?.mechanicaldescription}</div>
                            </div>
                        </div>)
                    }

        <div className='row d-flex align-items-center mt-5'>
            <div className='col-md-12'>
                <div className='blue-light-bg-listing'>
                <div className='listing-sec-heading-underline'>Full Specification </div>
                <div className='row'>
                    <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
                    <div className='row'>
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                        <div className='blue-sm-text-p-bold'>Make:</div>
                        <div className='blue-sm-text-p-bold'>Model:</div>
                        {/* <div className='blue-sm-text-p-bold'>Type:</div> */}
                        <div className='blue-sm-text-p-bold'>Body Colour:</div>
                      
                        <div className='blue-sm-text-p-bold'>Mileage:</div>
                        <div className='blue-sm-text-p-bold'>Trim:</div>
                    </div> 
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.make !=undefined?previewlistingobj?.add_condition_report_candition?.make:previewlistingobj?.add_condition_report_candition?.vehicle_id.make}</div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.model !=undefined?previewlistingobj?.add_condition_report_candition?.model:previewlistingobj?.add_condition_report_candition?.vehicle_id.model}</div>
                        {/* <div className='blue-sm-text-p-light'>AMG Line</div> */}
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.colour !=undefined?previewlistingobj?.add_condition_report_candition?.colour:previewlistingobj?.add_condition_report_candition?.vehicle_id.colour}</div>
                      
                        <div className='blue-sm-text-p-light'>{new Intl.NumberFormat('en-GB')
                                                .format(parseInt(previewlistingobj?.add_condition_report_candition?.vehicle_id?.mileage))
                                                .replace(/\.00$/, '')}</div>
                                <div className='blue-sm-text-p-light'>
                        {/* Half Leather */}
                        </div>
                    </div>                              
                    </div>
                    </div>
                    <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
                    <div className='row'>
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                        <div className='blue-sm-text-p-bold'>Fuel:</div>
                        <div className='blue-sm-text-p-bold'>Transmission:</div>
                        <div className='blue-sm-text-p-bold'>Engine Size:</div>
                        <div className='blue-sm-text-p-bold'>Number of Doors:</div>
                        <div className='blue-sm-text-p-bold'>Reg Number:</div>
                        <div className='blue-sm-text-p-bold'>Chassis Number:</div>
                    </div> 
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.fuel !=undefined&&previewlistingobj?.add_condition_report_candition?.fuel}</div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.transmission !=undefined&&previewlistingobj?.add_condition_report_candition?.transmission}</div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.engine_size !=undefined &&previewlistingobj?.add_condition_report_candition?.engine_size}</div>
                        <div className='blue-sm-text-p-light'>
                        
                        5
                        </div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}</div>
                        <div className='blue-sm-text-p-light'>
                        {/* WBAAX52050JX45750 */}
                        
                        
                        </div>
                    </div>                              
                    </div>
                    </div>
                    <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
                    <div className='row'>
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-bold'>First Registered:</div>
                        <div className='blue-sm-text-p-bold'>Total Owners:</div>
                        <div className='blue-sm-text-p-bold'>V5 Present:</div>
                        <div className='blue-sm-text-p-bold'>MOT Expiration:</div>
                    </div> 
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-light'>
                        {/* 01/04/2019 */}
                        </div>
                        <div className='blue-sm-text-p-light'>
                        {/* 3 */}
                        
                        
                        </div>
                        <div className='blue-sm-text-p-light'>
                        {/* Yes */}
                        </div>
                        <div className='blue-sm-text-p-light'>
                        {/* 01/04/2024 */}
                        </div>
                    </div>                              
                    </div>
                    </div>
                </div>
                </div>
            
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-sm-10 col-lg-10 col-md-10 col-xl-11 col mx-auto'>
            <div className='note-sm-text-listing'>
        * Although every effort has been made to give a full an accurate description of the vehicle, please allow up to £200 for items that may have been missed .
        Yo trader do not sell vehicles and do not take any responsibility if a vehicle has been misdescribed . 
        </div>
            </div>
        </div>
          
        </div>
      </div>

      <div className='fotter-calltoaction'>
       <div className='container container1200'>
       <div className='fixed-bottom-fotter'>
          <div className="footer-fix-bid-text">
          <div className="fotter-cta-text-blue">{new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format(previewlistingobj?.add_condition_report_candition?.price_now)
                                              .replace(/\.00$/, '')}</div>
          <div className="blue-textlg-footter">Buy it Now:</div>
          </div>

          <div className="footer-btn-part-cta">
          <button type="" className="btn fixed-btn-cta"  onClick={open_modal_submit_auction}>Submit to Auction</button>
          </div>
        </div>
       </div>
      </div>
      <Modal
        size='lg'
        show={thankspop}
        // onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'
        centered className='blue-pop-layout-notwork white-popup-layout'
      >
      <Modal.Header closeButton onClick={closeButton} className='close-icon-blue-pop'>
        </Modal.Header>
        <div className='modal-body text-center'>
         <div className='padding-popup'>
         <div className='sm-text-pop'>
            <div className='bold-green-text-color inline-block font-dm font-weight-700 font28'>Congratulations! </div> <div className='font-dm font-weight-700  font28 blue-text-color inline-block'> Your vehicle is now listed for auction! </div>
          </div>
          <div className='lg-text-pop'>
            <div className='blue-text-color'>GOOD LUCK!</div>
          </div>
          <div className='sm-text-pop'>
            <div className='blue-text-color'>You can manage your listing in the ‘My Auctions’ Tab.</div>
          </div>
          <div className='sm-text-pop'>
            <div className='bold-green-text-color'>Thank you for using YoTrader!</div>
          </div>
         </div>

          <div className='blue-card-logo pop-logo-blue'>
        {/* <img src="/static/media/logo.ff7ef83a3ac2ac52035afcb362294e5f.svg" alt="yotrader-logo" className="logo_main"></img> */}
        <img src={require("../asset_user/images/dark-popup-logo.svg").default} alt="yotrader-logo" className="logo_main" />    
        </div>
        </div>
   
      </Modal>
     <FooterHome />
    </>
  )
}
