import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';

import { toast } from 'react-toastify';

export default function Conditionreport6() {
    const urlParams = new URLSearchParams(window.location.search);
    const [loader,setLoader]=useState(1)
    const [wheel_efurbishment,setwheel_efurbishment]=useState("")
    const [condition_report_value,setcondition_report_value]=useState()
    const dispatch=useDispatch();
    const navigate=useNavigate()

        useEffect(()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){

        setwheel_efurbishment(data?.add_condition_report_candition?.wheel_efurbishment==undefined?"": data?.add_condition_report_candition?.wheel_efurbishment)
        setcondition_report_value(data);
        })
        },[])
 
 
 
 
    const save_data=async()=>{
             if(wheel_efurbishment !="")
             {
                setLoader(0)
       dispatch(MyvehiclesConditionreport({
             vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
             wheel_efurbishment:wheel_efurbishment,
            
         })).then(function(data){
             navigate("/conditionreport7?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
         })
 
 
     }else{
        toast.error("Please complete the following information to proceed.")
     }
     }



  return (

    <div className='white-bg-cover'>
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
                    <div className='full-height-view'>
                         {/* Back step*/}               
                         <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel 
                            </NavLink>                       
                        </div>

                        
                        <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Wheel Condition</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'36%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'> 
                        <div className='step-progress-heading'>Is there Damage to the Wheels?</div>
                        <div className='row mb-4'>
                            <div className='col-md-12 text-center'>
                            <div className='blue-text-steps mb-3'>Are any of the wheels damaged? This includes curb scrapes, buckled wheels or wheels that need to be replaced.</div>
                           </div>                            
                        </div>
                            <div className='row d-flex align-items-center mb-3'>
                                <div className='col-md-12'>
                                <div className="custom-radio-steps">		
                                    <ul>
                                    <li>
                                    <input type="radio" id="option1" name="selector"    value='0' onChange={(e)=>{
                                        setwheel_efurbishment(e.target.value)
                                    }}
                                    checked={wheel_efurbishment=="0"?true:false}  />
                                    <label for="option1">No Damage</label>
                                    
                                    <div className="check"></div>
                                    </li>
                                    
                                    <li>
                                    <input type="radio" id="option2" name="selector"   value='1' onChange={(e)=>{
                                        setwheel_efurbishment(e.target.value)
                                    }}
                                    checked={wheel_efurbishment=="1"?true:false} />
                                    <label for="option2">1 Wheel Refurbishment Needed</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    </li>  
                                    <li>
                                    <input type="radio" id="option3" name="selector"  value='2' onChange={(e)=>{
                                        setwheel_efurbishment(e.target.value)
                                    }}
                                    checked={wheel_efurbishment=="2"?true:false} />
                                    <label for="option3">2 Wheel Refurbishment Needed</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    </li> 
                                    <li>
                                    <input type="radio" id="option4" name="selector"  value='3' onChange={(e)=>{
                                        setwheel_efurbishment(e.target.value)
                                    }}
                                    checked={wheel_efurbishment=="3"?true:false} />
                                    <label for="option4">3 Wheel Refurbishment Needed</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    </li>  
                                    <li>
                                    <input type="radio" id="option5" name="selector" value='4' onChange={(e)=>{
                                        setwheel_efurbishment(e.target.value)
                                    }}
                                    checked={wheel_efurbishment=="4"?true:false} />
                                    <label for="option5">4 Wheel Refurbishment Needed</label>
                                    
                                    <div className="check"><div className="inside"></div></div>
                                    </li>  
                                   
                                      
                                </ul>
                                </div>
                                </div>                        
                            </div>                             
                        </div>

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step" to={"/conditionreport5?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                        <li className="nav-item">
                        {
                            loader==1?( 
                    
                             <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>
                            ):( 
                            
                           <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            )
                        }
                           
                        </li>
                        </ul>
                        </div>

                    </div>                    
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>                      
                        </div>                        
                    </div>
                </div>

            </div> 
        <div className="blue-bg-right"></div>
    </div>

  )
}
