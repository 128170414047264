import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';

import { toast } from 'react-toastify';

export default function Conditionreport14() {
    const urlParams = new URLSearchParams(window.location.search);
    const [count, setCount] = useState(0);

    const [description, setDescription] =useState("");
    const [condition_report_value,setcondition_report_value]=useState()
    const [loader,setLoader]=useState(1)
    const dispatch=useDispatch();
    const navigate=useNavigate()

        useEffect(()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
         
            setCount(data?.add_condition_report_candition?.description==undefined?0:(data?.add_condition_report_candition?.description.length))
            setDescription(data?.add_condition_report_candition?.description);
        setcondition_report_value(data);

     

        })
        },[])
 
    const save_data=async()=>{
        if(count>=100 &&count<2000){
            setLoader(0)
   
        
       dispatch(MyvehiclesConditionreport({
             vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
             description:description
         })).then(function(data){
             navigate("/conditionreport15?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
         })
 
        }else if(count<=100){
            toast.error("A minimum of 100 characters is required")
        }
     
     }


  return (
    // <UserMaster>
    <div className='white-bg-cover'>
            <div className='container container1200'>

                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
                    <div className='full-height-view'>
                        {/* Back step*/}               
                       <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel
                            </NavLink>                       
                        </div>

                          <div className='progress-part-view'>
                          <div className='sm-blue-text-progress'>Vehicle Description</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'84%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'>  
                        <div className='step-progress-heading'>Write a Description</div>
                        <div className='row mb-4'>
                            <div className='col-md-12 text-center'>
                            <div className='blue-text-steps mb-3'>Writing a detailed description could help to maximise your bid prices. 
                                Please include accurate details of the vehicle condition, including any damage to help buyers accurately evaluate your vehicle.</div>
                           </div>                            
                        </div>
                            <div className='row d-flex align-items-center mb-3'>
                                <div className='col-md-12'>
                                <div className='msg-type-step'>
                                    <textarea placeholder='Type here...'
                                    
                                    maxlength ="2000"
                                      onChange={(e )=>{ setCount(e.target.value.length);

                                                                                    setDescription(e.target.value)}
                                                                                    

                                    } 
                                    
                                    value={description}
                                    
                                    ></textarea>
                                    <div className='note-textarea-text'>{count}/2000</div>
                                </div>
                                </div>                        
                            </div>                             
                        </div>

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step"  to={"/conditionreport13?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                        <li className="nav-item">
                        {
                            loader==1?( 
                    
                             <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>
                            ):( 
                            
                           <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            )
                        }
                           
                        </li>
                        </ul>
                        </div>

                    </div>
                    
                     
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>                      
                        </div>                        
                    </div>
                </div>
            </div>
        <div className="blue-bg-right"></div>
    </div>
    // </UserMaster>
  )
}
