import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'


import { toast } from 'react-toastify'

import { useState } from 'react'
import { useEffect } from 'react'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions'

export default function UpdateReserveBuynow() {


    const [optionalfilvalue,Setoptionalfil]=useState("readOnly")
    const [optionalprice,setoptionalprice]=useState(0)
    const [price,setprice]=useState(0)
    const [loader,setLoader]=useState(1)
    const urlParams = new URLSearchParams(window.location.search);
    const [condition_report_value,setcondition_report_value]=useState()
    const dispatch=useDispatch();
    const navigate=useNavigate()
    useEffect(()=>{
    dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
        setprice( data?.add_condition_report_candition?.price_now)
        setoptionalprice( data?.add_condition_report_candition?.price_reserve)
        setcondition_report_value(data);
    })
    },[])
 
 
   

    const save_data=async()=>{
        
       
        
        if(price !=0 &&price !=undefined){
            console.log(price)
            setLoader(1)
    
          
    
            if(( optionalprice >0 && (parseInt(price))<=(optionalprice-500)) ){
                callapi()
            }else if(optionalprice==0||optionalprice==undefined){
                callapi()
            
            }else{
                toast.error("Buy it Now Price cannot be higher than Retail Price") 
            }
            
          
      
    
        }else{
            setLoader(1)
            toast.error("The Buy it Now Price is required") 
        }
       }
 

       function callapi(){
   
        if(optionalprice <= price){
    
            if(optionalprice>0){
    
         
              
            setLoader(0)
             dispatch(MyvehiclesConditionreport({
            vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
        
            price_now:price,
            type:(urlParams.get('manual')==null?0:1),
    
            price_reserve:optionalprice })).then(function(data){
       
    
            if(data.data !="There has been an error when trying to lookup the vehicle registration provided. The Registration is not known to the DVLA"){
              
                
                    navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
                
    
    
    
    
              }else{
                
                navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
            
    
              }
            
            
            
            })
        }else{
        setLoader(0)
        dispatch(MyvehiclesConditionreport({
       vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
    
       price_now:price,
       type:(urlParams.get('manual')==null?0:1),
      
            })).then(function(data){
    
    
                if(data.data !="There has been an error when trying to lookup the vehicle registration provided. The Registration is not known to the DVLA"){
              
                
                    navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
                
    
    
    
    
              }else{
                
                navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
            
    
              }
    
    
    
      
    })
    }
        }else{
            setLoader(1)
            toast.warning("Reserve Price cannot be higher than the Buy it Now Price")
            
             }
       }
  return (
    <div className='white-bg-cover'>
        <div className='container container1200'>
            <div className='row'>
                <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
                <div className='full-height-view'>
                      {/* Back step*/}               
                      <div className='white-bg-step '>  
                        <NavLink to={`/Vehiclesdetails?id=`+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel
                        </NavLink>                       
                        </div>  
                        <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Fun label for steps remaining</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'12%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex'>
                    <div className='middle-content-steps'>                   
                    <div className='step-progress-heading'>Price Settings</div>
                    <div className='row d-flex align-items-center mb-4'>
                        <div className='col-md-9'>
                        <div className='sub-heading-step'>Set a Buy It Now Price *</div>
                        <div className='step-grey-text'>
                        Setting a Buy it Now price will allow buyers to end the auction early and purchase your vehicle immediately. A Buy It Now price can not be higher than your vehicles retail price. 
                        </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='input-field-part'>
                                <CurrencyInput
                        
                        className='form-control'
                      intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                        onChange={(e)=>{ 
                            setprice(  Number(e.target.value.replace(/[^0-9\.-]+/g,"")))
                    }}

                      placeholder={   condition_report_value?.add_condition_report_candition?.price_now ==(0||undefined)?"£ Type here" :new Intl.NumberFormat('en-GB',
                             {   style: 'currency',  currency: 'GBP',}).format(  condition_report_value?.add_condition_report_candition?.price_now ==0?0 : condition_report_value?.add_condition_report_candition?.price_now).replace(/\.00$/, '')}
            />
                            </div>
                        </div>
                    </div>
                    <div className='row d-flex align-items-center mb-4'>
                        <div className='col-md-9'>
                        <div className='sub-heading-step'>Set a reserve price (Optional)</div>
                        <div className='step-grey-text'>
                        Setting a reserve will ensure that your vehicle will only sell if the highest bid exceeds the reserve price. A reserve price can not be higher than your vehicles retail price. 
                        </div>
                        </div>
                        <div className='col-md-3'>
                        <div className='toogle-switch-step'>
                        <div className="check-box">
                            <input type="checkbox"  onChange={()=>{
                                Setoptionalfil(optionalfilvalue=="readOnly"?"":"readOnly")
                            }}  />
                        </div>
                        </div>
                            <div className='input-field-part'>
                                <CurrencyInput
                        className='form-control'
                      intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                        onChange={(e)=>{ 
                        setoptionalprice(  Number(e.target.value.replace(/[^0-9\.-]+/g,"")))
                    }}
                    placeholder={   condition_report_value?.add_condition_report_candition?.price_reserve ==(0||undefined)?"£ Type here" :new Intl.NumberFormat('en-GB',
                             {   style: 'currency',  currency: 'GBP',}).format(  condition_report_value?.add_condition_report_candition?.price_reserve ==0?0 : condition_report_value?.add_condition_report_candition?.price_reserve).replace(/\.00$/, '')}
          
                        readOnly={optionalfilvalue=="readOnly"?true:false}
                               />
                            </div>
                        </div>
                    </div>
                    </div>
                    <ul className="fotter-steps">
                    <li className="nav-item">
                        <NavLink className="nav-link white-btn-step invisible" to="/conditionreport3">Next</NavLink>
                    </li>
                    <li className="nav-item">
                    {
                        loader==1?( 
                        urlParams.get('status')==null? <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>:<button className="nav-link green-btn-step"  onClick={save_data}  >   Update  </button>
                       ):( 
                        urlParams.get('status')==null? <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>:<button className="nav-link green-btn-step"  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Update  </button>
                        )
                    }
                       
                    </li>
                    </ul>
                    </div>
                </div>
                   
                </div>
                <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                    <div className='bg-blue-steps'>
                    <div className='logo-right-top'>
                        <a href='#'>
                            <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                        </a>
                    </div>
                    </div>                        
                </div>
            </div>
            <div className="blue-bg-right"></div>

        </div>  
</div>
  )
}
