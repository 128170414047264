import React from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import FooterHome from '../IncludeHome/FooterHome'
import { Modal } from 'react-bootstrap'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { BuyNowAction, GetMyvehiclesConditionreportmyvehicle, UpdateAuctionstatus } from '../../Redux/Action/SellerVehiclesActions'
import Gallery from '../Gallery/Gallery'
import Productheaderdetails from '../Previewdetails/Productheaderdetails'
import moment from 'moment'
import io from "socket.io-client";
import { toast } from 'react-toastify'
import { BidsBybuyer } from '../../Redux/Action/BuyerVehilesAution'
import CurrencyInput from 'react-currency-input-field'
import Countdown from '../CalculateCountdown/Countdown'

export default function Buyervehiclesdetails() {
  const [show, setShow] = useState(false); 
  const [previewlistingobj,setPreviewlistingobj]=useState()
  const [imageset,setSetimageset]=useState([])
  const [index, setIndex] = useState(0);
  const [watchbutton, setWatchbutton] = useState(0);
  const [currentbid, setCurrentbid] = useState(0);
  const [currentbidmore, setCurrentbidmore] = useState(0);
  const [totalbid, setTotalbid] = useState(0);
  const [bidvalue, setBidvalue] = useState("");
  const [price, setprice] = useState(0);
  const [counteroffer, setCounteroffer] = useState(0);
  const [showbuynow, setBuynow] = useState(false);
  
  const urlParams = new URLSearchParams(window.location.search);

  const dispatch=useDispatch();
  const navigate=useNavigate()
 

  
    useEffect(()=>{
      dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),1)).then(function(data){
         
          setPreviewlistingobj(data);
          setWatchbutton(data?.add_condition_report_candition?.watching ==null?0:1)
      })
      
      },[])
    

      
        const sumitbid=()=>{

          if(bidvalue !=0){
  
          if(Math.max(...previewlistingobj?.Bidlist.map(o => o.yotrader_value))+19 >=bidvalue){
              toast.warning("Bid value will £50 above of current bid")
              }else{

                  const socket =  io(process.env.REACT_APP_SOCKET_URL, {
                      autoConnect: true,
                    });
                  socket.emit("bidvalue",bidvalue)
                  dispatch(BidsBybuyer({vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm,valuation:bidvalue,expires:previewlistingobj?.add_condition_report_candition?.expires}));
             
             
                  setBidvalue("")
             
             
              }

              setShow(false)
               }

        }


        //socket  

      var k=0;
      const socket =  io(process.env.REACT_APP_SOCKET_URL, {
          autoConnect: true,
        });
   
     useEffect(()=>{

    

   
     

      socket.on('connect', () => {
          console.log('Socket connected');
        });
    
        socket.on('disconnect', () => {
          // socket.connect()
          console.log('Socket disconnected');
        });
    
        socket.on('connect_error', (error) => {
          console.log('Socket connection error:', error);
        });

        


        const handleSocketData = (value) => {
          // Handle the socket data here
          dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),1)).then((data) => {
            setPreviewlistingobj(data);
          });
    
          setCurrentbid(new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(value.yotrader_value).replace(/\.00$/, ''))
          setCurrentbidmore(new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format(value.yotrader_value+50).replace(/\.00$/, ''))
       
          setTotalbid(value.total_bid)
        };

          





        const handleBidEnd = (values) => {
   
          if (values.buyer_id.toString() !== localStorage.getItem('dealer_id')) {
            navigate(`/BuyerDontwindbid?id=${urlParams.get('id')}`);
          } else {
            navigate(`/BuyerCongratesVehilesdetails?id=${urlParams.get('id')}`);
          }
        };


        const handleBidEndAuction = (values) => {

          navigate(`/BuyerDontwindnobid?id=${urlParams.get('id')}`);
        };
    const handlecounteroffer=(values)=>{
      setCounteroffer(values)
    }



        socket.on(
          previewlistingobj?.add_condition_report_candition?.vehicle_id ==
            undefined
            ? previewlistingobj?.add_condition_report_candition?.vrm
            : previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm,
          handleSocketData
        );
    
        socket.on(`bidend${urlParams.get('id')}`, handleBidEnd);
    
        socket.on(`bidendauction${urlParams.get('id')}`, handleBidEndAuction);


        socket.on(`${localStorage.getItem('dealer_id')+urlParams.get('id')}`, handlecounteroffer);
      },[socket])
        //end socket
//modal




const handleClose = () => {
  
  setShow(false)
  setBidvalue("")
};
const handleShow = () => setShow(true);



const handlebuybiwClose = () => setBuynow(false);
            const submitBuynow=()=>{




            


            dispatch(BuyNowAction(({vrm:urlParams.get('id'),counterofferprice:counteroffer !=0? counteroffer:previewlistingobj?.add_condition_report_candition?.counteroffer !=null ?   previewlistingobj?.add_condition_report_candition?.counteroffer?.counter_offer:0})))

            navigate(`/BuyerCongratesVehilesdetails?id=${urlParams.get('id')}`)

            setBuynow(false)



            }

  return (
    <>
    <>
      <HeaderDashboard />
      {/* Header subheader white background */}
      <div className='bg-headerwhite-v2 detail-page-subheader'>
        <div className='container container1200'>
              <div className='row d-flex align-items-center'>
                <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 col-8'>
                <div className="page-heading-v2-details">Vehicles Details</div>
                </div>
                <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-4'>
                <div >
                <Countdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} />
                </div> 
                {/* <div className="bid-card-white">
                  <div className='blue-text-bid'>Bidding ends in:</div>
                  <div className='green-text-bid'> <CalculateCountdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} /></div>
                </div> */}
                </div>
              </div>
        </div>
      </div>
      <div className='detail-page-back'>
        <div className='container container1200'>
              <div className='detail-back-part'>
                <div className='row'>
                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
                    {/* Back */}               
                    <div className='white-bg-step '>  
                        <NavLink to={"/liveauction"} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to All Vehicles
                        </NavLink>                       
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>

      <div className='slider-img-details-Section'>
      <div className='container container1200'>
      <div className='bor-b-v2'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12 mobile-padding-0'>
            <div className='slider-img-left-part'>
              <Gallery  images_data={previewlistingobj?.add_condition_report_candition} />
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12'>
            <div className='slider-img-right-content'>
         <Productheaderdetails details={previewlistingobj?.add_condition_report_candition} />
            <div className='bor-b-v2'></div>            
              
            <div className='listing-rowSection'>
            <div className='listing-detail-row bor-b-v2'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Current Bid:</div>

              <div className='listing-colum35'>
                <div className='blue-text-price-lg'>{ currentbid==0?previewlistingobj?.Bidlist?.length ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((previewlistingobj?.Bidlist.length==0)?0: Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value)))).replace(/\.00$/, ''):currentbid}</div>
              </div>

              <div className='listing-colum40'>
                <div className='group-element'>
                <div className='listing-red-sm-text'>  {((previewlistingobj?.add_condition_report_candition?.bidvalueself ==0)?0 : 
                                       previewlistingobj?.add_condition_report_candition?.bidvalueself)>=previewlistingobj?.add_condition_report_candition?.price_reserve?<span className='biding-end-text-green'>Reserve met </span>:(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined && <span className='listing-red-sm-text'>Reserve not met </span>)}</div>
                <div className='listing-sm-text-blue'>({(totalbid==0?previewlistingobj?.Bidlist?.length:totalbid)+ ((totalbid==0?(previewlistingobj?.Bidlist?.length<=1):(totalbid<=1))?" Bid":" Bids")} )</div>
                </div>               
              </div>  
                          
            </div>  

            <div className='listing-detail-row bor-b-v2 mt-3'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 colum-hide-mobile'></div>

              <div className='listing-colum35 mobile50-colum'>
                <div className='bid-amt-input'>

                <div className='euro-symbol-sign'>£</div>  
                <CurrencyInput
              
                //  intlConfig={{ locale: 'en-US', currency: 'GBP' }}
               onChange={(e)=>{  setBidvalue(  Number(e.target.value.replace(/[^0-9\.-]+/g,""))) }}
                value={bidvalue}
                  placeholder={ bidvalue =="" &&"Bid amount" }
                      />
                </div>
                <div className='blue-text-price-xs or-text-sm'>Enter { currentbidmore==0?previewlistingobj?.Bidlist?.length ==0?" £50 ":" "+new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((previewlistingobj?.Bidlist.length==0)?0:Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))+50)).replace(/\.00$/, '') +" ":currentbidmore+" "} 
                 or more</div>
              </div>

              <div className='listing-colum40'>               
                <div className='mt-m-25 button-group-part'>
                  <button type='submit' className='fill-blue-btn-bid font-14-mobile'  onClick={handleShow}>Submit Bid</button>
                </div>
              </div>              
            </div>
            </div>
            <div className='listing-rowSection bor-b-v2'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Buy it Now:</div>

              <div className='listing-colum35'>
                <div className='green-text-price-lg'>{new Intl.NumberFormat('en-GB', {
                    style: 'currency',
                    currency: 'GBP',
                  })
                    .format(   (previewlistingobj?.add_condition_report_candition?.counteroffer==null    && counteroffer==0)?  parseInt (previewlistingobj?.add_condition_report_candition?.price_now):parseInt(counteroffer|| previewlistingobj?.add_condition_report_candition?.counteroffer?.counter_offer))
                    .replace(/\.00$/, '')}</div>
              </div>

              <div className='listing-colum40'>    
              <div className='button-group-part'>
                  <button type='button' className='fill-green-btn-bid' onClick={()=>{
                                        setBuynow(true)
                                       
                                    }}> {
                                    previewlistingobj?.add_condition_report_candition?.counteroffer==null    && counteroffer==0?"Buy Now" :"Accept"  }</button>
                </div>                        
              </div> 
                          
            </div>           
            </div>
            <div className='listing-rowSection'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Bidding Ends:</div>

              <div className='listing-colum35'>
              <div className='listing-sm-text-blue'>{moment(previewlistingobj?.add_condition_report_candition?.offer_expire).format('DD MMMM  YYYY, h:mm a')}</div>
              </div>
              <div className='listing-colum40'>
                <div className='group-element'>
                <div className='listing-sm-text-blue'>(-----)</div>
                </div>               
              </div>                           
            </div>           
            </div>
            <div className='listing-rowSection'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700'>Location:</div>

              <div className='listing-colum35'>
              <div className='listing-sm-text-blue'>Southend On Sea</div>
              </div>                                      
            </div>           
            </div>

            </div>
          </div>
          
        </div>
      </div>        
      </div>
      </div>

      <div className='bottom-content-details'>
        <div className='container container1200'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
            <div className="listing-sec-heading-underline">Vehicle Details</div>  
            </div>
          </div>
          <div className='row'>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
          <div className='listing-sec-subheading'>Condition Report</div>
          <div className='row'>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                  <div className='blue-sm-text-p-bold'>Mechanical Issues :</div>
                  <div className='blue-sm-text-p-bold'>Bodywork Prep :</div>
                  <div className='blue-sm-text-p-bold'>Interior Prep :</div>
                  <div className='blue-sm-text-p-bold'>Service History :</div>
                  <div className='blue-sm-text-p-bold'>V5 Present :</div>
                  <div className='blue-sm-text-p-bold'>No. Keys :</div>
              </div>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.mechanical}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.bodywork==undefined?"":"Up to £"}    {previewlistingobj?.add_condition_report_candition?.bodywork}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.interior_condition}</div>
                  <div className='blue-sm-text-p-light'> {previewlistingobj?.add_condition_report_candition?.service_history}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.v5_present}</div>
                  <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.keys}</div>
              </div>
          </div>
      </div>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
          <div className='listing-sec-subheading'>Optional Extras</div>
          <div className='row'>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
              <div className='blue-sm-text-p-bold'>Sunroof</div>
                  <div className='blue-sm-text-p-bold'>Panoramic Sunroof</div>
                  <div className='blue-sm-text-p-bold'>Wheel Upgrade</div>
                  <div className='blue-sm-text-p-bold'>Half - Leather Seats</div>
                  <div className='blue-sm-text-p-bold'>Full Leather Seats</div>
                  <div className='blue-sm-text-p-bold'>Heated Seats</div>
                  <div className='blue-sm-text-p-bold'>SatNav</div>
                  <div className='blue-sm-text-p-bold'>Bluetooth</div>
                  <div className='blue-sm-text-p-bold'>Other</div>
              </div> 

              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
              <div className='blue-sm-text-p-light'>{ (previewlistingobj?.add_condition_report_candition?.roof=="Sunroof"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.roof=="Panoramic Roof"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.wheel_upgrade=="Wheel Upgrade"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'>  {(previewlistingobj?.add_condition_report_candition?.leather=="Half Leather"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.leather=="Full Leather"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.heated_seats=="Heated Seats"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.satnav=="Satnav"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.bluetooth=="Bluetooth"?"Yes":"No")}</div>
                    <div className='blue-sm-text-p-light'> {(previewlistingobj?.add_condition_report_candition?.other??"")}</div>
              </div>                       
          </div>
      </div>
      <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12 mb-3'>
          <div className='listing-sec-subheading'>Trade Information</div>
          <div className='row'>
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                  <div className='blue-sm-text-p-bold'>VAT Qualifying:</div>
                  <div className='blue-sm-text-p-bold'>Plus VAT</div>
                  <div className='blue-sm-text-p-bold'>Write Off:</div>
              </div> 
              <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
              <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.vat_qualifying=="1"?"Yes":"No")}</div>
            <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.plubs_vat=="1"?"Yes":"No")}</div>
            <div className='blue-sm-text-p-light'>{(previewlistingobj?.add_condition_report_candition?.write_off_cat=="1"?"Yes":"No")}</div>
              </div>                              
          </div>
      </div>
        </div>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mb-4'>
          <div className="listing-sec-heading-underline">Buying Fees</div> 
          <div className='sub-title-block'>£100</div> 
          </div>
        <div className='row d-flex align-items-center mt-3'>
            <div className='col-md-12'>
                <div className='listing-sec-heading-underline'>Seller Description</div>
            </div>
        </div>
        <div className='row d-flex align-items-center '>
            <div className='col-md-12'>
            <div className='blue-sm-text-p-light'>            
            <div className='description-wrap'>{previewlistingobj?.add_condition_report_candition?.description ??""}</div>
            </div>
            </div>
        </div>
   



         {previewlistingobj?.add_condition_report_candition?.mechanicaldescription  !=undefined &&

                     (   <div className='row d-flex align-items-center mt-3'>
                            <div className='col-sm-3 col-lg-3 col-md-3 col-xl-3 col col-12'>
                            <div className='blue-sm-text-p-bold'>Mechanical Issues Recorded :</div>
                            </div>
                            <div className='col-md-9'>
                            <div className='blue-sm-text-p-light-i'>  {previewlistingobj?.add_condition_report_candition?.mechanicaldescription}</div>
                            </div>
                        </div>)
                    }
                    
                      


        <div className='row d-flex align-items-center mt-5'>
            <div className='col-md-12'>
                <div className='blue-light-bg-listing'>
                <div className='listing-sec-heading-underline'>Full Specification </div>
                <div className='row'>
                    <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
                    <div className='row'>
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                        <div className='blue-sm-text-p-bold'>Make:</div>
                        <div className='blue-sm-text-p-bold'>Model:</div>
                        {/* <div className='blue-sm-text-p-bold'>Type:</div> */}
                        <div className='blue-sm-text-p-bold'>Body Colour:</div>
                      
                        <div className='blue-sm-text-p-bold'>Mileage:</div>
                        <div className='blue-sm-text-p-bold'>Trim:</div>
                    </div> 
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.make !=undefined?previewlistingobj?.add_condition_report_candition?.make:previewlistingobj?.add_condition_report_candition?.vehicle_id.make}</div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.model !=undefined?previewlistingobj?.add_condition_report_candition?.model:previewlistingobj?.add_condition_report_candition?.vehicle_id.model}</div>
                        {/* <div className='blue-sm-text-p-light'>AMG Line</div> */}
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.colour !=undefined?previewlistingobj?.add_condition_report_candition?.colour:previewlistingobj?.add_condition_report_candition?.vehicle_id.colour}</div>
                      
                        <div className='blue-sm-text-p-light'>{new Intl.NumberFormat('en-GB')
                                                .format(parseInt(previewlistingobj?.add_condition_report_candition?.vehicle_id?.mileage))
                                                .replace(/\.00$/, '')}</div>
                                <div className='blue-sm-text-p-light'>
                        {/* Half Leather */}
                        
                        
                        </div>
                    </div>                              
                    </div>
                    </div>
                    <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
                    <div className='row'>
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6 pr-0'>
                        <div className='blue-sm-text-p-bold'>Fuel:</div>
                        <div className='blue-sm-text-p-bold'>Transmission:</div>
                        <div className='blue-sm-text-p-bold'>Engine Size:</div>
                        <div className='blue-sm-text-p-bold'>Number of Doors:</div>
                        <div className='blue-sm-text-p-bold'>Reg Number:</div>
                        <div className='blue-sm-text-p-bold'>Chassis Number:</div>
                    </div> 
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.fuel !=undefined&&previewlistingobj?.add_condition_report_candition?.fuel}</div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.transmission !=undefined&&previewlistingobj?.add_condition_report_candition?.transmission}</div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.engine_size !=undefined &&previewlistingobj?.add_condition_report_candition?.engine_size}</div>
                        <div className='blue-sm-text-p-light'>
                        
                        {/* 5 */}
                        
                        
                        </div>
                        <div className='blue-sm-text-p-light'>{previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}</div>
                        <div className='blue-sm-text-p-light'>
                        {/* WBAAX52050JX45750 */}
                        
                        
                        </div>
                    </div>                              
                    </div>
                    </div>
                    <div className='col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-12'>
                    <div className='row'>
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-bold'>First Registered:</div>
                        <div className='blue-sm-text-p-bold'>Total Owners:</div>
                        <div className='blue-sm-text-p-bold'>V5 Present:</div>
                        <div className='blue-sm-text-p-bold'>MOT Expiration:</div>
                    </div> 
                    <div className='col-sm-6 col-lg-6 col-md-6 col-xl-6 col col-6'>
                        <div className='blue-sm-text-p-light'>
                        {/* 01/04/2019 */}
                        </div>
                        <div className='blue-sm-text-p-light'>
                        {/* 3 */}
                        
                        
                        </div>
                        <div className='blue-sm-text-p-light'>
                        {/* Yes */}
                        </div>
                        <div className='blue-sm-text-p-light'>
                        {/* 01/04/2024 */}
                        </div>
                    </div>                              
                    </div>
                    </div>
                </div>
                </div>
            
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-sm-10 col-lg-10 col-md-10 col-xl-11 col mx-auto'>
            <div className='note-sm-text-listing'>
        * Although every effort has been made to give a full an accurate description of the vehicle, please allow up to £200 for items that may have been missed .
        Yo trader do not sell vehicles and do not take any responsibility if a vehicle has been misdescribed . 
        </div>
            </div>
        </div>
          
        </div>
      </div>
      <div className='fotter-calltoaction mobile-view-footer'>
       <div className='container container1200'>
       <div className='fixed-bottom-fotter '>
          <div className="footer-fix-bid-text">
          <div>
            <Countdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} />
            </div> 
        </div>
       </div>
      </div>  
      </div>                
    
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Submit Bid</Modal.Title>
        </Modal.Header>
        <Modal.Body>By submitting a bid you agreeing to purchase the vehicle if your bid is the winning bid at the end of the auction and the value exceeds the reserve price.</Modal.Body>
        <Modal.Footer>
       
         
        <div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-3 col-12'>
            <div className='bid-blue-sm-btn'>
            <button type="button" onClick={handleClose}>Cancel</button>
            </div>
        </div>



        <div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-3 col-12'>
            <div className='bid-green-sm-btn'>
            <button type="button" onClick={sumitbid}>Submit Bid</button>
            </div>
        </div>
        </Modal.Footer>
      </Modal>


      <Modal show={showbuynow} onHide={handlebuybiwClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Buy it Now</Modal.Title>
        </Modal.Header>
        <Modal.Body>By confirming buy it now, you fully accept the YoTrader Terms of purchase.
                    The vehicle will be removed from sale and the seller notified of your purchase.
                    Details of the vehicle sale can be found in the 'Your Purchases' Tab.</Modal.Body>
        <Modal.Footer>
       
         
        <div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-3 col-12'>
            <div className='bid-blue-sm-btn'>
            <button type="button" onClick={()=>{
                 setBuynow(false)
            }}>Cancel</button>
            </div>
        </div>



        <div className='col-sm-3 col-lg-3 col-md-3 col-xl col-xxl-3 col-12'>
            <div className='bid-green-sm-btn'>
            <button type="button" onClick={submitBuynow}>Buy Now</button>
            </div>
        </div>
        </Modal.Footer>
      </Modal>
     <FooterHome />
    </>
    </>
   
  )
}
