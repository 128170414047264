import React, { useEffect, useState } from 'react'
import HeaderDashboard from '../../IncludeDashboard/HeaderDashboard'
import { NavLink } from 'react-router-dom'
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dealer_details_dealer, Dealer_profile_update } from '../../../Redux/Action/AuthenticationAction';
import Accountheader from '../../IncludeDashboard/Accountheader';
import { Modal } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../DealerShipinfo/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51NSN9FK8IqEbWoY8cY6HCCFZ28bQECPn2v3iIuKQeBnzc4X7XqowWsgrDl7oy49J2UnAODFhm8f0w61rA6GLp1Ix00AjdWT8tF');

export default function DealerShipProfile() {

  const [img_data, setImg] = useState("");
  const [stripeshow,setStripe]=useState(false)
  const [imgbackend, setImgbackend] = useState();
  const user_logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };
  const dispatch = useDispatch();
  const [dealerinfo,setDealerinfo]=useState("")
  useEffect(() => {
    dispatch(Dealer_details_dealer(localStorage.getItem('dealer_id'))).then(
      (data) => {
        setImgbackend(data['image']);
        var fileds = ['website_url', 'business_type', 'dealer_bio', 'image'];

        fileds.forEach((el) => {
          formik.setFieldValue(el, data[el], false);
        });

        setDealerinfo(data)
      }
    );
  }, [localStorage.getItem('dealer_id')]);

  const [imgupload, setImgupload] = useState();

  const onImageChange = (e) => {
    const [file] = e.target.files;

    setImgupload(e.target.files[0]);

    setImg(URL.createObjectURL(file));
  };
  const formik = useFormik({
    initialValues: {
      website_url: '',
      business_type: '',
      dealer_bio: '',
    },
    validationSchema: yup.object({
      website_url: yup.string().required('url is required '),
      business_type: yup.string().required('Business type is required'),
      dealer_bio: yup.string().required('Dealer Bio is required'),
    }),
    onSubmit: (values) => {
      var save_dealer_profile_detail = new FormData();
      save_dealer_profile_detail.append('image', imgupload);
      save_dealer_profile_detail.append('website_url', values.website_url);
      save_dealer_profile_detail.append('business_type', values.business_type);
      save_dealer_profile_detail.append('dealer_bio', values.dealer_bio);
      dispatch(Dealer_profile_update(save_dealer_profile_detail));
    },
  });
  const closestripe=()=>{
    setStripe(false)
  }

  return (
    <>
         <Accountheader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" >Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" className='active-border'>Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson">Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity">Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews">Reviews <span>(1)</span></NavLink>
        </li>     */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>
    {/* dealership form */}
    <section className='dealership-form mt30'>
    <div className='container container1224'>
    <div className='row row-reverse-mobile'>
    <div className='col-sm-12 col-md-4 col-lg-5 col-xl-5 col-xxl-5 col-12'>
    <div className='dealership-whitecard mb-3'>
    <div className='page-heading-v2'>Dealer Support</div>
    <div className='contact-info-dealer'>
      <div className='blact-text-normal fontweight500'>Mon-Sat 9am-5pm</div>
      <div className='blact-text-normal fontweight500'>sales@yotrader.co.uk</div>
      <div className='blact-text-normal fontweight500'>0115 694 0404</div>

      <div className='blact-text-normal mt-4'>All our dealer reps have direct lines, if you call, we answer. No holding.</div>
    </div>
    </div> 

    <div className='dealership-whitecard mb-3'>
    <div className='bank-card-part'>
    <div className='page-heading-v2'>Update Payment Details</div>
    {
      dealerinfo.payment_link==undefined?( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Add</div>):( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Update</div>)
    }
   
   
    </div>
    </div> 

    <div className='dealership-whitecard mb-3'>
    <NavLink to="#" className="red-color-lg" onClick={() => user_logout()}>Logout</NavLink>
    </div>  
    </div>
   

 
    <div className='col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 col-12'>
    <form onSubmit={formik.handleSubmit}>
      <div className='dealership-whitecard mb-3'>
      <div className='page-heading-v2'>Dealership Profile</div>
      <div className='form_action'>
        <div className='row'>
        <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mb-5'>
            <div className='form-group'>
             <div className='dealer-logo-part'>
             <div className="imge_show_logo">
         

               <img
                                    src={
                                      img_data  != ""
                                        ?
                                        
                                      (  imgbackend !=undefined? process.env.REACT_APP_IMAGE_URL +'dealer_image/' +  imgbackend:img_data)
                                        : require('../../asset_user/images/placholder-img.png')
                                    }
                                    alt='logo-show'
                                  /> 
              </div>
              <div className="file_show">
              <div for="update_logo" className="update-logo-view-btn">Update Logo</div>
              <input id="update_logo" type="file" className='update_logo-input'   onChange={onImageChange} />
              </div>
             </div>
            </div>                 
          </div> 
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Website URL</label>
              <input
                type='text'
                className='form-control'
                placeholder='website url'

                name='website_url'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.website_url}
              />
                {formik.errors.website_url ? (
                  <strong style={{ color: 'red' }}>
                    {formik.errors.website_url}
                  </strong>
                ) : null}
            </div>                  
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Type of Business</label>
              <input
                type='text'
                className='form-control'
                placeholder='71 - 75'
                name='business_type'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.business_type}
              />
               {formik.errors.business_type ? (
                <strong style={{ color: 'red' }}>
                  {formik.errors.business_type}
                </strong>
              ) : null}
            </div>                  
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-12 col-xxl-12 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Dealership Bio</label>
              <textarea cols='30' rows='10' className='form-control' placeholder='Type a bio..'     name='dealer_bio'
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.dealer_bio}></textarea>
                                  {formik.errors.dealer_bio ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.dealer_bio}
                                  </strong>
                                ) : null}
            </div>                 
          </div>        
       
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mt-4'>
          <div className='update-btn-part'>
              <button className='dealer_btn_update'>Update</button>
            </div>             
          </div>
        </div>
      </div>
      </div>
      </form>  
    </div> 
       
      </div>
    </div>
    </section> 
    <Modal show={stripeshow} onHide={closestripe} className='mt-top checkout-popup'>
    <form >
    <div className='modal-body white-bg-popup-top'>     
     <Modal.Header closeButton></Modal.Header>
     <Elements stripe={stripePromise}>
      {/* Your component hierarchy */}
      <PaymentForm open={stripeshow} onClose={closestripe} />
    </Elements>

 </div>

</form>


  </Modal>
    </>
  )
}

