
import { DEALER_STOCKE_AGE, HOME_PAGE_VEHICLES, MODAL_CLOSE } from "../Constant/Constant";
import API from "../Service/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const LoginAction = (userinfo) => async (dispatch) => {
    try {
      const login_user = await API.post('/DealerLogin', userinfo);
  
      if (login_user.data.status == 400) {
        toast.warning(login_user?.data.message);
      }
      if (login_user.data.status == 200) {
        localStorage.setItem('accessToken', login_user?.data?.data?.token);
        localStorage.setItem('user_dealer', login_user?.data?.data);
        localStorage.setItem('dealer_id', login_user?.data?.data._id);
        localStorage.setItem('dealer_name', login_user?.data?.data.name);
        localStorage.setItem('company_name', login_user?.data?.data.company_name);
        localStorage.setItem('dealer_id_data', login_user?.data?.data.dealer_id);
  
     
        localStorage.setItem('login_user_type', 2);
        window.location.href = '/all-vehicles';
       
      }
    } catch (error) {
      console.log(error);
    }
  };


  export const DealerRegistrationAciton = (dealer_data, ) => async (dispatch) => {
    try {
      var dms = await API.post('/Dealer', dealer_data);
  
      if (dms.data.status == 200) {
     return dms.data.status
      } else {
        toast.warning(dms.data.message);
        return dms.data.status
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };

  
  export const ContactUsDa=(contactusdata)=>async(dispatch)=>{
    try {
      var Contactus = await API.post('/ContactUs',contactusdata);
  
      if(Contactus.data.status == 200){
        
          toast.success(Contactus.data.message);
      }else{
        toast.warning(Contactus.data.message);
      }
  
    } catch (error) {
      // toast.warning(error.message);
    }
  }


  export const Dealer_details_dealer = (dealer_id) => async (dispatch) => {
    try {
      var dealer_detail_data = await API.get(
        `/Dealer_details?dealer_id=${dealer_id}`
      );
  
      if (dealer_detail_data.data.status == 200) {
      
        return dealer_detail_data.data.data;
       
      } else {
        toast.warning(dealer_detail_data.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };


  export const Dealer_profile_update = (Dealer_profile_update) => async () => {
    try {
      var response_backend = await API.post(
        '/Dealer_profile_update',
        Dealer_profile_update
      );
      if (response_backend.data.status == 200) {
        toast.success(response_backend.data.message);
      } else {
        toast.warning(response_backend.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };

  export const AddDealer_stock_age = () => async (dispatch) => {
    try {
      var stock_age = await API.get('/AddDealer_stock_age');
  
      if (stock_age.data.status == 200) {
        dispatch({
          type: DEALER_STOCKE_AGE,
          payload: stock_age.data.data,
        });
      } else {
        toast.warning(stock_age.data.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };

  export const change_password_dealer = (change_password_data) => async () => {
    try {
      var response_backend = await API.post(
        '/Change_password',
        change_password_data
      );
    
      if (response_backend.data.status == 200) {
        toast.success(response_backend.data.message);
      } else {
        toast.warning(response_backend.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };
  export const email_update_dealer = (email_update_data) => async () => {
    try {
      var response_backend = await API.post('/update_email', email_update_data);
      if (response_backend.data.status == 200) {
        toast.success(response_backend.data.message);
      } else {
        toast.warning(response_backend.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };

  export const UpdatepaymentmethodAction = (update_paymentmethodlink) => async (dispatch) => {
    try {
      var response_backend = await API.post('/Updatepaymentmethod', update_paymentmethodlink);
      if (response_backend.data.status == 200) {

        toast.success(response_backend.data.message);
        dispatch({
          type:MODAL_CLOSE,
          payload:false
      })
      } else {
        toast.warning(response_backend.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };
  

  export const HomepageVehiclesAction = (pagination_object) => async (dispatch) => {
    try {
      var homepagevehicleslist = await API.post('/HomepageVehicles', pagination_object);
      if (homepagevehicleslist.data.status == 200) {


        dispatch({
          type:HOME_PAGE_VEHICLES,
          payload:homepagevehicleslist.data.data
      })
      } else {
        toast.warning(homepagevehicleslist.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };

  