import React from 'react'

export default function Productheaderdetails({details}) {
  return (
    <div className='border-b-listing '>
    <div className="listing-heading">  { details?.year !=undefined?
    details?.year+" "+ (details?.make !=undefined ?details?.make:"") +" "+ (details?.model !=undefined ?details?.model:""):(details?.make !=undefined ?details?.make:"")+" "+ (details?.model !=undefined ?details?.model:"")  }</div>
    <div className="row element-between">
      <div className="col-sm-8 col-lg-8 col-md-8 col-xl-8 col col-8">
        <div className="listing-info">{( details?.engine_size !=undefined) && details?.engine_size}{details?.engine_size !=undefined?"cc":""}</div>
        <div className="listing-info">{details?.year !=undefined? details?.year:"N/A"}</div>
        <div className="listing-info">{new Intl.NumberFormat('en-GB')
              .format(details?.mileage)
              .replace(/\.00$/, '')} Miles</div>
      </div>
    <div className="col-sm-4 col-lg-4 col-md-4 col-xl-4 col col-4 element-between">
      <div className='yellow-listing-no-v2'>{details?.vehicle_id==undefined?details?.vrm:details?.vehicle_id?.vrm}</div>
    </div>
    </div>
    </div>

  )
}
