

import { NavLink, useNavigate } from 'react-router-dom';
import Accountheader from '../../IncludeDashboard/Accountheader';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';

import { Dealer_details_dealer, Dealer_profile_update } from '../../../Redux/Action/AuthenticationAction';
import React,{ useEffect } from 'react';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Elements } from '@stripe/react-stripe-js';
import PaymentForm from '../DealerShipinfo/PaymentForm';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_test_51NSN9FK8IqEbWoY8cY6HCCFZ28bQECPn2v3iIuKQeBnzc4X7XqowWsgrDl7oy49J2UnAODFhm8f0w61rA6GLp1Ix00AjdWT8tF');

export default function ContactPerson() {
  const [stripeshow,setStripe]=useState(false)
  const [dealerinfo,setDealerinfo]=useState("")

  const user_logout = () => {
    localStorage.clear();
    window.location.href = '/';
  };
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Dealer_details_dealer(localStorage.getItem('dealer_id'))).then(
      (data) => {
        var fileds = [
          'position',
          'name',
          'email',
          'main_phone_number',
          'phone_number',
        ];

        fileds.forEach((el) => {
          formik.setFieldValue(el, data[el], false);
        });
        setDealerinfo(data)
      }
    );
  }, [localStorage.getItem('dealer_id')]);

  const formik = useFormik({
    initialValues: {
      position: '',
      name: '',
      email: '',
      main_phone_number: '',
      phone_number: '',
    },
    validationSchema: yup.object({
      email: yup.string().email().required('Contact email is required'),
      main_phone_number: yup
        .string()
        .required('Contact Number First is required'),
      phone_number: yup.string().required('Contact Second First is required'),
    }),
    onSubmit: (values) => {
      var save_dealer_profile_detail = new FormData();

      save_dealer_profile_detail.append('position', values.position);
      save_dealer_profile_detail.append('name', values.name);
      save_dealer_profile_detail.append('email', values.email);
      save_dealer_profile_detail.append(
        'main_phone_number',
        values.main_phone_number
      );
      save_dealer_profile_detail.append('phone_number', values.phone_number);
      dispatch(Dealer_profile_update(save_dealer_profile_detail));
    },
  });
  const closestripe=()=>{
    setStripe(false)
  }
  return (
    <>
    <Accountheader />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/dealershipinfo" >Dealership Info</NavLink>
        </li>
        <li>
          <NavLink to="/dealershiprofile" >Dealership Profile</NavLink>
        </li>
        <li>
          <NavLink to="/contactperson" className='active-border'>Contact Person</NavLink>
        </li>
        <li>
          <NavLink to="/accountsecurity" >Account & Security</NavLink>
        </li>
        {/* <li>
          <NavLink to="/reviews">Reviews <span>(1)</span></NavLink>
        </li>      */}
       </ul> 
     
      </div>
      <div className='col-sm-3 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn justify-end'>
       <NavLink to="/all-vehicles" className='blue-outline-btn-square'>Back to Home</NavLink>
       </div>
      </div>       
    </div>
    </div> 
      </div>
    </section>

    <section className='dealership-form mt30'>
    <div className='container container1224'>
    <div className='row row-reverse-mobile'>
    <div className='col-sm-12 col-md-4 col-lg-5 col-xl-5 col-xxl-5 col-12'>
    <div className='dealership-whitecard mb-3'>
    <div className='page-heading-v2'>Dealer Support</div>
    <div className='contact-info-dealer'>
      <div className='blact-text-normal fontweight500'>Mon-Sat 9am-5pm</div>
      <div className='blact-text-normal fontweight500'>sales@yotrader.co.uk</div>
      <div className='blact-text-normal fontweight500'>0115 694 0404</div>

      <div className='blact-text-normal mt-4'>All our dealer reps have direct lines, if you call, we answer. No holding.</div>
    </div>
    </div> 
    <div className='dealership-whitecard mb-3'>
    <div className='bank-card-part'>
    <div className='page-heading-v2'>Update Payment Details</div>
    {
      dealerinfo.payment_link==undefined?( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Add</div>):( <div className='green-text-update-btn' onClick={()=>{setStripe(true)}}>Update</div>)
    }
   
   
    </div>
    </div> 
    <div className='dealership-whitecard mb-3'>
    <NavLink to="#" className="red-color-lg"  onClick={() => user_logout()}>Logout</NavLink>
    </div>  
    </div>
    <div className='col-sm-12 col-md-8 col-lg-7 col-xl-7 col-xxl-7 col-12'>
    <form onSubmit={formik.handleSubmit}>


      <div className='dealership-whitecard mb-3'>
      <div className='page-heading-v2'>Contact Person</div>
      <div className='form_action'>
        <div className='row'>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Position</label>
              <input
                type='text'
                className='form-control'
                placeholder='Dealer Principal'
                name='position'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.position}
              />
                {formik.errors.position ? (
                <strong style={{ color: 'red' }}>
                  {formik.errors.position}
                </strong>
              ) : null}
            </div>                  
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Contact Person</label>
              <input
                type='text'
                className='form-control'
                placeholder='John Smith'
                name='name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
               {formik.errors.name ? (
                <strong style={{ color: 'red' }}>
                  {formik.errors.name}
                </strong>
              ) : null}
            </div>                 
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Contact Number - 1</label>
              <input
                type='number'
                className='form-control'
                placeholder='020 3880 6050'
                onChange={formik.handleChange}
                name='phone_number'
                                  onBlur={formik.handleBlur}
                                  value={formik.values.phone_number}
              />
               {formik.errors.phone_number ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.phone_number}
                                  </strong>
                                ) : null}
            </div>                  
          </div>
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Contact Number - 2</label>
              <input
                type='number'
                className='form-control'
                placeholder='020 3880 6050'
                name='main_phone_number'
                onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.main_phone_number}
              />
                {formik.errors.main_phone_number ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.main_phone_number}
                                  </strong>
                                ) : null}
            </div>                 
          </div>
         
          <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12 mb-3'>
            <div className='form-group'>
              <label for=''>Email Address</label>
              <input
                type='text'
                className='form-control'
                placeholder='Email Address'
                name='email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
               {formik.errors.email ? (
                                  <strong style={{ color: 'red' }}>
                                    {formik.errors.email}
                                  </strong>
                                ) : null}
            </div>                
          </div>          
          
          <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12 mt-4'>
          <div className='update-btn-part'>
              <button className='dealer_btn_update'>Update</button>
            </div>             
          </div>
        </div>
      </div>
      </div>
      </form>
    </div>          
      </div>
    </div>
    </section> 
    <Modal show={stripeshow} onHide={closestripe} className='mt-top checkout-popup'>
    <form >
    <div className='modal-body white-bg-popup-top'>     
     <Modal.Header closeButton></Modal.Header>
     <Elements stripe={stripePromise}>
      {/* Your component hierarchy */}
      <PaymentForm open={stripeshow} onClose={closestripe} />
    </Elements>

 </div>

</form>


  </Modal>
    </>
  
  )
}
