import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Home from './Component/Home/Home';
import { ToastContainer, toast } from 'react-toastify';

import AboutUs from './Component/AboutUs/AboutUs';
import Allvehicles from './Component/Dashboard/Allvehicles';
import Registrationindex from './Component/Registration/Registrationindex';
import ForgotPassword from './Component/ForgotPassword/ForgotPassword';
import ContactUs from './Component/ContactUs/ContactUs';
import MyAuction from './Component/MyAuction/MyAuction';
import Draft from './Component/Draft/Draft';
import SoldVehicles from './Component/SoldVehicles/SoldVehicles';
import Conditionreport1 from './Component/Conditionreport/Conditionreport1';
import Conditionreport2 from './Component/Conditionreport/Conditionreport2';
import Conditionreport3 from './Component/Conditionreport/Conditionreport3';
import Conditionreport4 from './Component/Conditionreport/Conditionreport4';
import Conditionreport5 from './Component/Conditionreport/Conditionreport5';
import Conditionreport6 from './Component/Conditionreport/Conditionreport6';
import Conditionreport7 from './Component/Conditionreport/Conditionreport7';
import Conditionreport8 from './Component/Conditionreport/Conditionreport8';
import Conditionreport9 from './Component/Conditionreport/Conditionreport9';
import Conditionreport10 from './Component/Conditionreport/Conditionreport10';
import Conditionreport11 from './Component/Conditionreport/Conditionreport11';
import Conditionreport12 from './Component/Conditionreport/Conditionreport12';
import Conditionreport13 from './Component/Conditionreport/Conditionreport13';
import Conditionreport14 from './Component/Conditionreport/Conditionreport14';
import Conditionreport15 from './Component/Conditionreport/Conditionreport15';
import Conditionreport16 from './Component/Conditionreport/Conditionreport16';
import { PrivateRoute } from './Component/Protected/protected_route';
import Previewdetails from './Component/Previewdetails/Previewdetails';
import SendtoAuctiondetails from './Component/SendtoAuctiondetails/SendtoAuctiondetails';
import Vehiclesdetails from './Component/Vehiclesdetails/Vehiclesdetails';
import LiveAuction from './Component/LiveAuction/LiveAuction';
import Buyervehiclesdetails from './Component/Buyervehiclesdetails/Buyervehiclesdetails';
import Lost from './Component/Lost/Lost';
import Won from './Component/Won/Won';
import Mystocks from './Component/Mystock/Mystocks';
import Mybids from './Component/MyBids/Mybids';
import Watching from './Component/Watching/Watching';
import SoldVehicledetails from './Component/SoldVehicledetails/SoldVehicledetails';
import UpdateReserveBuynow from './Component/Conditionreport/UpdateReserveBuynow';
import AuctionendReserNotMet from './Component/AuctionendReserNotMet/AuctionendReserNotMet';
import BuyerCongratesVehilesdetails from './Component/BuyerCongratesVehilesdetails/BuyerCongratesVehilesdetails';
import BuyerDontwind from './Component/BuyerDontwind/BuyerDontwind';
import Vehiclesolddetail from './Component/Vehiclesolddetail/Vehiclesolddetail';
import BuyerDontwindnobid from './Component/BuyerDontwindnobid/BuyerDontwindnobid';
import AuctionendSelerside from './Component/AuctionendSelerside/AuctionendSelerside';
import DealerShipinfo from './Component/Account/DealerShipinfo/DealerShipinfo';
import DealerShipProfile from './Component/Account/DealerShipProfile/DealerShipProfile';
import ContactPerson from './Component/Account/ContactPerson/ContactPerson';
import AccountSecurity from './Component/Account/AccountSecurity/AccountSecurity';
import Reviews from './Component/Account/Reviews/Reviews';
import PendingCollection from './Component/Account/PendingCollection/PendingCollection';
import Pendingreview from './Component/Account/Pendingreview/Pendingreview';
import Complete from './Component/Account/Complete/Complete';
import LeaveReview from './Component/Account/LeaveReview/LeaveReview';
import NotFound from './Component/404Error/NotFound';
import Archive from './Component/Archive/Archive';
import Past30days from './Component/Past30days/Past30days';
import ManualUpload from './Component/ManualUpload/ManualUpload';
import TcgbNoreturns from './Component/Conditionreport/TcgbNoreturns';
import BuyerPast30days from './Component/BuyerPast30days/BuyerPast30days';
import BuyerArchive from './Component/BuyerArchive/BuyerArchive';
import Home1 from './Component/Home/Home1';
import Homeanumation from './Component/Home/Homeanumation';



function App() {
  var authentication = localStorage.getItem('accessToken');


  return (
<Router>
<Routes>



            {!authentication && (
              <>
              
              <Route path='/' element={<Home1 />} />
              <Route path='/animation' element={<Homeanumation />} />
              <Route path='/registration' element={<Registrationindex />} />

                <Route path='/about-us' element={<AboutUs />} />
                <Route path='/forgotpassword' element={<ForgotPassword />} />
                <Route path='/contact-us' element={<ContactUs />} />
                {/* <Route path='/reset_password/:id/:time' element={<Resetpassword />} /> */}
              </>
                      )}

      { !authentication ?<Route  path="*" element={<Navigate to="/" />    }  /> :<Route path='*'    element={<Navigate to="/all-vehicles" replace />}  replace />}


          <Route path='/all-vehicles' element={ <PrivateRoute> <Allvehicles />  </PrivateRoute>} />
          <Route path='/myauction' element={<PrivateRoute><MyAuction /></PrivateRoute>   } />
          <Route path='/draft' element={  <PrivateRoute> <Draft /></PrivateRoute>} />
          <Route path='/manualupload' element={<PrivateRoute><ManualUpload /></PrivateRoute>   } />
        
          <Route path='/Conditionreport1' element={ 
            <PrivateRoute>
            <Conditionreport1 />
            </PrivateRoute>
           
         } />
          <Route path='/Conditionreport2' element={<PrivateRoute><Conditionreport2 /> </PrivateRoute>} />
          <Route path='/Conditionreport3' element={  <PrivateRoute><Conditionreport3 /> </PrivateRoute>} />
          <Route path='/Conditionreport4' element={ <PrivateRoute> <Conditionreport4 /></PrivateRoute>} />
          <Route path='/Conditionreport5' element={<PrivateRoute>  <Conditionreport5 />  </PrivateRoute> } />
          <Route path='/Conditionreport6' element={ <PrivateRoute><Conditionreport6 /></PrivateRoute>} />
          <Route path='/Conditionreport7' element={<PrivateRoute><Conditionreport7 /></PrivateRoute>} />
          <Route path='/Conditionreport8' element={<PrivateRoute><Conditionreport8 /></PrivateRoute>} />
          <Route path='/Conditionreport9' element={<PrivateRoute><Conditionreport9 /></PrivateRoute>} />
          <Route path='/Conditionreport10' element={<PrivateRoute><Conditionreport10 /></PrivateRoute>} />
          <Route path='/Conditionreport11' element={<PrivateRoute><Conditionreport11 /></PrivateRoute>} />
          <Route path='/Conditionreport12' element={<PrivateRoute><Conditionreport12 /></PrivateRoute>} />
          <Route path='/Conditionreport13' element={<PrivateRoute><Conditionreport13 /></PrivateRoute>} />
          <Route path='/Conditionreport14' element={<PrivateRoute><Conditionreport14 /></PrivateRoute>} />
          <Route path='/Conditionreport15' element={<PrivateRoute><Conditionreport15 /></PrivateRoute>} />
          <Route path='/Conditionreport16' element={<Conditionreport16 />} />
           <Route path='/tcgbnoreturn' element={<PrivateRoute><TcgbNoreturns /></PrivateRoute>} />
          <Route path='/previewdetails' element={<PrivateRoute><Previewdetails /> </PrivateRoute>} />
          <Route path='/sendtoAuctiondetails' element={<PrivateRoute><SendtoAuctiondetails /> </PrivateRoute>} />
          <Route path='/Vehiclesdetails' element={<PrivateRoute><Vehiclesdetails /> </PrivateRoute>} />
          <Route path='/liveauction' element={<PrivateRoute><LiveAuction /> </PrivateRoute>} />

           <Route path='/buyervehiclesdetails' element={<PrivateRoute><Buyervehiclesdetails /> </PrivateRoute>} />
           <Route path='/mybids' element={<PrivateRoute><Mybids /> </PrivateRoute>} />
           <Route path='/lost' element={<PrivateRoute><Lost /> </PrivateRoute>} />

           <Route path="/won">
              <Route path="/won/past30days" element={<PrivateRoute><Won /></PrivateRoute>} />
              <Route path="/won/archive" element={<PrivateRoute><BuyerArchive /></PrivateRoute>} />
            </Route>
           <Route path='/mystocks' element={<PrivateRoute><Mystocks /> </PrivateRoute>} />
           <Route path='/watching' element={<PrivateRoute><Watching /> </PrivateRoute>} />
           <Route path='/salercongtratesvehilesdetails' element={<PrivateRoute><SoldVehicledetails /> </PrivateRoute>} />
           <Route path='/updatereservebuynow' element={<PrivateRoute><UpdateReserveBuynow /> </PrivateRoute>} />
           <Route path='/AuctionendReserNotMet' element={<PrivateRoute><AuctionendReserNotMet /> </PrivateRoute>} />
           <Route path='/BuyerCongratesVehilesdetails' element={<PrivateRoute><BuyerCongratesVehilesdetails /> </PrivateRoute>} />
           <Route path='/BuyerDontwindbid' element={<PrivateRoute><BuyerDontwind /> </PrivateRoute>} />
           <Route path='/vehiclesolddetail' element={<PrivateRoute><Vehiclesolddetail /> </PrivateRoute>} />
           <Route path='/BuyerDontwindnobid' element={<PrivateRoute><BuyerDontwindnobid /> </PrivateRoute>} />
           <Route path='/AuctionendSelerside' element={<PrivateRoute><AuctionendSelerside /> </PrivateRoute>} />
    
           {/* account */}
           <Route path='/dealershipinfo' element={<PrivateRoute><DealerShipinfo /> </PrivateRoute>} />
           <Route path='/dealershiprofile' element={<PrivateRoute><DealerShipProfile /> </PrivateRoute>} />
           <Route path='/contactperson' element={<PrivateRoute><ContactPerson /> </PrivateRoute>} />
           <Route path='/accountsecurity' element={<PrivateRoute><AccountSecurity /> </PrivateRoute>} />
           <Route path='/reviews' element={<PrivateRoute><Reviews /> </PrivateRoute>} />
           <Route path='/pendingcollections' element={<PrivateRoute><PendingCollection /> </PrivateRoute>} />
           <Route path='/pendingreviews' element={<PrivateRoute><Pendingreview /> </PrivateRoute>} />
           <Route path='/complete' element={<PrivateRoute><Complete /> </PrivateRoute>} />
           <Route path='/leavereview' element={<PrivateRoute><LeaveReview /> </PrivateRoute>} />
            {/* end account */}


            {/* sold vehicles */}
            <Route path='/soldvehicles' element={   <PrivateRoute><SoldVehicles /> </PrivateRoute>} />
            <Route path='/archive' element={   <PrivateRoute><Archive /> </PrivateRoute>} />
            <Route path='/past30days' element={   <PrivateRoute><Past30days /> </PrivateRoute>} />
            {/*End sold vehicles */}

            {/* Error Pages */}
            <Route path='/notfound' element={<PrivateRoute><NotFound /> </PrivateRoute>} />
          </Routes>
          <ToastContainer />
          </Router>

  );
}

export default App;
