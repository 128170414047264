import React, { useEffect, useState } from 'react';

export default function CalculateCountdown(props) {
    const calculateTimeLeft = () => {
  
        const difference = new Date(props?.endDate) - new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }
    
        
      
          return timeLeft;
        
      };
    
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      useEffect(() => {
        const timer = setTimeout(() => {
          
            setTimeLeft(calculateTimeLeft());
          
          
        }, 1000);
    
        return () => {
          
         
          clearTimeout(timer)
        
        
        };
      },[timeLeft]);
  return (
    <div className='seconds-line'>
    {timeLeft?.hours > 0 && (
      <span>
        {Math.ceil(timeLeft?.hours/24)>1? "Days":" "+ timeLeft?.hours+" Hours "}: {timeLeft?.minutes  +" Minutes "}

        
      </span> 
    )}

    {  timeLeft?.hours==0 &&  timeLeft?.minutes > 0 && (
      <span>
        {" "+timeLeft?.minutes  +" Minutes "}: {timeLeft?.seconds+" Seconds"}


        
      </span>
    )}

    { timeLeft?.hours==0 && timeLeft?.minutes==0 &&  timeLeft?.seconds > 0 && (
      <span>

        {
          0 +" Minutes "+ timeLeft?.seconds+" Seconds"
        }
      </span>
    )}

   
  </div>



  )
}
