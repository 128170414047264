import React from 'react'
import FooterHome from '../IncludeHome/FooterHome'
import Gallery from '../Gallery/Gallery'
import { NavLink, useNavigate } from 'react-router-dom'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { GetMyvehiclesConditionreportmyvehicle } from '../../Redux/Action/SellerVehiclesActions'
import Productheaderdetails from '../Previewdetails/Productheaderdetails'
import BidLIstShow from '../../BidLIstShow'

export default function SendtoAuctiondetails() {

    const [previewlistingobj,setPreviewlistingobj]=useState()

    const urlParams = new URLSearchParams(window.location.search);




    const dispatch=useDispatch();


        useEffect(()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
         
            
           
            setPreviewlistingobj(data);

     

        })
        },[])
      

  
    


        
        





  return (
   <>
      <HeaderDashboard />
      <div className='bg-headerwhite-v2 detail-page-subheader'>
        <div className='container container1200'>
              <div className='row'>
                <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-6'>
                <div className="page-heading-v2-details">Vehicle Preview</div>
                </div>
                <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-6'>
                    <div className="sub-btn-header">
                      <NavLink className="sub-blue-btn-header" to={"/conditionreport4?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Send to Auction</NavLink>
                    </div>
                  </div>
              </div>
        </div>
      </div>
      <div className='detail-page-back'>
        <div className='container container1200'>
              <div className='detail-back-part'>
                <div className='row'>
                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
                    {/* Back */}               
                    <div className='white-bg-step '>  
                        <NavLink to="/draft" className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to Draft
                        </NavLink>                       
                    </div>
                  </div>                 
                </div>
              </div>
        </div>
      </div>

      <div className='slider-img-details-Section'>
      <div className='container container1200'>
      <div className='bor-b-v2'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12 mobile-padding-0'>
            <div className='slider-img-left-part'>
              <Gallery images_data={previewlistingobj?.add_condition_report_candition} />
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12'>
            <div className='slider-img-right-content'>
            <Productheaderdetails details={previewlistingobj?.add_condition_report_candition} />
            <div className='bor-b-v2'></div>            
              
            <div className='listing-rowSection'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 mobile30 listing-sm-text-blue pl-0 font-width700'>Offers Expire:</div>

              <div className='listing-colum35 mobile40'>
                <div className='listing-sm-text-blue'>N/A</div>
              </div>

              <div className='listing-colum40 mobile30'>
                <div className='group-element'>
                <div className='listing-sm-text-blue'>N/A</div>
                </div>               
              </div>                            
            </div>  
            <div className='listing-detail-row bor-b-v2'>
              <div className='listing-colum25 mobile30 listing-sm-text-blue pl-0 font-width700'>Location:</div>

              <div className='listing-colum35 mobile40'>
              <div className='listing-sm-text-blue'>Southend On Sea</div>
              </div>                                      
            </div> 

            {/* <div className='listing-detail-row bor-b-v2 mt-3'>
              <div className='listing-colum25 mobile30 listing-sm-text-blue pl-0'></div>

              <div className='listing-colum35 mobile40'>
                <div className='bid-amt-input'>
                  <input  type='text' placeholder='Bid amount'/>
                </div>
                <div className='blue-text-price-xs'>Enter £100 or more</div>
              </div>

              <div className='listing-colum40 mobile30'>               
                <div className='mt-m-25 button-group-part'>
                  <button type='submit' className='fill-blue-btn-bid'>Submit Bid</button>
                </div>
              </div>              
            </div> */}
            </div>
            
          
            <div className='listing-rowSection'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 mobile30 listing-sm-text-blue pl-0 font-width700'>YoTrader Says:</div>

              <div className='listing-colum75 mobile70'>
              <div className='listing-sm-text-blue'>You received  {previewlistingobj?.Bidlist.length} Instant Offers from {previewlistingobj?.Bidlist.length} Yo Trader Trusted Partners Send your vehicle to Auction Now to reach over 1500 bidders!</div>
              </div>                                     
            </div>           
            </div> 
            </div>
          </div>
          
        </div>
      </div>        
      </div>
      </div>

      <div className='bottom-content-details'>
        <div className='container container1200'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
            <div className="listing-sec-heading-underline">Vehicle Details</div>  
            </div>
          </div>
          <BidLIstShow  Bidlist={previewlistingobj?.Bidlist} offer_expire={ previewlistingobj?.add_condition_report_candition?.offer_expire} />

          
        </div>
      </div>
      <div className='fotter-calltoaction mobile-view-footer'>
       <div className='container container1200'>
       <div className='fixed-bottom-fotter '>
          <div className="footer-fix-bid-text">
          <div className="footer-btn-part-cta">
          <NavLink className="sub-blue-btn-header" to={"/conditionreport4?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Send to Auction</NavLink>
          </div>
        </div>
       </div>
      </div>  
      </div>    
      {/* <div className='fotter-calltoaction'>
       <div className='container container1200'>
       <div className='fixed-bottom-fotter'>
          <div className="footer-fix-bid-text">
          <div className="fotter-cta-text-blue"> £306</div>
          <div className="blue-textlg-footter">Buy it Now:</div>
          </div>

          <div className="footer-btn-part-cta">
          <button type="" className="btn fixed-btn-cta-blue">Send to Auction</button>
          </div>
        </div>
       </div>
      </div> */}
     
     <FooterHome />
   </>
  )
}
