import React, { useEffect, useState } from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard';
import FooterHome from '../IncludeHome/FooterHome';
import { NavLink, useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import {  Getvaluationvehicleunderwritevrm, MySaleAction, past30daysmysaleActon } from '../../Redux/Action/SellerVehiclesActions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { io } from 'socket.io-client';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { SalerheaderAction } from '../../Redux/Action/BuyerVehilesAution';
import Loader from 'react-loader-spinner'
export default function SoldVehicles() {
    const [searchInput, setSearchInput] = useState(''); 
    const [manualupload, setmanualupload] = useState(false);

    const [showaccept, setaccept] = useState(false);
    const [loadIndicator, setLoadIndicator] = useState(false);
        const dispatch =useDispatch();
        const navigate=useNavigate();
        const ManualUploadClose = () => setmanualupload(false);
    const formikunderwrite = useFormik({
        initialValues: {
          vrm: '',
           mileage: '',
        },
        validationSchema: Yup.object({
          vrm: Yup.string().required('TYPE VRM is required'),
          mileage: Yup.string().required('Mileage is required'),
        }),
        onSubmit: (values) => {
          values.type="GETOFFER";
          
          setLoadIndicator(true)

          dispatch(Getvaluationvehicleunderwritevrm(values)).then(function(data){
          if(data !=undefined){
            navigate('/Conditionreport1?vrm='+data?.vehicle?.vrm+"&manual=1")
          
            setaccept(false)
           setLoadIndicator(false)
            formikunderwrite.resetForm()
          }else{
            toast.warn('VRM could not be found. Please try a different VRM')
            setaccept(false)
            setLoadIndicator(false)
            formikunderwrite.resetForm()
          }
          })
          
           
           
       
        
        
        },
      });
      const handleshowaccept=()=>  setaccept(false);



      useEffect(()=>{
        dispatch(past30daysmysaleActon(1))
        dispatch(SalerheaderAction());
      },[])

      const salelist=useSelector((state)=>state.VehicleReducer.soldvehicles);
const header_count = useSelector(
  (state) => state.VehicleReducer?.header_count
);

  return (
    <>
<HeaderDashboard />
    {/* Header subheader white background */}
    <section className='bg-headerwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/all-vehicles" >My Stock <span>({window.allvehicles ?? header_count?.total_vehicles})</span></NavLink>
        </li>
        <li>
          <NavLink to="/myauction" >My Auctions <span>({window.totalmyauction ?? header_count?.totalmyauctionsvehicles})</span></NavLink>
        </li>
        <li>
          <NavLink to="/draft">Drafts <span>({window.totaldraftvehicles ?? header_count?.totaldraftvehicles})</span></NavLink>
        </li>
        <li>
          <NavLink to="/soldvehicles" className='active-border'>Sold Vehicles <span>({header_count?.totalsoldvehicles})</span></NavLink>
        </li>   
       </ul> 
     
      </div>
      <div className='col-sm-6 col-md-5 col-lg-4 col-xl-3 col-xxl-3 col-12 mtb-20'>
       <div className='white-subHeader-btn'>
       <NavLink to="" className='blue-btn-square' onClick={()=>{
        setmanualupload(true)
       }}>Manual Upload</NavLink>  
       <NavLink to="/liveauction" className='blue-outline-btn-square'>Live Auctions</NavLink>
       </div>
      </div> 
      <div className='col-sm-6 col-md-12 col-lg-12 col-xl-3 col-xxl-3 col-12 '>
      {/* <div className='app-search d-lg-block '>
        <div className='d-flex justify-content-between align-items-center search_box'>
          <div className='position-relative w-100'>
            <input
            className='form-control'
            placeholder='VRM, Make, Model'
            type='search'
            value={searchInput}
            onChange={e => {
            setSearchInput(e.target.value);}}
            />
            <span className='fa fa-search'></span>
          </div>
        </div>
      </div> */}
      <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
  </div>  
       
    </div>
    </div> 
      </div>
    </section>
      {/* Header subheader white background */}
      <section className='bg-subheaderwhite-v2'>
      <div className='container container1224'> 
      <div className='page-headerSec-v2'>
    <div className='row d-flex align-items-center'>
      <div className='col-sm-12 col-md-7 col-lg-8 col-xl-6 col-xxl-6 col-12'>
       <ul className='sub-menu-dashboard'>
       <li>
          <NavLink to="/past30days" className='active-border' >Past 30 Days</NavLink>
        </li>
        <li>
          <NavLink to="/archive" >Archive</NavLink>
        </li>   
       </ul>      
      </div>       
    </div>
    </div> 
      </div>
    </section>
    {/* mobile filter show */}
    <section className='filter-home-v2'>
        <div className='mobile-filter-show'>
        <ul>
            <li className='bor-r-c' onClick={()=>{
            
            }}><NavLink to="#" className='sort-home active-color-stock'>Days In Stock</NavLink> </li>
            <li >
            <div className={"dropdown show "}>
            <div className="dropdown-toggle sort-home" type="button" id="dropdownMenuButton" data-toggle="dropdown" onClick={()=>{
            
            }} aria-haspopup="true" aria-expanded="false">
            Sort By
            </div>
            <div className={"dropdown-menu  "} aria-labelledby="dropdownMenuButton">
            <a className="dropdown-item" href="#">Price (Lowest)</a>
            <a className="dropdown-item" href="#">Price (Highest)</a>
            <a className="dropdown-item" href="#">Latest Car</a>
            <a className="dropdown-item" href="#">Oldest Car</a>
            <a className="dropdown-item" href="#">Make</a>
            <a className="dropdown-item" href="#">Model</a>
            <a className="dropdown-item" href="#">Age (Newest First)</a>
            <a className="dropdown-item" href="#">Distance</a>
            <a className="dropdown-item" href="#">Mileage (Lowest First)</a>
            </div>
        </div>
            </li>
        </ul>
        </div>
  

    {/* conditions modal */}
    </section>
    {/* list-data-show */}
    <section className='list-data-show mt30'>
    <div className='container container1224'>

     
       
<div className='loader-full' style={{display:(loadIndicator==false?"none":"")}}>
{
      loadIndicator && <Loader type="Oval"  width={100} radius={5}  color="#323b65" height={65}  />
  }        
</div>

       
 {
  salelist?.mysalelist?.length>0?
  salelist?.mysalelist?.map(function(data_obj,i){
 
 
 return <div className='product-list-v2 mb-3'> <div className='row' key={i}>
      <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-12'>
        <div className='img_car_bg '>
          <img className='footer-logo-yo'  src={  data_obj?.newvaluation?.driverrearsidecornerimguploadfile==undefined?
                                                            
                                                          (  data_obj?.newvaluation?.vehicle_id?.length==0?require('../asset_user/images/placholder-img.png'):
                                                            
                                                            data_obj?.vehicle_id[0]?.image[0]==false?require('../asset_user/images/placholder-img.png'): data_obj?.vehicle_id[0]?.image[0]):
                                                            (data_obj?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("http://") == 0 ||data_obj?.newvaluation?.driverrearsidecornerimguploadfile.indexOf("https://") == 0) ?data_obj?.newvaluation?.driverrearsidecornerimguploadfile:
                                                        process.env.REACT_APP_IMAGE_URL+"condition_report_image/"+data_obj?.newvaluation?.driverrearsidecornerimguploadfile

                    }       onError={(e) => {  e.currentTarget.src = require('../asset_user/images/placholder-img.png'); }}
/>                               
        </div>
      </div>
      <div className='col-sm-8 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-12'>
        <div className='list-car-view-details'>
          <div className="grid-title-colum-v2">  { data_obj?.newvaluation?.year !=undefined?
data_obj?.newvaluation?.year+" "+ (data_obj?.newvaluation?.make !=undefined ?data_obj?.newvaluation?.make:"") +" "+ (data_obj?.newvaluation?.model !=undefined ?data_obj?.newvaluation?.model:""):(data_obj?.newvaluation?.make !=undefined ?data_obj?.newvaluation?.make:"")+" "+ (data_obj?.newvaluation?.model !=undefined ?data_obj?.newvaluation?.model:"")  }
                             </div> 
          <div className="home-viewcar-no-v2">{data_obj?.newvaluation?.vrm}</div>   
          <div className='car-extra-info'>
          <ul className='ul-fixed-width'>
            <li>Winning Dealer:</li>
            <li>Contact:</li>
            <li>Email: </li>
            <li>Phone</li>
          </ul>
          <ul className='car-extra-details-list'>
            <li>{data_obj?.user_detail[0]?.company_name}</li>
            <li>{data_obj?.user_detail[0]?.name ==""?"N/A":data_obj?.user_detail[0]?.name}</li>
            <li>{data_obj?.user_detail[0]?.email} </li>
            <li>{data_obj?.user_detail[0]?.phone_number}</li>
          </ul>
          </div>   
        </div>
      </div>
      <div className='col-sm-4 col-md-3 col-lg-3 col-xl-3 col-xxl-3 col-12'>
        <div className='right-side-part'>
          <div className='bid-price'>
            <div className='bid-group-mobile'>
            <div className='sm-black-text'>Winning Bid</div>
            <div className='lg-green-text'>{new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',
                                                                        })
                                                                        .format(parseInt(data_obj.saleprice==undefined?0:data_obj.saleprice))
                                                                        .replace(/\.00$/, '')}</div>
            </div>
            <div className='md-black-text'>Auction Ended: {moment(data_obj?.createdAt).format('DD MMMM  YYYY, h:mm a')}</div>
          </div>
          <div className='button-part-bottom'>

          
          <NavLink to={`/vehiclesolddetail?id=${data_obj?.newvaluation?.vrm}`} className='primary-btn-fill-blue '>
            <button className="home-square-white-btn-v2 width100-btn">View All Details</button></NavLink>
          </div>
        </div>
      </div>
  </div>
  </div>  
})
:(   <div className='no-data-placeholder'>
  No Vehicles Found
</div>)
 }

</div>
    </section>   

       {/* <!--manualupload  Modal --> */}
    <Modal show={manualupload} onHide={ManualUploadClose} className='mt-top'>
    <form onSubmit={formikunderwrite.handleSubmit}>
    <div className='modal-body blue-bg-popup-top'>     
     <Modal.Header closeButton> </Modal.Header>
   <div className="input-group mb-3">     
     <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm'   onChange={formikunderwrite.handleChange} onBlur={formikunderwrite.handleBlur}/>
   </div>
   {formikunderwrite.errors.vrm ? (
                  <p className='error'>{formikunderwrite.errors.vrm}</p>
                ) : null}
   <div className='form-group'>
     <input type="text"  className='form-control white-input-pop'  name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
    placeholder="Mileage" />      
    {formikunderwrite.errors.mileage ? (
                  <p className='error'>{formikunderwrite.errors.mileage}</p>
                ) : null} 
   </div>
   <div className='green-btn-pop-large'>
     <button type='submit'>Get Trade Bids</button>
   </div>
 </div>

</form>


  </Modal> 
<FooterHome />
    </>
    
  )
}
