import React, { useState } from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ContactUsDa, LoginAction } from '../../Redux/Action/AuthenticationAction';
import HeaderHome from '../IncludeHome/HeaderHome';
import FooterHome from '../IncludeHome/FooterHome';
export default function ContactUs() {
    const [show, setShow] = useState(false);
  const [password_text, Setpassword] = useState('password');
  const password_hide_show = () => {
    Setpassword(password_text == 'input' ? 'password' : 'input');
  };
  const dispatch=useDispatch()
  

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
     first_name: '',
      phone: '',
   
      email: '',
      subject: '',
      message: '',
     
     
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required('Name is required'),
      
      email: Yup.string().email().required('Email is required'),
      phone: Yup.string().required('Phone number is required'),
      subject: Yup.string().required('Subject is required'),
      message: Yup.string().required('Message is required'),
    
    }),
    onSubmit: (values) => {
      // console.log(values);
dispatch(ContactUsDa(values))


    },
  });


  const formiklogin = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(LoginAction(values, navigate));
    },
  });

  return (
    <>
    <HeaderHome />
   <div className='cotactUs_form'>
    <div className='container container1200'>
        <div className='contact-form-inner '>
        <h2 className="border_down mb-5 mt-4">Contact us</h2>
        <div className='row'>
            <div className='col-sm-7 col-md-7 col-lg-6 col-xl-6 col-12 mb-3'>
            <form onSubmit={formik.handleSubmit}>
              <div className='form_inner_content bg_white form-part-signup'>
                  <h3>Please fill in the form below to contact us</h3>
                  <div className='row'>
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12 mb-3'>
                    <label>First Name*</label>                      
                    <input type='text' className='form-control' name='first_name' onChange={formik.handleChange}  value={formik.first_name} />
                      {formik.errors.first_name ? (
                        <p className='error'>{formik.errors.first_name}</p>
                      ) : null}
                    </div>
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12 mb-3'>
                    <label>Phone*</label>                      
                        <input type='text' className='form-control' name='phone' onChange={formik.handleChange}  value={formik.phone} />
                          {formik.errors.phone ? (
                          <p className='error'>{formik.errors.phone}</p>
                        ) : null}
                    </div>    
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12 mb-3'>
                    <label>Email*</label>                         
                      <input type='email' className='form-control' name='email' onChange={formik.handleChange}  value={formik.email} />
                      {formik.errors.email ? (
                        <p className='error'>{formik.errors.email}</p>
                      ) : null}
                    </div>        
                    <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-12 mb-3'>
                    <label>Subject*</label>
                      <input type='text' className='form-control' name='subject' onChange={formik.handleChange}  value={formik.subject} />
                      {formik.errors.subject ? (
                              <p className='error'>{formik.errors.subject}</p>
                            ) : null}
                    </div>    
                    <div className='col-md-12'>
                    <label>Message*</label>
                      <textarea className='form-control' name='message'  onChange={formik.handleChange}  value={formik.message}>
                      </textarea>
                      {formik.errors.message ? (
                        <p className='error'>{formik.errors.message}</p>
                      ) : null}
                    </div>  
                    <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12'>
                    <div>
                    <button type='submit' className='btn green_btn mt-4'>SEND</button>
                  </div>
                    </div> 
                  </div>              
                  
              </div>
              </form>
            </div>
          <div className='col-sm-5 col-md-5 col-lg-6 col-xl-6 col-12 mb-3'>
              <div className='address_details'>
                  <h4>Contact Details</h4>
                  <ul className='address-ul'>
                    <li>
                      <img src={require('../asset_user/images/mail.svg').default} alt=''className='me-1'/>
                      <p>Mansfield Innovation Centre, Oakham Business Park,
                        Mansfield, Nottinghamshire, NG18 5BR
                      </p>
                    </li>
                    <li>
                      <img src={require('../asset_user/images/location.svg').default} alt=''className='me-1'/>
                      <p>sales@yotrader.co.uk
                      </p>
                    </li>
                    <li>
                    <img src={require('../asset_user/images/phone.svg').default} alt=''className='me-1'/>
                      <p>0115 6940 404
                      </p>
                    </li>
                  </ul>
              </div>
          </div>
        </div>
        </div>
    </div>

 {/* <img src={require('../asset_user/images/banner_img.png')} /> */}
  </div>
  <FooterHome />
    </>
 
  )
}
