import React, { useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik';
import {Button, Modal, Offcanvas } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { LoginAction } from '../../Redux/Action/AuthenticationAction';
import { useEffect } from 'react';
import { SalerheaderAction } from '../../Redux/Action/BuyerVehilesAution';
import { Getvaluationvehicleunderwritevrm } from '../../Redux/Action/SellerVehiclesActions';
import { toast } from 'react-toastify';

export default function HeaderDashboard() {
  const [password_text, Setpassword] = useState('password');
  const [show, setShow] = useState(false);
  const [showaccount, setShowaccount] = useState(false);
  const [showaccept, setaccept] = useState(false);
  const [loadIndicator, setLoadIndicator] = useState(true);
  const [manualupload, setmanualupload] = useState(false);
  const ManualUploadClose = () => setmanualupload(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: (values) => {
      console.log(values);
      dispatch(LoginAction(values));
    },
  });


  const [show1, setShow1] = useState(false);

const handleClose = () => setShow1(false);
const handleCloseaccount = () => setShowaccount(false);

const navigate=useNavigate();

const handleShow = () => setShow1(true);


useEffect(()=>{
  dispatch(SalerheaderAction());
},[])
const header_count = useSelector(
  (state) => state.VehicleReducer?.header_count
);




const formikunderwrite = useFormik({
  initialValues: {
    vrm: '',
     mileage: '',
  },
  validationSchema: Yup.object({
    vrm: Yup.string().required('TYPE VRM is required'),
    mileage: Yup.string().required('Mileage is required'),
  }),
  onSubmit: (values) => {
    values.type="GETOFFER";
    
    setLoadIndicator(true)
  
    dispatch(Getvaluationvehicleunderwritevrm(values)).then(function(data){

      console.log(data);
    if(data !=undefined){
      navigate('/Conditionreport1?vrm='+data?.vehicle?.vrm+"&manual=1")
    
      setaccept(false)
     setLoadIndicator(false)
     formikunderwrite.setFieldValue('vrm'," ",false)
     formikunderwrite.setFieldValue('mileage'," ",false)
      formikunderwrite.resetForm()
    }else{
      // formikunderwrite.setFieldValue('vrm'," ",false)
      // formikunderwrite.setFieldValue('mileage'," ",false)
      // formikunderwrite.resetForm()
      // toast.warn('VRM could not be found. Please try a different VRM')
      setaccept(false)
      setLoadIndicator(false)
    
    }
    })
  },
});

  return (
<>
{/* // Header Section start */}
<Navbar expand="lg" className="header_bg_black">
<Container className='container1224'>
<Navbar.Toggle  onClick={handleShow} />
  <Navbar.Brand href="/" className='logo-header'><img src={require('../asset_user/images/header-logo/parrot_logo.svg').default} alt='yotrader-logo'  className='header-logo'/></Navbar.Brand>


  {
    window.location.pathname=="/"?(   <div className='user-icon-drop' onClick={()=>{setShow(true)}}><img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'    />  </div>):( <div className='user-icon-drop' onClick={()=>{setShowaccount(true)}}><img src={require('../asset_user/images/user-header-icon.svg').default}  className='user-header-icon'    />  </div>)
  }
   





    <Nav className="ms-auto headerinner-menu-link"> 
      <NavLink to="/reviews" className='header-white-outline-btn-square mr-10' >Reviews</NavLink> 
      <NavLink to="/dealershipinfo" className='header-green-btn-square mr-30' >Account</NavLink>  
      <NavLink to="" className=''  onClick={()=>{
        localStorage.clear();
        window.location.href="/"
      }}>Log Out</NavLink>         
    </Nav>
</Container>
</Navbar>
{/*  mobile menu sidebar */}
<Offcanvas show={show1} onHide={handleClose} className="offcanvas-sidebar">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'> 
    <ul className='offcanvas-ul-btn'>
        <li>
        <NavLink to="/liveauction" className='blue-outline-btn-square mr-10'>Live Auctions</NavLink>       
        </li>    
        <li>
        <NavLink to="#" className='blue-btn-square' onClick={()=>{
        setmanualupload(true);
        setShow1(false)
      }} >Manual Upload</NavLink>         
        </li>     
      </ul>

    <ul className='offcanvas-ul mb-0'> 
      <li>
      {/* <div className='app-search d-lg-block p-2'>
        <div className='d-flex justify-content-between align-items-center search_box'>
          <div className='position-relative w-100'>
            <input
            className='form-control'
            placeholder='VRM, Make, Model'
            type='search' />
            <span className='fa fa-search'></span>
          </div>
        </div>
      </div> */}
      <div className="input-group search-header">
      <div className="input-group-prepend search-icon-header">
        <span className="fa fa-search"></span>
      </div>
      <input type="text" className="form-control search-input-style"  placeholder='VRM, Make, Model'/>
      <div className="input-group-append">
        <button className='search-btn-black'><img src={require('../asset_user/images/search-btn.svg').default} /></button>
      </div>
    </div>
      </li>
      <li>
      <NavLink to="/all-vehicles" className={`${window.location.pathname=="/all-vehicles"?"active-border":""}`}>My Stock <span>({header_count?.total_vehicles})</span></NavLink>
    </li>
    <li>
      <NavLink to="/myauction" className={`${window.location.pathname=="/myauction"?"active-border":""}`}>My Auctions <span>({header_count?.totalmyauctionsvehicles})</span></NavLink>
    </li>
    <li>
      <NavLink to="/draft"  className={`${window.location.pathname=="/draft"?"active-border":""}`}>Drafts <span>({header_count?.totaldraftvehicles})</span></NavLink>
    </li>
    <li>
      <NavLink to="/soldvehicles" className={`${window.location.pathname=="/soldvehicles"?"active-border":""}`}>Sold Vehicles <span>({header_count?.totalsoldvehicles})</span></NavLink>
    </li> 
    

    </ul> 
 
    </Offcanvas.Body>
  </Offcanvas>


  <Offcanvas show={showaccount} onHide={handleCloseaccount} className="offcanvas-sidebar"  placement="end">
    <Offcanvas.Header closeButton>
      <Offcanvas.Title></Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>
    <ul className='offcanvas-ul-btn'>
        <li>
        <NavLink to="/reviews" className='header-blue-outline-btn-square mr-10' >Reviews</NavLink>          
        </li>    
        <li>
        <NavLink to="/dealershipinfo" className='header-green-btn-square' >Account</NavLink>          
        </li>     
      </ul>
    <ul className='offcanvas-ul'>
     
   
 


      <li>
      <NavLink to="" className='' onClick={()=>{
        localStorage.clear();
        window.location.href="/"
      }}>Log Out</NavLink>   
      </li> 

    </ul> 

    </Offcanvas.Body>
  </Offcanvas>


  {/* conditions modal */}


    {/* <!--manualupload  Modal --> */}
    <Modal show={manualupload} onHide={ManualUploadClose} className='mt-top'>
    <form onSubmit={formikunderwrite.handleSubmit}>
    <div className='modal-body blue-bg-popup-top'>     
     <Modal.Header closeButton> </Modal.Header>
   <div className="input-group mb-3">     
     <input type="text" className="form-control yellow-bg-input" placeholder="TYPE VRM"  name='vrm'   onChange={formikunderwrite.handleChange}
      onBlur={formikunderwrite.handleBlur}
       value={formikunderwrite.values.vrm} />
   </div>
   {formikunderwrite.errors.vrm ? (
                  <p className='error'>{formikunderwrite.errors.vrm}</p>
                ) : null}
   <div className='form-group'>
     <input type="text"  className='form-control white-input-pop' 
      value={formikunderwrite.values.mileage}
      name='mileage' onChange={formikunderwrite.handleChange}  onBlur={formikunderwrite.handleBlur}
    placeholder="Mileage" />      
    {formikunderwrite.errors.mileage ? (
                  <p className='error'>{formikunderwrite.errors.mileage}</p>
                ) : null} 
   </div>
   <div className='green-btn-pop-large'>
     <button type='submit'>Get Trade Bids</button>
   </div>
 </div>

</form>


  </Modal>
</>
  )
}
