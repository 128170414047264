import React, { useEffect, useState,useRef  } from 'react'
import { useDispatch } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport } from '../../Redux/Action/SellerVehiclesActions';
import { scroller } from 'react-scroll';
import { toast } from 'react-toastify';
import ScrollToBottom, { useScrollToBottom, useSticky } from 'react-scroll-to-bottom';

export default function Conditionreport11() {
    const urlParams = new URLSearchParams(window.location.search);
    const [roof,setroof]=useState("")
    const [leather_data, setleather] = useState('');
    const [condition_report_value,setcondition_report_value]=useState()
    const [loader,setLoader]=useState(1)
 
    const [wheel_upgrade, setwheel_upgrade] = useState('');
    const [heated_seats, setheated_seats] = useState('');
    const [satnav_data, setsatnav] = useState('');
    const [bluetooth_data, setbluetooth] = useState('');
    const [other, setOther] = useState('');
    const titleRef = useRef(null);
    const containerRef = useRef(null);
    const dispatch=useDispatch();
    const navigate=useNavigate()

        useEffect(()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){

        setroof( data?.add_condition_report_candition?.roof)
        setcondition_report_value(data);

        setleather(data?.add_condition_report_candition?.leather)
        setwheel_upgrade(data?.add_condition_report_candition?.wheel_upgrade)
        setheated_seats(data?.add_condition_report_candition?.heated_seats)
        setsatnav(data?.add_condition_report_candition?.satnav)
        setbluetooth(data?.add_condition_report_candition?.bluetooth)
        setOther(data?.add_condition_report_candition?.other)
        
        })
        },[])

 
    const save_data=async()=>{



        if(commentstyle=="block"){
         
            if(other?.length ==undefined){
            toast.warning("Comment is required")
            }else{
                if(roof !="")
                {
    
   
                   setLoader(0)  
   
          dispatch(MyvehiclesConditionreport({
                vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
                roof:roof,
                leather:leather_data,
                wheel_upgrade:wheel_upgrade,
                heated_seats:heated_seats,
                satnav:satnav_data,
                bluetooth:bluetooth_data,
                other:other
            })).then(function(data){
                navigate("/conditionreport12?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
            })
    
    
               }else{
                   navigate("/conditionreport12?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
               }
            }

        }else{
            if(roof !="")
            {


               setLoader(0)  

      dispatch(MyvehiclesConditionreport({
            vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
            roof:roof,
            leather:leather_data,
            wheel_upgrade:wheel_upgrade,
            heated_seats:heated_seats,
            satnav:satnav_data,
            bluetooth:bluetooth_data,
            other:other
        })).then(function(data){
            navigate("/conditionreport12?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
        })


           }else{
               navigate("/conditionreport12?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
           }
        }
            
     }



const [commentstyle,setCommentstyle]=useState("none")




const scrollToBottom = useScrollToBottom();
  const [sticky] = useSticky();


  return (

    <div className='white-bg-cover' >
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'> 
                    
                    <div className='full-height-view'   >
                         {/* Back step*/}               
                         <div className='white-bg-step '>  
                            <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                            <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Cancel 
                            </NavLink>                       
                        </div> 
                        <div className='progress-part-view'> 
                        <div className='sm-blue-text-progress'>Optional Extras</div>
                            <div className='progressbar-c'>
                                <div className='blue-active-progress' style={{width:'66%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                            </div>
                        </div>
                        <div className='step-left-content-flex' >
                        <div className='middle-content-steps' >  
                        <div className='step-progress-heading'>Optional Extras</div>
                        <div className='row mb-4'>
                            <div className='col-md-12 text-center'>
                            <div className='blue-text-steps mb-3'>Please select all optional extras on this vehicle. This could help you to achieve higher bids in the auction.</div>
                           </div>                            
                        </div>
                            <div className='row d-flex align-items-center mb-3'  >
                                <div className='col-md-12' >
                               
                                <div className="custom-checkbox-steps over-height-yo" id='element'  >	
                                <ScrollToBottom>
                                <ul className="list"  > 
                                {/* <!-- Checkbox 1 --> */}
                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option1"
                                    
                                    onChange={(e)=>{

                                                           console.log(e.target.value)    
                                                                setroof(e.target.value)
                                                            }} 
                                                value= {roof=="Sunroof"?"":"Sunroof"}
                                        checked={(roof=="Sunroof")}
                                        
                                    
                                     name=""/>
                                    <label for="option1" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Sunroof</span>
                                    </label>
                                    </li>
                                    {/* <!-- Checkbox 2 --> */}
                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option2" name=""   onChange={(e)=>{
                                        console.log(e.target.value)
                                                                setroof(e.target.value)

                                                            }} 
                                                value={roof=="Panoramic Sunroof"?"":"Panoramic Sunroof"}
                                      
                                        
                                         checked={(roof=="Panoramic Sunroof")}
                                        
                                              />
                                    <label for="option2" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Panoramic Sunroof</span>
                                    </label>
                                    </li>
                                   
                                    {/* <!-- Checkbox 3 --> */}                        
                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option3"  
                                    
                                    checked={wheel_upgrade=="Wheel Upgrade"?true:false}
                
                                    value= {wheel_upgrade=="Wheel Upgrade"?'':"Wheel Upgrade"} 
                                    onChange={(e)=>{
                                        setwheel_upgrade(e.target.value)
                                    }} 
                                    name=""
                                     />




                                     
                                    <label for="option3" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Wheel Upgrade</span>
                                    </label>
                                    </li>

                                    {/* <!-- Checkbox 4 --> */}

                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option4" 
                                    onChange={(e)=>{
                                        setsatnav(e.target.value)
                                    }}
                                        checked={satnav_data=="Satnav"?true:false}
                                        
                                        value= {satnav_data=="Satnav"?'':"Satnav"}
                                     />
                                    <label for="option4" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">SatNav</span>
                                    </label>
                                    </li>
                                    {/* <!-- Checkbox 5 --> */}
                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option5"    onChange={(e)=>{
                                    setleather(e.target.value)
                                }}  
                                    checked={leather_data=="Half Leather"}
                                
                                    
                                    value={leather_data=="Half Leather"?"":"Half Leather"}

                                    />
                                    <label for="option5" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Half - Leather Seats</span>
                                    </label>
                                    </li>

                                   
                                    {/* <!-- Checkbox 6 --> */}

                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option6" 
                                    
                                    onChange={(e)=>{
                                    setleather(e.target.value)
                                }}  
                                    checked={leather_data=="Full Leather"}



                                    value={leather_data=="Full Leather"?"":"Full Leather"}
                                    
                                    
                                    name=""/>
                                    <label for="option6" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Full Leather Seats</span>
                                    </label>
                                    </li>

                                  
                                    {/* <!-- Checkbox 7 --> */}
                                    <li className="list-item">
                                    <input type="checkbox" className="hidden-box top0" id="option7"   
                                    onChange={(e)=>{
                                        setheated_seats(e.target.value)
                                         }}  
                                    checked={heated_seats=="Heated Seats"?true:false}
                                    value= {heated_seats=="Heated Seats"?'':"Heated Seats"}
                                                        
                                                        
                                    
                                     />
                                    <label for="option7" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Heated Seats</span>
                                    </label>
                                    </li>

                                   {/* <!-- Checkbox 8 --> */}      
                                    <li className="list-item" >
                                    <input type="checkbox" className="hidden-box top0" id="option8"
                                    
                                    onChange={(e)=>{
                                        setbluetooth(e.target.value)
                                    }} 
                                checked={bluetooth_data=="Bluetooth"?true:false}
                                value= {bluetooth_data=="Bluetooth"?'':"Bluetooth"}
                                    
                                    
                                     />
                                    <label for="option8" className="check--label">
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Bluetooth</span>
                                    </label>
                                    </li>

                                    <li className="list-item" >
                                    <input type="checkbox" className="hidden-box top0" id="option9"
                                    
                                   

                                    value={commentstyle=="block"?"none":"block"}  onChange={(e)=>{
                            
                                        setCommentstyle(e.target.value)
                                      
                                    }} />
                                    <label for="option9" className="check--label"  >
                                        <span className="check--label-box"></span>
                                        <span className="check--label-text">Other </span>
                                    </label>                                                                    
                                    </li>
                                  
                                     <div className='option-textarea-show'  style={{display:commentstyle}} >
                                   <div className='blue-text-steps mb-2'>Please Specify</div>
                                    <div className='textarea-option-show' >
                                        <textarea placeholder='Type here...' maxLength="1000"  onChange={(e)=>{
                                            setOther(e.target.value)
                                        }} value={other}>{other}</textarea>
                                    </div>
                                    </div>                                
                                     
                                </ul>
                                </ScrollToBottom>
                                </div>
                              
                                </div>                        
                            </div>                             
                        </div>

                        {!sticky && <button onClick={scrollToBottom}>Click me to scroll to bottom</button>}
                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step"  to={"/conditionreport10?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Back</NavLink>
                        </li>
                        <li className="nav-item">
                        {
                            loader==1?( 
                    
                             <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>
                            ):( 
                            
                           <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            )
                        }
                           
                        </li>
                        </ul>
                        </div>    
                    </div> 
                   
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>                      
                        </div>                        
                    </div>
                </div>
            </div>
        <div className="blue-bg-right"></div>
    </div>

  )
}
