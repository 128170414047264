import React from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import FooterHome from '../IncludeHome/FooterHome'
import { NavLink, useNavigate } from 'react-router-dom'

import "react-image-gallery/styles/css/image-gallery.css";
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { GetMyvehiclesConditionreportmyvehicle, UpdateAuctionstatus } from '../../Redux/Action/SellerVehiclesActions'
import moment from 'moment/moment'
import io from "socket.io-client";
import { Modal } from 'react-bootstrap'
import Productheaderdetails from '../Previewdetails/Productheaderdetails'
import Gallery from '../Gallery/Gallery';
import BidLIstShow from '../../BidLIstShow';
import CalculateCountdown from '../CalculateCountdown/CalculateCountdown';
import Countdown from '../CalculateCountdown/Countdown';
export default function Vehiclesdetails() {
  const [previewlistingobj,setPreviewlistingobj]=useState()
  
  const [showendauctionmodal, setEndauctionmodal] = useState(false); 
 
  const urlParams = new URLSearchParams(window.location.search);

  const dispatch=useDispatch();
  const navigate=useNavigate()

  useEffect(()=>{
      dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
       
          
         
          setPreviewlistingobj(data);

   

      })
      },[])
    

     

      const socket =  io(process.env.REACT_APP_SOCKET_URL, {
          autoConnect: true,
        });


        socket.on(`bidend${previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}`,(values)=>{
         
          navigate('/salercongtratesvehilesdetails?id='+(previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm))
          
          })

          socket.on(`bidendauction${previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}`,(values)=>{
         
              navigate('/AuctionendReserNotMet?id='+(previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm))
              
              })

             
          
          
      useEffect(() => {
          
        socket.on("buyerportal", (value) => {
          if(value !=null){

         
          dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
       
          
         
              setPreviewlistingobj(data);
  
       
  
          }) 
      }
        });
      },[dispatch])

    
      const handleCloseautionend = () => {
          
  
          setEndauctionmodal(false)
         
      };




      const callaftercounteroffer=()=>{
        dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
       
          
         
          setPreviewlistingobj(data);

   

      })
      }

  return (
    <>
      <HeaderDashboard />
      <div className='bg-headerwhite-v2 detail-page-subheader'>
        <div className='container container1200'>
              <div className='row d-flex align-items-center'>
                <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 col-8'>
                <div className="page-heading-v2-details">Vehicles Details</div>
                </div>
                <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-4'>
                <div>
                <Countdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} />
                </div> 
                {/* <div className="bid-card-white">
                  <div className='blue-text-bid'>Bidding ends in:</div>
                  <div className='green-text-bid'> <CalculateCountdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} /></div>
                </div> */}
                </div>
              </div>
        </div>
      </div>
      <div className='detail-page-back'>
        <div className='container container1200'>
              <div className='detail-back-part'>
                <div className='row'>
                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
                    {/* Back */}               
                    <div className='white-bg-step '>  
                        <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to my auctions
                        </NavLink>                       
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>

      <div className='slider-img-details-Section'>
      <div className='container container1200'>
      <div className='bor-b-v2'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12 mobile-padding-0'>
            <div className='slider-img-left-part'>
              <Gallery  images_data={previewlistingobj?.add_condition_report_candition} />
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12'>
            <div className='slider-img-right-content'>
         <Productheaderdetails details={previewlistingobj?.add_condition_report_candition} />
            <div className='bor-b-v2'></div>            
              
            <div className='listing-rowSection'>
            <div className='listing-detail-row bor-b-v2'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700 mobile-width15'>Current Bid:</div>

              <div className='listing-colum35'>
                <div className='blue-text-price-lg'>{previewlistingobj?.add_condition_report_candition?.current_bid ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((previewlistingobj?.Bidlist.length==0)?0:parseInt(previewlistingobj?.add_condition_report_candition?.current_bid)).replace(/\.00$/, '')}</div>
              </div>

              <div className='listing-colum40 mobile-width42'>
                <div className='group-element'>
                <div className='listing-red-sm-text'> {((Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))) ==0)?0 : 
                                                Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))))>=previewlistingobj?.add_condition_report_candition?.price_reserve?<span className='biding-end-text-green'>Reserve met <br />£  {(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined) &&  (new Intl.NumberFormat('en-GB')
                                              .format(parseInt(previewlistingobj?.add_condition_report_candition?.price_reserve))
                                              .replace(/\.00$/, ''))}</span>:(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined && <span className='listing-red-sm-text'>Reserve not met <br />£ {(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined) &&  (new Intl.NumberFormat('en-GB')
                                              .format(parseInt(previewlistingobj?.add_condition_report_candition?.price_reserve))
                                              .replace(/\.00$/, ''))}</span>)}
                                              
                                              
                                              
                                              
                                              </div>               
                </div>               
              </div>  
                          
            </div>  

         
            </div>
            <div className='listing-rowSection bor-b-v2'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700 mobile-width15'>Buy it Now:</div>

              <div className='listing-colum35 mobile-width43'>
                <div className='green-text-price-lg'>{new Intl.NumberFormat('en-GB', {
                                              style: 'currency',
                                              currency: 'GBP',
                                            })
                                              .format( previewlistingobj?.add_condition_report_candition?.counteroffer==0    ?   previewlistingobj?.add_condition_report_candition?.price_now:( previewlistingobj?.add_condition_report_candition?.counteroffer?.counter_offer))
                                              .replace(/\.00$/, '')}</div>
              </div>

              <div className='listing-colum40 mobile-width42'>    
              <div className='button-group-part'>
       

                  <NavLink className="bid-white-sm-btn"  to={`/updatereservebuynow?id=`+previewlistingobj?.add_condition_report_candition?.vrm+"&status=1"}>
                                    <button type="button"   className='fill-white-btn-bid'>   Update Reserve & Buy it Now</button>
                                    </NavLink>
                </div>                        
              </div> 
                          
            </div>           
            </div>
            <div className='listing-rowSection bor-b-v2'>
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700 mobile-width15'>Bidding Ends:</div>

              <div className='listing-colum40 mobile-width42'>
              <div className='listing-sm-text-blue'>{moment(previewlistingobj?.add_condition_report_candition?.offer_expire).format('DD MMMM  YYYY, h:mm a')}</div>
              </div>
              <div className='listing-colum35 mobile-width43'>
                <div className='group-element '>
                <div className='listing-sm-text-blue time-sec-part'>(<CalculateCountdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} />)</div>
                </div>               
              </div>                           
            </div> 
            <div className='listing-detail-row'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700 mobile-width15'>Location:</div>

              <div className='listing-colum75'>
              <div className='listing-sm-text-blue'>Mansfield, North Yorkshire</div>
              </div>                                      
            </div>           
            </div>           
            <div className='listing-rowSection'>
            <div className='listing-detail-row align-start'>
              <div className='listing-colum25 listing-sm-text-blue pl-0 font-width700 mobile-width15'>YoTrader Says:</div>


              <div className='listing-colum75'>



{
    
    
    ((Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))) ==0)?0 : 
    ((Math.max(parseInt( previewlistingobj?.Bidlist.map(o => o.yotrader_value))) ))>=previewlistingobj?.add_condition_report_candition?.price_reserve)?<span className='biding-end-text-green'>You already hit your reserve! </span>:(previewlistingobj?.add_condition_report_candition?.price_reserve !=undefined && (<>  <div className='listing-sm-text mb-2'>You currently have
 {(previewlistingobj?.Bidlist?.length<=1)?" "+previewlistingobj?.Bidlist.length+" Bid ":" "+previewlistingobj?.Bidlist.length+" Bids "}
 
 
   from {(previewlistingobj?.Bidlist?.length<=1)?+previewlistingobj?.Bidlist.length+" dealer":+previewlistingobj?.Bidlist.length+" Bids dealer"} </div>
<div className='listing-sm-text'>Your highest bid is <div className='green-text-color inline-block'>  {previewlistingobj?.Bidlist?.length>0 && 

      (  previewlistingobj?.add_condition_report_candition?.price_reserve==undefined? new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })
          .format(parseInt((previewlistingobj?.add_condition_report_candition?.price_now) - ( Math.max.apply( null, Object.values( previewlistingobj?.Bidlist ).map( c => c.yotrader_value ) ))))
          .replace(/\.00$/, ''):




(   new Intl.NumberFormat('en-GB', {
          style: 'currency',
          currency: 'GBP',
        })
          .format(parseInt((previewlistingobj?.add_condition_report_candition?.price_reserve) -  (Math.max.apply( null, Object.values( previewlistingobj?.Bidlist ).map( c => c.yotrader_value ) ))))
          .replace(/\.00$/, ''))


)


   }</div> away from your { previewlistingobj?.add_condition_report_candition?.price_reserve==undefined?"buy it now price":"reserve price"}!</div> </>))}



</div>      


            </div>           
            </div>

            </div>
          </div>
          
        </div>
      </div>        
      </div>
      </div>

      <div className='bottom-content-details'>
        <div className='container container1200'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
            <div className="listing-sec-heading-underline">Bid History ({(previewlistingobj?.Bidlist?.length<=1)?previewlistingobj?.Bidlist?.length+" Bid":previewlistingobj?.Bidlist?.length+" Bids"})</div>  
            </div>
          </div>
        <BidLIstShow   Bidlist={previewlistingobj?.Bidlist}   offer_expire={ previewlistingobj?.add_condition_report_candition?.offer_expire}  callaftercounteroffer={callaftercounteroffer} />
          
        </div>
      </div>
                                         
      <div className='fotter-calltoaction mobile-view-footer'>
       <div className='container container1200'>
       <div className='fixed-bottom-fotter '>
          <div className="footer-fix-bid-text">
          <div>
          <Countdown endDate={previewlistingobj?.add_condition_report_candition?.offer_expire} navigate={navigate} />
            </div> 
        </div>
       </div>
      </div>  
      </div> 
     <FooterHome />
    </>
  )
}
