import React from 'react'
import { NavLink } from 'react-router-dom'

export default function TcgbNoreturns() {
    const urlParams = new URLSearchParams(window.location.search);
  return (
    <div className='white-bg-cover'>
<div className='container container1200'>

<div className='row'>
    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
    <div className='full-height-view'>
    {/* Back step*/}               
    <div className='white-bg-step '>  
        <NavLink to={("/all-vehicles")} className='back-step-arrow'>
        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to All Stock
        </NavLink>                       
        </div> 
        <div className='progress-part-view'>
        <div className='sm-blue-text-progress'>Fun label for steps remaining</div>
            <div className='progressbar-c'>
                <div className='blue-active-progress' style={{width:'24%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
            </div>
        </div>
        <div className='step-left-content-flex'>
        <div className='middle-content-steps'>        
        <div className='step-progress-heading'>More Information Needed</div>
        <div className='row mb-4'>
            <div className='col-md-12 text-center'>
            <div className='blue-text-steps mb-3'>We need some more information from you in order to send your vehicle to auction.<br />
            Press Next to supply more information to list your car in front of over 1500 active bidders!</div>
           </div>                            
        </div>
           

        </div>

        <ul className="fotter-steps">
        <li className="nav-item">
            <NavLink className="nav-link white-btn-step"  to={"/conditionreport2?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))} style={{display
            :"none"}}>Back</NavLink>
        </li>
        <li className="nav-item">
            <NavLink className="nav-link blue-btn-step "    to={"/conditionreport4?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id'))}>Next</NavLink>
        </li>
        </ul>
        </div>
    </div>
     

      
    </div>

    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
        <div className='bg-blue-steps'>
        <div className='logo-right-top'>
            <a href='#'>
                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
            </a>
        </div>                      
        </div>                        
    </div>
</div>

</div> 
    <div className="blue-bg-right"></div>
</div>
  )
}
