import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
export default function ForgotPassword() {
  return (
    <div className='container container1200'>
    <div className='row d-flex align-items-center mt-5'>
    <div className='col-sm-12 col-md-5 col-lg-5 col-xl-5 col-xxl-5 col-12 mx-auto'>
        <div className='signin-form-banner'>
        <div className='sign_in'>
                <form className='w-100'>
                  <div className='card box-shadow-none'>
                    <div className='sign-card'>
                      <div className='title_signin'>
                        <h1>Forgot Password</h1>
                        <p className='g-color fw-regular'>
                        Enter your email to change your password.
                      </p>
                        <div className='form-group mb-3 mt-4'>
                          <label for=''>Email</label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Email Credentials'
                          />
                        </div>                     
                        
                        <div className='btn_submit mt-4'>
                          <button className='black-lg-btn-primary-home'>
                            Submit
                          </button>
                        </div>                       
                      </div>
                    </div>
                  </div>
                </form>
              </div>
        </div>
      </div>
     
    </div>
</div>  
  )
}
