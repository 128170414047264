
import API from "../Service/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DEALER_VEHICLES, DMS_PROVIDER, DRAFT_DATA, MY_AUCTION_DATA, MY_SALE_LIST } from "../Constant/Constant";


export const VehicleslistsellerAction = (object) => async (dispatch) => {
    try {
      var dealer_detail_data = await API.post(`/Dealervehicals_list`,object);
  
      if (dealer_detail_data.data.status == 200) {
        dispatch({
          type: DEALER_VEHICLES,
          payload: dealer_detail_data.data.data,
        });
      } else {
        toast.warning(dealer_detail_data.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };

  export const DmsProviderAction = () => async (dispatch) => {
    try {
      var dms = await API.get('/dms_provider');
  
      if (dms.data.status == 200) {
        dispatch({
          type: DMS_PROVIDER,
          payload: dms.data.data,
        });
      } else {
        toast.warning(dms.data.data.message);
      }
    } catch (error) {
      // toast.warning(error.message);
    }
  };


  export const Getvaluationvehicleunderwritevrm=(data_for_evaluation)=>async(dispatch)=>{
    try {
      var Getevaluation = await API.post('/Getvaluationvehicleunderwrite',data_for_evaluation);
  
  
      if(Getevaluation.data.status == 200){
  
       return Getevaluation.data.data;
      }else{
        
        toast.warning(Getevaluation.data.message);
      }
  
    } catch (error) {
      // toast.warning(error.message);
    }
  }

  export const Getvaluationvehicleunderwrite_data=(data_for_evaluation)=>async(dispatch)=>{
    try {
      var Getevaluation = await API.post('/Getvaluationvehicleunderwrite',data_for_evaluation);
  
      if(Getevaluation.data.status == 200){
  
  
          ///return Getevaluation.data.data;
      }else{
        toast.warning(Getevaluation.data.data);
      }
  
    } catch (error) {
      // toast.warning(error.message);
    }
  }

  export const deletenewvaluation=(id,vrm)=>async(dispatch)=>{
    try {
        const deletedata=  await API.get(`/deletenewvaluation?id=${id}&vrm=${vrm}`);
  
        if(deletedata?.data?.status==200){
          return deletedata?.data?.status
        //    toast.success(deletedata.data.message)
        }else{
        //    toast.error(deletedata.data.message)
        }
    } catch (error) {
        // toast.error(error.message)
    }
}



export const MannualValuation=(data_obj)=>async(dispatch)=>{
  try {
    var new_evaluations = await API.post(`/MannualValuation`,data_obj);
    if(new_evaluations?.data?.status == 200){
      
      dispatch({
        type: MY_AUCTION_DATA,
        payload: new_evaluations.data.data,
      });
    }else{
      toast.warning(new_evaluations.data);
    }

  } catch (error) {
    // toast.warning(error.message);
  }
}


export const MannualuploadAction=(pageno)=>async(dispatch)=>{
  try {
    var Mannualupload = await API.get(`/Mannualupload?page_no=${pageno}`);
    if(Mannualupload?.data?.status == 200){
      
      dispatch({
        type:  DRAFT_DATA,
        payload: Mannualupload.data.data,
      });

      return Mannualupload.data.data
    }else{
      toast.warning(Mannualupload.data);
    }

  } catch (error) {
    // toast.warning(error.message);
  }
}



export const MySaleAction=(pageno)=>async(dispatch)=>{
  try {
      const mysalelist=     await API.get(`/MySale?page_no=${pageno}`);
 if(mysalelist.data.status==200){


      dispatch({
          type:MY_SALE_LIST,
          payload:mysalelist.data.data
          })


 }
 
 
  } catch (error) {
      // toast.error(error.message)
  }
}


export const past30daysmysaleActon=(pageno)=>async(dispatch)=>{
  try {
      const mysalelist=     await API.get(`/past30daysmysale?page_no=${pageno}`);
          if(mysalelist.data.status==200){


                dispatch({
                    type:MY_SALE_LIST,
                    payload:mysalelist.data.data
                    })


          }else{
            return "dfdfdfdf"
          }
 
 
  } catch (error) {
      // toast.error(error.message)
  }
}





export const GetMyvehiclesConditionreportmyvehicle=(vehicle_id,value)=>async(dispatch)=>{
  try {

        if(value==1){
          const getconditionreport_obj= await API.get(`/buyerGetMyvehiclesConditionreport?vehicle_id=${vehicle_id}`);
          if (getconditionreport_obj?.data?.status == 200) {
   
   
        
            return getconditionreport_obj.data.data
    
     } else {
       // toast.warning(getconditionreport_obj.data.message);
     }
        }else{
          const getconditionreport_obj= await API.get(`/GetMyvehiclesConditionreport?vehicle_id=${vehicle_id}`);
          if (getconditionreport_obj?.data?.status == 200) {
   
   
        
            return getconditionreport_obj.data.data
    
     } else {
       // toast.warning(getconditionreport_obj.data.message);
     }
        }
      
   

   
    
   
  } catch (error) {
      // toast.warning(error.message);
  }
}


export const MyvehiclesConditionreport=(condition_report)=>async(dispatch)=>{
  try {

    console.log(condition_report)


      var Condtionreportnewvaluation = await API.post('/MyvehiclesConditionreport', condition_report);
      
     

    
      if (Condtionreportnewvaluation?.data?.status == 200) {
       
          return Condtionreportnewvaluation.data
        
        
          //toast.success(Condtionreportnewvaluation.data.message);
      } else {
        toast.warning(Condtionreportnewvaluation.data.message);
      }

  } catch (error) {
      // toast.warning(error.message);
  }
}


export const VehiclesDetailsAction=(id)=>async(dispatch)=>{
  try {
    var vehiclesdetails = await API.get(`/VehiclesDetails?id=${id}`);

    if (vehiclesdetails?.data?.status == 200) {
      return vehiclesdetails.data.data;
    } else {
      // toast.warning(vehiclesdetails.data.message);
    }
  } catch (error) {
    // toast.warning(error.message);
  }
}

export const BuyNowAction=(buynowinfo)=>async(dispatch)=>{
  try {
 const saveinfo=     await API.post('/BuyNow',buynowinfo);
 if(saveinfo?.data?.status==200){
  // toast.success(saveinfo?.data?.message)

 }

  } catch (error) {
      // toast.error(error.message)
  }
}


export const CounterofferAction=(obj_data)=>async(dispatch)=>{
  try {
      const statusupdated=  await API.post('/CounterofferSave',obj_data);
      if(statusupdated){

      
          toast.success(statusupdated.data.message)
      }else{
               toast.error(statusupdated.data.message)
      }
  } catch (error) {
      
  }
}


export const UpdateAuctionstatus=(obj_data)=>async(dispatch)=>{
  try {
      
   const statusupdated=  await API.post('/UpdateAuctionstatus',obj_data);

   if(statusupdated?.data?.status==200){
     
    
   }else{
      toast.error(statusupdated.data.message)
   }
  } catch (error) {
      // toast.error(error.message)
  }
}