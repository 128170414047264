import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';

export default function Countdown(props) {
    const calculateTimeLeft = () => {
  
        const difference = new Date(props?.endDate) - new Date();
        let timeLeft = {};
    
        if (difference > 0) {
          timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
          };
        }
    
        
      
          return timeLeft;
        
      };
    
    
      const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
      useEffect(() => {
        const timer = setTimeout(() => {
          
            setTimeLeft(calculateTimeLeft());
          
          
        }, 1000);
    
        return () => {
          
         
          clearTimeout(timer)
        
        
        };
      },[timeLeft]);
      if(Math.ceil(timeLeft?.days)>1){
        var color ="blue-box-square"

      }else if(Math.ceil(timeLeft?.hours/24)==1 && timeLeft?.hours>0){
        
        var color ="orange-box-square"

      }else if(Math.ceil(timeLeft?.hours/24)==0 && timeLeft?.hours==0 &&  timeLeft?.minutes<10){
        var color ="red-box-square"
      }else if(Math.ceil(timeLeft?.hours/24)==0 && timeLeft?.hours==0 &&  timeLeft?.minutes==0){
        var color ="red-box-square"
      }else{
        var color ="red-box-square"
      }


     
  return (
    <div className={color}>
    <ul className='time-part-ul'>
    <li>
      <p>{Math.ceil(timeLeft?.days)>1?Math.ceil(timeLeft?.days):0}</p>
      <span>Days</span>
    </li>
    <li>:</li>
    <li>
      <p>{timeLeft?.hours>0?timeLeft?.hours:0}</p>
      <span>Hours</span>
    </li>
    <li>:</li>
    <li>
      <p>{timeLeft?.minutes>0?timeLeft?.minutes:0}</p>
      <span>Mins</span>
    </li>
    <li>:</li>
    <li>
      <p>{timeLeft?.seconds>0?timeLeft?.seconds:0}</p>
      <span>Secs</span>
    </li>
  </ul>
  </div>
  )
}
