import React from 'react'
import HeaderDashboard from '../IncludeDashboard/HeaderDashboard'
import FooterHome from '../IncludeHome/FooterHome'
import { NavLink, useNavigate } from 'react-router-dom'

import "react-image-gallery/styles/css/image-gallery.css";
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { GetMyvehiclesConditionreportmyvehicle, UpdateAuctionstatus, deletenewvaluation } from '../../Redux/Action/SellerVehiclesActions'
import moment from 'moment/moment'
import io from "socket.io-client";
import { Modal } from 'react-bootstrap'
import Productheaderdetails from '../Previewdetails/Productheaderdetails'
import Gallery from '../Gallery/Gallery';
import BidLIstShow from '../../BidLIstShow';
import CalculateCountdown from '../CalculateCountdown/CalculateCountdown';
export default function AuctionendReserNotMet() {
  const [previewlistingobj,setPreviewlistingobj]=useState()
  
  const [showendauctionmodal, setEndauctionmodal] = useState(false); 
 
  const urlParams = new URLSearchParams(window.location.search);

  const dispatch=useDispatch();
  const navigate=useNavigate()

  useEffect(()=>{
      dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
       
          
         
          setPreviewlistingobj(data);

   

      })
      },[])
    

     

      const socket =  io(process.env.REACT_APP_SOCKET_URL, {
          autoConnect: true,
        });


        socket.on(`bidend${previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}`,(values)=>{
         
          navigate('/salercongtratesvehilesdetails?id='+(previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm))
          
          })

          socket.on(`bidendauction${previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm}`,(values)=>{
         
              navigate('/AuctionendReserNotMet?id='+(previewlistingobj?.add_condition_report_candition?.vehicle_id==undefined?previewlistingobj?.add_condition_report_candition?.vrm:previewlistingobj?.add_condition_report_candition?.vehicle_id?.vrm))
              
              })


          
          
      useEffect(() => {
          
        socket.on("buyerportal", (value) => {
          if(value !=null){

         
          dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
       
          
         
              setPreviewlistingobj(data);
  
       
  
          }) 
      }
        });
      },[dispatch])

    
      const handleCloseautionend = () => {
          
  
          setEndauctionmodal(false)
         
      };

  return (
    <>
      <HeaderDashboard />
      <div className='bg-headerwhite-v2 detail-page-subheader'>
        <div className='container container1200'>
              <div className='row d-flex align-items-center'>
                <div className='col-sm-8 col-md-8 col-lg-8 col-xl-8 col-xxl-8 col-8'>
                <div className="page-heading-v2-details">Reserve Not met</div>
                </div>
                <div className='col-sm-4 col-md-4 col-lg-4 col-xl-4 col-xxl-4 col-4'>
                <div className="header-right-btn-part">
                <button type="text" className='blue-btn-header'  onClick={()=>{
           

           dispatch(deletenewvaluation( previewlistingobj?.add_condition_report_candition?._id, previewlistingobj?.add_condition_report_candition?.vrm))
           navigate("/conditionreport1?id="+previewlistingobj?.add_condition_report_candition?.vrm)
         }}>Re-List</button>
                </div>
                </div>           
              </div>
        </div>
      </div>
      <div className='detail-page-back'>
        <div className='container container1200'>
              <div className='detail-back-part'>
                <div className='row'>
                  <div className='col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 col-12'>
                    {/* Back */}               
                    <div className='white-bg-step '>  
                        <NavLink to={"/all-vehicles"} className='back-step-arrow'>
                        <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/>Back to All Vehicles
                        </NavLink>                       
                    </div>
                  </div>
                </div>
              </div>
        </div>
      </div>

      <div className='slider-img-details-Section'>
      <div className='container container1200'>
      <div className='bor-b-v2'>
        <div className='row'>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12 mobile-padding-0'>
            <div className='slider-img-left-part'>
              <Gallery  images_data={previewlistingobj?.add_condition_report_candition} />
            </div>
          </div>
          <div className='col-sm-12 col-md-12 col-lg-6 col-xl-6 col-xxl-6 col-12'>
            <div className='slider-img-right-content'>
         <Productheaderdetails details={previewlistingobj?.add_condition_report_candition} />
            <div className='bor-b-v2'></div>            
              
            <div className='listing-rowSection'>
            <div className='listing-detail-row bor-b-v2'>
              <div className='listing-colum25 mobile30 listing-sm-text-blue pl-0 font-width700'>Highest Bid:</div>

              <div className='listing-colum35'>
                <div className='blue-text-price-lg'>{previewlistingobj?.add_condition_report_candition?.current_bid ==0?"£0":new Intl.NumberFormat('en-GB', {   style: 'currency',  currency: 'GBP',}).format((previewlistingobj?.Bidlist.length==0)?0:parseInt(previewlistingobj?.add_condition_report_candition?.current_bid)).replace(/\.00$/, '')}</div>
              </div>                          
            </div>  

           
            </div>
            <div className='listing-rowSection bor-b-v2'>
            <div className='listing-detail-row'>
              <div className='listing-colum100 listing-sm-text-blue pl-0 '>Unfortunately your vehicle didn’t sell this time..</div>
         
                          
            </div>           
            </div>
            <div className='listing-rowSection bor-b-v2'>
            <div className='listing-detail-row'>
              <div className='listing-colum100 listing-sm-text-blue pl-0'>But don’t worry! You can still accept your best bid or any of the offers from our trusted partners within 24 hours after the auction end.</div>
                      
            </div> 
            <div className='listing-detail-row mt-2'>
              <div className='listing-colum25 mobile30 listing-sm-text-blue pl-0 font-width700'>Offers Expire:</div>

              <div className='listing-colum35'>
              <div className='listing-sm-text-blue'>{moment(previewlistingobj?.add_condition_report_candition?.offer_expire).format('DD MMMM  YYYY, h:mm a')}</div>
              </div>                                      
            </div>           
            </div>  
            </div>
          </div>
          
        </div>
      </div>        
      </div>
      </div>

      <div className='bottom-content-details'>
        <div className='container container1200'>
          <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 col-12'>
            <div className="listing-sec-heading-underline">Bid History ({(previewlistingobj?.Bidlist?.length<=1)?previewlistingobj?.Bidlist?.length+" Bid":previewlistingobj?.Bidlist?.length+" Bids"})</div>  
            </div>
          </div>
        <BidLIstShow   Bidlist={previewlistingobj?.Bidlist}   offer_expire={ previewlistingobj?.add_condition_report_candition?.offer_expire}  />
          
        </div>
      </div>
      <div className='table-view-card'>
      


      </div>                                        
    
     <FooterHome />
    </>
  )
}
