import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { useDispatch } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { toast } from 'react-toastify'
import { GetMyvehiclesConditionreportmyvehicle, MyvehiclesConditionreport, VehiclesDetailsAction, deletenewvaluation } from '../../Redux/Action/SellerVehiclesActions'

export default function Conditionreport2() {
   const [optionalfilvalue,Setoptionalfil]=useState("readOnly")
   const [optionalprice,setoptionalprice]=useState(0)
   const [retailprice,setRetailprice]=useState(0)
   const [price,setprice]=useState(0)
   const [loader,setLoader]=useState(1)
   const [buttoncolor,setButtoncolor]=useState(0)
   const urlParams = new URLSearchParams(window.location.search);
   const [condition_report_value,setcondition_report_value]=useState()
   const dispatch=useDispatch();
   const navigate=useNavigate()

   useEffect(()=>{
            dispatch(GetMyvehiclesConditionreportmyvehicle((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
                setprice( data?.add_condition_report_candition?.price_now==undefined?0:data?.add_condition_report_candition?.price_now)
                setoptionalprice( data?.add_condition_report_candition?.price_reserve==undefined?0:data?.add_condition_report_candition?.price_reserve)
                setcondition_report_value(data);


                
        
               
            })


            
   },[])


   useEffect(()=>{
    dispatch(VehiclesDetailsAction((urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))).then(function(data){
        setRetailprice(data)

       
    
    })


    
},[])




   const save_data=async()=>{
    console.log(price)
   
    
    if(price !=0 &&price !=undefined){
        setButtoncolor(1)

      

        if(( retailprice >0 && (parseInt(price))<=(retailprice-500)) ){
            callapi()
        }else if(retailprice==0||retailprice==undefined){
            callapi()
        
        }else{
            toast.error("Buy it Now Price cannot be higher than Retail Price") 
        }
        
      
  

    }else{
        setButtoncolor(1)
        toast.error("The Buy it Now Price is required") 
    }
   }


  

   const deletenewvaluat=(id_newvaluation,vehicle_vrm)=>{

    dispatch(deletenewvaluation(id_newvaluation,vehicle_vrm)).then(function(){
        navigate('/all-vehicles')
    })
  
   }


   function callapi(){
   
    if(optionalprice <= price){

        if(optionalprice>0){

     
          
        setLoader(0)
         dispatch(MyvehiclesConditionreport({
        vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),
    
        price_now:price,
        type:(urlParams.get('manual')==null?0:1),

        price_reserve:optionalprice })).then(function(data){
   

        if(data.data !="There has been an error when trying to lookup the vehicle registration provided. The Registration is not known to the DVLA"){
          
            if(urlParams.get('status')==null){

          
                navigate("/conditionreport3?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
            }else{
                navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
            }




          }else{
            if(urlParams.get('status')==null){

          
            navigate("/tcgbnoreturn?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
        }else{
            navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
        }

          } })
    }else{
    setLoader(0)
    dispatch(MyvehiclesConditionreport({
   vehicle_id:(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')),

   price_now:price,
   type:(urlParams.get('manual')==null?0:1),
  
        })).then(function(data){


   if(data.data !="There has been an error when trying to lookup the vehicle registration provided. The Registration is not known to the DVLA"){
     
       if(urlParams.get('status')==null){

     
           navigate("/conditionreport3?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
       }else{
           navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
       }




     }else{
       if(urlParams.get('status')==null){

     
       navigate("/tcgbnoreturn?id="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
   }else{
       navigate("/Vehiclesdetails?vrm="+(urlParams.get('id')==null?urlParams.get('vrm'): urlParams.get('id')))
   }

     }



  
})
}
    }else{
        setLoader(1)
        toast.warning("Reserve Price cannot be higher than the Buy it Now Price")
        
         }
   }
  return (
  
    <div className='white-bg-cover'>
    <ReactTooltip
        anchorId="pricebuynow"
        effect="solid"
        place="top"
      
        content="A Maximum Buy It Now price can not be higher than your retail price - £500."
      />
            <div className='container container1200'>
                <div className='row'>
                    <div className='col-sm-12 col-md-9 col-lg-9 col-xl-9 col-xxl-9 col-12'>
                    <div className='full-height-view'>
                     {/* Back step*/}               
                    <div className='white-bg-step '>  
                    <NavLink to="" onClick={()=>{
                            deletenewvaluat(condition_report_value?.add_condition_report_candition?._id,condition_report_value?.add_condition_report_candition?.vehicle_id?.vrm)}} className='back-step-arrow'>
                    <img src={require('../asset_user/images/mobile-arrow-back.svg').default} alt='back' className='arrow-back-icon'/> Cancel Auction Setup
                    </NavLink>  

                    </div>         
                     <div className='progress-part-view'>
                        <div className='sm-blue-text-progress'>Auction Pricing</div>
                        <div className='progressbar-c'>
                            <div className='blue-active-progress' style={{width:'12%', height:'5px', background:'#11190C',borderRadius:'0px'}}></div>
                        </div>
                    </div>                 
                        
                        <div className='step-left-content-flex'>
                        <div className='middle-content-steps'>
                        <div className='step-progress-heading'>Auction Pricing</div>
                        <div className='row d-flex align-items-center mb-4'>
                            <div className='col-md-9'>
                            <div className='sub-heading-step'>Set a Buy It Now Price *</div>
                            <div className='step-grey-text'>
                            Setting a Buy it Now price will allow buyers to end the auction early and purchase your vehicle immediately. A Buy It Now price can not be higher than your vehicles retail price. 
                            </div>
                            </div>
                            <div className='col-md-3'>                            
                                <div className='input-field-part'> 
                                    <CurrencyInput                            
                            className='form-control input-custom-v2'
                          intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                            onChange={(e)=>{ 
                                setprice(  Number(e.target.value.replace(/[^0-9\.-]+/g,"")))
                                 }}     placeholder={   price ==(0||undefined)?"£ Type here" :new Intl.NumberFormat('en-GB',
                                 {   style: 'currency',  currency: 'GBP',}).format(  price ==0?0 :price).replace(/\.00$/, '')}   />
                                    
                                </div>


                                {
                                    (urlParams.get('manual')==null?        <div className='label-step-input'>Maximum: {new Intl.NumberFormat('en-GB',
                                 {   style: 'currency',  currency: 'GBP',}).format( ( retailprice ==0 ||retailprice==undefined)?0 :(retailprice-500)).replace(/\.00$/, '')}  <div className='info-pop'>
                                    <img src={require("../asset_user/images/information.svg").default} 
                                    
                                    id="pricebuynow"
                                    
                                    
                                     />
                                </div> </div>:"")
                                }
                               
                        
                            </div>
                        </div>
                        <div className='row d-flex align-items-center mb-4'>
                            <div className='col-md-9'>
                            <div className='sub-heading-step'>Set a reserve price (Optional)</div>
                            <div className='step-grey-text'>
                            Setting a reserve will ensure that your vehicle will only sell if the highest bid exceeds the reserve price. A reserve price can not be higher than your  buy it now price.
                            </div>
                            </div>
                            <div className='col-md-3'>
                            <div className='toogle-switch-step'>
                            <div className="check-box">
                                <input type="checkbox"  onChange={()=>{
                                    Setoptionalfil(optionalfilvalue=="readOnly"?"":"readOnly",setoptionalprice(""))
                                }}  />
                            </div>
                            </div>
                                <div className='input-field-part '>



                                    <CurrencyInput
                            
                            className='form-control input-custom-v2'
                          intlConfig={{ locale: 'en-US', currency: 'GBP' }}
                            onChange={(e)=>{ 
                            setoptionalprice(  Number(e.target.value.replace(/[^0-9\.-]+/g,"")))
                        }}

                        value={optionalprice}

                        placeholder={   condition_report_value?.add_condition_report_candition?.price_reserve ==(0||undefined)?"£ Type here" :new Intl.NumberFormat('en-GB',
                                 {   style: 'currency',  currency: 'GBP',}).format(  condition_report_value?.add_condition_report_candition?.price_reserve ==0?0 : condition_report_value?.add_condition_report_candition?.price_reserve).replace(/\.00$/, '')}
              
                            readOnly={optionalfilvalue=="readOnly"?true:false}
                                   />
                                </div>
                            </div>
                        </div>
                        
                           
                        </div>

                        <ul className="fotter-steps">
                        <li className="nav-item">
                            <NavLink className="nav-link white-btn-step invisible" to="/conditionreport3">Next</NavLink>
                        </li>
                        <li className="nav-item">
                        {
                            loader==1?( 
                                
                               buttoncolor==0?
                           
                             <button className="nav-link blue-btn-step "  onClick={save_data}  >   Next  </button>: <button className="nav-link red-btn-step "  onClick={save_data}  >   Next  </button>

                            ):( 
                            
                           <button className="nav-link blue-btn-step "  onClick={save_data}  >  <i className='fa fa-spinner fa-spin '></i>  Next  </button>
                            
                            
                            
                            
                            )
                        }
                           
                        </li>
                        </ul>
                        </div>
                    </div> 
                    </div>
                    <div className='col-sm-12 col-lg-3 col-md-3 col-xl-3 col col-12'>                    
                        <div className='bg-blue-steps'>
                        <div className='logo-right-top'>
                            <a href='#'>
                                <img src={require('../asset_user/images/logo1.svg').default} alt='yotrader-logo' className='logo_main' />
                            </a>
                        </div>
                        </div>                        
                    </div>
                </div>
            </div>  
        <div className="blue-bg-right"></div>
    </div>
  
  )
}
